import React, { useContext } from 'react';

import styles from './DeleteSequenceItemDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  onConfirm: () => void;
}

const DeleteSequenceItemDrawer: React.FC<Props> = (props) => {
  const { onConfirm } = props;
  const { closeDrawer } = useContext(DrawerContext);

  const handleCancelButtonClick = () => {
    closeDrawer('sequence-delete-item');
  };

  const handleButtonClick = () => {
    onConfirm();
    closeDrawer('sequence-delete-item');
  };

  return (
    <Drawer removeWhenClosed className={styles.drawer} rootKey="sequence-delete-item">
      <div className={styles.contentRoot}>
        <div>
          <TextPreset
            className={styles.titleTextPreset}
            header={<Text type="d2" msg="invite_outreach_creators.sequence_delete.title" />}
          />
          <Text
            type="md"
            className={styles.subtitleText}
            msg="invite_outreach_creators.sequence_delete.subtitle"
          />
        </div>
        <div className={styles.trashIcon} />
        <ButtonPreset className={styles.submitButtonWrapper}>
          <Button
            fluid
            color="white"
            dataTest="button:deleteSequenceItemCancelButton"
            className={styles.submitButton}
            onClick={handleCancelButtonClick}
            msg="invite_outreach_creators.sequence_delete.cancel_button"
          />
          <Button
            fluid
            color="black"
            icon="Trash-delele"
            className={styles.submitButton}
            onClick={handleButtonClick}
            msg="invite_outreach_creators.sequence_delete.submit_button"
          />
        </ButtonPreset>
      </div>
    </Drawer>
  );
};

export default DeleteSequenceItemDrawer;
