export const FIRST_VIDEO = 100;
export const NEXT_VIDEOS = 80;
export const FIRST_PHOTO = 50;
export const NEXT_PHOTOS = 30;

export const SPECIAL_CATEGORIES = [
  'Gaming & Tech',
  'Business & Finance',
  'Travel',
  'Outdoors & Nature',
];

export type PriceDataType = { min: number; max: number; minValue: number; maxValue?: number }[];

export const INFLUENCER_IG_POST_FOLLOWERS: PriceDataType = [
  {
    min: 125,
    max: 300,
    minValue: 0,
    maxValue: 10000,
  },
  {
    min: 300,
    max: 500,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 500,
    max: 1100,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 1100,
    max: 5000,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 5000,
    max: 5000,
    minValue: 500000,
  },
];

export const INFLUENCER_IG_STORIES_FOLLOWERS: PriceDataType = [
  {
    min: 63,
    max: 150,
    minValue: 0,
    maxValue: 10000,
  },
  {
    min: 150,
    max: 250,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 250,
    max: 550,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 550,
    max: 2500,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 2500,
    max: 2500,
    minValue: 500000,
  },
];

export const AFFILIATE_IG_POST_FOLLOWERS: PriceDataType = [
  {
    min: 60,
    max: 150,
    minValue: 0,
    maxValue: 10000,
  },
  {
    min: 150,
    max: 250,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 250,
    max: 550,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 550,
    max: 2500,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 2500,
    max: 2500,
    minValue: 500000,
  },
];

export const AFFILIATE_IG_STORIES_FOLLOWERS: PriceDataType = [
  {
    min: 30,
    max: 75,
    minValue: 0,
    maxValue: 10000,
  },
  {
    min: 75,
    max: 125,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 125,
    max: 275,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 275,
    max: 1250,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 1250,
    max: 1250,
    minValue: 500000,
  },
];

export const INFLUENCER_TIKTOK_POST_FOLLOWERS: PriceDataType = [
  {
    min: 125,
    max: 325,
    minValue: 0,
    maxValue: 10000,
  },
  {
    min: 325,
    max: 600,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 600,
    max: 1100,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 1100,
    max: 5000,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 5000,
    max: 5000,
    minValue: 500000,
  },
];

export const INFLUENCER_TIKTOK_POST_VIEWS: PriceDataType = [
  {
    min: 125,
    max: 325,
    minValue: 0,
    maxValue: 1000,
  },
  {
    min: 325,
    max: 600,
    minValue: 1000,
    maxValue: 5000,
  },
  {
    min: 600,
    max: 1100,
    minValue: 5000,
    maxValue: 10000,
  },
  {
    min: 1100,
    max: 5000,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 5000,
    max: 5000,
    minValue: 50000,
  },
];

export const AFFILIATE_TIKTOK_POST_FOLLOWERS: PriceDataType = [
  {
    min: 60,
    max: 160,
    minValue: 0,
    maxValue: 10000,
  },
  {
    min: 160,
    max: 300,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 300,
    max: 550,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 550,
    max: 2500,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 2500,
    max: 2500,
    minValue: 500000,
  },
];

export const AFFILIATE_TIKTOK_POST_VIEWS: PriceDataType = [
  {
    min: 60,
    max: 160,
    minValue: 0,
    maxValue: 1000,
  },
  {
    min: 160,
    max: 300,
    minValue: 1000,
    maxValue: 5000,
  },
  {
    min: 300,
    max: 550,
    minValue: 5000,
    maxValue: 10000,
  },
  {
    min: 550,
    max: 2500,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 2500,
    max: 2500,
    minValue: 50000,
  },
];

export const TIKTOK_SHOP_POST_FOLLOWERS: PriceDataType = [
  {
    min: 60,
    max: 160,
    minValue: 0,
    maxValue: 10000,
  },
  {
    min: 160,
    max: 300,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 300,
    max: 550,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 550,
    max: 2500,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 2500,
    max: 2500,
    minValue: 500000,
  },
];

export const TIKTOK_SHOP_POST_VIEWS: PriceDataType = [
  {
    min: 60,
    max: 160,
    minValue: 0,
    maxValue: 1000,
  },
  {
    min: 160,
    max: 300,
    minValue: 1000,
    maxValue: 5000,
  },
  {
    min: 300,
    max: 550,
    minValue: 5000,
    maxValue: 10000,
  },
  {
    min: 550,
    max: 2500,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 2500,
    max: 2500,
    minValue: 50000,
  },
];

export const TIKTOK_SPARK_ADS_FOLLOWERS: PriceDataType = [
  {
    min: 225,
    max: 425,
    minValue: 0,
    maxValue: 10000,
  },
  {
    min: 425,
    max: 700,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 700,
    max: 1200,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 1200,
    max: 5100,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 5100,
    max: 5100,
    minValue: 500000,
  },
];

export const TIKTOK_SPARK_ADS_VIEWS: PriceDataType = [
  {
    min: 225,
    max: 425,
    minValue: 0,
    maxValue: 1000,
  },
  {
    min: 425,
    max: 700,
    minValue: 1000,
    maxValue: 5000,
  },
  {
    min: 700,
    max: 1200,
    minValue: 5000,
    maxValue: 10000,
  },
  {
    min: 1200,
    max: 5100,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 5100,
    max: 5100,
    minValue: 50000,
  },
];

export const META_ADS_STORIES_FOLLOWERS: PriceDataType = [
  {
    min: 112,
    max: 200,
    maxValue: 10000,
    minValue: 0,
  },
  {
    min: 200,
    max: 300,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 300,
    max: 600,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 600,
    max: 2550,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 2550,
    max: 2550,
    minValue: 500000,
  },
];

export const META_ADS_POST_FOLLOWERS: PriceDataType = [
  {
    min: 225,
    max: 400,
    maxValue: 10000,
    minValue: 0,
  },
  {
    min: 400,
    max: 600,
    minValue: 10000,
    maxValue: 50000,
  },
  {
    min: 600,
    max: 1200,
    minValue: 50000,
    maxValue: 100000,
  },
  {
    min: 1200,
    max: 5100,
    minValue: 100000,
    maxValue: 500000,
  },
  {
    min: 5100,
    max: 5100,
    minValue: 500000,
  },
];
