import React, { PropsWithChildren } from 'react';

import styles from './Warning.css';

import modsClasses from 'Util/modsClasses.js';
import icons from 'Icons';
import Text, { Props as TextProps } from 'Components/ui/Text/Text';
import { textSize } from 'Types/common';

const MODS = ['color'];

interface Props {
  className?: string;
  text?: string;
  textSize?: textSize;
  values?: TextProps['formatValues'];
  msg?: string;
  color?: 'red';
  hasIcon?: boolean;
}

const Warning: React.FC<PropsWithChildren<Props>> = (props) => {
  const { text, msg, values, children, hasIcon = true } = props;

  const el =
    text || msg ? <Text type="sm" text={text} msg={msg} formatValues={values} /> : children;

  const classes = modsClasses(MODS, props, styles);

  const icon = icons.attention;

  return (
    <div className={`${classes} ${styles.root}`}>
      {hasIcon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.content}>{el}</div>
    </div>
  );
};

export default Warning;
