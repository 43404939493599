import React, { PropsWithChildren } from 'react';

import Modal from 'Templates/Layout/Modal/Modal';
import { HistoryReferrerListener } from 'Hooks/useHistoryReferrer';
import DrawerContainer from 'Containers/Drawer/DrawerContainer';

const PublicRoute: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <HistoryReferrerListener>
      <DrawerContainer>
        <div>
          <main>{children}</main>
          <Modal />
        </div>
      </DrawerContainer>
    </HistoryReferrerListener>
  );
};

export default PublicRoute;
