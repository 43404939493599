import React, { useState, useContext, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import styles from './FbAdsAction.pcss';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ProjectInfoContext } from 'Page/common/Chat/Dialog/ProjectInfo/ProjectInfo.Context';
import AlterButton, { ButtonType } from 'Components/ui/AlterButton/AlterButton';
import Drawer from 'Components/ui/Drawer/Drawer';
import ActivatePs from 'Molecules/ActivatePs/ActivatePs';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Image from 'Components/ui/Image/Image';
import BcaActivation from 'CommonPage/Chat/Dialog/ProjectInfo/BcaActivation/BcaActivation';
import PaidSocialLicenseModal from 'Modal/advertiser/PaidSocialLicenseModal/PaidSocialLicenseModal';
import MaxLicensedCreatorsExceededDrawer from 'Modal/advertiser/MaxLicensedCreatorsExceeded/MaxLicensedCreatorsExceeded';
import paidAdsConnectedImg from 'Images/icons/paid-ads-connected.svg';
import paidAdsErrorImg from 'Images/icons/paid-ads-error.svg';
import { FbAdsAction_project$key } from 'GraphTypes/FbAdsAction_project.graphql';

interface Props {
  btnType?: ButtonType;
  className?: string;
  project: FbAdsAction_project$key;
}

const FbAdsAction: React.FC<Props> = (props) => {
  const { className, project, btnType = 'grey' } = props;

  const { openDrawer } = useContext(DrawerContext);
  const {
    isMaxPsLicensedCreatorsExceeded,
    subscription: { maxLicensedCreators, planId },
  } = useContext(ProjectInfoContext);

  const [showPsDetails, setShowPsDetails] = useState(false);
  const data = useFragment(
    graphql`
      fragment FbAdsAction_project on Project {
        id
        paidSocial
        advertiserStage
        brief {
          ... on V2Brief {
            paidSocialBca
          }
        }
        creator {
          id
          ... on InstagramCreator {
            paidSocialEnabled
          }
        }
        paidSocialActivation {
          id
          facebookBusinessName
          facebookBusinessPictureUrl
          facebookPageName
          facebookPagePictureUrl
          license {
            id
            state
            expiresAt
          }
        }
      }
    `,
    project
  );

  if (!data) return null;

  const { id: projectId, paidSocialActivation, creator, paidSocial, advertiserStage } = data;

  const paidSocialBca = data.brief?.paidSocialBca;

  if (!(paidSocial || paidSocialBca) || advertiserStage === 'COMPLETED') return null;

  const paidSocialEnabled = creator?.paidSocialEnabled;

  if (paidSocialBca) {
    return <BcaActivation projectId={projectId} simpleView={true} />;
  }
  const hasActivation = !!paidSocialActivation;

  let psStatus = <Image src={paidAdsConnectedImg} size={24} />;

  const license = paidSocialActivation?.license;
  const licenseId = license?.id;
  const licenseState = license?.state;

  if (licenseState === 'EXPIRED' || licenseState === 'FAILED_TO_EXPIRE') {
    psStatus = <Image src={paidAdsErrorImg} size={24} />;
  }

  const handleDetailsModalClose = () => {
    setShowPsDetails(false);
  };

  const handleDetailsModalOpen = useCallback(() => {
    if (!license) return;
    setShowPsDetails(true);
  }, [license]);

  const handleCheckingConnectionPossibility = useCallback(() => {
    if (isMaxPsLicensedCreatorsExceeded) {
      openDrawer('max-licensed-creators-exceeded');
      return false;
    }

    return true;
  }, [isMaxPsLicensedCreatorsExceeded, maxLicensedCreators, planId]);

  const connectBtn = (
    <AlterButton
      type={btnType}
      msg="chat.in_modal.action.ads"
      icon="Paid-ads"
      className={className}
      disabled={!paidSocialEnabled}
    />
  );

  let btnWrap: JSX.Element;

  if (paidSocialEnabled) {
    if (hasActivation) {
      btnWrap = (
        <AlterButton
          type={btnType}
          msg="chat.in_modal.action.ads"
          leftElement={psStatus}
          className={classnames(styles.btn, className)}
          onClick={handleDetailsModalOpen}
        />
      );
    } else {
      btnWrap = (
        <ActivatePs
          projectId={projectId}
          onCheckingConnectionPossibility={handleCheckingConnectionPossibility}
        >
          {connectBtn}
        </ActivatePs>
      );
    }
  } else {
    btnWrap = (
      <Tooltip id="connectAds" tooltipMsg="tooltip.fb_ads_unavailable" place="top">
        {connectBtn}
      </Tooltip>
    );
  }

  return (
    <>
      {btnWrap}
      <Drawer
        opened={showPsDetails}
        onClose={handleDetailsModalClose}
        rootKey="license"
        className={styles.drawer}
      >
        <PaidSocialLicenseModal id={licenseId} />
      </Drawer>
      <MaxLicensedCreatorsExceededDrawer
        attach={{
          planId,
          maxLicensedCreators,
        }}
      />
    </>
  );
};

export default FbAdsAction;
