import React, { Suspense, lazy, useContext } from 'react';

import MODALS from 'Constants/modals';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';
import { LayoutContext, Types as LayoutTypes } from 'Templates/Layout/Layout.Context';

// common modals

const Message = lazy(() => import('Modal/common/Message/Message'));
const Simple = lazy(() => import('Modal/common/Simple/Simple'));

// advertiser modals

const ActivatePs = lazy(() => import('Modal/advertiser/ActivatePs/ActivatePs'));

const CloseCampaignRequest = lazy(() =>
  import('Modal/advertiser/CloseCampaignRequest/CloseCampaignRequest')
);
const DeleteCampaign = lazy(() => import('Modal/advertiser/DeleteCampaign/DeleteCampaign'));
const FbAdsManager = lazy(() => import('Modal/advertiser/FbAdsManager/FbAdsManager'));
const ImageCropper = lazy(() => import('Modal/advertiser/ImageCropper/ImageCropper'));
const Media = lazy(() => import('Modal/advertiser/Media/Media'));

const ProjectPsActivationInfo = lazy(() =>
  import('Modal/advertiser/ProjectPsActivationInfo/ProjectPsActivationInfo')
);
const CreativePreview = lazy(() => import('Modal/advertiser/CreativePreview/CreativePreview'));

const StartNewDeal = lazy(() => import('Modal/advertiser/StartNewDeal/StartNewDeal'));
const CampaignForNewDeal = lazy(() =>
  import('Modal/advertiser/CampaignForNewDeal/CampaignForNewDeal')
);

const TiktokAdvertisers = lazy(() =>
  import('Modal/advertiser/TiktokAdvertisers/TiktokAdvertisers')
);

// contractor modals

const AddCreatorProfile = lazy(() =>
  import('Modal/contractor/AddCreatorProfile/AddCreatorProfile')
);
const AddInstagramProfile = lazy(() =>
  import('Modal/contractor/AddInstagramProfile/AddInstagramProfile')
);
const AddTiktokProfile = lazy(() => import('Modal/contractor/AddTiktokProfile/AddTiktokProfile'));
const AcceptOffer = lazy(() => import('Modal/contractor/AcceptOffer/AcceptOffer'));
const BriefOfferReaction = lazy(() =>
  import('Modal/contractor/BriefOfferReaction/BriefOfferReaction')
);
const DownloadApp = lazy(() => import('Modal/contractor/DownloadApp/DownloadApp'));
const ConnectToFB = lazy(() => import('Modal/contractor/ConnectToFB/ConnectToFB'));
const InstagramBusinessAccounts = lazy(() =>
  import('Modal/contractor/InstagramBusinessAccounts/InstagramBusinessAccounts')
);
const PsInfo = lazy(() => import('Modal/contractor/PsInfo/PsInfo'));
const PotentialParticipant = lazy(() =>
  import('Modal/contractor/PotentialParticipant/PotentialParticipant')
);
const RejectOffer = lazy(() => import('Modal/contractor/RejectOffer/RejectOffer'));
const ReservedProfile = lazy(() => import('Modal/contractor/ReservedProfile/ReservedProfile'));
const WithdrawalRequest = lazy(() =>
  import('Modal/contractor/WithdrawalRequest/WithdrawalRequest')
);
const CreatorProfileReviewing = lazy(() =>
  import('Modal/contractor/CreatorProfileReviewing/CreatorProfileReviewing')
);

// admin modals

const CreatorDisconnect = lazy(() => import('Modal/admin/CreatorDisconnect/CreatorDisconnect'));
const FailedReviewReason = lazy(() => import('Modal/admin/FailedReviewReason/FailedReviewReason'));
const TransferUserToOrganization = lazy(() =>
  import('Modal/admin/TransferUserToOrganization/TransferUserToOrganization')
);

const ModalContainer = () => {
  const { state: modalState, dispatch: modalDispatch } = useContext(ModalContext);
  const { dispatch: layoutDispatch } = useContext(LayoutContext);

  const { name, attach } = modalState;

  const handleSetModal = (name, attach) => {
    modalDispatch({ type: ModalTypes.SET_MODAL, payload: { name, attach } });
  };

  const handleCloseModal = () => {
    modalDispatch({ type: ModalTypes.TOGGLE_MODAL });
  };

  const handleTogglePreloader = (value) => {
    layoutDispatch({ type: LayoutTypes.TOGGLE_PRELOADER, payload: { value } });
  };

  const getModal = () => {
    switch (name) {
      case modalName.ADD_CREATOR_PROFILE:
        return <AddCreatorProfile />;
      case modalName.ADD_INSTAGRAM_PROFILE:
        return <AddInstagramProfile />;
      case modalName.ADD_TIKTOK_PROFILE:
        return <AddTiktokProfile />;
      case modalName.ACCEPT_OFFER:
        return <AcceptOffer />;
      case modalName.ACTIVATE_PS:
        return <ActivatePs />;
      case modalName.BRIEF_OFFER_REACTION:
        return <BriefOfferReaction />;
      case modalName.CAMPAIGN_FOR_NEW_DEAL:
        return <CampaignForNewDeal />;
      case modalName.CONNECT_PS:
        return <ConnectToFB />;
      case modalName.CLOSE_CAMPAIGN_REQUEST:
        return <CloseCampaignRequest />;
      case modalName.CREATOR_PROFILE_REVIEWING:
        return <CreatorProfileReviewing />;
      case modalName.CREATOR_DISCONNECT:
        return <CreatorDisconnect />;
      case modalName.DELETE_CAMPAIGN:
        return <DeleteCampaign />;
      case modalName.DOWNLOAD_APP:
        return <DownloadApp />;
      case modalName.FB_ADS_MANAGER:
        return <FbAdsManager />;
      case modalName.INSTAGRAM_BUSINESS_ACCOUNTS:
        return <InstagramBusinessAccounts />;
      case modalName.IMAGE_CROPPER:
        return <ImageCropper />;
      case modalName.MEDIA:
        return <Media />;
      case modalName.POTENTIAL_PARTICIPANT:
        return <PotentialParticipant />;
      case modalName.PROJECT_PS_ACTIVATION_INFO:
        return <ProjectPsActivationInfo />;
      case modalName.PS_INFO:
        return <PsInfo />;
      case modalName.REJECT_OFFER:
        return <RejectOffer />;
      case modalName.RESERVED_PROFILE:
        return <ReservedProfile />;
      case modalName.SIMPLE:
        return <Simple />;
      case modalName.START_NEW_DEAL:
        return <StartNewDeal />;
      case modalName.TIKTOK_ADVERTISERS:
        return <TiktokAdvertisers />;
      case modalName.TRANSFER_USER_TO_ORGANIZATION:
        return <TransferUserToOrganization />;
      case modalName.WITHDRAWAL_REQUEST:
        return <WithdrawalRequest />;
      case MODALS.MEDIA:
        return <Media />;
      case MODALS.MESSAGE:
        return <Message />;
      case MODALS.CREATIVE_PREVIEW:
        return <CreativePreview />;
      case MODALS.FAILED_REVIEW_REASON:
        return <FailedReviewReason />;
      default:
        return <div />;
    }
  };

  const content = getModal();

  return (
    <Suspense fallback={<div />}>
      {React.cloneElement(content, {
        attach,
        onSetModal: handleSetModal,
        onCloseModal: handleCloseModal,
        onTogglePreloader: handleTogglePreloader,
      })}
    </Suspense>
  );
};

export default ModalContainer;
