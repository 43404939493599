import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';
import {
  ImportCreatorsBySearchQueryOperationUpdatedSubscription,
  ImportCreatorsBySearchQueryOperationUpdatedSubscription$data,
} from 'GraphTypes/ImportCreatorsBySearchQueryOperationUpdatedSubscription.graphql';

const subscription = graphql`
  subscription ImportCreatorsBySearchQueryOperationUpdatedSubscription {
    importCreatorsBySearchQueryOperationUpdated {
      campaign {
        id
        latestImportCreatorsBySearchQueryOperation {
          state
        }
      }
    }
  }
`;

interface Props {
  updater: (data?: ImportCreatorsBySearchQueryOperationUpdatedSubscription$data | null) => void;
}

export default (props: Props) => {
  requestSubscription<ImportCreatorsBySearchQueryOperationUpdatedSubscription>(environment, {
    subscription,
    variables: {},
    updater: (store, data) => {
      props.updater(data);
    },
  });
};
