import React from 'react';
import compact from 'lodash/compact';

import { createShortNumber, createPercent, createSum } from 'Util/numberFormatter';
import { INSTAGRAM, YOUTUBE, TIKTOK } from 'Constants/general';
import { CreatorInfoStats_creator$data } from 'GraphTypes/CreatorInfoStats_creator.graphql';
import { Currency } from 'GraphTypes/MediaplanItemInfoQuery.graphql';

type DataType = {
  creator: CreatorInfoStats_creator$data;
  price?: number | null;
  currency?: Currency | null;
  admin?: boolean;
};

const enumerationList = (list: { name: string }[] = []) => {
  const names = list.map((item) => {
    return item.name;
  });

  return names.join(', ');
};

const createlinkShortcut = (link: string) => {
  try {
    const url = new URL(link);

    return (
      <a href={link} target="_blank">
        {url.hostname}
      </a>
    );
  } catch {
    return (
      <a href={link} target="_blank">
        Link
      </a>
    );
  }
};

export const generateCreatorData = (data: DataType) => {
  const price = data.price;
  const currency = data.currency;

  const creator = data.creator;
  const type = creator.type;
  const profile = creator.profile;

  const countries = profile?.countries || [];
  const cities = profile?.cities || [];
  const portfolioUrl = profile?.portfolioLink;

  const location = enumerationList([...cities, ...countries]);

  const user = creator.user || creator.tiktokUser;

  const er = user?.engagementRate;

  const userInsightsData = data.creator.userInsightsData;

  const followers = userInsightsData?.followers;
  const followersDelta = userInsightsData?.followersDelta;
  const impressions = userInsightsData?.impressions;
  const reach = userInsightsData?.reach;
  const websiteClicks = userInsightsData?.websiteClicks;

  const delta = Number(followersDelta) / (Number(followers) - Number(followersDelta));

  const avgComments = user?.averageComments;
  const avgLikes = user?.averageLikes;
  const avgViews = user?.averageViews;
  const followedByCount = user?.followedByCount;

  const priceValue = price || profile?.estimatedPrice;

  const cpe = avgLikes && priceValue ? priceValue / avgLikes : null;
  const cpv = avgViews && priceValue ? priceValue / avgViews : null;

  if (type === INSTAGRAM) {
    return compact([
      !followers && followedByCount
        ? {
            value: createShortNumber(followedByCount),
            title: 'creator_statistic.subtitles_followers',
            tooltipMsg: 'creator_statistic.subtitles_followers_tooltip',
          }
        : undefined,
      followers
        ? {
            title: 'creator_statistic.subtitles.creator.followers',
            tooltipMsg: 'creator_statistic.subtitles.creator.followers.tooltip',
            value: createShortNumber(followers),
            additionalValue: followersDelta ? createPercent(delta) : undefined,
          }
        : null,
      reach
        ? {
            title: 'creator_statistic.subtitles.creator.reach',
            tooltipMsg: 'creator_statistic.subtitles.creator.reach.tooltip',
            value: createShortNumber(reach),
          }
        : null,
      impressions
        ? {
            title: 'creator_statistic.subtitles.creator.impressions',
            tooltipMsg: 'creator_statistic.subtitles.creator.impressions.tooltip',
            value: createShortNumber(impressions),
          }
        : null,
      location
        ? {
            value: location,
            title: 'creator_statistic.subtitles_location',
            tooltipMsg: 'creator_statistic.subtitles_location_tooltip',
          }
        : null,
      er
        ? {
            title: 'creator_statistic.subtitles_er',
            tooltipMsg: 'creator_statistic.subtitles_er_tooltip',
            value: createPercent(er),
          }
        : null,
      avgComments
        ? {
            title: 'creator_statistic.subtitles_averageComments',
            tooltipMsg: 'creator_statistic.subtitles_averageComments_tooltip',
            value: createShortNumber(avgComments),
          }
        : null,
      websiteClicks
        ? {
            title: 'creator_statistic.subtitles.creator.website_clicks',
            tooltipMsg: 'creator_statistic.subtitles.creator.website_clicks.tooltip',
            value: createShortNumber(websiteClicks),
          }
        : null,
      avgLikes
        ? {
            title: 'creator_statistic.subtitles_averageLikes',
            tooltipMsg: 'creator_statistic.subtitles_averageLikes_tooltip',
            value: createShortNumber(avgLikes),
          }
        : null,
      cpe && currency
        ? {
            title: 'creator_statistic.subtitles_cpe',
            tooltipMsg: 'creator_statistic.subtitles_cpe_tooltip',
            value: createSum(cpe, currency, 0, '0,0.[00]'),
          }
        : null,
      portfolioUrl
        ? {
            title: 'creator_statistic.subtitles_portfolio',
            tooltipMsg: 'creator_statistic.subtitles_portfolio_tooltip',
            value: createlinkShortcut(portfolioUrl),
          }
        : null,
    ]);
  } else if (type === YOUTUBE) {
    const subscribers = creator.channel?.subscribers;

    return compact([
      subscribers
        ? {
            value: createShortNumber(subscribers),
            title: 'creator_statistic.subtitles_subscribers',
            tooltipMsg: 'creator_statistic.subtitles_subscribers_tooltip',
          }
        : null,
      location
        ? {
            value: location,
            title: 'creator_statistic.subtitles_location',
            tooltipMsg: 'creator_statistic.subtitles_location_tooltip',
          }
        : null,
      er
        ? {
            title: 'creator_statistic.subtitles_er',
            tooltipMsg: 'creator_statistic.subtitles_er_tooltip',
            value: createPercent(er),
          }
        : null,
      avgViews
        ? {
            title: 'creator_statistic.subtitles_averageViews',
            tooltipMsg: 'creator_statistic.subtitles_averageViews_tooltip',
            value: createShortNumber(avgViews),
          }
        : null,
      avgComments
        ? {
            title: 'creator_statistic.subtitles_averageComments',
            tooltipMsg: 'creator_statistic.subtitles_averageComments_tooltip',
            value: createShortNumber(avgComments),
          }
        : null,
      avgLikes
        ? {
            title: 'creator_statistic.subtitles_averageLikes',
            tooltipMsg: 'creator_statistic.subtitles_averageLikes_tooltip',
            value: createShortNumber(avgLikes),
          }
        : null,
      portfolioUrl
        ? {
            title: 'creator_statistic.subtitles_portfolio',
            tooltipMsg: 'creator_statistic.subtitles_portfolio_tooltip',
            value: createlinkShortcut(portfolioUrl),
          }
        : null,
    ]);
  } else if (type === TIKTOK) {
    const tiktokUser = creator.tiktokUser;
    const medianViewsValue = tiktokUser?.medianViews;

    return compact([
      followedByCount
        ? {
            value: createShortNumber(followedByCount),
            title: 'creator_statistic.subtitles_followers',
            tooltipMsg: 'creator_statistic.subtitles_followers_tooltip',
          }
        : undefined,
      location
        ? {
            value: location,
            title: 'creator_statistic.subtitles_location',
            tooltipMsg: 'creator_statistic.subtitles_location_tooltip',
          }
        : null,
      er
        ? {
            title: 'creator_statistic.subtitles_er',
            tooltipMsg: 'creator_statistic.subtitles_er_tooltip',
            value: createPercent(er),
          }
        : null,
      avgComments
        ? {
            title: 'creator_statistic.subtitles_averageComments',
            tooltipMsg: 'creator_statistic.subtitles_averageComments_tooltip',
            value: createShortNumber(avgComments),
          }
        : null,
      avgLikes
        ? {
            title: 'creator_statistic.subtitles_averageLikes',
            tooltipMsg: 'creator_statistic.subtitles_averageLikes_tooltip',
            value: createShortNumber(avgLikes),
          }
        : null,
      avgViews
        ? {
            title: 'creator_statistic.subtitles_averageViews',
            tooltipMsg: 'creator_statistic.subtitles_averageViews_tooltip',
            value: createShortNumber(avgViews),
          }
        : null,
      cpe && currency
        ? {
            title: 'creator_statistic.subtitles_cpe',
            tooltipMsg: 'creator_statistic.subtitles_cpe_tooltip',
            value: createSum(cpe, currency, 0, '0,0.[00]'),
          }
        : null,
      cpv && currency
        ? {
            title: 'creator_statistic.subtitles_cpv',
            tooltipMsg: 'creator_statistic.subtitles_cpv_tooltip',
            value: createSum(cpe, currency, 0, '0,0.[00]'),
          }
        : null,
      medianViewsValue
        ? {
            title: 'creator_statistic.subtitles_median_views',
            tooltipMsg: 'creator_statistic.subtitles_median_views_tooltip',
            value: createShortNumber(medianViewsValue),
          }
        : null,
      portfolioUrl
        ? {
            title: 'creator_statistic.subtitles_portfolio',
            tooltipMsg: 'creator_statistic.subtitles_portfolio_tooltip',
            value: createlinkShortcut(portfolioUrl),
          }
        : null,
    ]);
  }

  return [];
};

type BarChartsType = {
  creator: CreatorInfoStats_creator$data;
};

export const generateBarCharts = (data: BarChartsType) => {
  const type = data.creator.type;
  const profile = data.creator.profile;

  if (type === INSTAGRAM) {
    const mentions = profile?.mentions.map((item) => {
      return { ...item, name: `@${item.name}` };
    });

    const brands = profile?.postBrands.map((item) => {
      return { ...item, name: item.name };
    });

    const hashtags = profile?.hashtags.map((item) => {
      return { ...item, name: `#${item.name}` };
    });

    return compact([
      hashtags
        ? {
            title: 'creator_statistic.section_top_hashtags',
            tooltipMsg: 'creator_statistic.section_top_hashtags_tooltip',
            items: hashtags,
          }
        : null,
      mentions
        ? {
            title: 'creator_statistic.section_brand_mentions',
            tooltipMsg: 'creator_statistic.section_brand_mentions_tooltip',
            items: mentions,
          }
        : null,
      brands
        ? {
            title: 'creator_statistic.section_top_brands',
            items: brands,
          }
        : null,
    ]);
  } else if (type === TIKTOK) {
    const hashtags =
      Number(profile?.hashtags.length) > 0
        ? profile?.hashtags.map((item) => {
            return { ...item, name: `#${item.name}` };
          })
        : null;

    return compact([
      hashtags
        ? {
            title: 'creator_statistic.section_top_hashtags',
            tooltipMsg: 'creator_statistic.section_top_hashtags_tooltip',
            items: hashtags,
          }
        : null,
    ]);
  }

  return [];
};
