import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import { BriefContext } from '../../../../Brief.Context';
import SegmentedControls from '../../components/SegmentedControls/SegmentedControls';

import styles from './PartnershipAdsDetails.pcss';
import { getPsDaysList, getDays } from './util';

import { amplitude } from 'Helpers/amplitude';
import updateBrief from 'Mutations/UpdateBrief.Mutation';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import Toggle from 'Components/ui/Toggle/Toggle';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { IncreaseCreatorLicensingDurationDrawer } from 'Modal/advertiser/IncreaseCreatorLicensingDuration/IncreaseCreatorLicensingDuration';
import bcaTagImg from 'Images/brief/bca_tag.png';
import bcaTag2xImg from 'Images/brief/bca_tag_2x.png';
import expandReachImg from 'Images/brief/expand_reach.png';
import expandReach2xImg from 'Images/brief/expand_reach_2x.png';
import {
  FREE_PLAN,
  PARTERSHIP_ADS_SETUP_INFO,
  PARTERSHIP_ADS_INFO,
  WHITELISTING_INFO,
} from 'Constants/general';
import { PartnershipAdsDetails_brief$key } from 'GraphTypes/PartnershipAdsDetails_brief.graphql';

interface Props {
  className?: string;
  brief: PartnershipAdsDetails_brief$key;
  trialMonthPlanAvailable?: boolean;
  maxPaidSocialActivationDays?: number | null;
  psLicensedCreators?: number;
}

const PartnershipAdsDetails: React.FC<Props> = (props) => {
  const {
    className,
    brief,
    trialMonthPlanAvailable,
    maxPaidSocialActivationDays,
    psLicensedCreators,
  } = props;

  const data = useFragment(
    graphql`
      fragment PartnershipAdsDetails_brief on Brief {
        ... on V2Brief {
          id
          paidSocial
          paidSocialActivationDays
          paidSocialBca
        }
      }
    `,
    brief
  );
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const wlAvaiable = hasFeatureFlag('whitelisting_available');
  const [briefContext, briefDispatch] = useContext(BriefContext);

  const [
    isIncreaseCreatorLicensingDurationDrawerOpened,
    setIsIncreaseCreatorLicensingDurationDrawerOpened,
  ] = useState(false);

  const { id: briefId, paidSocial, paidSocialBca, paidSocialActivationDays } = data;

  useEffect(() => {
    if (!paidSocial && !paidSocialBca && briefId) {
      updateBrief({
        id: briefId,
        paidSocialBca: true,
      });
    }
  }, []);

  const days = getDays(trialMonthPlanAvailable, maxPaidSocialActivationDays);

  const currentPsActivationDays = useMemo(() => {
    if (!paidSocialActivationDays) return null;

    let currentItem;
    let n = 0;

    while (n < days.length) {
      if (days[n].id <= paidSocialActivationDays) {
        currentItem = days[n].id;
      }
      n++;
    }
    return currentItem;
  }, [paidSocialActivationDays]);

  const handleIncreaseCreatorLicensingDurationDrawerClose = useCallback(() => {
    setIsIncreaseCreatorLicensingDurationDrawerOpened(false);
  }, []);

  const handlePsChange = useCallback(
    (value: boolean) => {
      if (!briefId) return;
      const newPsDays = value ? 15 : null;
      updateBrief({
        id: briefId,
        paidSocial: value,
        paidSocialActivationDays: newPsDays,
        paidSocialBca: !value,
      });
    },
    [briefId]
  );

  const handleDaysChange = useCallback(
    (data: { paidSocialActivationDays: null | number }) => {
      const id = data.paidSocialActivationDays;
      const paidSocial = !!(id && id > 0);
      const ignoreMaxPaidSocialActivationDays =
        briefContext.planId ===
        FREE_PLAN; /*@TODO Available all values for FREE plan because backend can't deliver correct maxPaidSocialActivationDays for FREE plan. Agreed with QA team, backend team and business */

      if (Number(maxPaidSocialActivationDays) >= Number(id) || ignoreMaxPaidSocialActivationDays) {
        briefDispatch({ key: 'briefIsSaving', value: true });
        const paidSocialBcaValue = paidSocial === false ? false : undefined;
        updateBrief({
          id: briefId,
          paidSocial,
          paidSocialBca: paidSocialBcaValue,
          paidSocialActivationDays: id,
        });
      } else {
        setIsIncreaseCreatorLicensingDurationDrawerOpened(true);
      }
    },
    [maxPaidSocialActivationDays, briefId]
  );

  const handlePartnershipInfoClick = () => {
    amplitude.sendEvent({
      id: '186',
      category: 'links_click',
      name: 'what_are_partnership_ads',
    });
  };

  const handlePartnershipSetupInfoClick = () => {
    amplitude.sendEvent({
      id: '187',
      category: 'links_click',
      name: 'how_to_set_up_partnership_ads',
    });
  };

  const handleWlInfoClick = () => {
    amplitude.sendEvent({
      id: '188',
      category: 'links_click',
      name: 'how_to_set_up_creator_licensing',
    });
  };

  const psDaysList = useMemo(() => {
    return getPsDaysList(maxPaidSocialActivationDays);
  }, [maxPaidSocialActivationDays]);

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.banners}>
        <SmartLink
          path={PARTERSHIP_ADS_INFO}
          onClick={handlePartnershipInfoClick}
          className={styles.reach}
        >
          <div className={styles.imageWrap}>
            <img srcSet={`${expandReachImg} 1x, ${expandReach2xImg} 2x`} />
          </div>
          <Text msg="brief.partnership.title_1" type="sm" className={styles.bannerDescr} />
        </SmartLink>
        <SmartLink
          path={PARTERSHIP_ADS_SETUP_INFO}
          onClick={handlePartnershipSetupInfoClick}
          className={styles.tag}
        >
          <div className={styles.imageWrap}>
            <img srcSet={`${bcaTagImg} 1x, ${bcaTag2xImg} 2x`} />
          </div>
          <Text msg="brief.partnership.title_2" type="sm" className={styles.bannerDescr} />
        </SmartLink>
      </div>
      {wlAvaiable && (
        <div className={styles.info}>
          <Text type="md" msg="brief.partnership.wl_1" className={styles.infoDescr} />
          <Text
            type="md"
            msg="brief.partnership.wl_2"
            className={`${styles.infoAlert} ${styles.infoDescr}`}
          />
          <Text type="md" msg="brief.partnership.wl_3" className={styles.infoDescr} />
          <div className={styles.wlToggler}>
            <div>
              <Text type="md" msg="brief.partnership.wl.toggler.descr" />
              <SmartLink path={WHITELISTING_INFO} onClick={handleWlInfoClick}>
                <TextButton msg="general.read_more" size="xs" className={styles.readMore} />
              </SmartLink>
            </div>
            <Toggle on={!!paidSocial} onChange={handlePsChange} />
          </div>
        </div>
      )}
      {wlAvaiable && paidSocial && (
        <div className={styles.psDays}>
          <SegmentedControls
            id="paidSocialActivationDays"
            currentValue={currentPsActivationDays}
            items={psDaysList}
            onChange={handleDaysChange}
          />
        </div>
      )}
      <IncreaseCreatorLicensingDurationDrawer
        open={isIncreaseCreatorLicensingDurationDrawerOpened}
        onClose={handleIncreaseCreatorLicensingDurationDrawerClose}
      />
      {!wlAvaiable && Number(psLicensedCreators) > 0 && (
        <div className={styles.info}>
          <Text type="md" msg="brief.partnership.wl.active" />
        </div>
      )}
    </div>
  );
};

export default PartnershipAdsDetails;
