import React, { Fragment, useContext, useMemo } from 'react';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import Text from 'Components/ui/Text/Text';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import classNames from 'classnames';
import { findTimezoneByOffset, Timezone, timezoneList } from 'Util/timezone';

interface Props {}

type TimezoneItem = keyof typeof timezoneList.zones;

const TimezoneSelector: React.FC<Props> = () => {
  const { timezone, setTimezone, setTemplateChangedStatus } = useContext(
    InviteOutreachCreatorsContext
  );

  const selectedTimezone = useMemo(() => {
    return findTimezoneByOffset(timezone);
  }, [timezone]);

  return (
    <>
      <div className={styles.subtitle}>
        <Text type="md" msg="invite_outreach_creators_modal.timezone.title" />
      </div>
      <Dropdown
        value={
          <Text
            type="md"
            className={classNames(styles.dropdownValue)}
            textPriority
            text={`(${selectedTimezone?.offset}) ${selectedTimezone?.name}`}
          />
        }
        className={styles.dropdownSettings}
      >
        <DropdownGroup className={styles.dropdownMenu}>
          {Object.keys(timezoneList.zones).map((zone) => {
            return timezoneList.zones[zone as TimezoneItem].map((item: Timezone, idx: number) => {
              const isActive = item.numericOffset === timezone;

              const handleSelectList = () => {
                setTimezone(item.numericOffset);
                setTemplateChangedStatus(true);
              };

              return (
                <Fragment key={item.name}>
                  {idx === 0 && (
                    <Text
                      type="label"
                      className={styles.dropdownGroupLabel}
                      text={timezoneList.continents[zone as TimezoneItem]}
                    />
                  )}
                  <DropdownGroupItem>
                    <AlterButton
                      text={`(${item.offset}) ${item.name}`}
                      onClick={handleSelectList}
                      fluid
                      iconPosition="right"
                      icon={isActive ? 'Check' : null}
                    />
                  </DropdownGroupItem>
                </Fragment>
              );
            });
          })}
        </DropdownGroup>
      </Dropdown>
    </>
  );
};

export default TimezoneSelector;
