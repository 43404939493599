import React from 'react';
import classnames from 'classnames';

import styles from './Checkbox.pcss';

interface Props {
  isChecked?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  alignedRight?: boolean;
  onClick?: () => void;
}

const Checkbox: React.FC<Props> = (props) => {
  const { isChecked, isDisabled, isIndeterminate, alignedRight, onClick } = props;
  return (
    <div
      className={classnames(styles.box, {
        [styles.checked]: isChecked,
        [styles.disabled]: isDisabled,
        [styles.alignedRight]: alignedRight,
        [styles.clickable]: !!onClick,
        [styles.indeterminate]: !!isIndeterminate,
      })}
      onClick={onClick}
    >
      {isChecked && <div className={styles.checkboxCheckedItem} />}
      {isIndeterminate && <div className={styles.line} />}
    </div>
  );
};

export default Checkbox;
