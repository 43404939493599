import React, { useEffect, useState, useContext } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import styles from './ChangeAddonsDrawer.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Drawer from 'Components/ui/Drawer/Drawer';
import Icon from 'Components/ui/Icon/Icon';
import { IconName } from 'Components/ui/types';
import { SubscriptionParams } from 'Page/advertiser/BillingPlans/BillingPlans';
import { AddonSettings, addonsKeyMap } from 'Page/advertiser/BillingPlans/data';
import { SubscriptionLimits_organization$data } from 'GraphTypes/SubscriptionLimits_organization.graphql';
import { BrandsLimitQuery$data } from 'GraphTypes/BrandsLimitQuery.graphql';
import { AddonItemInput } from 'GraphTypes/BuySubscriptionViaStripeMutation.graphql';
import { AddonId } from 'GraphTypes/PreviewProrationMutation.graphql';
import { OrganizationTeamQuery$data } from 'GraphTypes/OrganizationTeamQuery.graphql';
import { WlSlotsBanner_campaign$data } from 'GraphTypes/WlSlotsBanner_campaign.graphql';
import { amplitude } from 'Helpers/amplitude';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  icon: IconName;
  type: 'maxActiveBrands' | 'maxSeats' | 'maxBcaConnections';
  disabledManage?: boolean;
  defaultValue?: number;
  limitExcceded?: boolean;
  addons: AddonSettings[];
  subscriptionData:
    | SubscriptionLimits_organization$data['subscription']
    | NonNullable<NonNullable<BrandsLimitQuery$data['currentUser']>['organization']>['subscription']
    | NonNullable<
        NonNullable<OrganizationTeamQuery$data['currentUser']>['organization']
      >['subscription']
    | NonNullable<WlSlotsBanner_campaign$data['organization']>['subscription'];
  defaultLimit?: number | null;
  onParamsChange: (params: SubscriptionParams) => void;
}

const typeToMsgMap = {
  maxActiveBrands: 'brands',
  maxSeats: 'seats',
  maxBcaConnections: 'licenses',
};

const ChangeAddonsDrawer: React.FC<Props> = (props) => {
  const {
    type,
    icon,
    disabledManage = false,
    defaultValue = 1,
    limitExcceded,
    addons,
    subscriptionData,
    defaultLimit,
    onParamsChange,
  } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const addon = (addons.find((addon) => addonsKeyMap[addon.key] === type) as AddonSettings) || {};
  const minValue = typeof defaultLimit === 'number' ? defaultLimit : addon?.minValue;
  const { state } = useLocation() as {
    state: {
      addon?: 'maxActiveBrands' | 'maxSeats' | 'maxBcaConnections';
    };
  };

  useEffect(() => {
    if (state?.addon && !disabledManage) {
      if (state.addon === type) {
        setDrawerStatus(true);
      }
    }
  }, [state, disabledManage]);
  const price = addon.price;
  const stepCount = addon.stepCount || 1;
  const [openedDrawer, setDrawerStatus] = useState(false);
  const [count, setCount] = useState(stepCount);

  if (!subscriptionData) {
    return null;
  }

  const { planId: currentPlanId, interval, availableAddonIds, addonItems } = subscriptionData;
  const userAddonData = (addonKey: AddonId) => {
    const allAddons = addonItems.filter((item) => {
      return item.addon.id === addonKey;
    });
    if (allAddons.length === 0) {
      return undefined;
    }
    const resultAddon = { ...allAddons[0] };
    if (allAddons.length > 1) {
      for (let i = 1; i < allAddons.length; i++) {
        resultAddon.quantity = resultAddon.quantity + allAddons[i].quantity;
      }
    }
    return resultAddon;
  };

  const handleDrawerOpen = () => {
    amplitude.sendEvent<367>({
      id: '367',
      category: 'billing',
      name: 'add_more_addons_click',
      param: {
        addon_name: addon.key,
        addon_count: defaultValue / stepCount,
      },
    });
  };

  const handleBuyClick = () => {
    if (limitExcceded) {
      amplitude.sendEvent<421>({
        id: '421',
        category: 'addon_drawer',
        name: 'purchase',
        param: { type },
      });
    }
    const userChangedAddons: AddonItemInput[] = availableAddonIds.reduce((list, addonId) => {
      if (addonId === addon.key) {
        const userAddonStripeData = userAddonData(addon.key);
        let purchasedAddonCount = 0;
        if (userAddonStripeData) {
          purchasedAddonCount = userAddonStripeData.quantity;
        }
        return [
          ...list,
          {
            addonId,
            quantity: count / stepCount + purchasedAddonCount,
          },
        ];
      }
      const unchangedAddon = addons.find((addon) => addon.key === addonId) as AddonSettings;
      if (!unchangedAddon) return list;
      let unchangedAddonQuantity = 0;
      const userAddonStripeData = userAddonData(unchangedAddon.key);
      if (userAddonStripeData) {
        unchangedAddonQuantity = userAddonStripeData.quantity;
        if (unchangedAddonQuantity) {
          return [
            ...list,
            {
              addonId,
              quantity: unchangedAddonQuantity,
            },
          ];
        }
      }
      return list;
    }, []);

    if (!interval) return;

    const changedAddonKeys: AddonId[] = userChangedAddons.map((item) => item.addonId);
    const unchangedAddonKeys: AddonId[] = _.xor(
      ['BRANDS', 'SEATS', 'WHITELISTING_LICENSES'],
      changedAddonKeys
    );

    unchangedAddonKeys.forEach((item) => {
      const addon = addons.find((addon) => addon.key === item);
      if (!addon || !addon.currentValue || addon.currentValue === addon.minValue) {
        return;
      }
      userChangedAddons.push({ addonId: item, quantity: addon.currentValue - addon.minValue });
    });

    const params = {
      planId: currentPlanId,
      interval,
      addonItems: userChangedAddons.length > 0 ? userChangedAddons : undefined,
    };

    onParamsChange(params);
    closeDrawer(`buy-${type}-addon`);
  };

  const handleDecreaseClick = () => {
    const newValue = count - stepCount;
    if (newValue === 0) {
      if (minValue === newValue + defaultValue) {
        return;
      }
      setCount(-1 * stepCount);
    } else if (minValue <= newValue + defaultValue) {
      setCount(newValue);
    }
  };

  const handleIncreaseClick = () => {
    const newValue = count + stepCount;
    if (newValue === 0) {
      setCount(stepCount);
    } else {
      setCount(count + stepCount);
    }
  };

  return (
    <Drawer
      rootKey={`buy-${type}-addon`}
      className={styles.drawerWrapper}
      containerClassName={styles.drawerContainer}
      backdropClassName={styles.drawerBackdrop}
      opened={openedDrawer}
      onOpen={handleDrawerOpen}
      needCloseButton
      removeWhenClosed
    >
      <div className={styles.root}>
        <div className={styles.formContainer}>
          <div className={styles.titleWrap}>
            <Text type="d2" msg="billing.addons.drawer.title" />
            {limitExcceded && (
              <div className={styles.limitWarning}>
                <Text type="md" msg={`billing.addons.drawer.${type}.limit_excceded`} />
              </div>
            )}
          </div>
          <Icon name={icon} size={36} />
          <Text
            type="d2"
            msg={`billing.addons.drawer.${typeToMsgMap[type]}`}
            className={styles.addonType}
          />
          <Text
            type="md"
            msg={`billing.addons.drawer.${typeToMsgMap[type]}.description`}
            className={styles.addonDescription}
          />
          <Text
            type="md"
            msg={`billing.addons.drawer.${typeToMsgMap[type]}.price`}
            formatValues={{ price }}
            className={styles.addonPrice}
          />
          <div className={styles.addonCounter}>
            <div className={styles.addonCounterButtonContainer}>
              <div
                className={classnames(styles.addonCounterButton, styles.buttonMinus, {
                  [styles.disabled]: limitExcceded && count === 1,
                })}
                onClick={limitExcceded && count === 1 ? undefined : handleDecreaseClick}
              />
              <Text type="d1" className={styles.addonCounterValue} text={count} />
              <div
                className={classnames(styles.addonCounterButton, styles.buttonPlus)}
                onClick={handleIncreaseClick}
              />
            </div>
          </div>
          <div className={styles.addonPriceContainer}>
            <Text
              type="d2"
              text={`${price * count > 0 ? `+$${price * count}` : `-$${Math.abs(price * count)}`}`}
            />
            <Text type="md" msg="billing.addons.drawer.price.per_month" />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Text
            type="sm"
            formatValues={{ count: count + defaultValue }}
            msg={`billing.addons.drawer.${typeToMsgMap[type]}.counter`}
          />
          <AlterButton
            type="black"
            fluid
            msg={
              limitExcceded
                ? 'billing.addons.drawer.brands.purchase'
                : `billing.addons.drawer.${typeToMsgMap[type]}.button${count < 0 ? '.remove' : ''}`
            }
            formatValues={{ count: Math.abs(count) }}
            classes={{ buttonText: styles.buttonText }}
            onClick={handleBuyClick}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ChangeAddonsDrawer;
