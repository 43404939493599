import React, { FC, useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import track, { useTracking } from 'react-tracking';
import { fetchQuery, graphql } from 'relay-runtime';
import compact from 'lodash/compact';
import pick from 'lodash/pick';
import classnames from 'classnames';

import styles from './MediaDrawer.pcss';
import MediaCount from './MediaCount/MediaCount';

import environment from 'Api/Environment';
import { createDate } from 'Util/dateCreator';
import { downloadFile } from 'Util/downloadFile';
import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import ModalWrapper from 'Modal/ModalWrapper/ModalWrapper';
import ExportContentToAdsManager from 'Organisms/ExportContentToAdsManager/ExportContentToAdsManager';
import { MediaQuery as queryType } from 'GraphTypes/MediaQuery.graphql';
import { File } from 'Types/common';
import { modalItemDataType } from 'AdvertiserPage/Campaign/Creators/CreatorsList/CreatorsListContainer/CreatorCard/PortfolioContent/PortfolioContent';
import Icon from 'Components/ui/Icon/Icon';
import Drawer from 'Components/ui/Drawer';

const MediaQuery = graphql`
  query MediaQuery($url: String!) {
    instagramOembed(instagramPostUrl: $url) {
      thumbnailUrl
    }
  }
`;

type attachType = Partial<{
  isAutoplay: boolean;
  file?: File;
  thumbnailUrl?: string | null;
  url?: string;
  caption?: string;
  secureUrl: string;
  originalFilename?: string;
  videoUrl?: string;
  finalVideoUrl?: string;
  type: string;
  instagramUrl?: string;
  isYoutube?: boolean;
  canDownload?: boolean;
  canSentToAdsManager?: boolean;
  date?: string;
  galleryData?: modalItemDataType[];
  isGallery?: boolean;
}>;

interface Props {
  className?: string;
  onCloseModal?: () => void;
  attach: attachType;
  projectId?: string;
}

const MediaDrawer: FC<Props> = (props) => {
  const { className, attach, onCloseModal, projectId } = props;
  const {
    isAutoplay,
    file,
    thumbnailUrl,
    url,
    caption,
    secureUrl,
    originalFilename,
    videoUrl: defaultVideoUrl,
    finalVideoUrl,
    type,
    instagramUrl,
    isYoutube,
    canDownload,
    canSentToAdsManager,
    date,
    galleryData,
    isGallery,
  } = attach;

  const [videoUrl, setVideoUrl] = useState(defaultVideoUrl);
  const [imageUrl, setImageUrl] = useState(thumbnailUrl || url);

  useEffect(() => {
    if (!videoUrl && !imageUrl) {
      if (defaultVideoUrl) {
        setVideoUrl(defaultVideoUrl);
      } else {
        setImageUrl(thumbnailUrl || url);
      }
    }
  }, [defaultVideoUrl, thumbnailUrl, url]);

  const [currentIndex, setCurrentIndex] = useState<number>(() => {
    if (!isGallery) {
      return 0;
    }
    if (videoUrl) {
      return (
        galleryData?.findIndex(
          (item) => item.secureUrl === videoUrl || item.videoUrl === videoUrl
        ) || 0
      );
    }
    return (
      galleryData?.findIndex(
        (item) =>
          item.url === imageUrl || item.secureUrl === imageUrl || item.thumbnailUrl === imageUrl
      ) || 0
    );
  });

  const pauseAllVideo = () => {
    const videos = document.querySelectorAll('.mediaGalleryVideo');
    videos.forEach((item) => item?.pause());
  };

  const handleNextClick = () => {
    if (!galleryData || currentIndex === galleryData?.length - 1) {
      return;
    }
    pauseAllVideo();
    setCurrentIndex(currentIndex + 1);
  };

  const handlePrevClick = () => {
    if (!galleryData || currentIndex === 0) {
      return;
    }
    pauseAllVideo();
    setCurrentIndex(currentIndex - 1);
  };

  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCloseModal?.();
      }
      if (e.key === 'ArrowRight') {
        handleNextClick();
      }
      if (e.key === 'ArrowLeft') {
        handlePrevClick();
      }
    };

    window.addEventListener('keyup', onKeyPress);

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  }, [currentIndex]);

  const tracking = useTracking();
  const count = pick(attach, [
    'viewCount',
    'likeCount',
    'commentCount',
    'impressions',
    'reach',
    'engagement',
  ]);

  const hasCounts = compact(Object.values(count));

  if (isYoutube) {
    if (!thumbnailUrl) {
      return null;
    }
    const list = thumbnailUrl.split('/');
    const videoId = list[list.length - 2];

    return (
      <Drawer
        rootKey={`media-${projectId}`}
        backdropClassName={styles.backdrop}
        className={styles.container}
        fullView
        position="center"
        theme="dark"
      >
        <YouTube videoId={videoId} opts={{ width: '640', height: '360' }} />
      </Drawer>
    );
  }

  const videoSrc = finalVideoUrl || videoUrl;

  const handleVideoError = () => {
    setVideoUrl(secureUrl);

    if (instagramUrl) {
      window.open(instagramUrl);
      onCloseModal?.();
    }
  };

  const handleDownload = () => {
    if (!secureUrl) return;
    const filename = originalFilename || secureUrl?.split('\\')?.pop()?.split('/')?.pop();
    const finalLink = file?.bcaTaggedUrl || secureUrl;
    downloadFile(finalLink, filename);
    tracking.trackEvent({ element: 'download', event: 'click', payload: { secureUrl } });
  };

  const handleExportClick = () => {
    tracking.trackEvent({ element: 'send_to_ads_manager', event: 'click', payload: { secureUrl } });
  };

  const onError = () => {
    if (!instagramUrl) return;

    fetchQuery<queryType>(environment, MediaQuery, { url: instagramUrl }).subscribe({
      next: (result) => {
        const newUrl = result?.instagramOembed?.thumbnailUrl;

        if (newUrl) setImageUrl(newUrl);
      },
    });
  };

  if (!projectId) {
    return null;
  }

  return (
    <Drawer
      rootKey={`media-${projectId}`}
      backdropClassName={styles.backdrop}
      className={styles.container}
      fullView
      position="center"
      theme="dark"
    >
      <div className={styles.wrapper}>
        <div className={`${className} ${styles.root}`}>
          <div className={styles.container}>
            <div className={styles.galleryContainer}>
              {isGallery && (
                <div
                  onClick={handlePrevClick}
                  className={classnames(styles.arrow, { [styles.hidden]: currentIndex <= 0 })}
                >
                  <Icon name={'Arrow-small-left'} size={48} />
                </div>
              )}
              {isGallery && (
                <div className={styles.sliderContainer}>
                  {galleryData?.map((item) => (
                    <div
                      className={styles.slide}
                      style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                    >
                      {item.videoUrl ? (
                        <video
                          onError={handleVideoError}
                          controls
                          className={classnames('mediaGalleryVideo', styles.image)}
                          src={item.videoUrl}
                          poster={item.thumbnailUrl || undefined}
                          autoPlay={isAutoplay}
                        />
                      ) : (
                        <img
                          src={item.url || item.thumbnailUrl}
                          className={styles.image}
                          onError={onError}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
              {!isGallery &&
                (videoSrc ? (
                  <video
                    onError={handleVideoError}
                    controls
                    className={styles.image}
                    src={videoSrc}
                    poster={thumbnailUrl || undefined}
                    autoPlay={isAutoplay}
                  />
                ) : (
                  imageUrl && (
                    <img
                      src={imageUrl}
                      className={styles.image}
                      onError={onError}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  )
                ))}

              {isGallery && (
                <div
                  onClick={handleNextClick}
                  className={classnames(styles.arrow, {
                    [styles.hidden]: currentIndex === (galleryData?.length || 0) - 1,
                  })}
                >
                  <Icon name={'Arrow-small-right'} size={48} />
                </div>
              )}
            </div>
            {(caption || hasCounts.length > 0) && (
              <div className={thumbnailUrl || videoUrl ? styles.caption : styles.singleCaption}>
                <Text text={caption} className={styles.text} />
                {type !== 'simple' && (
                  <div className={styles.counts}>
                    <MediaCount {...count} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <footer className={styles.footer}>
          <div>{date && <Text color="white" text={createDate(date)} />}</div>
          <div className={styles.controls}>
            {canSentToAdsManager && (
              <div className={styles.control}>
                <ExportContentToAdsManager
                  file={file}
                  anchor={
                    <TextButton
                      color="normal"
                      theme="dark"
                      iconName="adsManager"
                      onClick={handleExportClick}
                    />
                  }
                  type={type || ''}
                  mediaUrls={[secureUrl || '']}
                  dropdownPosition="topRight"
                  independent={false}
                />
              </div>
            )}
            {canDownload && (
              <div className={styles.control}>
                <TextButton
                  color="normal"
                  theme="dark"
                  iconName="download"
                  onClick={handleDownload}
                />
              </div>
            )}
          </div>
        </footer>
      </div>
    </Drawer>
  );
};

export default track({ page: 'media_modal' })(MediaDrawer);
