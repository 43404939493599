import React, { useState, useContext } from 'react';

import styles from './DeleteBriefCreative.pcss';

import deleteBriefCreative from 'Mutations/DeleteBriefCreative.Mutation';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  briefId: string;
  briefCreativeId: string;
  callback: () => void;
}

const DeleteCreative: React.FC<Props> = (props) => {
  const { briefId, briefCreativeId, callback } = props;

  const [loading, setLoading] = useState(false);

  const { closeDrawer } = useContext(DrawerContext);

  const handleBtnClick = () => {
    setLoading(true);
    deleteBriefCreative({ id: briefCreativeId, briefId }, handleCreativeDeleteDone);
  };

  const handleCreativeDeleteDone = () => {
    if (callback) {
      callback();
    }
    setLoading(false);
    closeDrawer(`delete-brief-creative-${briefCreativeId}`);
  };

  return (
    <Drawer rootKey={`delete-brief-creative-${briefCreativeId}`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <div className={styles.content}>
          <Text type="d2" msg="delete_campaign_creative.title" className={styles.title} />
          <Text type="md" msg="delete_campaign_creative.descr" />
        </div>
        <Button
          color="black"
          loading={loading}
          msg="delete_campaign_creative.btn"
          onClick={handleBtnClick}
        />
      </div>
    </Drawer>
  );
};

export default DeleteCreative;
