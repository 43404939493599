import React, { useContext } from 'react';

import styles from './ContractorBanner.pcss';

import Text from 'Components/ui/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import logo from 'Images/general/logo_white_bg.svg';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const ContractorBanner = () => {
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const onReadMoreClick = () => {
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.DOWNLOAD_APP, attach: {} },
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.imageContainer}>
        <img src={logo} />
      </div>
      <Text msg="general.download_the_app" />
      <TextButton
        className={styles.link}
        msg="general.read_more"
        onClick={onReadMoreClick}
        size="lg"
      />
    </div>
  );
};

export default ContractorBanner;
