import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import classnames from 'classnames';

import styles from './CreatorDetails.pcss';

import { getCreatorData } from 'Util/creatorData';
import { createCountriesList } from 'Util/dataFormatter';
import Avatar from 'Atoms/Avatar/Avatar';
import Text from 'Components/ui/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import CreatorBadge from 'Molecules/CreatorBadge/CreatorBadge';
import BlockedUsername from 'Molecules/BlockedUsername/BlockedUsername';
import { CreatorBadgeEnum } from 'Types/enums';
import { IconType } from 'Types/common';
import {
  CreatorDetails_creator$key,
  CreatorDetails_creator$data,
} from 'GraphTypes/CreatorDetails_creator.graphql';
import { normalizeFragmentRef } from 'Util/normalizeFragmentRef';
import Tag from 'Components/ui/Tag/Tag';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { createPercent, createShortNumber } from 'Util/numberFormatter';
import RegistrationMark from 'Molecules/RegistrationMark/RegistrationMark';

interface Props {
  creator: CreatorDetails_creator$key;
  shipment?: boolean;
  disableBadge?: boolean;
  isBlockedView?: boolean;
  shipmentReceiverName?: string;
  showUnlockCreatorBanner?: () => void;
}

const CreatorDetails: React.FC<Props> = (props) => {
  const {
    creator,
    shipment,
    disableBadge,
    shipmentReceiverName,
    isBlockedView,
    showUnlockCreatorBanner,
  } = props;

  const data = useFragment<KeyType<CreatorDetails_creator$data>>(
    graphql`
      fragment CreatorDetails_creator on Creator {
        id
        ratingVotes {
          totalCount
        }
        ownership {
          owner {
            id
            rating {
              averageScore
              voteCount
            }
          }
        }
        profile {
          badges {
            name
          }
          countries {
            name
          }
          categories {
            name
          }
          profilePictureFile {
            url
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
          shippingRecipientLastName
          shippingRecipientFirstName
        }
        type
        username
        ... on InstagramCreator {
          user {
            estimatedLikes
            followedByCount
            engagementRate
            averageViews
          }
        }
        ... on YoutubeCreator {
          channel {
            id
            name
          }
        }
        ... on TiktokCreator {
          user {
            name
            engagementRate
            followedByCount
            averageViews
          }
          userInsightsData {
            engagementRate
            followersCount
          }
        }
      }
    `,
    normalizeFragmentRef<CreatorDetails_creator$key>(creator)
  );

  if (!data) return null;

  const { avatarUrl, username, iconColored, link } = useMemo(() => {
    return getCreatorData(data);
  }, [data]);

  const countries = data?.profile?.countries;
  const engagementRate = data.user?.engagementRate;
  const followers = data.user?.followedByCount;

  const followedByCountText = createShortNumber(followers);
  const erText = createPercent(engagementRate);

  const averageScore = data?.ownership?.owner.rating?.averageScore;
  const averageScoreText = averageScore ? averageScore.toFixed(2) : '—';
  const voteCount = data?.ownership?.owner.rating?.voteCount;
  const voteCountText = voteCount ? ` (${voteCount})` : '';
  const isRegistered = !!data.ownership?.owner.id;

  const countriesList = useMemo(() => {
    return createCountriesList(countries);
  }, [countries]);

  const categories = data.profile?.categories;

  if (!data) return null;

  const badges = data?.profile?.badges;

  return (
    <div className={styles.creatorInfo}>
      <div className={styles.creatorData}>
        <div className={styles.details}>
          <div className={styles.avatarWrap}>
            <Avatar src={avatarUrl} size={shipment ? 40 : 48} />
            <div className={styles.platform}>
              <Icon name={iconColored as IconType['name']} size="sm" />
            </div>
          </div>
          <div className={styles.creatorName}>
            {isBlockedView && showUnlockCreatorBanner ? (
              <BlockedUsername
                creatorId={data.id}
                onLockClick={showUnlockCreatorBanner}
                textClassName={styles.blockedUsername}
              />
            ) : (
              <SmartLink path={link} target="_blank">
                <Text
                  type={shipment ? 'md' : 'h2'}
                  text={shipment ? shipmentReceiverName : username}
                  className={classnames(styles.username, { [styles.shipment]: shipment })}
                />
              </SmartLink>
            )}
            <Text
              type={'label'}
              text={shipment ? username : countriesList}
              className={styles.countries}
            />
          </div>
        </div>
        {!shipment && (
          <>
            <div className={styles.categories}>
              {categories?.map((category) => (
                <Tag
                  needMargin={false}
                  hover={false}
                  text={category.name}
                  key={category.name}
                  color="grey"
                />
              ))}
            </div>
            <div className={styles.followers}>
              <Text type="md" text={followedByCountText || '—'} />
            </div>
            <div className={styles.er}>
              <Text type="md" text={erText || '—'} />
            </div>
            <div className={styles.profile}>
              <div className={styles.profileInfo}>
                {isRegistered && (
                  <div className={styles.registartionMark}>
                    <RegistrationMark size="sm" tooltipKey={data.id} />
                  </div>
                )}
                {!disableBadge && (
                  <div className={styles.badges}>
                    {badges?.map((badge) => {
                      if (!badge.name) return null;
                      return (
                        <CreatorBadge
                          key={badge.name}
                          badge={badge.name as CreatorBadgeEnum}
                          withText={false}
                          withTooltip
                          tooltipId={`creator_badge_${badge.name}_${data.id}`}
                          className={styles.badge}
                          iconClassName={styles.badgeIcon}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              <div className={styles.profileInfo}>
                {averageScoreText && (
                  <Tag
                    icon="Star"
                    iconPosition="left"
                    color="white"
                    needMargin={false}
                    text={`${averageScoreText}${voteCountText}`}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreatorDetails;
