import React from 'react';

import styles from './CustomListUploadPlatformSelect.pcss';

import Text from 'Components/ui/Text/Text';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { CreatorImportPlatform } from 'GraphTypes/ImportCreatorsToCustomListMutation.graphql';

interface Props {
  platform: CreatorImportPlatform | null;
  setPlatform: (platform: CreatorImportPlatform) => void;
  supportedPlatformList: CreatorImportPlatform[];
}

const CustomListUploadPlatformSelect: React.FC<Props> = (props) => {
  const { platform, setPlatform, supportedPlatformList } = props;

  return (
    <>
      <div className={styles.attachTitle}>
        <Text type="md" msg="invite_outreach_creators_modal.step_platform" />
      </div>
      <Dropdown
        value={
          <Text
            type="md"
            className={styles.dropdownValue}
            msg={
              !platform
                ? 'custom_list_create_modal.select_other_platform'
                : `invite_outreach_creators_modal.platform.${platform.toLowerCase()}`
            }
          />
        }
        className={styles.dropdownPlatform}
      >
        <DropdownGroup className={styles.dropdownMenu}>
          {supportedPlatformList.map((item) => {
            const isActive = platform === item;

            const handleSelectPlatform = () => {
              setPlatform(item);
            };

            return (
              <DropdownGroupItem key={item}>
                <AlterButton
                  msg={`invite_outreach_creators_modal.platform.${item.toLowerCase()}`}
                  onClick={handleSelectPlatform}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownGroupItem>
            );
          })}
        </DropdownGroup>
      </Dropdown>
    </>
  );
};

export default CustomListUploadPlatformSelect;
