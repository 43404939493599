import React, { Fragment, useContext, useEffect } from 'react';

import { InviteOutreachCreatorsContext, SequenceStep } from '../InviteOutreachCreatorsContext';
import { sequenceDefaultEmptyStep } from '../InviteOutreachCreatorsProvider';
import DeleteSequenceItemDrawer from '../DeleteSequenceItemDrawer/DeleteSequenceItemDrawer';

import styles from './SequenceSettings.pcss';
import SequenceItem from './SequenceItem/SequenceItem';
import SequenceNewItem from './SequenceItem/SequenceNewItem';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  onChangeData: (data: Partial<SequenceStep>) => void;
}

const SequenceSettings: React.FC<Props> = (props) => {
  const { onChangeData } = props;
  const { openDrawer } = useContext(DrawerContext);
  const {
    setCreatingTemplateStatus,
    setActiveStep,
    activeStep,
    stepsWithError,
    setStepsWithErrorList,
    sequenceList,
    setSequenceList,
  } = useContext(InviteOutreachCreatorsContext);

  const addToSequenceNewElement = () => {
    setActiveStep(sequenceList.length);
    setSequenceList((list) => {
      return [...list, { ...sequenceDefaultEmptyStep, delayInDays: 1 }];
    });
  };

  const handleAddEmailClick = () => {
    addToSequenceNewElement();
  };

  const handleStepSelect = (index: number) => {
    setActiveStep(index);
  };

  const handleStepRemove = (index: number) => {
    setSequenceList((list) => {
      return list.filter((_, idx) => idx !== activeStep);
    });
    if (index === 0) {
      setActiveStep(0);
    } else {
      setActiveStep(index - 1);
    }
  };

  const handleRemoveActiveStep = () => {
    handleStepRemove(activeStep);
  };

  useEffect(() => {
    const invalidSteps = checkStepsValidation();
    setStepsWithErrorList(invalidSteps.length > 0 ? invalidSteps : []);
  }, [sequenceList]);

  const handleSaveSequenceClick = () => {
    const invalidSteps = checkStepsValidation();
    if (invalidSteps.length) {
      setStepsWithErrorList(invalidSteps);
      return;
    }
    setCreatingTemplateStatus(true);
    openDrawer('sequence-create');
  };

  const checkStepsValidation = () => {
    const stepsWithError: number[] = [];
    sequenceList.forEach((step, idx) => {
      if (!step.template?.subject || !step.validTemplate) {
        stepsWithError.push(idx);
      }
    });
    return stepsWithError;
  };

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {sequenceList.map((item, idx) => (
          <Fragment key={item.id || `${item.template?.id}_${idx}`}>
            {idx > 0 && (
              <Text
                type="md"
                msg="invite_outreach_creators_modal.sequence.delay"
                className={styles.firstStepText}
              />
            )}
            <SequenceItem
              data={item}
              index={idx}
              error={stepsWithError.includes(idx)}
              isActive={idx === activeStep}
              isLastInList={sequenceList.length === 1}
              onSelect={handleStepSelect}
              onRemove={handleStepRemove}
              onChangeData={onChangeData}
            />
          </Fragment>
        ))}
      </div>
      <div className={styles.actions}>
        <SequenceNewItem onClick={handleAddEmailClick} />
        <AlterButton
          type="white"
          bordered
          disabled={stepsWithError.length > 0}
          className={styles.saveButton}
          textByCenter
          onClick={handleSaveSequenceClick}
          msg="invite_outreach_creators_modal.editor.save_sequence_template"
        />
      </div>
      <DeleteSequenceItemDrawer onConfirm={handleRemoveActiveStep} />
    </div>
  );
};

export default SequenceSettings;
