import React, { useState } from 'react';
import track, { useTracking } from 'react-tracking';
import type { PlacesType } from 'react-tooltip';

import styles from './ExportContentToAdsManager.css';

import Dropdown from 'Molecules/DropDown/DropDown';
import SnapchatAdsManager from 'Molecules/SnapchatAdsManager/SnapchatAdsManager';
import FacebookAdsManager from 'Molecules/FacebookAdsManager/FacebookAdsManager';
import TiktokAdsManager from 'Molecules/TiktokAdsManager/TiktokAdsManager';
import TextButton from 'Atoms/TextButton/TextButton';
import Spinner from 'Atoms/Spinner/Spinner';
import Icon from 'Atoms/Icon/Icon';
import { dropDownPosition, File, IconType } from 'Types/common';
import { IMAGE, VIDEO } from 'Constants/general';

const LOADING = 'loading';
const FAILURE = 'failure';
const COMPLETION = 'completion';

interface Props {
  mediaUrls: string[];
  file: File;
  type: string;
  anchor?: JSX.Element;
  dropdownPosition?: dropDownPosition;
  independent?: boolean;
}

const ExportContentToAdsManager: React.FC<Props> = (props) => {
  const { mediaUrls, file, type, anchor, dropdownPosition, independent = true } = props;

  const [snapchatState, setSnapchatState] = useState('');
  const [facebookState, setFacebookState] = useState('');
  const [tiktokState, setTiktokState] = useState('');

  const tracking = useTracking();

  const handleFacebookLoading = () => {
    setFacebookState(LOADING);
  };

  const handleFacebookComplete = () => {
    setFacebookState(COMPLETION);
    setTimeout(() => {
      setFacebookState('');
    }, 5000);
  };

  const handleFacebookFailed = () => {
    setFacebookState(FAILURE);
    setTimeout(() => {
      setFacebookState('');
    }, 5000);
  };

  const handleSnapchatLoading = () => {
    setSnapchatState(LOADING);
  };

  const handleSnapchatComplete = () => {
    setSnapchatState(COMPLETION);
    setTimeout(() => {
      setSnapchatState('');
    }, 5000);
  };

  const handleSnapchatFailed = () => {
    setSnapchatState(FAILURE);
    setTimeout(() => {
      setSnapchatState('');
    }, 5000);
  };

  const handleTiktokLoading = () => {
    setTiktokState(LOADING);
  };

  const handleTiktokComplete = () => {
    setTiktokState(COMPLETION);
    setTimeout(() => {
      setTiktokState('');
    }, 5000);
  };

  const handleTiktokFailed = () => {
    setTiktokState(FAILURE);
    setTimeout(() => {
      setTiktokState('');
    }, 5000);
  };

  const getIcon = (valueState: string, defaultIcon: IconType['name']) => {
    if (valueState === LOADING) {
      return <Spinner size="sm" />;
    } else if (valueState === COMPLETION) {
      return <Icon name="check" color="base" />;
    } else if (valueState === FAILURE) {
      return <Icon name="attention" color="red" />;
    }

    return <Icon name={defaultIcon} />;
  };

  const getStatusText = (valueState: string, adsName: string) => {
    const value = valueState ? `.${valueState.toLowerCase()}` : '';

    return `chat.messages.message.send_to_${adsName}${value}`;
  };

  const checkSnapchatExportAvaiable = () => {
    const { bytes, width, height, type } = file;

    if (type === VIDEO) {
      if (Number(width) < 1081 || Number(height) < 1920) {
        return false;
      }
    }

    if (type === IMAGE) {
      if (
        Number(bytes) > 5e6 ||
        Number(width) < 1080 ||
        Number(height) < 1920 ||
        Number(width) / Number(height) !== 9 / 16
      ) {
        return false;
      }
    }

    return true;
  };

  const snapchatDisableTooltip =
    type === VIDEO ? 'tooltip.video_snapchat_fail' : 'tooltip.image_snapchat_fail';
  const snapchatAvaiable = checkSnapchatExportAvaiable();

  const list = [
    {
      id: 'send_to_tiktok',
      wrap: (
        <TiktokAdsManager
          mediaUrls={mediaUrls}
          type={type}
          callLoading={handleTiktokLoading}
          callSuccess={handleTiktokComplete}
          callFail={handleTiktokFailed}
          disabled={tiktokState !== ''}
          independent={independent}
        />
      ),
      tooltipData:
        tiktokState === FAILURE
          ? {
              id: 'send_to_tiktok',
              tooltipMsg: 'chat.messages.message.send.failure',
              place: 'left' as PlacesType,
            }
          : undefined,
      isDisabled: tiktokState === LOADING,
      textData: {
        leftIcon: getIcon(tiktokState, 'tiktokColored'),
        textData: { msg: getStatusText(tiktokState, 'tiktok') },
      },
    },
    {
      id: 'send_to_fb',
      wrap: (
        <FacebookAdsManager
          mediaUrls={mediaUrls}
          type={type}
          callLoading={handleFacebookLoading}
          callSuccess={handleFacebookComplete}
          callFail={handleFacebookFailed}
          disabled={facebookState !== ''}
          independent={independent}
        />
      ),
      tooltipData:
        facebookState === FAILURE
          ? {
              id: 'send_to_fb',
              tooltipMsg: 'chat.messages.message.send.failure',
              place: 'left' as PlacesType,
            }
          : undefined,
      isDisabled: facebookState === LOADING,
      textData: {
        leftIcon: getIcon(facebookState, 'facebookColored'),
        textData: { msg: getStatusText(facebookState, 'fb') },
      },
    },
    {
      id: 'send_to_snapchat',
      wrap: (
        <SnapchatAdsManager
          mediaUrls={mediaUrls}
          type={type}
          callLoading={handleSnapchatLoading}
          callSuccess={handleSnapchatComplete}
          callFail={handleSnapchatFailed}
          disabled={snapchatState !== '' || !snapchatAvaiable}
          independent={independent}
        />
      ),
      tooltipData:
        snapchatState === FAILURE || !snapchatAvaiable
          ? {
              id: 'send_to_snapchat',
              tooltipMsg: !snapchatAvaiable
                ? snapchatDisableTooltip
                : 'chat.messages.message.send.failure',
              place: 'left' as PlacesType,
            }
          : undefined,
      isDisabled: !snapchatAvaiable || snapchatState === LOADING,
      textData: {
        leftIcon: getIcon(snapchatState, 'snapchatColored'),
        textData: { msg: getStatusText(snapchatState, 'snapchat') },
      },
    },
  ];

  const handleAnchorClick = () => {
    tracking.trackEvent({ event: 'click' });
  };

  const exportFailed =
    tiktokState === FAILURE || snapchatState === FAILURE || facebookState === FAILURE;

  return (
    <div className={styles.root}>
      <div className={styles.adsWrapper}>
        <Dropdown
          position={dropdownPosition}
          anchor={
            anchor || (
              <TextButton
                color="normal"
                mainColor={exportFailed ? 'red' : undefined}
                flex="column"
                leftIconName={exportFailed ? 'attention' : 'adsManager'}
                msg={
                  exportFailed
                    ? 'chat.messages.message.send_to_ads.failed'
                    : 'chat.messages.message.send_to_ads'
                }
                className={styles.adsManager}
                onClick={handleAnchorClick}
              />
            )
          }
          list={list}
        />
      </div>
    </div>
  );
};

export default track({ element: 'content_export' })(ExportContentToAdsManager);
