import React, { useMemo, useContext, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import classnames from 'classnames';

import styles from './PortfolioContent.pcss';
import PortfolioContentQuery from './PortfolioContent.Query';
import PortfolioItem from './PortfolioItem/PortfolioItem';
import PortfolioItemResume from './PortfolioItemResume/PortfolioItemResume';
import PortfolioItemMedia from './PortfolioItemMedia/PortfolioItemMedia';

import Slider, { Slide as SlideType } from 'Components/ui/Slider/Slider';
import { amplitude } from 'Helpers/amplitude';
import { MEDIA } from 'Constants/modals';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';
import { PortfolioContentQuery as QueryType } from 'GraphTypes/PortfolioContentQuery.graphql';
import createProjectAnalyticsEventMutation from 'Mutations/CreateProjectAnalyticsEvent.Mutation';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';
import Media from 'Modal/advertiser/MediaDrawer/MediaDrawer';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  creatorId: string;
  parentRef: React.RefObject<HTMLElement>;
  index: number;
  projectId: string;
  portfolioTypeName: string;
  portfolioCategoryName: string;
  isNewCard?: boolean;
}

export type modalItemDataType = {
  videoUrl?: string;
  isVideo: boolean;
  secureUrl?: string;
  url?: string;
  thumbnailUrl?: string;
  date?: number;
};

type ModalParamsData = {
  videoUrl?: string;
  url?: string;
  thumbnailUrl?: string;
  instagramUrl?: string;
  secureUrl?: string;
  galleryData?: modalItemDataType[];
};

const PortfolioContent: React.FC<Props> = (props) => {
  const { creatorId, parentRef, projectId, portfolioTypeName, portfolioCategoryName, isNewCard } =
    props;

  const { dispatch: modalDispatch } = useContext(ModalContext);
  const { openDrawer } = useContext(DrawerContext);
  const [mediaState, setMediaState] = useState<ModalParamsData>({});

  const { creator } = useLazyLoadQuery<QueryType>(PortfolioContentQuery, {
    id: creatorId,
    portfolioTypeName,
    portfolioCategoryName,
  });

  if (!creator) return null;

  const handleImageClick = (data: ModalParamsData) => {
    setMediaState(data);
    openDrawer(`media-${creatorId}`);
  };

  const { portfolioItems, profile, medias } = creator;

  const videoResumeFile = profile?.videoResumeFile;
  const videoResumeUrl = videoResumeFile?.transformations?.autoUrl;

  const galleryData =
    portfolioItems?.edges
      ?.filter((item) => !!item && !!item.node && !!item.node.file)
      .map((item) => {
        const file = item?.node?.file;
        const itemType = file?.type;
        const isVideo = itemType === 'VIDEO';
        const finalUrlData = isVideo
          ? { videoUrl: file?.transformations?.autoUrl }
          : { url: file?.secureUrl };
        return {
          ...finalUrlData,
          isVideo,
          secureUrl: file?.secureUrl,
          thumbnailUrl: file?.thumbnailUrl,
          date: new Date(file?.createdAt as string).getTime(),
        };
      })
      .sort((a, b) => b.date - a.date)
      .slice(0, 9) || [];

  const galleryItems = useMemo(() => {
    const items: Array<
      {
        date?: number;
      } & SlideType
    > = [];
    if (portfolioItems?.edges?.length) {
      portfolioItems?.edges.forEach((item) => {
        if (!item?.node?.file) return;
        const file = item.node.file;
        const itemType = file?.type;
        const isVideo = itemType === 'VIDEO';
        const handleItemClick = () => {
          const finalUrlData = isVideo
            ? { videoUrl: file?.transformations?.autoUrl }
            : { url: file?.secureUrl };
          handleImageClick({
            ...finalUrlData,
            secureUrl: file.secureUrl,
            thumbnailUrl: file?.thumbnailUrl,
            galleryData,
          });
          createProjectAnalyticsEventMutation({ projectId, portfolioClicked: true });
        };
        const portfolioThumbnailUrl = isVideo
          ? file?.transformations?.portfolioSliderThumbnailUrl
          : file.transformations?.collageThumbnailUrl;
        const hoverUrl = isVideo ? file.transformations?.portfolioSliderUrl : undefined;
        items.push({
          key: item?.node?.file?.id,
          date: new Date(item?.node?.file?.createdAt as string).getTime(),
          component: {
            // @ts-ignore
            element: (
              <PortfolioItem
                {...item?.node?.file}
                thumbnailUrl={portfolioThumbnailUrl}
                videoUrl={hoverUrl}
                onClick={handleItemClick}
                parentRef={parentRef}
              />
            ),
          },
        });
      });
    }
    // If needed add media from social network
    if (medias?.edges?.length && !items.length) {
      medias?.edges?.forEach((post) => {
        const handleItemClick = () => {
          handleImageClick({
            thumbnailUrl: post?.node?.thumbnailUrl,
            instagramUrl: post?.node?.instagramUrl,
          });
          createProjectAnalyticsEventMutation({ projectId, portfolioClicked: true });
        };
        items.push({
          key: post?.node?.id,
          date: new Date(post?.node?.createdAt as string).getTime(),
          component: {
            // @ts-ignore
            element: (
              <PortfolioItemMedia {...post?.node} parentRef={parentRef} onClick={handleItemClick} />
            ),
          },
        });
      });
    }
    return items;
  }, [videoResumeFile, portfolioItems?.edges]);

  if (!galleryItems.length) {
    return (
      <div className={classnames(styles.stub, { [styles.inNewCard]: isNewCard })}>
        <div className={styles.stubWrap}>
          <Icon name="Photo-disabled" />
          <Text type="md" msg="creator.no_portfolio" className={styles.stubTitle} />
        </div>
      </div>
    );
  }

  const sortedGalleryItems = galleryItems
    .sort((a, b) => Number(b.date) - Number(a.date))
    .slice(0, 9);

  if (videoResumeFile) {
    const handleVideoClick = () => {
      amplitude.sendEvent({
        id: '54',
        category: 'client_video_resume',
        name: 'playback',
        param: {},
      });
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: MEDIA,
          attach: {
            videoUrl: videoResumeUrl,
            secureUrl: videoResumeFile?.secureUrl,
            isAutoplay: true,
          },
        },
      });
      createProjectAnalyticsEventMutation({ projectId, portfolioClicked: true });
    };
    const portfolioSliderUrl = videoResumeFile?.transformations?.portfolioSliderUrl;
    const portfolioSliderThumbnailUrl = videoResumeFile?.thumbnailUrl;
    sortedGalleryItems.push({
      key: videoResumeFile.createdAt,
      date: new Date(videoResumeFile.createdAt as string).getTime(),
      component: {
        // @ts-ignore
        element: (
          <PortfolioItemResume
            src={portfolioSliderUrl}
            poster={portfolioSliderThumbnailUrl}
            parentRef={parentRef}
            onClick={handleVideoClick}
          />
        ),
      },
    });
  }

  return (
    <div className={classnames(styles.contentRoot, { [styles.inNewCard]: isNewCard })}>
      <div className={styles.sliderContainer}>
        <Slider classNameWithSize={styles.portfolioSlider} slides={sortedGalleryItems} />
      </div>
      <Media projectId={creatorId} attach={{ ...mediaState, isAutoplay: false, isGallery: true }} />
    </div>
  );
};

export default PortfolioContent;
