import React, {
  useContext,
  useState,
  useCallback,
  ChangeEventHandler,
  useEffect,
  useMemo,
} from 'react';
import { useFragment, graphql } from 'react-relay';
import debounce from 'lodash/debounce';

import styles from './BriefPriceRange.pcss';
import { calculateRecommendedPrice } from './util';

import Warning from '../BriefTemplateContent/Warning/Warning';

import { amplitude } from 'Helpers/amplitude';
import { createSum } from 'Util/numberFormatter';
import updateBrief from 'Mutations/UpdateBrief.Mutation';
import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import { BriefContext } from 'AdvertiserPage/Brief/Brief.Context';

import { BriefPriceRange_campaign$key } from 'GraphTypes/BriefPriceRange_campaign.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

export const MIN_PRICE_AMOUNT = 30;

interface Props {
  briefId: string;
  campaign?: BriefPriceRange_campaign$key | null;
}

const BriefPriceRange: React.FC<Props> = (props) => {
  const { briefId, campaign } = props;

  const [warningMsg, setWarning] = useState('');

  const [briefState, briefDispatch] = useContext(BriefContext);
  const { showErrors } = briefState;

  const { openDrawer } = useContext(DrawerContext);

  const fragment = useFragment(
    graphql`
      fragment BriefPriceRange_campaign on Campaign {
        type
        useCase
        platform
        organization {
          currency
        }
        searchQuery {
          categories {
            id
            name
          }
          creatorBirthdateFrom
          creatorBirthdateTo
          followersFrom
          followersTo
          viewsMedianFrom
          viewsMedianTo
        }
        brief {
          ... on V2Brief {
            submitted
            priceLimitMin
            priceLimitMax
            briefCreatives: creatives {
              edges {
                node {
                  publishingRequired
                  mediaType
                  contentType
                  contentAmount
                  contentPlacement
                  v2ContentPlacement
                }
              }
            }
          }
        }
      }
    `,
    campaign
  );

  const priceLimitMin = fragment?.brief?.priceLimitMin;
  const priceLimitMax = fragment?.brief?.priceLimitMax;
  const useCase = fragment?.useCase;
  const currency = fragment?.organization?.currency;

  const recommendedPriceData = useMemo(
    () => calculateRecommendedPrice({ campaign: fragment }),
    [fragment]
  );
  const finalPrice = recommendedPriceData.finalPrice;
  const rows = recommendedPriceData.rows;

  const isPriceOptional = useMemo(() => {
    return ['PRODUCT_SEEDING', 'AFFILIATE_CAMPAIGN', 'TIKTOK_SHOP'].includes(useCase as string);
  }, [useCase]);

  useEffect(() => {
    let isPriceMaxValid = true;
    if (!isPriceOptional) {
      if (!priceLimitMax) {
        isPriceMaxValid = false;
      }
      if (priceLimitMax && priceLimitMax < MIN_PRICE_AMOUNT) {
        isPriceMaxValid = false;
        setWarning('brief_template.field.campaign_price_range.limit.error');
      } else if (priceLimitMin && priceLimitMin < MIN_PRICE_AMOUNT) {
        setWarning('brief_template.field.campaign_price_range.limit.error');
      } else if (priceLimitMax && priceLimitMin && priceLimitMax < priceLimitMin) {
        setWarning('brief_template.field.campaign_price_range.min_limit.error');
      } else if (priceLimitMax && finalPrice && priceLimitMax < finalPrice) {
        setWarning('brief_template.field.campaign_price_range.recommended_price.error');
      } else {
        setWarning('');
      }
    } else {
      setWarning('');
    }

    briefDispatch({ key: 'maxPriceValid', value: isPriceOptional ? true : isPriceMaxValid });
  }, [priceLimitMin, priceLimitMax, finalPrice, isPriceOptional]);

  const handleMinBlur = useCallback<ChangeEventHandler<HTMLInputElement>>(
    debounce((e) => {
      const newValue = e.target.value;
      briefDispatch({ key: 'briefIsSaving', value: true });
      updateBrief({ id: briefId, priceLimitMin: newValue ? +newValue : null });
    }, 500),
    []
  );

  const handleMaxBlur = useCallback<ChangeEventHandler<HTMLInputElement>>(
    debounce((e) => {
      const newValue = e.target.value;
      briefDispatch({ key: 'briefIsSaving', value: true });
      updateBrief({ id: briefId, priceLimitMax: newValue ? +newValue : null });

      if (finalPrice) {
        amplitude.sendEvent<464>({
          id: '464',
          category: 'brief',
          name: 'with_recommended_price_max_price_change',
          param: {
            recommendedPrice: finalPrice,
            maxPrice: newValue ? +newValue : undefined,
            bellowRecommendationPrice: newValue && finalPrice ? +newValue < finalPrice : undefined,
          },
        });
      }
    }, 500),
    [briefId, finalPrice]
  );

  const handleInfoClick = () => {
    openDrawer('recommended-price');
  };

  if (!useCase || useCase === 'PRODUCT_SEEDING') return <div className={styles.root} />;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Text msg="brief_template.field.campaign_price_range.title" />
        <Text type="label" msg="brief_template.field.campaign_price_range.descr" color="grey" />
        <div className={styles.range}>
          <Input
            value={priceLimitMin || ''}
            bordered
            placeholderMsg="general.min"
            onChange={handleMinBlur}
            hideCloseIcon={true}
            error={!!showErrors && !priceLimitMax && !priceLimitMin && !isPriceOptional}
          />
          <Text text="—" />
          <Input
            value={priceLimitMax || ''}
            bordered
            placeholderMsg="general.max"
            onChange={handleMaxBlur}
            hideCloseIcon={true}
            error={!!showErrors && !priceLimitMax && !isPriceOptional}
          />
        </div>
        {warningMsg && (
          <Warning
            titleMsg={warningMsg}
            error={true}
            textColor="red"
            withIcon={false}
            className={styles.warning}
          />
        )}
        <div className={styles.disclaimer}>
          {!!finalPrice && (
            <>
              <div className={styles.rows}>
                {rows.map((row) => {
                  return (
                    <div className={styles.row}>
                      <div>
                        <Text text={row.label} />
                        {row.subLabel && <Text type="label" color="grey" text={row.subLabel} />}
                      </div>
                      <Text text={createSum(row.price, currency)} />
                    </div>
                  );
                })}
              </div>
              <div className={styles.row}>
                <Text
                  color="green"
                  msg="brief_template.field.campaign_price_range.recommended_price"
                />
                <Text color="green" text={createSum(finalPrice, currency)} />
              </div>
            </>
          )}
          <Text
            type="label"
            color="grey"
            msg={
              finalPrice
                ? 'brief_template.field.campaign_price_range.disclaimer'
                : 'brief_template.field.campaign_price_range.empty.disclaimer'
            }
          />
          <Text
            type="label"
            color="purple"
            msg="brief_template.field.campaign_price_range.info"
            className={styles.info}
            handleClick={handleInfoClick}
          />
        </div>
      </div>
    </div>
  );
};

export default BriefPriceRange;
