import React from 'react';

import styles from '../SequenceItem.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { SequenceStep } from '../../../InviteOutreachCreatorsContext';

interface Props {
  disableEmptyValue: boolean;
  data: SequenceStep;
  handleDelayValue: (value: number) => void;
}

const DelaySelector: React.FC<Props> = (props) => {
  const { data, disableEmptyValue, handleDelayValue } = props;

  return (
    <div className={styles.mailOptions}>
      <Text type="md" text="Wait:" />
      <Dropdown
        showArrow={false}
        className={styles.delayInput}
        value={
          <Text
            type="md"
            className={styles.mailOptionValue}
            text={
              data?.delayInDays === 0
                ? '-'
                : `${data?.delayInDays || '0'} ${data?.delayInDays === 1 ? 'day' : 'days'}`
            }
          />
        }
      >
        <DropdownGroup align="left" className={styles.delayValueList}>
          {disableEmptyValue ? null : (
            <DropdownGroupItem
              handleClick={() => {
                handleDelayValue(0);
              }}
            >
              <AlterButton text={'-'} className={styles.delayValue} />
            </DropdownGroupItem>
          )}

          {[...Array(30).keys()].map((item) => (
            <DropdownGroupItem
              key={item}
              handleClick={() => {
                handleDelayValue(item + 1);
              }}
            >
              <AlterButton text={(item + 1).toString()} className={styles.delayValue} />
            </DropdownGroupItem>
          ))}
        </DropdownGroup>
      </Dropdown>
    </div>
  );
};

export default DelaySelector;
