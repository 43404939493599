import React, { FC } from 'react';

import styles from './OutreachBatchesControls.pcss';

import ProgressBar from 'Components/ui/ProgressBar/ProgressBar';
import Text from 'Components/ui/Text/Text';
import Toggle from 'Components/ui/Toggle/Toggle';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  handleInvite: () => void;
  showPercentages: boolean;
  onShowPercentagesClick: () => void;
}

const OutreachBatchesControls: FC<Props> = ({
  handleInvite,
  onShowPercentagesClick,
  showPercentages,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.controls}>
        <Text msg="campaign.outreach.percentage.toggle" type="md" />
        <Toggle onChange={onShowPercentagesClick} on={showPercentages} />
        <AlterButton
          msg="campaign.outreach.invite"
          type="black"
          className={styles.button}
          onClick={handleInvite}
        />
      </div>
    </div>
  );
};

export default OutreachBatchesControls;
