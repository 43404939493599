/**
 * @generated SignedSource<<68c43d44f5e1215eae497076eff78ac0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Locale = "EN" | "RU" | "%future added value";
export type OutreachStepBlueprint_ProjectEventType = "MESSAGE" | "OFFER" | "%future added value";
export type CreateOutreachSequenceBlueprintInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  name: string;
  steps: ReadonlyArray<OutreachStepBlueprintInput>;
};
export type OutreachStepBlueprintInput = {
  delayInDays?: number | null | undefined;
  projectEventType: OutreachStepBlueprint_ProjectEventType;
  startsAtHour: any;
  template?: OutreachTemplateInput | null | undefined;
  templateId?: string | null | undefined;
};
export type OutreachTemplateInput = {
  htmlBody: string;
  locale: Locale;
  subject: string;
};
export type CreateOutreachSequenceBlueprintMutation$variables = {
  input: CreateOutreachSequenceBlueprintInput;
};
export type CreateOutreachSequenceBlueprintMutation$data = {
  readonly createOutreachSequenceBlueprint: {
    readonly __typename: "AlreadyExists";
    readonly __typename: "AlreadyExists";
  } | {
    readonly __typename: "CreateOutreachSequenceBlueprintPayload";
    readonly outreachSequenceBlueprint: {
      readonly id: string;
      readonly name: string;
      readonly steps: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly delayInDays: number | null | undefined;
            readonly id: string;
            readonly projectEventType: OutreachStepBlueprint_ProjectEventType;
            readonly sequence: {
              readonly id: string;
            } | null | undefined;
            readonly startsAtHour: any;
            readonly template: {
              readonly htmlBody: string;
              readonly id: string;
              readonly subject: string;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type CreateOutreachSequenceBlueprintMutation = {
  response: CreateOutreachSequenceBlueprintMutation$data;
  variables: CreateOutreachSequenceBlueprintMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createOutreachSequenceBlueprint",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachSequenceBlueprint",
            "kind": "LinkedField",
            "name": "outreachSequenceBlueprint",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachStepBlueprintConnection",
                "kind": "LinkedField",
                "name": "steps",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OutreachStepBlueprintEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OutreachStepBlueprint",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startsAtHour",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "delayInDays",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectEventType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OutreachTemplate",
                            "kind": "LinkedField",
                            "name": "template",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "subject",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "htmlBody",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OutreachSequenceBlueprint",
                            "kind": "LinkedField",
                            "name": "sequence",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CreateOutreachSequenceBlueprintPayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOutreachSequenceBlueprintMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOutreachSequenceBlueprintMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d5efa97fe328c582222328ad70958c3d",
    "id": null,
    "metadata": {},
    "name": "CreateOutreachSequenceBlueprintMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOutreachSequenceBlueprintMutation(\n  $input: CreateOutreachSequenceBlueprintInput!\n) {\n  createOutreachSequenceBlueprint(input: $input) {\n    __typename\n    ... on CreateOutreachSequenceBlueprintPayload {\n      outreachSequenceBlueprint {\n        id\n        name\n        steps {\n          edges {\n            node {\n              id\n              startsAtHour\n              delayInDays\n              projectEventType\n              template {\n                id\n                subject\n                htmlBody\n              }\n              sequence {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    ... on AlreadyExists {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dc2bd50590409293809c8009d74ca99a";

export default node;
