import React, { PropsWithChildren, useState, useContext } from 'react';
import track, { useTracking } from 'react-tracking';

import { encodeQueryData } from 'Util/encodeQueryData';
import ExportFileStatus from 'Organisms/ExportFileStatus/ExportFileStatus';
import { FB_OAUTH_API, OAUTH_PROXY_URL } from 'Constants/general';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  type: string;
  mediaUrls: string[];
  disabled?: boolean;
  callLoading?: () => void;
  callSuccess?: () => void;
  callFail?: () => void;
  independent?: boolean;
}

const FacebookAdsManager: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, disabled, mediaUrls, type, callSuccess, callLoading, callFail, independent } =
    props;

  const [exportId, setExportId] = useState('');

  const { dispatch: modalDispatch } = useContext(ModalContext);
  const tracking = useTracking();

  const handleLoginToFacebook = () => {
    tracking.trackEvent({ event: 'click' });
    const params = {
      redirect_uri: `${OAUTH_PROXY_URL}`,
      client_id: process.env.FB_APP_ID,
      response_type: 'token',
      scope:
        'email,pages_read_engagement,pages_show_list,business_management,ads_management,instagram_basic,instagram_manage_insights,pages_manage_metadata',
      state: `origin=${location.origin}&route=/auth`,
    };
    const url = `${FB_OAUTH_API}${encodeQueryData(params)}`;
    (window as any).callSuccess = (socialIdentityId: string) => {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.FB_ADS_MANAGER,
          attach: {
            mediaUrls,
            type,
            socialIdentityId,
            independent,
            callback: handleExportStarted,
          },
        },
      });
    };
    (window as any).callFail = () => {
      if (callFail) {
        callFail();
      }
    };
    window.open(url, 'auth', 'width=500,height=500');
  };

  const handleExportStarted = (id: string) => {
    if (callLoading) {
      callLoading();
    }
    setExportId(id);
  };

  const handleExportResult = (value: boolean) => {
    setExportId('');
    if (value) {
      if (callSuccess) {
        callSuccess();
      }
    } else {
      if (callFail) {
        callFail();
      }
    }
  };

  return (
    <div onClick={disabled ? undefined : handleLoginToFacebook}>
      {children}
      {exportId && <ExportFileStatus id={exportId} onGetResult={handleExportResult} />}
    </div>
  );
};

export default track({
  element: 'add_to_fb',
})(FacebookAdsManager);
