import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CreateProjectMutation$data,
  CreateProjectMutation,
  CreateProjectInput,
} from 'GraphTypes/CreateProjectMutation.graphql';
import ErrorHandler from 'Util/errorHandler';

const mutation = graphql`
  mutation CreateProjectMutation($createdByAdmin: Boolean, $input: CreateProjectInput!) {
    createProject(input: $input) {
      newProjectEdge {
        node {
          price
          currency
          longlistCreator {
            interactedWith
          }
          invitedByOrganizationMember
          creator {
            username
            type
            ... on InstagramCreator {
              user {
                fullName
                estimatedLikes
                followedByCount
                engagementRate
              }
              businessAccountEnabled
              paidSocialEnabled
            }
            ... on YoutubeCreator {
              channel {
                name
                thumbnailUrl
                subscribers
              }
            }
            ... on TiktokCreator {
              tiktokUser: user {
                name
                followedByCount
                engagementRate
              }
            }
            ... on ContentCreator {
              avatarUrl
              name
              id
            }
            profile {
              estimatedPrice
              currency
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
          }
        }
      }
      campaign {
        projects(archived: false, orderBy: LAST_ADDED) {
          totalCount
          summary {
            totalPrice
          }
        }
        newCount: projects(archived: false, orderBy: LAST_ADDED, createdByAdmin: $createdByAdmin) {
          totalCount
        }
        unsentBriefs: projects(
          archived: false
          stages: [UNSENT]
          orderBy: LAST_ADDED
          createdByAdmin: $createdByAdmin
        ) {
          totalCount
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

interface DataInput {
  organizationId?: string;
  createdByAdmin?: boolean;
}

export default (
  data: CreateProjectInput & DataInput,
  resolve?: (response: CreateProjectMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();
  const { campaignId, creatorId, createdByAdmin, organizationId } = data;
  const variables = {
    input: {
      id,
      campaignId,
      creatorId,
    },
    createdByAdmin,
  };

  commitMutation<CreateProjectMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const campaign = store.get(campaignId);
      if (!campaign) return;
      const projects = campaign.getLinkedRecord('projects', {
        archived: false,
        orderBy: 'LAST_ADDED',
      });
      if (!projects) return;

      const totalCount = projects.getValue('totalCount');
      const newValue = Number(totalCount) + 1;
      projects.setValue(newValue, 'totalCount');
    },
    updater: (store) => {
      const campaign = store.get(campaignId);
      if (campaign) {
        const projects = campaign.getLinkedRecord('projects', {
          archived: false,
          orderBy: 'LAST_ADDED',
        });
        if (!projects) return;
        const mediaplan = ConnectionHandler.getConnection(campaign, 'Mediaplan_projects');

        if (mediaplan) {
          const response = store.getRootField('createProject');

          if (!response) return;

          const node = response.getLinkedRecord('newProjectEdge').getLinkedRecord('node');
          const edge = ConnectionHandler.createEdge(store, mediaplan, node, 'ProjectEdge');
          ConnectionHandler.insertEdgeBefore(mediaplan, edge);
        }
      }

      if (organizationId) {
        const organization = store.get(organizationId);
        if (!organization) return;

        const searchCreators = ConnectionHandler.getConnection(
          organization,
          'SearchResults_searchCreators'
        );
        if (searchCreators) {
          const edges = searchCreators.getLinkedRecords('edges');

          if (!edges) return;

          const nodes = edges.map((edge) => edge.getLinkedRecord('node'));
          const node = nodes.find(
            (node) => node?.getLinkedRecord('creator')?.getValue('id') === creatorId
          );

          if (node) {
            const project = store.get(id);

            if (project) node.setLinkedRecord(project, 'project');
          }
        }
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      ErrorHandler.error('Crash while creating project', { error });
      if (reject) {
        reject(error);
      }
    },
  });
};
