import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import styles from './CreatorModeration.pcss';

import { FAILED_REVIEW_REASON } from 'Constants/modals';
import updateCreatorProfile from 'Mutations/UpdateCreatorProfile.Mutation';
import markCreatorAsPassedReview from 'Mutations/MarkCreatorAsPassedReview.Mutation';
import Text from 'Components/ui/Text/Text';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';
import {
  Result,
  CreatorReviewReason,
  CreatorReviewSource,
} from 'GraphTypes/ModerationSettings_creator.graphql';

interface Props {
  profileId?: string;
  creatorId: string;
  searchable?: boolean;
  reviewType?: Result;
  reviewReason?: CreatorReviewReason | null;
  reviewSource?: CreatorReviewSource | null;
}

const CreatorModeration: React.FC<Props> = (props) => {
  const { profileId = '', creatorId, searchable, reviewType, reviewReason, reviewSource } = props;

  const intl = useIntl();

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const visibleOpt = [
    {
      value: intl.formatMessage({ id: 'creator.is_searchable' }),
      id: 'VISIBLE',
      isChecked: !!searchable,
    },
    {
      value: intl.formatMessage({ id: 'creator.is_no_searchable' }),
      id: 'HIDDEN',
      isChecked: searchable === false,
    },
  ];

  const reviewedOpt = [
    {
      value: intl.formatMessage({ id: 'creator.is_reviewed' }),
      id: 'SUCCESS',
      isChecked: reviewType === 'SUCCESS',
    },
    {
      value: intl.formatMessage({ id: 'creator.failed_review' }),
      id: 'FAILURE',
      isChecked: reviewType === 'FAILURE',
    },
  ];

  const handleReviewChange = (newReviewedByAdmin: any) => {
    if (reviewType === newReviewedByAdmin) return;

    if (newReviewedByAdmin === 'FAILURE') {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: { name: FAILED_REVIEW_REASON, attach: { creatorId } },
      });
    } else if (newReviewedByAdmin === 'SUCCESS') {
      markCreatorAsPassedReview({ creatorId });
    }
  };

  const handleSearchabeChange = (searchable: string) => {
    updateCreatorProfile({ id: profileId, searchable: searchable === 'VISIBLE' });
  };

  return (
    <>
      <ProgressiveInput
        type="radio"
        titleMsg="creator.searchable"
        className={styles.field}
        inputProps={{
          items: visibleOpt,
          bordered: true,
          handleClick: handleSearchabeChange,
          hideByAlphabet: true,
          withArrow: true,
        }}
      />
      <div className={styles.field}>
        <ProgressiveInput
          type="radio"
          titleMsg="creator.is_reviewed"
          inputProps={{
            items: reviewedOpt,
            bordered: true,
            handleClick: handleReviewChange,
            hideByAlphabet: true,
            withArrow: true,
          }}
        />
        <div className={styles.reason}>
          {reviewReason && (
            <Text
              type="label"
              className={styles.subtitle}
              msg={`failed_review_reason_modal.${reviewReason.toLowerCase()}`}
            />
          )}
          {reviewSource && (
            <Text
              type="label"
              className={styles.subtitle}
              msg={`failed_review_reason_modal.source.${reviewSource.toLowerCase()}`}
            />
          )}
        </div>
      </div>
      <div />
    </>
  );
};

export default CreatorModeration;
