import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import xor from 'lodash/xor';

import styles from './CreatorCategories.pcss';

import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import TextButton from 'Components/ui/TextButton/TextButton';
import Text from 'Components/ui/Text/Text';

interface Props {
  allCategories: {
    id: string;
    name: string;
    description?: string | null;
  }[];
  creatorCategories: {
    id: string;
    name: string;
    primary?: boolean;
  }[];
  admin?: boolean;
  maxCategoriesToChoose: number;
  onChange: (ids: string[]) => void;
  onPrimaryChange: (id: string) => void;
}

const CreatorCategories: React.FC<Props> = (props) => {
  const {
    allCategories,
    creatorCategories,
    maxCategoriesToChoose,
    admin,
    onChange,
    onPrimaryChange,
  } = props;

  const intl = useIntl();

  const creatorCategoryIds = useMemo(() => {
    return creatorCategories.map((item) => item.id);
  }, [creatorCategories]);

  const categoriesList = useMemo(() => {
    return allCategories.map((item) => {
      const isActive = creatorCategories.find((selectedItem) => selectedItem.id === item.id);
      const isDisabled = !isActive && creatorCategories.length >= maxCategoriesToChoose;
      const handlePrimaryChange = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (isActive?.id && onPrimaryChange) {
          onPrimaryChange(isActive?.id);
        }
      };
      const cta =
        isActive?.primary && admin ? (
          <Text type="md" msg="creator_statistic.categories.primary" className={styles.primary} />
        ) : (
          <TextButton
            color="purple"
            msg="creator_statistic.categories.set_primary"
            onClick={handlePrimaryChange}
          />
        );
      return {
        id: item.id,
        label: item.name,
        isChecked: !!isActive,
        isDisabled,
        subtitleData: admin ? undefined : { text: ` (${item.description})` },
        ctaBtn: isActive ? cta : undefined,
      };
    });
  }, [allCategories, creatorCategories]);

  const disabled = useMemo(() => {
    return creatorCategories.length > 0 && !admin;
  }, []);

  const creatorCategoriesList = creatorCategories.map((item) => {
    return { value: item.id, label: item.name };
  });

  const handleCategoriesChange = (newCategory: string) => {
    const newList = xor(creatorCategoryIds, [newCategory]);
    onChange(newList);
  };

  const handleCategoriesClear = () => {
    onChange([]);
  };

  const title = intl.formatMessage(
    { id: 'creator_profile.categories' },
    { amount: creatorCategoriesList.length, total: maxCategoriesToChoose }
  );

  return (
    <ProgressiveInput
      type="checkboxSelect"
      title={title}
      className={styles.field}
      inputProps={{
        limitForShow: 3,
        items: categoriesList,
        bordered: true,
        handleClick: handleCategoriesChange,
        handleClear: handleCategoriesClear,
        withArrow: true,
        withSearch: true,
        disabled,
      }}
      isDirty={Number(creatorCategoriesList.length) > 0}
    />
  );
};

export default CreatorCategories;
