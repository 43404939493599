import React from 'react';

import styles from './TinyBarChart.css';

import colors from 'Styles/vars/colors.css';
import { createPercent } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';

type BarType = {
  color?: string | null;
  value: number;
};

type ItemType = {
  name?: string | null;
  msg?: string | null;
  bars: BarType[];
};

interface Props {
  items: ItemType[];
}

const TinyBarChart: React.FC<Props> = (props) => {
  const { items } = props;

  let maxValue = 0;

  for (let index = 0; index < items.length; index++) {
    const bars = items[index].bars;
    for (let y = 0; y < bars.length; y++) {
      const bar = bars[y];
      maxValue = maxValue < bar.value ? bar.value : maxValue;
    }
  }

  return (
    <div className={styles.root}>
      {items.map((item) => {
        const { name, bars } = item;

        return (
          <div key={name} className={styles.item}>
            <div className={styles.bars}>
              {bars.map((bar) => {
                const { value, color = colors.colorBase } = bar;

                return (
                  <div key={value} className={styles.barWrap}>
                    <Text size="sm" weight="700" text={createPercent(value)} />
                    <div>
                      <div
                        className={styles.bar}
                        style={{ backgroundColor: color, height: `${(value / maxValue) * 144}px` }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <Text align="center" className={styles.title} text={name} />
          </div>
        );
      })}
    </div>
  );
};

export default TinyBarChart;
