import React from 'react';
import classnames from 'classnames';

import styles from './ProjectStatus.pcss';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Components/ui/Text/Text';
import { AdvertiserProjectStage } from 'GraphTypes/CreatorsListQuery.graphql';

interface Props {
  id: string;
  hasReaction: boolean;
  advertiserStage?: AdvertiserProjectStage | null;
}

const ProjectStatus: React.FC<Props> = (props) => {
  const { id, advertiserStage, hasReaction } = props;

  if (!advertiserStage) return null;

  const stageLabel = advertiserStage?.toLowerCase();

  return (
    <Tooltip
      place="top"
      id={`chat_stage_${stageLabel}_${id}`}
      tooltipMsg={hasReaction ? `project.stage.${stageLabel}.tooltip` : undefined}
    >
      <div className={classnames(styles.stage, stageLabel ? styles[stageLabel] : null)}>
        <Text type="md" msg={`project.stage.${stageLabel}`} />
      </div>
    </Tooltip>
  );
};

export default ProjectStatus;
