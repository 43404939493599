import React, { PropsWithChildren, useState, useContext } from 'react';
import track, { useTracking } from 'react-tracking';

import { encodeQueryData } from 'Util/encodeQueryData';
import ExportFileStatus from 'Organisms/ExportFileStatus/ExportFileStatus';
import { SNAPCHAT_OAUTH } from 'Constants/general';
import { SNAPCHAT } from 'Constants/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  type: string;
  mediaUrls: string[];
  disabled?: boolean;
  callLoading?: () => void;
  callSuccess?: () => void;
  callFail?: () => void;
  independent?: boolean;
}

const SnapchatAdsManager: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, disabled, mediaUrls, type, callSuccess, callLoading, callFail, independent } =
    props;

  const [exportId, setExportId] = useState('');

  const { dispatch: modalDispatch } = useContext(ModalContext);
  const tracking = useTracking();

  const handleLoginToSnapchat = () => {
    tracking.trackEvent({ event: 'click' });
    const path = location.origin;
    const params = {
      redirect_uri: `${path}${SNAPCHAT_OAUTH}`,
      client_id: process.env.SNAPCHAT_APP_ID,
      response_type: 'code',
      scope: 'snapchat-marketing-api',
    };
    const url = `https://accounts.snapchat.com/login/oauth2/authorize${encodeQueryData(params)}`;
    (window as any).callSuccess = () => {
      handleLoginSuccess();
    };
    (window as any).callFail = () => {
      if (callFail) {
        callFail();
      }
    };
    window.open(url, 'auth', 'width=500,height=500');
  };

  const handleLoginSuccess = () => {
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: SNAPCHAT,
        attach: { mediaUrls, type, callback: handleExportStarted, independent },
      },
    });
  };

  const handleExportStarted = (id: string) => {
    if (callLoading) {
      callLoading();
    }
    setExportId(id);
  };

  const handleExportResult = (value: boolean) => {
    setExportId('');
    if (value) {
      if (callSuccess) {
        callSuccess();
      }
    } else {
      if (callFail) {
        callFail();
      }
    }
  };

  return (
    <div onClick={disabled ? undefined : handleLoginToSnapchat}>
      {children}
      {exportId && <ExportFileStatus id={exportId} onGetResult={handleExportResult} />}
    </div>
  );
};

export default track({
  element: 'add_to_snapchat',
})(SnapchatAdsManager);
