import React from 'react';
import { QueryRenderer, graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';

import { createSearchData } from '../data';

import styles from './CreatorsSearchWithParams.pcss';

import environment from 'Api/Environment';
import { getCreatorData } from 'Util/creatorData';
import Avatar from 'Atoms/Avatar/Avatar';
import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Spinner from 'Atoms/Spinner/Spinner';
import { Currency, CampaignPlatform } from 'GraphTypes/SearchParamsQuery.graphql';
import { CreatorsSearchWithParams_campaign$key } from 'GraphTypes/CreatorsSearchWithParams_campaign.graphql';
import {
  CreatorsSearchWithParamsQuery as QueryType,
  CreatorType,
} from 'GraphTypes/CreatorsSearchWithParamsQuery.graphql';

const CreatorsSearchWithParamsQuery = graphql`
  query CreatorsSearchWithParamsQuery(
    $searchQueryInput: SearchQueryInput!
    $searchQueryOwnerInput: SearchQueryInput!
    $searchQueryActiveInput: SearchQueryInput!
    $searchQueryNotOwnerInput: SearchQueryInput!
    $organizationId: ID!
  ) {
    organization(id: $organizationId) {
      searchCreators(first: 3, query: $searchQueryInput) {
        totalCount
        edges {
          node {
            creator {
              id
              username
              __typename
              profile {
                profilePictureFile {
                  url
                  ... on Image {
                    transformations {
                      collageThumbnailUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
      owned: searchCreators(query: $searchQueryOwnerInput) {
        totalCount
      }
      activeUsers: searchCreators(query: $searchQueryActiveInput) {
        totalCount
      }
      notOwned: searchCreators(query: $searchQueryNotOwnerInput) {
        totalCount
      }
    }
  }
`;

interface Props {
  campaignId: string;
  organizationId: string;
  currency: Currency;
  platform?: CampaignPlatform | null;
  campaign: CreatorsSearchWithParams_campaign$key;
}

const CreatorsSearchWithParams: React.FC<Props> = (props) => {
  const { campaignId, currency, platform, campaign, organizationId } = props;

  const data = useFragment(
    graphql`
      fragment CreatorsSearchWithParams_campaign on Campaign {
        searchQuery {
          exclusions {
            campaign {
              id
              name
              organization {
                name
              }
            }
            advertiserProjectStages
          }
          excludeHiredMoreThanOnce
          audienceCountryPercentage
          audienceGenderPercentage
          audienceAgeRangePercentage
          audienceCityPercentage
          onlyCreatorsWorkedWith
          customLists {
            id
            name
          }
          ageRanges {
            id
            name
          }
          audienceCities {
            id
            name
          }
          audienceGender {
            id
            name
          }
          categories {
            id
            name
          }
          cities {
            id
            name
          }
          countries {
            id
            name
          }
          creatorCountries {
            id
            name
          }
          currency
          creatorEthnicities {
            id
            name
          }
          creatorLanguages {
            id
            name
          }
          creatorBodyTypes {
            id
            name
          }
          audienceEthnicities {
            id
            name
          }
          creatorAnimals {
            id
            name
          }
          creatorHairTypes {
            id
            name
          }
          tags {
            id
            name
          }
          creatorBirthdateFrom
          creatorBirthdateTo
          engagementRateFrom
          engagementRateTo
          followersFrom
          followersTo
          realFollowersFrom
          realFollowersTo
          viewsPerPostFrom
          viewsPerPostTo
          viewsMedianFrom
          viewsMedianTo
          storiesReachFrom
          storiesReachTo
          postsReachFrom
          postsReachTo
          reelsPlaysMedianFrom
          reelsPlaysMedianTo
          campaignsCompletionsCountFrom
          campaignsCompletionsCountTo
          creatorGender {
            id
            name
          }
          excludeBadges
          strictlyByPrimaryCategory
          includeAmazonStorefrontLink
          favorite
          withTiktokShop
          experienced
          highAverageRating
          reliable
        }
      }
    `,
    campaign
  );
  const searchQuery = data.searchQuery;

  if (!searchQuery) return null;

  const { exclusions, excludeHiredMoreThanOnce } = searchQuery;

  const dataForSearch = createSearchData(searchQuery);

  let creatorTypes: CreatorType[] | undefined;

  if (platform === 'FACEBOOK' || platform === 'INSTAGRAM') {
    creatorTypes = ['INSTAGRAM'];
  } else if (platform === 'TIKTOK') {
    creatorTypes = ['TIKTOK'];
  } else if (platform === 'YOUTUBE') {
    creatorTypes = ['YOUTUBE'];
  }

  const searchQueryInput = {
    creatorTypes,
    currency: 'USD',
    ...dataForSearch,
  };

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={CreatorsSearchWithParamsQuery}
      variables={{
        organizationId,
        currency,
        searchQueryInput,
        searchQueryOwnerInput: { ...searchQueryInput, owned: true },
        searchQueryActiveInput: { ...searchQueryInput, owned: true, active: true },
        searchQueryNotOwnerInput: { ...searchQueryInput, owned: false },
      }}
      render={({ props: queryProps }) => {
        if (!queryProps) {
          return (
            <div className={styles.root}>
              <div className={styles.creatorsLoading}>
                <Spinner />
              </div>
            </div>
          );
        }

        const organization = queryProps.organization;

        if (!organization) return null;

        const totalCount = organization.searchCreators?.totalCount;
        const ownedTotalCount = organization.owned?.totalCount;
        const activeUsersTotalCount = organization.activeUsers?.totalCount;
        const notOwnedTotalCount = organization.notOwned?.totalCount;

        const sampleCreators = organization.searchCreators?.edges;

        return (
          <div className={styles.root}>
            <div className={styles.creatorsWrap}>
              {sampleCreators?.map((item, index) => {
                if (!item?.node) return null;
                const creator = item.node?.creator;
                const { avaSrc } = getCreatorData(creator);

                return (
                  <div
                    key={creator?.id}
                    className={styles.creatorAvaWrap}
                    style={{ zIndex: 3 - index }}
                  >
                    <Avatar size="44" src={avaSrc} className={styles.creatorAva} />
                  </div>
                );
              })}
            </div>
            <div>
              <div className={styles.countWrap}>
                <Text
                  weight="500"
                  msg="brief_template.field.creators.params.total_count"
                  values={{ count: totalCount }}
                  className={styles.totalCount}
                />
                <Link
                  state={{
                    ...searchQuery,
                    audienceEthnicity: searchQuery.audienceEthnicities,
                    creatorEthnicity: searchQuery.creatorEthnicities,
                    includeAmazonStorefrontLinkValue: searchQuery.includeAmazonStorefrontLink
                      ? [
                          {
                            id: 'STOREFRONT',
                            primaryMsg: 'search_section.form.includeAmazonStorefrontLink',
                            msg: 'search_section.form.include_amazon_link',
                          },
                        ]
                      : [],
                    withTiktokShopValue: searchQuery.withTiktokShop
                      ? [
                          {
                            id: 'TIKTOK_SHOP',
                            primaryMsg: 'search_section.form.withTiktokShop',
                            msg: 'search_section.form.withTiktokShop.item',
                          },
                        ]
                      : [],
                    favorite: searchQuery.favorite
                      ? [
                          {
                            id: 'FAVORITE',
                            primaryMsg: 'search_section.form.favorite',
                            msg: 'search_section.form.creators_added_to_favorites',
                          },
                        ]
                      : [],
                    onlyCreatorsWorkedWith: searchQuery.onlyCreatorsWorkedWith
                      ? [
                          {
                            id: 'ONLY_CREATORS_WORKED_WITH',
                            primaryMsg: 'search_section.form.onlyCreatorsWorkedWith',
                            msg: 'search_section.form.only_creators_worked_with',
                            itemClassName: styles.creatorsWorkedWithFilter,
                          },
                        ]
                      : [],
                    experienced: searchQuery.experienced
                      ? [
                          {
                            id: 'experienced',
                            msg: 'search_section.form.experienced',
                            itemClassName: styles.creatorsWorkedWithFilter,
                          },
                        ]
                      : [],
                    highAverageRating: searchQuery.highAverageRating
                      ? [
                          {
                            id: 'highAverageRating',
                            msg: 'search_section.form.high_average_rating',
                            itemClassName: styles.creatorsWorkedWithFilter,
                          },
                        ]
                      : [],
                    reliable: searchQuery.reliable
                      ? [
                          {
                            id: 'reliable',
                            msg: 'search_section.form.reliable',
                            itemClassName: styles.creatorsWorkedWithFilter,
                          },
                        ]
                      : [],
                    defaultExclusions: {
                      exclusions,
                      excludeHiredMoreThanOnce,
                    },
                    creatorTypes,
                  }}
                  to={`/campaigns/${campaignId}/mediaplan`}
                >
                  <TextButton msg="brief_template.field.creators.params.show_creators" />
                </Link>
              </div>
              <Text
                size="sm"
                color="grayDog"
                msg="brief_template.field.creators.params.total_counts"
                values={{
                  registered: ownedTotalCount,
                  active: activeUsersTotalCount,
                  unregistered: notOwnedTotalCount,
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default CreatorsSearchWithParams;
