import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './Field.pcss';

import InfoTooltip from 'Molecules/InfoTooltip/InfoTooltip';
import Input from 'Atoms/Input/Input';
import Text from 'Atoms/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Number from 'Atoms/Number/Number';
import { FieldType, FieldElementType } from 'Types/common';

interface Props {
  className?: string;
  dataTest?: string;
}

const Field: React.FC<PropsWithChildren<Props & FieldType & FieldElementType>> = (props) => {
  const {
    className,
    children,
    disabled,
    element,
    name,
    elementData,
    title,
    titleText,
    titleValues,
    titleTooltip,
    linkWrap,
    linkData,
    error,
    errorData,
    dataTest,
  } = props;

  const getElement = () => {
    const commonProps = { error: !!error, disabled, name };
    switch (element) {
      case 'input':
        return <Input {...commonProps} {...elementData} />;
      case 'number':
        return <Number {...commonProps} {...elementData} />;
      default:
        return children;
    }
  };

  const getError = () => {
    return (
      <div data-error="field">
        <Text size="sm" color="red" align="left" className={styles.errorText} {...errorData} />
      </div>
    );
  };

  const headerLink = <TextButton tabIndex={-1} size="sm" {...linkData} />;

  const header = (
    <header className={styles.header}>
      <div className={styles.titleWrap}>
        {(title || titleText) && (
          <Text
            size="sm"
            color="grayDog"
            msg={title}
            text={titleText}
            values={titleValues}
            className={styles.title}
          />
        )}
        {/* @TODO Using optional "name" as required "id" prop
        @ts-ignore */}
        {titleTooltip && <InfoTooltip id={name} tooltipMsg={titleTooltip} />}
      </div>
      {linkWrap ? React.cloneElement(linkWrap, { children: headerLink }) : headerLink}
    </header>
  );

  const headerNode = (title || titleText || linkData) && header;
  const node = getElement();
  const errorNode = error && errorData && getError();

  const classes = classnames(styles.root, className, {
    [styles.error]: error,
  });

  return (
    <div className={classes} data-test={dataTest}>
      {headerNode}
      {node}
      {errorNode}
    </div>
  );
};

export default Field;
