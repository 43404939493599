import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'react-intl';
import filter from 'lodash/filter';

import styles from './ProfileSettings.pcss';
import { getAccountTypeOpt, getCurrencyOpt } from './data';
import UpdateStat from './UpdateStat/UpdateStat';
import UserHandle from './UserHandle/UserHandle';

import { createNumber } from 'Util/numberFormatter';
import { getAges } from 'Util/dateCreator';
import { getCreatorProfileLink } from 'Util/links';
import { getCreatorData } from 'Util/creatorData';
import updateCreatorProfile from 'Mutations/UpdateCreatorProfile.Mutation';
import updateContractorProfile from 'Mutations/UpdateContractorProfile.Mutation';
import Text from 'Atoms/Text/Text';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Icon from 'Atoms/Icon/Icon';
import AvatarItem from 'Molecules/AvatarItem/AvatarItem';
import CreatorCities from 'Molecules/CreatorCities/CreatorCities';
import Field from 'Molecules/Field/Field';
import { modalName } from 'Types/modals';
import {
  ProfileSettings_creator$key,
  InfluencerType,
} from 'GraphTypes/ProfileSettings_creator.graphql';
import { CreatorAdminPanelQuery$data, Currency } from 'GraphTypes/CreatorAdminPanelQuery.graphql';
import Checkbox from 'Atoms/Checkbox/Checkbox';
import { MAX_CATEGORIES_AMOUNT } from 'Constants/general';
import createDate from 'Util/dateCreator';
import TextButton from 'Atoms/TextButton/TextButton';
import DatePicker from 'Components/ui/DatePicker/DatePicker';
import NewText from 'Components/ui/Text/Text';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import CreatorCategories from 'Organisms/CreatorProfile/CreatorCategories/CreatorCategories';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  creator: ProfileSettings_creator$key;
  genders: NonNullable<CreatorAdminPanelQuery$data>['genders'];
  settings: CreatorAdminPanelQuery$data['settings'];
  categories: CreatorAdminPanelQuery$data['categories'];
}

const ProfileSettings: React.FC<Props> = (props) => {
  const { creator, settings, categories, genders = [] } = props;

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const intl = useIntl();

  const data = useFragment(
    graphql`
      fragment ProfileSettings_creator on Creator {
        id
        username
        type
        ownership {
          createdAt
          owner {
            email
            contractorProfile {
              id
              phoneNumber
              creatorAgent
            }
            creators {
              edges {
                node {
                  id
                  username
                  profile {
                    profilePictureFile {
                      url
                      ... on Image {
                        transformations {
                          collageThumbnailUrl
                        }
                      }
                    }
                  }
                  type
                  ... on InstagramCreator {
                    username
                  }
                  ... on YoutubeCreator {
                    channel {
                      name
                    }
                  }
                  ... on TiktokCreator {
                    user {
                      name
                    }
                  }
                }
              }
            }
          }
        }
        ... on InstagramCreator {
          paidSocialEnabled
          paidSocialRightsVerification {
            verifiedAt
            success
          }
        }
        profile {
          acceptsBarter
          birthdate
          gender {
            id
          }
          id
          influencerType
          cities {
            id
            name
          }
          profilePictureFile {
            url
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
          currency
          customPrice
          contactEmail
          estimatedPrice
          categories {
            id
            name
            primary
          }
        }
      }
    `,
    creator
  );

  if (!data) return null;

  const creatorId = data.id;
  const username = data.username;
  const paidSocialEnabled = data.paidSocialEnabled;
  const createdAt = data.ownership?.createdAt;
  const owner = data.ownership?.owner;
  const email = owner?.email;
  const profile = data.profile;
  const creatorType = data.type;
  const profileId = profile?.id;
  const gender = profile?.gender?.id;
  const influencerType = profile?.influencerType;
  const birthdate = profile?.birthdate;
  const cities = profile?.cities;
  const customPrice = profile?.customPrice;
  const estimatedPrice = profile?.estimatedPrice;
  const currency = profile?.currency;
  const contactEmail = profile?.contactEmail;
  const acceptsBarter = profile?.acceptsBarter;
  const contractorProfileId = owner?.contractorProfile?.id;
  const phoneNumber = owner?.contractorProfile?.phoneNumber;
  const creatorAgent = owner?.contractorProfile?.creatorAgent;
  const bindedCreators = filter(owner?.creators?.edges || [], (item) => {
    return item?.node?.id !== creatorId;
  });

  if (!profileId) return null;

  const paidSocialRightsVerification = data?.paidSocialRightsVerification;
  const creatorCategories = profile?.categories || [];
  const maxCategoriesToChoose = settings?.maxMandatoryCategoriesCount || MAX_CATEGORIES_AMOUNT;
  const creatorCategoriesList = creatorCategories.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const categoriesList = categories?.map((item) => {
    const activeCateory = creatorCategories.find((selectedItem) => selectedItem.id === item.id);
    const isDisabled = !activeCateory && creatorCategories.length >= maxCategoriesToChoose;
    const handlePrimaryStateChange = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const newList = creatorCategories.map((category) => {
        return { id: category.id, primary: category.id === item.id };
      });
      updateCreatorProfile({ id: profileId, categories: newList });
    };

    const isPrimary = !!activeCateory?.primary;

    const optionText = (
      <div className={styles.option}>
        <div className={styles.optionName}>{item.name}</div>
        {activeCateory && !isPrimary && (
          <TextButton
            msg="creator_statistic.categories.set_primary"
            onClick={handlePrimaryStateChange}
          />
        )}
        {activeCateory && isPrimary && (
          <Text color="grey100" msg="creator_statistic.categories.primary" />
        )}
      </div>
    );

    return {
      value: item.id,
      id: item.id,
      text: optionText,
      label: item.name,
      leftIcon: <Checkbox active={!!activeCateory} />,
      disableNativeIcon: true,
      isDisabled: isDisabled,
      marked: isPrimary,
    };
  });

  const ages = getAges(birthdate);

  const handleDisconnect = () => {
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.CREATOR_DISCONNECT, attach: { creatorId, username, email } },
    });
  };

  const handleContractorProfileChange = (data: any) => {
    if (!contractorProfileId) return;
    updateContractorProfile({ id: contractorProfileId, ...data });
  };

  const handleCreatorProfileChange = (data: any) => {
    if (!profileId) return;
    updateCreatorProfile({ id: profileId, ...data });
  };

  const handleCitiesChange = (cityIds: string[]) => {
    if (!profileId) return;
    updateCreatorProfile({ id: profileId, cityIds });
  };

  const handlePriceChange = (data: any) => {
    const newPrice = parseInt(data.customPrice, 10);
    if (customPrice === newPrice) return;
    handleCreatorProfileChange({ customPrice: newPrice });
  };

  const handleAgentValueChange = () => {
    if (!contractorProfileId) return;
    updateContractorProfile({ id: contractorProfileId, creatorAgent: !creatorAgent });
  };

  const handleBarterValueChange = () => {
    if (!profileId) return;
    updateCreatorProfile({ id: profileId, acceptsBarter: !acceptsBarter });
  };

  // const handleCategoriesChange = (data: any) => {
  //   const newCategoriesList = data?.categoryIds?.map((item) => item.id) || [];

  //   handleCreatorProfileChange({ categoryIds: newCategoriesList });
  // };

  const handeBirthdateChange = (date: Date | null) => {
    updateCreatorProfile({ id: profileId, birthdate: date });
  };

  // const handlePrimaryCategoriesChange = () => {
  //   const handlePrimaryStateChange = (e: React.MouseEvent<HTMLElement>) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     const newList = creatorCategories.map((category) => {
  //       return { id: category.id, primary: category.id === item.id };
  //     });
  //     updateCreatorProfile({ id: profileId, categories: newList });
  //   };
  // }

  const accountTypeOpt = getAccountTypeOpt({ intl, checkedValue: influencerType as string });

  const currencyOpt = getCurrencyOpt({ intl, checkedValue: currency });

  const gendersList = genders.map((item) => {
    return { value: item.name, id: item.id, isChecked: item.id === gender };
  });

  const createCheckbox = (active: boolean, title: string, descr: string, callback: () => void) => {
    return (
      <div className={styles.checkboxWrap}>
        <Checkbox active={active} className={styles.checkbox} onChange={callback} />
        <div>
          <Text weight="500" msg={title} />
          <Text color="grayDog" msg={descr} />
        </div>
      </div>
    );
  };

  const handleCreatorTypeChange = (newType: string) => {
    handleCreatorProfileChange({ influencerType: newType as InfluencerType });
  };

  const handleCreatorGenderChange = (newGender: string) => {
    handleCreatorProfileChange({ genderId: newGender });
  };

  const handleCreatorCurrencyChange = (newCurrency: string) => {
    handleCreatorProfileChange({ currency: newCurrency as Currency });
  };

  const handleCategoriesChange = (ids: string[]) => {
    if (!profileId) return;

    const newCategoriesList = ids || [];

    handleCreatorProfileChange({ categoryIds: newCategoriesList });
  };

  const handlePrimaryChange = (primaryId: string) => {
    const newCategories = creatorCategories.map((item) => {
      return { id: item.id, primary: primaryId === item.id };
    });
    handleCreatorProfileChange({ categories: newCategories });
  };

  return (
    <div className={styles.root}>
      <Text
        tag="h3"
        weight="700"
        msg="creator_statistic.settings.profile.title"
        transform="uppercase"
        className={styles.title}
      />
      <UserHandle creatorType={creatorType} username={username} creatorId={creatorId} />
      {bindedCreators.length > 0 && (
        <div className={styles.bindedCreators}>
          <Text weight="700" text="Connected creator profiles" />
          {bindedCreators.map((bindedCreator) => {
            if (!bindedCreator?.node) return null;
            const { id: bindedCreatorId } = bindedCreator.node;
            const bindedCreatorLink = getCreatorProfileLink({ creatorId: bindedCreatorId });
            const {
              avaSrc,
              username: bindedCreatorUsername,
              iconColored,
            } = getCreatorData(bindedCreator.node);

            return (
              <SmartLink
                path={bindedCreatorLink}
                target="_blank"
                key={bindedCreatorId}
                className={styles.bindedCreator}
              >
                <AvatarItem
                  avaData={{ src: avaSrc }}
                  avaEl={<Icon name={iconColored} size="sm" className={styles.bindedCreatorType} />}
                  titleData={{ text: bindedCreatorUsername, color: 'creamCan' }}
                />
              </SmartLink>
            );
          })}
        </div>
      )}
      <div className={styles.row}>
        <Field
          name="createdAt"
          title="creator.registration_date"
          element="input"
          elementData={{ value: createDate(createdAt) || undefined }}
          disabled={true}
          className={styles.field}
        />
        <Field
          key={email}
          name="email"
          element="input"
          elementData={{
            value: email,
          }}
          title="creator.registered_email"
          disabled={true}
          linkData={
            email
              ? {
                  mainColor: 'red',
                  msg: 'creator.disconnect',
                  onClick: handleDisconnect,
                }
              : undefined
          }
          className={styles.field}
        />
      </div>
      <div className={styles.row}>
        <UpdateStat creatorId={creatorId} className={styles.field} />
        <Field
          name="paidSocialEnabled"
          title="creator.ps_status"
          element="input"
          elementData={{ value: paidSocialEnabled ? 'Connected' : 'Not connected' }}
          disabled={true}
          className={styles.field}
        />
      </div>
      {paidSocialRightsVerification && (
        <div className={styles.row}>
          <Field
            name="licensingAvailabilityDate"
            title="creator.licensingAvailabilityDate"
            element="input"
            disabled={true}
            className={styles.field}
            elementData={{
              value: createDate(paidSocialRightsVerification?.verifiedAt) || undefined,
            }}
          />
          <Field
            name="licensingAvailabilityResult"
            title="creator.licensingAvailabilityResult"
            element="input"
            disabled={true}
            className={styles.field}
            elementData={{
              value: paidSocialRightsVerification?.success ? 'Available' : 'Restricted',
            }}
          />
        </div>
      )}
      <div className={styles.row}>
        <ProgressiveInput
          type="radio"
          titleMsg="creator.influencerType"
          className={styles.field}
          inputProps={{
            items: accountTypeOpt,
            bordered: true,
            handleClick: handleCreatorTypeChange,
            hideByAlphabet: true,
            withArrow: true,
          }}
          isDirty={!!influencerType}
        />
        <ProgressiveInput
          type="radio"
          titleMsg="creator.gender"
          className={styles.field}
          inputProps={{
            items: gendersList,
            bordered: true,
            handleClick: handleCreatorGenderChange,
            hideByAlphabet: true,
            withArrow: true,
          }}
          isDirty={!!gender}
        />
        <div className={styles.birthdateWrap}>
          <NewText type="md" msg="creator.birthdate" className={styles.fieldTitle} />
          <DatePicker
            inputClassName={styles.birthdateInput}
            customInputProps={{ hideCloseIcon: true }}
            reactDatePickerProps={{
              selected: birthdate ? new Date(birthdate as string) : undefined,
              dateFormat: 'MMM dd,yyyy',
              onChange: handeBirthdateChange,
            }}
          />
          {birthdate && <Text color="grayDog" className={styles.age} text={ages} />}
        </div>
      </div>
      <CreatorCities
        className={styles.cities}
        cities={cities}
        onCitiesChange={handleCitiesChange}
      />
      <div className={styles.row}>
        <CreatorCategories
          admin={true}
          allCategories={categories}
          creatorCategories={creatorCategories}
          maxCategoriesToChoose={maxCategoriesToChoose}
          onChange={handleCategoriesChange}
          onPrimaryChange={handlePrimaryChange}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="customPrice"
          title="creator.average_price"
          titleValues={{ currency }}
          element="input"
          elementData={{
            defaultValue: customPrice,
            additionalComponent: estimatedPrice ? (
              <Text size="sm" color="grayDog" text={createNumber(estimatedPrice)} />
            ) : undefined,
            onBlur: handlePriceChange,
          }}
          className={styles.field}
        />
        <ProgressiveInput
          type="radio"
          titleMsg="creator.creator_curency"
          className={styles.field}
          inputProps={{
            items: currencyOpt,
            bordered: true,
            handleClick: handleCreatorCurrencyChange,
            hideByAlphabet: true,
            withArrow: true,
          }}
          isDirty={!!currency}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="contactEmail"
          title="creator.contact_email"
          element="input"
          elementData={{
            defaultValue: contactEmail,
            onBlur: handleCreatorProfileChange,
          }}
          className={styles.field}
        />
        <Field
          name="phoneNumber"
          title="creator.mobile_phone"
          element="input"
          elementData={{
            defaultValue: phoneNumber,
            onBlur: handleContractorProfileChange,
          }}
          disabled={!contractorProfileId}
          className={styles.field}
        />
      </div>
      <div>
        {createCheckbox(
          !!creatorAgent,
          'creator.is_agent',
          'creator.is_agent.descr',
          handleAgentValueChange
        )}
        {createCheckbox(
          !!acceptsBarter,
          'creator_profile.barter_title',
          'creator_profile.barter_descr',
          handleBarterValueChange
        )}
      </div>
    </div>
  );
};

export default ProfileSettings;
