import React, { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import xor from 'lodash/xor';
import remove from 'lodash/remove';

import styles from './CreatorExclusion.pcss';
import OrganzationsList from './OrganzationsList/OrganzationsList';

import { xorObjectById } from 'Util/dataFormatter';
import { cutStringWithExt } from 'Util/textFormatter';
import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import TextButton from 'Atoms/TextButton/TextButton';
import Button from 'Atoms/Button/Button';
import Checkbox from 'Atoms/Checkbox/Checkbox';
import Autocomplete from 'Components/SearchParams/Form/Autocomplete/Autocomplete';
import CampaignList from 'Modal/admin/ManualTransactions/Form/CampaignList/CampaignList';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import { ExclusionStatusList, ExclusionStatusesValue } from 'Types/enums';
import { AdvertiserProjectStage } from 'GraphTypes/AdvertiserProjectActions_project.graphql';

const getStatuses = (currentStatus: ExclusionStatusList) => {
  return [
    {
      id: ExclusionStatusList.ALL_STATUSES,
      valueMsg: 'create_campaign.search_params.exclude_campaigns.all_statuses',
      isChecked: currentStatus === ExclusionStatusList.ALL_STATUSES,
    },
    {
      id: ExclusionStatusList.HIRED,
      valueMsg: 'create_campaign.search_params.exclude_campaigns.hired',
      isChecked: currentStatus === ExclusionStatusList.HIRED,
    },
    {
      id: ExclusionStatusList.NOT_HIRED,
      valueMsg: 'create_campaign.search_params.exclude_campaigns.not_hired',
      isChecked: currentStatus === ExclusionStatusList.NOT_HIRED,
    },
    {
      id: ExclusionStatusList.HIRED_MORE_ONCE,
      valueMsg: 'create_campaign.search_params.exclude_campaigns.hired_more_once',
      isChecked: currentStatus === ExclusionStatusList.HIRED_MORE_ONCE,
    },
  ];
};

const MIN_AMOUNT_FOR_SHOW = 8;

interface Props {
  admin?: boolean;
  organizationId: string;
  defaultCampaignId?: string;
  isShownByDefault?: boolean;
  onChange: (data: any) => void;
  defaultExclusions: DefaultExclusionItem;
}

const emptyExclusions = {
  exclusions: [],
  excludeHiredMoreThanOnce: false,
};

const CreatorExclusion: React.FC<Props> = (props) => {
  const {
    admin,
    organizationId,
    defaultExclusions = emptyExclusions,
    defaultCampaignId,
    isShownByDefault,
    onChange,
  } = props;

  const [showAll, setShowAll] = useState(false);

  const defaultCampaignIds: string[] = [];

  const formattedExclusions = useMemo(
    () =>
      defaultExclusions?.exclusions?.map(({ campaign, ...exclusionData }) => ({
        ...exclusionData,
        campaign: {
          id: campaign.id,
          name: campaign.name,
          subName: campaign?.organization?.name,
        },
      })) || [],
    [defaultExclusions]
  );

  const excludeHiredMoreThanOnce = defaultExclusions.excludeHiredMoreThanOnce;

  const defaultExclusionsValue = useMemo(
    () =>
      formattedExclusions.map((item) => {
        const {
          campaign: { id, name = '', subName = '' },
          advertiserProjectStages,
        } = item;
        let status = ExclusionStatusList.ALL_STATUSES;
        if (advertiserProjectStages) {
          if (advertiserProjectStages.length === 0) {
            status = ExclusionStatusList.ALL_STATUSES;
          } else if (advertiserProjectStages.includes('LAUNCHED')) {
            status = ExclusionStatusList.HIRED;
          } else {
            status = ExclusionStatusList.NOT_HIRED;
          }
        }
        defaultCampaignIds.push(id);

        return { id, name, subName, status };
      }),
    [formattedExclusions]
  );

  const defaultShowExcludeCampaigns =
    defaultExclusionsValue.length > 0 || isShownByDefault || excludeHiredMoreThanOnce;
  const defaultStatus = excludeHiredMoreThanOnce
    ? ExclusionStatusList.HIRED_MORE_ONCE
    : ExclusionStatusList.ALL_STATUSES;

  const [showExcludeCampaigns, setShowExcludeCampaigns] = useState(defaultShowExcludeCampaigns);
  const [exclusions, setExclusions] = useState<ExclusionItem[]>(defaultExclusionsValue);
  const [currentStatus, setStatus] = useState(defaultStatus);
  const [campaignIds, setCampaignIds] = useState<string[]>(defaultCampaignIds);
  const [currentOrganizationId, setOrganization] = useState(organizationId);

  const intl = useIntl();

  const statuses = getStatuses(currentStatus);

  useEffect(() => {
    const newCampaignExclusions = createCampaignExclusions();
    onChange({ exclusions: newCampaignExclusions });
  }, [campaignIds]);

  useEffect(() => {
    onChange({ excludeHiredMoreThanOnce: currentStatus === ExclusionStatusList.HIRED_MORE_ONCE });
  }, [currentStatus]);

  useEffect(() => {
    if (showExcludeCampaigns === false) {
      setExclusions([]);
      setStatus(statuses[0].id);
      setCampaignIds([]);
      setOrganization(organizationId);
      onChange({ exclusions: [] });
    }
  }, [showExcludeCampaigns]);

  const createCampaignExclusions = () => {
    return exclusions.map((item) => {
      const projectStages = item.id === 'allCampaigns' ? [] : ExclusionStatusesValue[item.status];

      return { campaignId: item.id, advertiserProjectStages: projectStages };
    });
  };

  const handleStatusChange = (newStatus: any) => {
    setStatus(newStatus);
  };

  const handleOrganizationChange = (data: any) => {
    setOrganization(data.organizationId);
  };

  const handleCampaignChange = (data: { campaignId: ExclusionItem[] }) => {
    const newCampaignIds = data.campaignId.map((item) => item.id);
    setCampaignIds(newCampaignIds);
    const newExclusions = data.campaignId.map((item) => {
      if (item.status) {
        return item;
      }
      const statusValue =
        item.id === 'allCampaigns' ? ExclusionStatusList.ALL_STATUSES : currentStatus;
      const specStyles = item.id === 'allCampaigns' ? styles.allCampaigns : '';

      return {
        id: item.id,
        name: item.name,
        subName: item.subName,
        status: statusValue,
        className: specStyles,
      };
    });
    setExclusions(newExclusions);
  };

  const handleExcludeClick = () => {
    setShowExcludeCampaigns(!showExcludeCampaigns);
  };

  const handleRemoveCampaign = (campaignId: string) => {
    const newCampaignIds = xor(campaignIds, [campaignId]);
    setCampaignIds(newCampaignIds);
    const newExclusions = xorObjectById(exclusions, campaignId);
    setExclusions(newExclusions);
  };

  const handleShowAllToggle = () => {
    setShowAll(!showAll);
  };

  const handleClearAll = () => {
    setCampaignIds([]);
    setExclusions([]);
    setStatus(statuses[0].id);
  };

  const resetStatus = () => {
    setStatus(statuses[0].id);
  };

  const tempList = [...exclusions];
  const removedList = remove(tempList, (item) => item.id === 'allCampaigns');
  const chosenParams = removedList.length > 0 ? removedList : tempList;

  const selectedIds = exclusions.map((item) => item.id);

  const limitForShow = showAll ? undefined : MIN_AMOUNT_FOR_SHOW;

  return (
    <div className={styles.root}>
      {!isShownByDefault && (
        <div className={styles.excludeWrap}>
          <Checkbox active={showExcludeCampaigns} onChange={handleExcludeClick} />
          <Text
            weight="500"
            msg="create_campaign.search_params.exclude_campaigns"
            className={styles.excludeTitle}
          />
        </div>
      )}
      {showExcludeCampaigns && (
        <div>
          {admin && (
            <div className={styles.organizations}>
              <Autocomplete
                autoClose={true}
                name="organizationId"
                value={currentOrganizationId}
                isSingle={true}
                hasCheckbox={false}
                placeholderMsg="create_campaign.search_params.organization.placeholder"
                onChange={handleOrganizationChange}
                queryRequest={<OrganzationsList />}
              />
            </div>
          )}
          <div className={styles.form}>
            <div className={styles.status}>
              <ProgressiveInput
                type="radio"
                inputProps={{
                  items: statuses,
                  bordered: true,
                  withArrow: true,
                  handleClick: handleStatusChange,
                  inputClassName: styles.statuses,
                  className: styles.statuses,
                }}
              />
            </div>
            {currentStatus !== ExclusionStatusList.HIRED_MORE_ONCE && (
              <div className={styles.campaigns}>
                <Autocomplete
                  clearAfterSelect={true}
                  name="campaignId"
                  value={exclusions}
                  hasCheckbox={true}
                  placeholderMsg="create_campaign.search_params.exclude_campaigns.placeholder"
                  onChange={handleCampaignChange}
                  queryRequest={
                    <CampaignList
                      id={currentOrganizationId}
                      selectedIds={selectedIds}
                      defaultCampaignId={defaultCampaignId}
                    />
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
      {showExcludeCampaigns && (
        <div className={styles.exclusions}>
          {currentStatus === ExclusionStatusList.HIRED_MORE_ONCE && (
            <div key={'hired_more_once'} className={styles.exlusion}>
              <Text
                size="sm"
                text={intl.formatMessage({
                  id: `create_campaign.search_params.exclude_campaigns.hired_more_once`,
                })}
              />
              <TextButton
                icon={<Icon name="Close-small" size={16} />}
                className={styles.icon}
                onClick={resetStatus}
              />
            </div>
          )}
          {chosenParams.slice(0, limitForShow).map((exlusion) => {
            const { name, id: exlusionId, subName, status } = exlusion;
            const statusValue = intl.formatMessage({
              id: `create_campaign.search_params.exclude_campaigns.${status}`,
            });

            const onRemoveClick = () => {
              handleRemoveCampaign(exlusionId);
            };

            const nameValue = admin ? cutStringWithExt(name, 32) : name;
            const organizationName = admin ? ` / ${subName} ` : '';

            return (
              <div key={exlusionId} className={styles.exlusion}>
                <Text size="sm" text={`${nameValue} / ${statusValue}${organizationName}`} />
                <TextButton
                  icon={<Icon name="Close-small" size={16} />}
                  className={styles.icon}
                  onClick={onRemoveClick}
                />
              </div>
            );
          })}
          {chosenParams.length > MIN_AMOUNT_FOR_SHOW && (
            <div
              className={`${styles.exlusion} ${styles.showStateControl}`}
              onClick={handleShowAllToggle}
            >
              <Text
                size="sm"
                msg={
                  showAll
                    ? 'create_campaign.search_params.exclude_campaigns.show_less'
                    : 'create_campaign.search_params.exclude_campaigns.show_more'
                }
                values={{ count: chosenParams.length - MIN_AMOUNT_FOR_SHOW }}
              />
            </div>
          )}
          {chosenParams.length > 1 && (
            <Button
              size="sm"
              color="normal"
              msg="create_campaign.search_params.exclude_campaigns.clear_all"
              className={styles.clearAll}
              onClick={handleClearAll}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CreatorExclusion;

type ExclusionItem = {
  id: string;
  status: ExclusionStatusList;
  name: string;
  subName: string;
};

type DefaultExclusionItem = {
  exclusions: {
    campaign: {
      id: string;
      name: string;
      subName: string;
      organization: {
        name: string;
      };
    };
    advertiserProjectStages: AdvertiserProjectStage[];
  }[];
  excludeHiredMoreThanOnce: boolean;
};
