import React, { useEffect, useState } from 'react';
import { QueryRenderer } from 'react-relay';

import Skeleton from '../../components/Skeleton/Skeleton';

import styles from './Blocklist.pcss';
import BlocklistItems from './BlocklistItems/BlocklistItems';
import { BlocklistQuery, BlocklistQueryType } from './Blocklist.Query';

import { amplitude } from 'Helpers/amplitude';
import environment from 'Api/Environment';

type Props = {
  renderStartHeaderControls?: () => React.ReactNode;
};

const Blocklist: React.FC<Props> = (props) => {
  const { renderStartHeaderControls } = props;
  const [organizationId, setOrganizationId] = useState<string>('');

  useEffect(() => {
    if (organizationId) {
      amplitude.sendEvent<261>({
        id: '261',
        category: 'pageview',
        name: 'blocklist_visit',
        param: undefined,
      });
    }
  }, [organizationId]);

  return (
    <div className={styles.root}>
      <div className={styles.headerControls}>
        {renderStartHeaderControls && (
          <div className={styles.startHeaderControls}>{renderStartHeaderControls?.()}</div>
        )}
        <div className={styles.endHeaderControls} />
      </div>
      <QueryRenderer<BlocklistQueryType>
        environment={environment}
        query={BlocklistQuery}
        variables={{}}
        render={({ props: queryProps }) => {
          if (!queryProps?.currentUser?.organization) {
            return <Skeleton />;
          }

          const organization = queryProps.currentUser?.organization;
          const permissions = queryProps.currentUser?.permissions;
          const isAdmin = queryProps.currentUser.admin;
          const isChurned = queryProps.currentUser?.organization?.churned;
          const isNewClient = queryProps.currentUser?.organization?.newClient;
          const isPlanPaused = Boolean(queryProps.currentUser?.organization?.subscription?.paused);

          const isBlockedView =
            !isAdmin &&
            (isChurned || isNewClient || isPlanPaused) &&
            !permissions?.includes('CREATOR_PROFILES__MODERATE');

          setOrganizationId(organization.id);

          return (
            <div className={styles.list}>
              <BlocklistItems
                organization={organization}
                organizationId={organization?.id}
                isBlockedView={isBlockedView}
                isPlanPaused={isPlanPaused}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default Blocklist;
