import { graphql } from 'react-relay';

import type { CustomListSelectionQuery as CustomListSelectionQueryType } from 'GraphTypes/CustomListSelectionQuery.graphql';

const CustomListSelectionQuery = graphql`
  query CustomListSelectionQuery($organizationId: ID!) {
    organization(id: $organizationId) {
      customLists {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export { CustomListSelectionQuery };
export type { CustomListSelectionQueryType };
