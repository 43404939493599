import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import type {
  CreateCreativeMutation,
  CreateCreativeMutation$data,
  CreateCreativeInput,
} from 'GraphTypes/CreateCreativeMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation CreateCreativeMutation($input: CreateCreativeInput!) {
    createCreative(input: $input) {
      creative {
        id
        file {
          id
          width
          height
          type
          secureUrl
          thumbnailUrl
        }
        postPublication {
          instagramUrl
          media {
            likeCount
            commentCount
            viewCount
            caption
            thumbnailUrl
            videoUrl
          }
        }
        project {
          creatives {
            edges {
              node {
                id
                file {
                  id
                  type
                  thumbnailUrl
                  secureUrl
                  url
                  type
                  ... on Video {
                    processingStatus
                    transformations {
                      autoUrl
                      collageThumbnailUrl
                    }
                  }
                  ... on Image {
                    transformations {
                      collageThumbnailUrl
                    }
                  }
                }
              }
            }
          }
          creator {
            id
            type
            username
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
            ... on YoutubeCreator {
              channel {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default (
  data: CreateCreativeInput,
  resolve?: (response: CreateCreativeMutation$data) => void,
  reject?: (data: object) => void
) => {
  const { id, projectId, fileId } = data;
  const creativeId = id || uuid();
  commitMutation<CreateCreativeMutation>(environment, {
    mutation,
    variables: {
      input: {
        id: creativeId,
        projectId,
        fileId,
      },
    },
    onCompleted: (response, errors) => {
      if (errors && reject) reject(errors);
      if (response && resolve) resolve(response);
    },
    onError: reject,
  });
};
