import React, { Suspense, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { graphql, useLazyLoadQuery, useQueryLoader } from 'react-relay';

import styles from './TiktokShopProductsList.pcss';
import TiktokShopProductsList from './ProductList/ProductList';

import { useTikTokShopAuth } from 'Hooks/useTikTokShopAuth';
import updateBrief from 'Mutations/UpdateBrief.Mutation';
import launchProject from 'Mutations/LaunchProject.Mutation';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import { TiktokShopProductsListQuery as QueryType } from 'GraphTypes/TiktokShopProductsListQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';
import Button from 'Components/ui/Button/Button';
import { useCreateTiktokShopCollaborationMutation } from 'Api/mutations/CreateTiktokShopCollaboration.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { copyText } from 'Util/copyText';
import { TiktokShopProductsListAuthorizationLinkQuery as LinkQueryType } from 'GraphTypes/TiktokShopProductsListAuthorizationLinkQuery.graphql';
import Tooltip from 'Atoms/Tooltip/Tooltip';

type DrawerProps = React.ComponentProps<typeof Drawer>;

interface Props {
  campaignId: string;
  briefId?: string;
  projectId?: string;
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
}

export const TiktokShopProductsListQuery = graphql`
  query TiktokShopProductsListQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      organization {
        tiktokShopAuthorizations {
          shops {
            id
            name
            products {
              id
              shopId
              description
              isNotForSale
              status
              title
              packageDimensions {
                height
                width
              }
              mainImages {
                urls
              }
            }
          }
        }
      }
    }
  }
`;

export const TiktokShopProductsListAuthorizationLinkQuery = graphql`
  query TiktokShopProductsListAuthorizationLinkQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      organization {
        tiktokShopSellerAuthorizationLink
      }
    }
  }
`;

export type CollaborationInformation = {
  comissionRate: number | null;
  isSampleApprovalExempt: boolean;
  hasFreeSample: boolean;
};

const TiktokShopProductsListDrawer: React.FC<Props> = (props) => {
  const { campaignId, briefId, projectId, drawerProps } = props;
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const [queryReference, loadQuery] = useQueryLoader<QueryType>(TiktokShopProductsListQuery);
  const linkData = useLazyLoadQuery<LinkQueryType>(TiktokShopProductsListAuthorizationLinkQuery, {
    campaignId,
  });
  const [isLinkCopied, setLinkCopied] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState<string>('');
  const [selectedProductId, setSelectedProductId] = useState<string>('');
  const [additionalProductInfo, setProductInfo] = useState<CollaborationInformation>({
    comissionRate: null,
    isSampleApprovalExempt: false,
    hasFreeSample: false,
  });

  const { authorize: authorizeTiktokShop } = useTikTokShopAuth();
  const [createTiktokShopCollaboration, loading] = useCreateTiktokShopCollaborationMutation();
  useEffect(() => {
    loadQuery({
      campaignId,
    });
  }, [campaignId]);

  const handleSelectProduct = (id: string) => {
    setSelectedProductId(id);
  };

  const changeProductInfo = (params: Partial<CollaborationInformation>) => {
    setProductInfo((info) => ({
      ...info,
      ...params,
    }));
  };

  const handleDarwerClose = () => {
    closeDrawer('tt-shop-product-list');
  };

  const handleCreateCollaborationClick = () => {
    if (!selectedProductId) return;
    if (projectId) {
      createTiktokShopCollaboration({
        variables: {
          input: {
            productId: selectedProductId,
            comissionRate: Number(additionalProductInfo.comissionRate) / 100,
            shopId: selectedShopId,
            isSampleApprovalExempt: additionalProductInfo.isSampleApprovalExempt,
            hasFreeSample: additionalProductInfo.hasFreeSample,
            projectId,
          },
        },
        onCompleted: (data) => {
          if (
            data.createTiktokShopCollaboration?.__typename ===
              'CreateTiktokShopCollaborationPayload' &&
            data.createTiktokShopCollaboration.collaboration?.id
          ) {
            launchProject({ projectId }, () => {
              openDrawer(`project-draft-deadline-${projectId}`);
            });
          }
        },
      });
    } else if (campaignId && briefId) {
      updateBrief(
        {
          id: briefId,
          tiktokShop_ProductId: selectedProductId,
          tiktokShop_ShopId: selectedShopId,
          includeReferralFee: !!additionalProductInfo.comissionRate,
          referralFeeRate: additionalProductInfo.comissionRate
            ? Number(additionalProductInfo.comissionRate)
            : undefined,
        },
        handleDarwerClose
      );
    }
  };

  const handleAuthDone = () => {
    loadQuery(
      {
        campaignId,
      },
      { fetchPolicy: 'network-only' }
    );
  };

  const handleAddNewShopClick = async () => {
    await authorizeTiktokShop();
    handleAuthDone();
  };

  const handleSelectedShopIdChange = (id: string) => {
    setSelectedShopId(id);
    setSelectedProductId('');
  };

  const copyShopLink = () => {
    copyText(linkData.campaign?.organization?.tiktokShopSellerAuthorizationLink);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 10000);
  };

  return (
    <Drawer
      {...drawerProps}
      className={classnames(styles.drawer, drawerProps?.className)}
      rootKey="tt-shop-product-list"
    >
      <div className={styles.contentRoot}>
        <Text type="d2" msg="tiktok_shop_products_modal.title" className={styles.title} />
        <div className={styles.description}>
          <Text type="md" msg="tiktok_shop_products_modal.descr" />
        </div>
        <div className={styles.contentContainer}>
          <Suspense
            fallback={
              <div className={styles.preloader}>
                <Spinner />
              </div>
            }
          >
            {queryReference && (
              <TiktokShopProductsList
                campaignId={campaignId}
                queryReference={queryReference}
                selectedShopId={selectedShopId}
                selectedProductId={selectedProductId}
                onSelectedShopIdChange={handleSelectedShopIdChange}
                handleSelectProduct={handleSelectProduct}
                additionalProductInfo={additionalProductInfo}
                setProductInfo={changeProductInfo}
                createShopAction={handleAddNewShopClick}
              />
            )}
          </Suspense>
        </div>
        <div className={styles.buttonContainer}>
          <Tooltip
            id="addNewShop"
            tooltipMsg={'tiktok_shop_products_modal.tooltip.new_shop'}
            place="top"
          >
            <AlterButton
              type="grey"
              fluid
              msg="tiktok_shop_products_modal.add_new"
              onClick={handleAddNewShopClick}
              className={styles.additionalButton}
            />
          </Tooltip>
          <Tooltip
            id="copyShopLink"
            tooltipMsg={'tiktok_shop_products_modal.tooltip.copy_link'}
            place="top-end"
          >
            <AlterButton
              type="grey"
              fluid
              disabled={Boolean(
                !linkData.campaign?.organization?.tiktokShopSellerAuthorizationLink
              )}
              msg={
                isLinkCopied
                  ? 'tiktok_shop_products_modal.link_copied'
                  : 'tiktok_shop_products_modal.send_link'
              }
              className={styles.additionalButton}
              onClick={copyShopLink}
            />
          </Tooltip>
        </div>
        <Button
          color="black"
          msg="set_draft_deadline_modal.btn"
          disabled={!selectedProductId}
          loading={loading}
          onClick={handleCreateCollaborationClick}
        />
      </div>
    </Drawer>
  );
};

export default TiktokShopProductsListDrawer;
