import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './BriefInfo.css';

import Text from 'Components/ui/Text/Text';
import { IconName } from 'Components/ui/types';
import Icon from 'Components/ui/Icon/Icon';
import { LONG_TERM } from 'Constants/general';
import { BriefInfo_campaign$key } from 'GraphTypes/BriefInfo_campaign.graphql';

interface Props {
  mobileView?: boolean;
  campaign?: BriefInfo_campaign$key;
}

const BriefInfo: React.FC<Props> = (props) => {
  const { campaign, mobileView } = props;

  const data = useFragment(
    graphql`
      fragment BriefInfo_campaign on Campaign {
        type
        brief {
          ... on V2Brief {
            additionalInfo
            brandAccountName
            hashtags
          }
          ... on V1Brief {
            additionalInfo
            brandAccountName
            hashtags
          }
        }
      }
    `,
    campaign
  );
  const brief = data?.brief;
  const campaignType = data?.type;

  if (!brief || campaignType === LONG_TERM) return null;

  const { additionalInfo, brandAccountName, hashtags } = brief;

  const brandName = brandAccountName ? brandAccountName : '';
  const hashtagList = hashtags ? hashtags : '';

  const rootStyles = mobileView ? styles.mobileView : '';

  const createSection = (text: string, title: string) => {
    return (
      <div className={styles.section}>
        <Text className={styles.title} type="h1" msg={title} />
        <Text text={text} />
      </div>
    );
  };

  const additionalBlock =
    additionalInfo || brandAccountName || hashtags ? (
      <div>
        {(brandAccountName || hashtags) &&
          createSection(`${brandName} ${hashtagList}`, 'brief_modal.brand_info')}
        {additionalInfo && createSection(additionalInfo, 'brief_modal.add_info')}
      </div>
    ) : null;

  const createRequirement = (iconName: IconName, descr: string) => {
    return (
      <div className={styles.requirement}>
        <Icon name={iconName} className={styles.requirementIcon} />
        <Text msg={descr} />
      </div>
    );
  };

  return (
    <div className={rootStyles}>
      <div className={styles.root}>
        {additionalBlock}
        <div className={styles.section}>
          <Text
            className={styles.title}
            type="h1"
            msg="brief.creative.technical_requirements.title"
          />
          {createRequirement('Hd-resolution', 'brief.creative.technical_requirements.hd.descr')}
          {createRequirement('Lamp-spark', 'brief.creative.technical_requirements.light.descr')}
          {createRequirement('Photo', 'brief.creative.technical_requirements.bg.descr')}
        </div>
      </div>
    </div>
  );
};

export default BriefInfo;
