import { graphql } from 'react-relay';

import type { FavoritesQuery as QueryType } from 'GraphTypes/FavoritesQuery.graphql';
import type { FavoriteCreators_organization$data } from 'GraphTypes/FavoriteCreators_organization.graphql';
import type { FavoritesCreatorsCountQuery as FavoritesCreatorsCountQueryType } from 'GraphTypes/FavoritesCreatorsCountQuery.graphql';

const FavoritesQuery = graphql`
  query FavoritesQuery($textQuery: String) {
    currentUser {
      admin
      permissions
      organization {
        id
        newClient
        churned
        subscription {
          paused
        }
        ...FavoriteCreators_organization @arguments(textQuery: $textQuery)
      }
    }
  }
`;

const FavoritesCreatorsCountQuery = graphql`
  query FavoritesCreatorsCountQuery {
    currentUser {
      admin
      organization {
        newClient
        churned
        favoriteCreators {
          totalCount
        }
      }
    }
  }
`;

export { FavoritesQuery, FavoritesCreatorsCountQuery };

// types

type FavoritesQueryType = QueryType & {
  readonly response: QueryType['response'] & {
    readonly currentUser:
      | (QueryType['response']['currentUser'] & {
          readonly organization: FavoriteCreators_organization$data | null;
        })
      | null;
  };
};

export type { FavoritesQueryType, FavoritesCreatorsCountQueryType };
