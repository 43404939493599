import type { IntlShape } from 'react-intl';

type VisibleOptType = {
  intl: IntlShape;
  checkedValue?: string;
};
export const getVisibleOpt = ({ intl, checkedValue }: VisibleOptType) => {
  return [
    {
      value: intl.formatMessage({ id: 'creator.is_searchable' }),
      id: 'VISIBLE',
      isChecked: checkedValue === 'VISIBLE',
    },
    {
      value: intl.formatMessage({ id: 'creator.is_no_searchable' }),
      id: 'HIDDEN',
      isChecked: checkedValue === 'HIDDEN',
    },
  ];
};

type EmployedOptType = {
  intl: IntlShape;
  checkedValue?: boolean | null;
};
export const getEmployedOpt = ({ intl, checkedValue }: EmployedOptType) => {
  return [
    {
      value: intl.formatMessage({ id: 'creator.has_job' }),
      id: 'HAS_JOB',
      isChecked: checkedValue === true,
    },
    {
      value: intl.formatMessage({ id: 'creator.has_no_job' }),
      id: 'HAS_NO_JOB',
      isChecked: checkedValue === false,
    },
  ];
};

type ReviewedOptType = {
  intl: IntlShape;
  checkedValue?: string;
};
export const getReviewedOpt = ({ intl, checkedValue }: ReviewedOptType) => {
  return [
    {
      value: intl.formatMessage({ id: 'creator.is_reviewed' }),
      id: 'SUCCESS',
      isChecked: checkedValue === 'SUCCESS',
    },
    {
      value: intl.formatMessage({ id: 'creator.failed_review' }),
      id: 'FAILURE',
      isChecked: checkedValue === 'FAILURE',
    },
  ];
};

type BadgesOptType = {
  intl: IntlShape;
  checkedValue?: string;
};
export const getBadges = ({ intl, checkedValue }: BadgesOptType) => {
  return [
    {
      value: intl.formatMessage({ id: 'creator.badge.absent' }),
      id: 'ABSENT',
      isChecked: !checkedValue,
    },
    {
      value: intl.formatMessage({ id: 'creator.badge.high_potential' }),
      id: 'HIGH_POTENTIAL',
      isChecked: checkedValue === 'HIGH_POTENTIAL',
    },
    {
      value: intl.formatMessage({ id: 'creator.badge.top_rated' }),
      id: 'TOP_RATED',
      isChecked: checkedValue === 'TOP_RATED',
    },
  ];
};
