import React, { useCallback, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';
import classNames from 'classnames';

import styles from './BcaPermissionListItem.pcss';
import BcaPermissionState from './BcaPermissionState/BcaPermissionState';

import { BcaPermissionListItem_bcaPermission$key } from 'GraphTypes/BcaPermissionListItem_bcaPermission.graphql';
import { getCreatorData } from 'Util/creatorData';
import { copyText } from 'Util/copyText';
import { amplitude } from 'Helpers/amplitude';
import Avatar from 'Atoms/Avatar/Avatar';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import BcaRequestPermissions from 'Modal/advertiser/BcaRequestPermissions/BcaRequestPermissions';

interface Props {
  index: number;
  bcaPermission?: BcaPermissionListItem_bcaPermission$key;
  onDrawerShow: (value: string | null) => void;
  onConnectionDone: () => void;
}

const BcaPermissionListItem: React.FC<Props> = (props) => {
  const { bcaPermission, onDrawerShow, onConnectionDone } = props;

  const data = useFragment<BcaPermissionListItem_bcaPermission$key>(
    graphql`
      fragment BcaPermissionListItem_bcaPermission on BcaPermission {
        id
        state
        brandInstagramUsername
        requests(first: 1) {
          edges {
            node {
              project {
                id
                brand {
                  id
                }
              }
            }
          }
        }
        creator {
          id
          type
          username
          profile {
            profilePictureFile {
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
          ownership {
            owner {
              contractorProfile {
                firstName
                lastName
              }
            }
          }
        }
      }
    `,
    bcaPermission
  );

  if (!data) return null;

  const { id, creator, state } = data;

  const { avaSrc, username } = useMemo(() => getCreatorData(creator), [creator]);

  const contractor = creator?.ownership?.owner.contractorProfile;
  const ownerName = compact([contractor?.firstName, contractor?.lastName]).join(' ');
  const firstProjectId =
    (data?.requests?.edges && data?.requests?.edges[0]?.node?.project?.id) || '';

  const { brandInstagramUsername } = data;

  const handleMoreInfoClick = () => {
    amplitude.sendEvent({
      id: 112,
      category: 'creator_licensing_dashboard',
      name: 'more_information_click',
    });
    onDrawerShow(firstProjectId);
  };

  const handleUsernameCopy = useCallback(() => {
    amplitude.sendEvent({
      id: 133,
      category: 'creator_licensing_dashboard',
      name: 'ig_handle_copy',
    });
    copyText(`@${username}`);
  }, [username]);

  const handleBrandInstagramUsernameCopy = useCallback(() => {
    copyText(`@${brandInstagramUsername}`);
  }, [brandInstagramUsername]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>
          <Avatar size={40} src={avaSrc} />
          <Text className={styles.name} text={ownerName} type="md" />
        </div>
        <div className={styles.label}>
          <Text
            className={styles.columnLabel}
            msg="dashboard.advertiser.licenses_section.license_item.creator_account_title"
            type="s"
          />
          <Tooltip
            id={`${id}_username`}
            tooltipMsg="dashboard.advertiser.licenses_section.license_item.copy"
            place="top"
          >
            <Text
              className={styles.username}
              text={`@${username}`}
              color="grey"
              type="md"
              handleClick={handleUsernameCopy}
            />
          </Tooltip>
        </div>
        <div className={styles.label}>
          <Text
            className={styles.columnLabel}
            msg="dashboard.advertiser.licenses_section.license_item.brand_account_title"
            type="s"
          />
          <Tooltip
            id={`${id}_pageName`}
            tooltipMsg="dashboard.advertiser.licenses_section.license_item.copy"
            place="top"
          >
            <Text
              className={styles.pageName}
              text={`@${brandInstagramUsername}`}
              color="grey"
              type="md"
              handleClick={handleBrandInstagramUsernameCopy}
            />
          </Tooltip>
        </div>
        <div className={classNames(styles.label, styles.dates)}>
          <div className={styles.state}>
            {state === 'UNKNOWN' ? (
              <Tooltip
                id={`${id}_state`}
                tooltipMsg="dashboard.advertiser.licenses_section.license_item.state.unknown"
                place="top"
              >
                <BcaPermissionState state={state} />
              </Tooltip>
            ) : (
              <BcaPermissionState state={state} />
            )}
          </div>
        </div>
        <div className={styles.moreInfoWrap}>
          <AlterButton icon="Info" type="white" hover={false} onClick={handleMoreInfoClick} />
        </div>
      </div>
      <BcaRequestPermissions projectId={firstProjectId} onDone={onConnectionDone} />
    </div>
  );
};

export default BcaPermissionListItem;
