import React from 'react';
import classnames from 'classnames';

import styles from './Warning.pcss';

import Icon from 'Components/ui/Icon/Icon';
import Text, { Props as TextProps } from 'Components/ui/Text/Text';
import { Color } from 'Components/ui/types';

interface Props {
  titleMsg: string;
  titleValues?: TextProps['formatValues'];
  className?: string;
  error?: boolean;
  textColor?: Color;
  withIcon?: boolean;
}

const Warning: React.FC<Props> = (props) => {
  const { className, titleMsg, titleValues, error, textColor, withIcon = true } = props;
  return (
    <div className={classnames(styles.root, className, { [styles.error]: error })}>
      {withIcon && <Icon name={error ? 'Support-sad' : 'Support-help'} className={styles.icon} />}
      <Text type="sm" msg={titleMsg} formatValues={titleValues} color={textColor} />
    </div>
  );
};

export default Warning;
