import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryRenderer, graphql } from 'react-relay';
import { useParams } from 'react-router-dom';

import BriefTemplate from './BriefTemplate/BriefTemplate';
import BriefForLongTermCampaign from './BriefForLongTermCampaign/BriefForLongTermCampaign';
import { prepareCampaignValue } from './utils';

import environment from 'Api/Environment';
import Spinner from 'Atoms/Spinner/Spinner';
import Page from 'Templates/Page/Page';
import { ERROR_ROUTE, LONG_TERM } from 'Constants/general';
import { BriefQuery as QueryType, BriefQuery$data } from 'GraphTypes/BriefQuery.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

const BriefQuery = graphql`
  query BriefQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      type
      useCase
      brief {
        id
      }
      briefSubmission {
        id
      }
      organization {
        subscription {
          availableCampaignTypes
          availableCampaignUseCases
        }
      }
      ...BriefHeader_campaign
      ...BriefPriceRange_campaign
    }
    currentUser {
      admin
      organization {
        subscription {
          planId
          effectiveLimits {
            maxLaunchedCampaigns
            maxActiveBrands
          }
        }
        counters {
          launchedCampaigns
          activeBrands
        }
      }
    }
  }
`;

const Brief = () => {
  const { id: campaignId } = useParams<{ id?: string }>();
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const navigate = useNavigate();

  if (!campaignId) return null;

  return (
    <Page>
      <QueryRenderer<QueryType>
        environment={environment}
        query={BriefQuery}
        variables={{ campaignId }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <Spinner style={{ margin: '96px auto' }} />;

          const admin = queryProps.currentUser?.admin;
          const currentUser = queryProps.currentUser;
          const useCase = queryProps?.campaign?.useCase;
          const isUserHacker = hasFeatureFlag('tiktok_shop');
          const availableCampaignTypes = Array.from(
            queryProps.campaign?.organization?.subscription?.availableCampaignTypes || []
          );
          const campaign = queryProps.campaign
            ? prepareCampaignValue<NonNullable<BriefQuery$data['campaign']>>(
                queryProps.campaign,
                availableCampaignTypes
              )
            : queryProps.campaign;
          const briefId = campaign?.brief?.id;
          const briefSubmission = campaign?.briefSubmission;
          const type = campaign?.type;

          if (!briefId) return null;

          if (!admin && briefSubmission && !isUserHacker) {
            navigate(ERROR_ROUTE);
            return null;
          }

          if (type === 'PAYOUT') {
            return null;
          }

          if (type === LONG_TERM) {
            return <BriefForLongTermCampaign campaignId={campaignId} />;
          }

          return (
            <BriefTemplate
              campaignId={campaignId}
              briefId={briefId}
              campaign={campaign}
              currentUser={currentUser}
              useCase={useCase}
            />
          );
        }}
      />
    </Page>
  );
};
export default Brief;
