/**
 * @generated SignedSource<<efec7ad5b7b4e2a95179d9817a7d649c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TiktokShop_campaign$data = {
  readonly brief: {
    readonly __typename: "V2Brief";
    readonly tiktokShop_ProductId: string | null | undefined;
    readonly tiktokShop_ShopId: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly organization: {
    readonly tiktokShopLinked: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "TiktokShop_campaign";
};
export type TiktokShop_campaign$key = {
  readonly " $data"?: TiktokShop_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"TiktokShop_campaign">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TiktokShop_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tiktokShop_ShopId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tiktokShop_ProductId",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tiktokShopLinked",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "56ad77cdb33a6c96dcba654a5c7cf272";

export default node;
