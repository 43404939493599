import React from 'react';

import styles from './UnreadCount.css';

import modsClasses from 'Util/modsClasses';
import Text from 'Components/ui/Text/Text';
import { UnreadCountType } from 'Types/common';

const MAX_COUNT = 99;

const MODS = ['size'];

const UnreadCount: React.FC<UnreadCountType> = (props) => {
  const { count } = props;
  if (!count) return null;

  const classes = modsClasses(MODS, props, styles);

  const countText = count > MAX_COUNT ? `${MAX_COUNT}+` : count;

  return (
    <div className={`${classes} ${styles.root}`}>
      <Text color="white" text={countText} />
    </div>
  );
};

export default UnreadCount;
