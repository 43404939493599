import React, { useMemo } from 'react';

import styles from '../SequenceItem.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { SequenceStep } from '../../../InviteOutreachCreatorsContext';

interface Props {
  data: SequenceStep;
  handleTimeValue: (value: number) => void;
}

const TimeOptionSelector: React.FC<Props> = (props) => {
  const { data, handleTimeValue } = props;

  const startDate = useMemo(() => {
    if (typeof data.startsAtHour === 'string') {
      return new Date(data.startsAtHour);
    }
    return data.startsAtHour;
  }, [data.startsAtHour]);

  return (
    <div className={styles.mailOptions}>
      <Text type="md" text="Time:" />
      <Dropdown
        showArrow={false}
        className={styles.timeInput}
        value={
          <Text
            type="md"
            className={styles.mailOptionValue}
            text={`${startDate?.getHours() || '00'}:00`}
          />
        }
      >
        <DropdownGroup className={styles.timeValueList}>
          {[...Array(24).keys()].map((item) => (
            <DropdownGroupItem
              key={item}
              handleClick={() => {
                handleTimeValue(item);
              }}
            >
              <AlterButton
                text={`${item < 10 ? '0' : ''}${item}:00`}
                className={styles.delayValue}
              />
            </DropdownGroupItem>
          ))}
        </DropdownGroup>
      </Dropdown>
    </div>
  );
};

export default TimeOptionSelector;
