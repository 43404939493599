import React from 'react';
import track from 'react-tracking';

import styles from './SearchParams.css';
import Form from './Form/Form';
import Params from './Params/Params';
import TextQuery from './TextQuery/TextQuery';

const SearchParams = (props) => {
  const {
    className,
    searchParams,
    paramsKit,
    data,
    audienceHide,
    formStyles,
    hasTextQuery,
    showClearButton,
    onChange,
    onRemove,
    onClearParams,
    queryPlaceholder,
    queryStyles,
    position,
    content,
    element,
    beforeElement,
    afterElement,
    newDesign,
    customElement,
    disableLowParams,
    disableAdminFilter,
  } = props;

  const { textQuery } = searchParams;
  const parsedSearchParams = {
    ...searchParams,
    onlyCreatorsWorkedWith:
      searchParams?.onlyCreatorsWorkedWith === true || searchParams?.onlyCreatorsWorkedWith?.length
        ? [
            {
              id: 'ONLY_CREATORS_WORKED_WITH',
              primaryMsg: 'search_section.form.onlyCreatorsWorkedWith',
              msg: 'search_section.form.only_creators_worked_with',
            },
          ]
        : [],

    experienced:
      searchParams?.experienced === true || searchParams?.experienced?.length
        ? [
            {
              id: 'experienced',
              msg: 'search_section.form.experienced',
              itemClassName: styles.creatorsWorkedWithFilter,
            },
          ]
        : [],
    highAverageRating:
      searchParams?.highAverageRating === true || searchParams?.highAverageRating?.length
        ? [
            {
              id: 'highAverageRating',
              msg: 'search_section.form.high_average_rating',
              itemClassName: styles.creatorsWorkedWithFilter,
            },
          ]
        : [],
    reliable:
      searchParams?.reliable === true || searchParams?.reliable?.length
        ? [
            {
              id: 'reliable',
              msg: 'search_section.form.reliable',
              itemClassName: styles.creatorsWorkedWithFilter,
            },
          ]
        : [],
    favorite: searchParams?.favorite?.length ? [{ id: 'FAVORITE' }] : [],
    withTiktokShopValue:
      searchParams?.withTiktokShopValue === true || searchParams?.withTiktokShopValue?.length
        ? [
            {
              id: 'TIKTOK_SHOP',
              primaryMsg: 'search_section.form.withTiktokShop.title',
              msg: 'search_section.form.withTiktokShop.item',
            },
          ]
        : [],
    includeAmazonStorefrontLink:
      searchParams?.includeAmazonStorefrontLink === true ||
      searchParams?.includeAmazonStorefrontLink?.length
        ? [
            {
              id: 'STOREFRONT',
              primaryMsg: 'search_section.form.includeAmazonStorefrontLink',
              msg: 'search_section.form.include_amazon_link',
            },
          ]
        : [],
  };
  return (
    <div className={className}>
      <div className={styles.content}>
        <div className={styles.row}>
          {element}
          {hasTextQuery && (
            <TextQuery
              textQuery={textQuery}
              onChange={onChange}
              queryPlaceholder={queryPlaceholder}
              queryStyles={queryStyles}
            />
          )}
        </div>
        {!disableAdminFilter && (
          <Form
            searchParams={parsedSearchParams}
            data={data}
            audienceHide={audienceHide}
            onChange={onChange}
            position={position}
            content={content}
            className={formStyles}
            newDesign={newDesign}
            afterElement={afterElement}
            beforeElement={beforeElement}
            customElement={customElement}
          />
        )}
      </div>
      {!disableLowParams && (
        <Params
          paramsKit={paramsKit}
          searchParams={parsedSearchParams}
          showClearButton={showClearButton}
          onChange={onRemove}
          onClearParams={onClearParams}
        />
      )}
    </div>
  );
};

export default track({
  section: 'search_params',
})(SearchParams);
