import React, { useCallback, useContext } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './PsActivation.pcss';

import { getCreatorData } from 'Util/creatorData';
import MaxLicensedCreatorsExceededDrawer from 'Modal/advertiser/MaxLicensedCreatorsExceeded/MaxLicensedCreatorsExceeded';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Button from 'Atoms/Button/Button';
import ActivatePs from 'Molecules/ActivatePs/ActivatePs';
import AvatarItem from 'Molecules/AvatarItem/AvatarItem';
import stubImg from 'Images/chat/cabinetStub.png';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { modalName } from 'Types/modals';
import { PsActivationQuery as QueryType } from 'GraphTypes/PsActivationQuery.graphql';
import createDate from 'Util/dateCreator';
import { ProjectInfoContext } from 'Page/common/Chat/Dialog/ProjectInfo/ProjectInfo.Context';
import Icon from 'Atoms/Icon/Icon';
import TextButton from 'Components/ui/TextButton/TextButton';
import { PARTERSHIP_ADS_INFO } from 'Constants/general';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const PsActivationQuery = graphql`
  query PsActivationQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      currency
      paidSocial
      campaign {
        id
      }
      creator {
        id
        type
        profile {
          profilePictureFile {
            url
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
        }
        ... on InstagramCreator {
          paidSocialEnabled
          username
        }
      }
      paidSocialActivation {
        id
        facebookBusinessName
        facebookBusinessPictureUrl
        facebookPageName
        facebookPagePictureUrl
        license {
          state
          expiresAt
        }
      }
      launch {
        id
      }
    }
    currentUser {
      organization {
        subscription {
          canUsePaidSocial
        }
      }
    }
  }
`;

interface Props {
  className?: string;
  projectId: string;
}

const PsActivation: React.FC<Props> = (props) => {
  const { projectId, className } = props;
  const { openDrawer } = useContext(DrawerContext);
  const {
    isMaxPsLicensedCreatorsExceeded,
    subscription: { maxLicensedCreators, planId },
  } = useContext(ProjectInfoContext);

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const handleCheckingConnectionPossibility = useCallback(() => {
    if (isMaxPsLicensedCreatorsExceeded) {
      openDrawer('max-licensed-creators-exceeded');
      return false;
    }

    return true;
  }, [isMaxPsLicensedCreatorsExceeded, maxLicensedCreators, planId]);

  const data = useLazyLoadQuery<QueryType>(PsActivationQuery, { projectId });

  const project = data.project;

  if (!data || !project) return null;

  const launch = project?.launch?.id;
  const paidSocial = project?.paidSocial;
  const currency = project?.currency;
  const campaignId = project?.campaign.id;
  const creatorId = project?.creator?.id;
  const { avatarUrl } = getCreatorData(project?.creator);

  const canUsePaidSocial = data.currentUser?.organization?.subscription?.canUsePaidSocial;

  if (!launch || !canUsePaidSocial || !paidSocial) return null;

  const paidSocialActivation = project?.paidSocialActivation;
  const psState = paidSocialActivation?.license?.state;
  const expiresAt = paidSocialActivation?.license?.expiresAt;
  const date = createDate(expiresAt);

  const paidSocialEnabled = project?.creator?.paidSocialEnabled;

  const handleStartNewDealClick = () => {
    if (!campaignId || !creatorId) return;
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.START_NEW_DEAL, attach: { campaignId, creatorId } },
    });
  };

  const btn = (
    <Button
      width="full"
      color="secondary"
      disabled={!paidSocialEnabled}
      msg="project.ads_manager.activate"
    />
  );

  const btnWrap = paidSocialEnabled ? (
    <ActivatePs
      projectId={projectId}
      onCheckingConnectionPossibility={handleCheckingConnectionPossibility}
    >
      {btn}
    </ActivatePs>
  ) : (
    <Tooltip id="connectAds" tooltipMsg="tooltip.fb_ads_unavailable" place="top">
      {btn}
    </Tooltip>
  );

  const stateMsg =
    psState && currency === 'USD' ? `project.ads_manager.${psState.toLowerCase()}` : '';

  return (
    <div className={className}>
      <header className={styles.header}>
        <Text
          weight="500"
          transform="uppercase"
          msg="project.ads_manager.title"
          className={styles.title}
        />
        <Tooltip
          id="adsGuide"
          place="top"
          className={styles.adsTooltip}
          tooltipClassName={styles.adsTooltipContainer}
          delayHide={1000}
          customTooltip={
            <>
              <Text msg="tooltip.ads_guide" size="md" color="white" />
              <a href={PARTERSHIP_ADS_INFO} target="_blank" className={styles.adsGuideLink}>
                <TextButton msg="general.read_more" color="pink" />
              </a>
            </>
          }
        >
          <Icon name="info" size={'20'} />
        </Tooltip>
        {stateMsg && (
          <Text
            color={psState === 'ACTIVE' ? 'creamCan' : 'red'}
            weight="500"
            msg={stateMsg}
            transform="uppercase"
            className={styles.status}
          />
        )}
      </header>
      {psState === 'ACTIVE' && currency === 'USD' && expiresAt && (
        <Text
          size="sm"
          msg="project.ads_manager.active.descr"
          values={{ date }}
          className={styles.activeDescr}
        />
      )}
      {!paidSocialActivation && !psState && <div>{btnWrap}</div>}
      {paidSocialActivation && (!psState || psState === 'ACTIVE') && (
        <div>
          <AvatarItem
            avaData={{
              src: paidSocialActivation.facebookBusinessPictureUrl,
              defaultStubImg: stubImg,
            }}
            titleData={{
              text: paidSocialActivation.facebookBusinessName,
            }}
            subtitleData={{ msg: 'project.ads_manager.fb.manager' }}
            className={styles.item}
          />
          <AvatarItem
            avaData={{ src: avatarUrl }}
            titleData={{ text: project?.creator?.username }}
            subtitleData={{ msg: 'project.ads_manager.instagram' }}
            className={styles.item}
          />
          <AvatarItem
            avaData={{
              src: paidSocialActivation.facebookPagePictureUrl,
              defaultStubImg: stubImg,
            }}
            titleData={{ text: paidSocialActivation.facebookPageName }}
            subtitleData={{ msg: 'project.ads_manager.fb.page' }}
            className={styles.item}
          />
        </div>
      )}
      {psState && psState !== 'ACTIVE' && (
        <div>
          <Text
            size="sm"
            msg="project.ads_manager.start_new_deal"
            className={styles.newDealDescr}
          />
          <Button
            width="full"
            color="secondary"
            msg="project.ads_manager.start_new_deal.submit"
            onClick={handleStartNewDealClick}
          />
        </div>
      )}
      <MaxLicensedCreatorsExceededDrawer
        attach={{
          planId,
          maxLicensedCreators,
        }}
      />
    </div>
  );
};

export default PsActivation;
