import React, { useState, useEffect } from 'react';
import { createRefetchContainer, graphql, RelayProp } from 'react-relay';
import classnames from 'classnames';

import styles from './CreatorImportProgress.pcss';

import cancelCreatorImport from 'Mutations/CancelCreatorImport.Mutation';
import Text from 'Atoms/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import { AdvertiserProjectStage } from 'GraphTypes/MediaplanSectionQuery.graphql';
import { CreatorImportProgress_creatorImport$data } from 'GraphTypes/CreatorImportProgress_creatorImport.graphql';

const PROGRESS_WIDTH = 460;
const INTERVAL = 10000;

interface Props {
  campaignId: string;
  showCreatedByAdmin?: boolean;
  creatorImport: CreatorImportProgress_creatorImport$data;
  relay: RelayProp;
  stages?: AdvertiserProjectStage[];
}

const CreatorImportProgress: React.FC<Props> = (props) => {
  const { creatorImport, campaignId, stages } = props;

  const importedCreators = creatorImport.importedCreators;
  const totalCreators = creatorImport.totalCreators;
  const completed = creatorImport.completed;
  const creatorImportId = creatorImport.id;

  const isOpened = completed !== true;

  const [opened, setOpened] = useState(isOpened);

  let interval: NodeJS.Timer;

  const handleRefetch = () => {
    if (!creatorImport) return;

    if (props.relay.refetch) {
      props.relay.refetch({ id: campaignId, stages }, null, null, { force: true });
    }
  };

  useEffect(() => {
    if (isOpened) {
      interval = setInterval(() => {
        handleRefetch();
      }, INTERVAL);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    const completed = creatorImport.completed;

    if (completed === false) {
      setOpened(true);

      if (!interval) {
        interval = setInterval(() => {
          handleRefetch();
        }, INTERVAL);
      }
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [creatorImport]);

  const handleHideClick = () => {
    if (!completed) {
      cancelCreatorImport({ creatorImportId });
    } else {
      setOpened(false);
    }
  };

  const diff = importedCreators / totalCreators;

  let textData = {
    msg: 'mediaplan.uploading',
    values: { count: `(${importedCreators}/${totalCreators})` },
  };

  if (completed) {
    textData = {
      msg: 'mediaplan.uploading_complete',
      values: { count: `(${importedCreators}/${totalCreators})` },
    };
  } else if (diff === 0) {
    textData = { msg: 'mediaplan.uploading', values: { count: '...' } };
  }

  return (
    <div
      className={classnames(styles.root, {
        [styles.completed]: completed,
        [styles.opened]: opened,
      })}
    >
      <div className={styles.progress} style={{ width: diff * PROGRESS_WIDTH }} />
      <Text size="sm" weight="700" color="white" {...textData} className={styles.text} />
      <div className={styles.close} onClick={handleHideClick}>
        <Icon name="deleteIconSm" color="white" />
      </div>
    </div>
  );
};

export default createRefetchContainer(
  CreatorImportProgress,
  {
    creatorImport: graphql`
      fragment CreatorImportProgress_creatorImport on CreatorImport {
        completed
        id
        importedCreators
        totalCreators
      }
    `,
  },
  graphql`
    query CreatorImportProgressRefetchQuery(
      $id: ID!
      $stages: [AdvertiserProjectStage!]
      $createdByAdmin: Boolean
    ) {
      campaign: campaign(id: $id) {
        creatorImport {
          ...CreatorImportProgress_creatorImport
        }
        projects(first: 20, stages: $stages, createdByAdmin: $createdByAdmin)
          @connection(key: "Mediaplan_projects", filters: []) {
          edges {
            node {
              id
              price
              currency
              longlistCreator {
                interactedWith
              }
              creator {
                id
                type
                username
                profile {
                  profilePictureFile {
                    ... on Image {
                      transformations {
                        collageThumbnailUrl
                      }
                    }
                  }
                }
                ... on YoutubeCreator {
                  channel {
                    id
                    name
                  }
                }
                profile {
                  estimatedPrice
                  currency
                }
              }
            }
          }
          totalCount
        }
        newCount: projects(archived: false, orderBy: LAST_ADDED, createdByAdmin: $createdByAdmin) {
          totalCount
        }
        unsentBriefs: projects(
          archived: false
          stages: [UNSENT]
          orderBy: LAST_ADDED
          createdByAdmin: $createdByAdmin
        ) {
          totalCount
        }
      }
    }
  `
);
