import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as amplitudeSdk from '@amplitude/analytics-browser';

import styles from './OutreachApplicationSent.pcss';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import imageAppSrc1x from 'Images/creators/application_sent_app_1x.png';
import imageAppSrc2x from 'Images/creators/application_sent_app_2x.png';
import imageQRSrc1x from 'Images/creators/application_sent_QR_code_1x.png';
import imageQRSrc2x from 'Images/creators/application_sent_QR_code_2x.png';
import Button from 'Components/ui/Button/Button';
import Logo from 'Atoms/Logo/Logo';

interface Props {
  campaignId?: string;
}

const OutreachApplicationSent: React.FC<Props> = (props) => {
  const [searchParams] = useSearchParams();
  const { campaignId } = props;
  const imageScrSet = isMobile
    ? `${imageAppSrc1x} 1x, ${imageAppSrc2x} 2x`
    : `${imageQRSrc1x} 1x, ${imageQRSrc2x} 2x`;
  const deviceId = amplitudeSdk.getDeviceId();

  const code = searchParams.get('code');

  const campaignLink = `https://link.insense.pro/invite?code=${code}${
    deviceId ? `&uid=${deviceId}` : ''
  }`;

  const handleDownloadAppClick = () => {
    amplitude.sendEvent({
      id: '476',
      category: 'campaign',
      name: 'outreach_download_app_clicked',
      param: { campaign_id: campaignId },
    });
    const loadedAt = +new Date();
    setTimeout(() => {
      if (+new Date() - loadedAt < 2000) window.location.href = campaignLink;
    }, 25);

    window.open(`insense://invite?code=${code}${deviceId ? `&uid=${deviceId}` : ''}`, '_self');
  };

  return (
    <Drawer
      rootKey={'outreach-application-sent'}
      fullView
      className={styles.wrapper}
      containerClassName={styles.drawerContainer}
      backdropClassName={styles.drawerBackdrop}
      needCloseButton={false}
    >
      <header className={styles.header}>
        <Logo className={styles.logo} />
      </header>
      <div className={styles.textWrapper}>
        <Text type="d1" msg="outreach.application_sent.title" className={styles.title} />
      </div>

      <div className={styles.imageWrapper}>
        <img srcSet={imageScrSet} />
      </div>

      <div className={styles.textWrapper}>
        <Text type="md" msg="outreach.application_sent.description" />
      </div>

      {isMobile && (
        <footer className={styles.footer}>
          <Button
            onClick={handleDownloadAppClick}
            color="black"
            fluid
            msg="outreach.application_sent.btn"
            className={styles.footerBtn}
          />
        </footer>
      )}
    </Drawer>
  );
};

export default OutreachApplicationSent;
