/**
 * @generated SignedSource<<5aabdbc88a50f97a4c264bf5bc858ead>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OutreachStepBlueprint_ProjectEventType = "MESSAGE" | "OFFER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SequenceSelectorForOutreach_list$data = {
  readonly organization: {
    readonly outreachSequenceBlueprints: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly steps: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly delayInDays: number | null | undefined;
                readonly id: string;
                readonly projectEventType: OutreachStepBlueprint_ProjectEventType;
                readonly startsAtHour: any;
                readonly template: {
                  readonly htmlBody: string;
                  readonly id: string;
                  readonly subject: string;
                } | null | undefined;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SequenceSelectorForOutreach_list";
};
export type SequenceSelectorForOutreach_list$key = {
  readonly " $data"?: SequenceSelectorForOutreach_list$data;
  readonly " $fragmentSpreads": FragmentRefs<"SequenceSelectorForOutreach_list">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SequenceSelectorForOutreach_list",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OutreachSequenceBlueprintConnection",
          "kind": "LinkedField",
          "name": "outreachSequenceBlueprints",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OutreachSequenceBlueprintEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OutreachSequenceBlueprint",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OutreachStepBlueprintConnection",
                      "kind": "LinkedField",
                      "name": "steps",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "OutreachStepBlueprintEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "OutreachStepBlueprint",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "startsAtHour",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "delayInDays",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "projectEventType",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "OutreachTemplate",
                                  "kind": "LinkedField",
                                  "name": "template",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "subject",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "htmlBody",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "eb8ca7d7f75383d4ece3bf3c93cd6cb8";

export default node;
