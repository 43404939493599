import React, { useCallback, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';

import styles from './TransactionType.pcss';

import { createStartOfNewYorkDay, createEndOfNewYorkDay } from 'Util/dateCreator';
import type {
  Accounting_RevenueTransactionType,
  PlanId,
} from 'GraphTypes/Transactions_transactions.graphql';
import Text from 'Atoms/Text/Text';
import updateTransaction from 'Mutations/accounting/UpdateTransaction.Mutation';
import {
  ACTIVATION_FEE,
  CUSTOM_SERVICE,
  DEPOSIT,
  PSP_INVOICE_PAYMENT_METHOD,
  SUBSCRIPTION,
} from 'Constants/general';
import type { Accounting_PaymentMethodType } from 'GraphTypes/ChangeSubscriptionMutation.graphql';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Components/ui/Icon/Icon';
import DatePicker from 'Components/ui/DatePicker/DatePicker';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import { capitalizeText } from 'Util/textFormatter';

const transactionTypes = [
  { msg: 'search_section.form.payment_type_deposit', id: 'DEPOSIT' },
  { msg: 'search_section.form.payment_type_custom_services', id: 'CUSTOM_SERVICE' },
  { msg: 'search_section.form.payment_type_subscription', id: 'SUBSCRIPTION' },
  { msg: 'search_section.form.payment_type.bonus', id: 'SALES_PEAK_REWARD' },
  { msg: 'search_section.form.payment_type.referral', id: 'REFERRAL_REWARD' },
  { msg: 'search_section.form.payment_type.refund', id: 'REFUND' },
  { msg: 'search_section.form.payment_type.creator_expense', id: 'CREATOR_EXPENSE' },
  { msg: 'search_section.form.payment_type.fully_managed_zeroing', id: 'FULLY_MANAGED_ZEROING' },
];

type Addon = {
  addon: {
    id: string;
  };
  quantity: number;
};

type Props = {
  revenueTransactionId?: string;
  transactionType?: RevenueTransactionType;
  subscriptionPlanId?: PlanId;
  paymentMethodType?: PaymentMethodType;
  planIds?: ReadonlyArray<PlanId>;
  subscriptionAddonItems: Addon[];
  billingPeriodEndsAt?: string;
  billingPeriodStartsAt?: string;
};

const TransactionType = (props: Props) => {
  const {
    revenueTransactionId,
    transactionType,
    subscriptionPlanId,
    paymentMethodType,
    planIds,
    subscriptionAddonItems,
    billingPeriodEndsAt,
    billingPeriodStartsAt,
  } = props;

  const intl = useIntl();

  const [transactionTypeSelectValue, setTransactionTypeSelectValue] = useState(transactionType);

  const [subscriptionPlanIdSelectValue, setSubscriptionPlanIdSelectValue] =
    useState(subscriptionPlanId);

  const isPspInvoicePaymentMethod = paymentMethodType === PSP_INVOICE_PAYMENT_METHOD;

  const transactionTypeOptions = useMemo(() => {
    return transactionTypes.map((item) => ({
      labelMsg: item.msg,
      id: item.id,
      isChecked: transactionTypeSelectValue === item.id,
    }));
  }, [transactionTypes, transactionTypeSelectValue]);

  const subscriptionPlanIdOptions = useMemo(() => {
    return (planIds || []).map((item) => ({
      labelMsg: `plan.admin_name.${item.toLowerCase()}`,
      id: item,
      isChecked: subscriptionPlanId === item,
    }));
  }, [planIds, subscriptionPlanId]);

  const values: Values = {
    [DEPOSIT]: () => ({
      msg: 'admin_transactions.topup_type',
      text: '',
    }),
    [SUBSCRIPTION]: () => {
      if (subscriptionPlanId) {
        return {
          msg: `plan.admin_name.${subscriptionPlanId.toLowerCase()}`,
          text: '',
        };
      }
      if (paymentMethodType === 'MANUAL') {
        return {
          msg: 'admin_transactions.subscription_type',
          text: '',
        };
      }
      return {
        msg: '',
        text: '—',
      };
    },
    [CUSTOM_SERVICE]: () => ({
      msg: 'admin_transactions.custom_service_type',
      text: '',
    }),
    [ACTIVATION_FEE]: () => ({
      msg: 'admin_transactions.activation_fee',
      text: '',
    }),
    ['SALES_PEAK_REWARD']: () => ({
      msg: 'admin_transactions.bonus',
      text: '',
    }),
    ['REFERRAL_REWARD']: () => ({
      msg: 'admin_transactions.referral',
      text: '',
    }),
    ['REFUND']: () => ({
      msg: 'admin_transactions.refund',
      text: '',
    }),
    ['CREATOR_EXPENSE']: () => ({
      msg: 'admin_transactions.creator_expense',
      text: '',
    }),
    ['FULLY_MANAGED_ZEROING']: () => ({
      msg: 'admin_transactions.fully_managed_zeroing',
      text: '',
    }),
    ['']: () => ({
      msg: '',
      text: '',
    }),
  };

  const handleTransactionTypeSelectChange = useCallback(
    (newTransactionType: RevenueTransactionType) => {
      setTransactionTypeSelectValue(newTransactionType);
      if (newTransactionType !== SUBSCRIPTION) {
        setSubscriptionPlanIdSelectValue(undefined);
      }
      updateTransaction({
        revenueTransactionId,
        type: newTransactionType as Accounting_RevenueTransactionType,
        billingPeriodEndsAt: newTransactionType !== SUBSCRIPTION ? null : undefined,
        billingPeriodStartsAt: newTransactionType !== SUBSCRIPTION ? null : undefined,
      });
    },
    [revenueTransactionId, subscriptionPlanIdSelectValue]
  );

  const handleSubscriptionPlanIdSelectChange = useCallback(
    (newSubscriptionPlanId: PlanId) => {
      setSubscriptionPlanIdSelectValue(newSubscriptionPlanId);
      updateTransaction({
        revenueTransactionId,
        type: transactionType || undefined,
        subscriptionPlanId: newSubscriptionPlanId,
      });
    },
    [revenueTransactionId, transactionType]
  );

  const addonsTextValue = useMemo(
    () =>
      subscriptionAddonItems?.reduce((str: string, addon: Addon) => {
        return `${str}${capitalizeText(addon.addon.id.replace('_', ' '))}: ${addon.quantity}\n`;
      }, ''),
    [subscriptionAddonItems]
  );

  const handleDateToChange = (date: Date | null) => {
    updateTransaction({
      revenueTransactionId,
      billingPeriodEndsAt: createEndOfNewYorkDay(date),
    });
  };

  const handleDateFromChange = (date: Date | null) => {
    updateTransaction({
      revenueTransactionId,
      billingPeriodStartsAt: createStartOfNewYorkDay(date),
    });
  };

  const handleDateFromClear = () => {
    updateTransaction({
      revenueTransactionId,
      billingPeriodStartsAt: null,
    });
  };

  const handleDateToClear = () => {
    updateTransaction({
      revenueTransactionId,
      billingPeriodEndsAt: null,
    });
  };

  if (isPspInvoicePaymentMethod) {
    return (
      <div className={styles.transactionTypeSelectionWrapper}>
        <ProgressiveInput
          type="checkboxSelect"
          inputProps={{
            className: styles.select,
            placeholderMsg: 'admin_transactions.selection.transaction_type',
            items: transactionTypeOptions,
            bordered: true,
            withArrow: true,
            handleClick: handleTransactionTypeSelectChange,
          }}
        />
        {transactionTypeSelectValue === SUBSCRIPTION && (
          <ProgressiveInput
            type="checkboxSelect"
            inputProps={{
              className: styles.select,
              placeholderMsg: 'admin_transactions.selection.plan_id',
              items: subscriptionPlanIdOptions,
              bordered: true,
              withArrow: true,
              handleClick: handleSubscriptionPlanIdSelectChange,
            }}
          />
        )}
        {transactionTypeSelectValue === SUBSCRIPTION && (
          <DatePicker
            className={styles.datePicker}
            inputClassName={styles.selectField}
            onResetValue={handleDateFromClear}
            customInputProps={{
              rightIcon: 'Calendar-schedule',
              inputClassName: styles.dateInput,
            }}
            reactDatePickerProps={{
              withPortal: true,
              selected: billingPeriodStartsAt ? new Date(billingPeriodStartsAt) : undefined,
              placeholderText: intl.formatMessage({ id: 'search_section.form.dateFrom' }),
              onChange: handleDateFromChange,
            }}
          />
        )}
        {transactionTypeSelectValue === SUBSCRIPTION && (
          <DatePicker
            className={styles.datePicker}
            inputClassName={styles.selectField}
            onResetValue={handleDateToClear}
            customInputProps={{
              rightIcon: 'Calendar-schedule',
              inputClassName: styles.dateInput,
            }}
            reactDatePickerProps={{
              withPortal: true,
              selected: billingPeriodEndsAt ? new Date(billingPeriodEndsAt) : undefined,
              placeholderText: intl.formatMessage({ id: 'search_section.form.dateTo' }),
              onChange: handleDateToChange,
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.valueText}>
      <Text {...values[transactionType || '']()} />
      {subscriptionAddonItems?.length ? (
        <Tooltip tooltipText={addonsTextValue} id={`type_${revenueTransactionId}`}>
          <Icon name="Paid-ads" />
        </Tooltip>
      ) : null}
    </div>
  );
};

export default TransactionType;

type PaymentMethodType = Exclude<Accounting_PaymentMethodType, '%future added value'>;
type TextProps = React.ComponentProps<typeof Text>;
type RevenueTransactionType =
  | Exclude<Accounting_RevenueTransactionType, '%future added value'>
  | '';
type Values = {
  [K in RevenueTransactionType]: () => {
    msg: TextProps['msg'];
    text: TextProps['text'];
  };
};
