import React from 'react';

import { createDateV2 } from 'Util/dateCreator';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import activeImg from 'Images/icons/activeState.svg';
import noActiveImg from 'Images/icons/noactiveState.svg';

interface Props {
  lastActivityAt?: any;
  active?: boolean;
}

const CreatorActivityStatus: React.FC<Props> = (props) => {
  const { active, lastActivityAt } = props;

  const iconName = active ? activeImg : noActiveImg;

  const noActiveLastActivityMsg = lastActivityAt
    ? 'creator.noactive.last_activity_at'
    : 'creator.noactive.no_date';

  const tooltipMsg = active ? 'creator.active.last_activity_at' : noActiveLastActivityMsg;

  const lastActivityAtDate = lastActivityAt ? createDateV2(lastActivityAt) : '-';

  return (
    <div>
      <Tooltip
        place="bottom"
        id="creatorActivity"
        tooltipMsg={tooltipMsg}
        tooltipValues={{ date: lastActivityAtDate }}
      >
        <img src={iconName} />
      </Tooltip>
    </div>
  );
};

export default CreatorActivityStatus;
