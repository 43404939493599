import React, { useMemo, MouseEvent, Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import { Link } from 'react-scroll';
import compact from 'lodash/compact';

import styles from './Info.pcss';

import { amplitude } from 'Helpers/amplitude';
import { getPlatformIcon } from 'Util/icons';
import { getCreatorData } from 'Util/creatorData';
import Text from 'Atoms/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import RegistrationMark from 'Molecules/RegistrationMark/RegistrationMark';
import Avatar from 'Atoms/Avatar/Avatar';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import SimpleLink from 'Components/SimpleLink';
import CreatorBadge from 'Molecules/CreatorBadge/CreatorBadge';
import CreatorActivityStatus from 'Molecules/CreatorActivityStatus/CreatorActivityStatus';
import { YOUTUBE, CONTENT } from 'Constants/general';
import { Info_creator$key } from 'GraphTypes/Info_creator.graphql';
import TextButton from 'Atoms/TextButton/TextButton';
import { useHistoryReferrer } from 'Hooks/useHistoryReferrer';
import AmazonStoreLink from 'Molecules/AmazonStoreLink/AmazonStoreLink';
import CustomListSelection, {
  CustomListSelectionProps,
} from 'Organisms/CustomListSelection/CustomListSelection';
import BlockedUsername from 'Molecules/BlockedUsername/BlockedUsername';

interface Props {
  admin?: boolean;
  isBlockedView?: boolean;
  className?: string;
  creator?: Info_creator$key;
  creatorId: string;
  organizationId?: string;
  onUnlockBannerShow: (e: MouseEvent<HTMLDivElement>) => void;
}

const Info: React.FC<Props> = (props) => {
  const {
    className,
    creator,
    admin,
    creatorId,
    organizationId,
    isBlockedView,
    onUnlockBannerShow,
  } = props;

  const data = useFragment(
    graphql`
      fragment Info_creator on Creator {
        id
        username
        type
        favorite
        customLists {
          edges {
            node {
              id
              name
            }
          }
        }
        profile {
          badges {
            name
          }
          active
          lastActivityAt
          currency
          tags {
            name
          }
          profilePictureFile {
            url
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
          ...useCreatorAmazonStorelink_creator
        }
        projects(stages: [COMPLETED]) {
          totalCount
        }
        ratingVotes(side: AGENCY) {
          totalCount
        }
        ownership {
          owner {
            id
            rating {
              averageScore
              voteCount
            }
            contractorProfile {
              creatorAgent
            }
          }
        }
        ... on InstagramCreator {
          username
          user {
            username
            fullName
          }
        }
        ... on YoutubeCreator {
          channel {
            id
            name
          }
        }
        ... on TiktokCreator {
          user {
            name
          }
        }
      }
    `,
    creator
  );
  const { referrer } = useHistoryReferrer();

  const type = data?.type;
  const badges = data?.profile?.badges;

  const active = data?.profile?.active;
  const lastActivityAt = data?.profile?.lastActivityAt;
  const currency = data?.profile?.currency;
  const isFavoriteCreator = data?.favorite === true;
  const creatorCustomLists = useMemo(() => {
    return compact(data?.customLists?.edges?.map((item) => item?.node?.id)) || [];
  }, [data?.customLists?.edges]);

  const owner = data?.ownership?.owner;

  const isRegistered = !!owner?.id;
  const rating = owner?.rating;

  const isAgent = owner?.contractorProfile?.creatorAgent;
  const averageScore = rating?.averageScore ? rating?.averageScore.toFixed(2) : '–';
  const voteCount = data?.ratingVotes.totalCount;

  const tracking = useTracking();

  const isYoutube = type === YOUTUBE;

  const totalCount = data?.projects.totalCount;

  const tags = useMemo<string[]>(() => {
    const tags = data?.profile?.tags;
    return (tags || []).map((tag) => tag.name);
  }, [data]);

  const handleLinkClick = () => {
    amplitude.sendEvent({
      id: '152',
      category: 'pageview',
      name: 'profile_handle_clicked',
      param: {
        creatorId: creatorId,
        source: referrer,
        creator_tags: tags,
      },
    });
    const linkElement = isYoutube ? 'youtube_link' : 'instagram_link';
    tracking.trackEvent({
      element: linkElement,
      event: 'clicked',
    });
  };

  const handleReviewClick = () => {
    amplitude.sendEvent({
      id: '228',
      category: 'pageview',
      name: 'profile_handle_clicked_reviews',
      param: {
        creatorId: creatorId,
        source: referrer,
        creator_tags: tags,
      },
    });
  };

  const handleStorefrontClick = () => {
    amplitude.sendEvent({
      id: '156',
      category: 'brand',
      name: 'amazon_link_clicked',
      param: {
        source: 'profile',
      },
    });
  };

  const handleCustomListSelectionOpen = () => {
    amplitude.sendEvent<297>({
      id: '297',
      category: 'creator_profile',
      name: 'list_icon_click',
      param: undefined,
    });
  };

  const handleAddCustomListButtonClick = () => {
    amplitude.sendEvent<298>({
      id: '298',
      category: 'creator_profile',
      name: 'new_list_click',
      param: undefined,
    });
  };

  const handleCustomListSuccessfulCreated: NonNullable<
    CustomListSelectionProps['onCustomListSuccessfulCreated']
  > = (response) => {
    if (response.createCustomList?.__typename === 'CreateCustomListPayload') {
      const listName = response.createCustomList.customList?.name || '';

      amplitude.sendEvent<299>({
        id: '299',
        category: 'creator_profile',
        name: 'list_created',
        param: {
          list_name: listName,
        },
      });
    }
  };

  const handleAddCreatorToCustomList: NonNullable<
    CustomListSelectionProps['onAddCreatorToCustomList']
  > = (customListItem) => {
    amplitude.sendEvent<300>({
      id: '300',
      category: 'creator_profile',
      name: 'creator_added_lists',
      param: {
        creator_id: creatorId,
        list_name: customListItem.name,
      },
    });
  };

  const handleRemoveCreatorFromCustomList: NonNullable<
    CustomListSelectionProps['onRemoveCreatorFromCustomList']
  > = (customListItem) => {
    amplitude.sendEvent<301>({
      id: '301',
      category: 'creator_profile',
      name: 'creator_removed_lists',
      param: {
        creator_id: creatorId,
        list_name: customListItem.name,
      },
    });
  };

  const handleFavoriteValueChange: NonNullable<
    CustomListSelectionProps['onFavoriteValueChange']
  > = (isFavorite) => {
    if (isFavorite) {
      amplitude.sendEvent<302>({
        id: '302',
        category: 'creator_profile',
        name: 'creator_added_favorites',
        param: {
          creator_id: creatorId,
        },
      });
    } else {
      amplitude.sendEvent<303>({
        id: '303',
        category: 'creator_profile',
        name: 'creator_removed_favorites',
        param: {
          creator_id: creatorId,
        },
      });
    }
  };

  const { link, avatarUrl, username } = getCreatorData(data);

  return (
    <div className={`${className} ${styles.root}`}>
      <div className={styles.userWrap}>
        <div className={styles.user}>
          <div className={styles.avaWrap}>
            <Avatar size={64} src={avatarUrl} />
            {type && type !== CONTENT && (
              <div className={styles.platform}>
                <Icon name={getPlatformIcon(type.toLowerCase())} className={styles.platformItem} />
              </div>
            )}
          </div>
          <div className={styles.names}>
            <div className={styles.namesContent}>
              <div className={styles.title}>
                {isBlockedView ? (
                  <BlockedUsername creatorId={creatorId} onLockClick={onUnlockBannerShow} />
                ) : (
                  <SimpleLink href={link} target="_blank" onClick={handleLinkClick}>
                    <Text
                      tag="h2"
                      weight="700"
                      color="creamCan"
                      text={username}
                      className={styles.username}
                    />
                  </SimpleLink>
                )}
              </div>
              <div className={styles.signs}>
                {isRegistered && (currency === 'RUB' || admin) && (
                  <div className={styles.icon}>
                    <RegistrationMark />
                  </div>
                )}
                {admin && (
                  <div className={styles.icon}>
                    <CreatorActivityStatus active={active} lastActivityAt={lastActivityAt} />
                  </div>
                )}
                {badges?.map((item) => {
                  const { name } = item;
                  return (
                    <Tooltip
                      id={name}
                      key={name}
                      tooltipMsg={`creator.badge.${item.name.toLowerCase()}.tooltip`}
                      place="bottom"
                    >
                      <CreatorBadge
                        withIcon={true}
                        withText={false}
                        badge={name}
                        className={styles.badgeTitle}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </div>
            <div className={styles.additionInfo}>
              {totalCount > 0 && admin && (
                <Text
                  weight="500"
                  msg="creator_statistic.subtitles_past_projects"
                  values={{ count: totalCount }}
                />
              )}
            </div>
          </div>
        </div>

        {!isAgent && (
          <div>
            <div className={styles.score}>
              <Text tag="h1" weight="700" text={averageScore} className={styles.scoreTitle} />
              <Icon name="starFull" color={rating?.averageScore ? 'yellow' : undefined} />
              <Suspense fallback={null}>
                <CustomListSelection
                  className={styles.customListSelection}
                  isFavoriteCreator={isFavoriteCreator}
                  creatorCustomLists={creatorCustomLists}
                  organizationId={organizationId}
                  creatorId={creatorId}
                  iconTogglerProps={{
                    kind: 'custom-list-gray',
                    size: 24,
                  }}
                  dropdownProps={{
                    onOpen: handleCustomListSelectionOpen,
                  }}
                  onFavoriteValueChange={handleFavoriteValueChange}
                  onAddCustomListButtonClick={handleAddCustomListButtonClick}
                  onCustomListSuccessfulCreated={handleCustomListSuccessfulCreated}
                  onAddCreatorToCustomList={handleAddCreatorToCustomList}
                  onRemoveCreatorFromCustomList={handleRemoveCreatorFromCustomList}
                />
              </Suspense>
            </div>
            {Number(voteCount) > 0 && (
              <Link
                to="reviews"
                smooth
                duration={100}
                containerId="statistic"
                onClick={handleReviewClick}
                offset={-80}
              >
                <TextButton
                  size="sm"
                  msg="creator_statistic.creator_rating.vote_count"
                  msgValues={{ count: voteCount }}
                />
              </Link>
            )}
          </div>
        )}
      </div>
      <AmazonStoreLink
        creatorProfile={data?.profile}
        onClick={handleStorefrontClick}
        className={styles.storeFront}
      />
    </div>
  );
};

const InfoWrap = track({
  section: 'creator_info',
})(Info);

export default InfoWrap;
