import React, { useContext } from 'react';

import styles from './CreatorsBulkActions.pcss';
import BulkMessage from './BulkMessage/BulkMessage';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Checkbox from 'Components/ui/CheckboxSelect/Checkbox/Checkbox';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  idsForBulkAction: string[];
  onClear: () => void;
}

const CreatorsBulkActions: React.FC<Props> = (props) => {
  const { idsForBulkAction, onClear } = props;

  const { openDrawer } = useContext(DrawerContext);

  const notEmptyList = idsForBulkAction.length > 0;

  const handleBulkMessageClick = () => {
    openDrawer('bulk-message');
  };

  return (
    <div className={styles.root}>
      {notEmptyList && (
        <Text
          msg="creators.filter.selected_creators_amount"
          formatValues={{ count: idsForBulkAction.length }}
          color="white"
        />
      )}
      <AlterButton
        icon="Chat"
        msg="creators.filter.action.message"
        hover={false}
        disabled={!notEmptyList}
        onClick={handleBulkMessageClick}
      />
      <BulkMessage idsForBulkAction={idsForBulkAction} onSuccess={onClear} />
    </div>
  );
};

export default CreatorsBulkActions;
