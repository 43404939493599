import { ControlItem } from '../../components/SegmentedControls/SegmentedControls';

export const getCurrentCreatorsAmountValue = (
  creatorsCountFrom: number | null | undefined,
  creatorsCountTo: number | null | undefined
) => {
  if (creatorsCountFrom && creatorsCountTo && creatorsCountFrom > creatorsCountTo) {
    return '21-100';
  }
  if (creatorsCountTo) {
    if (creatorsCountTo > 20) {
      return '21-100';
    } else if (creatorsCountTo > 10 && creatorsCountTo < 21) {
      return '11-20';
    } else if (creatorsCountTo > 4 && creatorsCountTo < 11) {
      return '5-10';
    }

    return '1-4';
  } else if (creatorsCountFrom) {
    if (creatorsCountFrom > 20) {
      return '21-100';
    } else if (creatorsCountFrom > 10 && creatorsCountFrom < 21) {
      return '11-20';
    } else if (creatorsCountFrom > 4 && creatorsCountFrom < 11) {
      return '5-10';
    }

    return '1-4';
  }

  return undefined;
};

export const getCreatorsAmountList = (): ControlItem[] => {
  return [
    {
      id: '1-4',
      value: '1-4',
      titleText: '<5',
      dataTest: 'button:creatorsToHireAmount:<5',
    },
    {
      id: '5-10',
      value: '5-10',
      titleText: '5-10',
      dataTest: 'button:creatorsToHireAmount:5-10',
    },
    {
      id: '11-20',
      value: '11-20',
      titleText: '10-20',
      dataTest: 'button:creatorsToHireAmount:10-20',
    },
    {
      id: '21-100',
      value: '21-100',
      titleText: '>20',
      dataTest: 'button:creatorsToHireAmount:>20',
    },
  ];
};
