import React from 'react';

import styles from './RecommendedPrice.pcss';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Accordion from 'Components/ui/Accordion/Accordion';
import instagramColoredImg from 'Images/general/instagramColored.svg';
import tiktokColoredImg from 'Images/general/tiktokColored.svg';
import { CampaignUseCase } from 'GraphTypes/BriefPriceRange_campaign.graphql';

const useCases: CampaignUseCase[] = [
  'USER_GENERATED_CONTENT',
  'INFLUENCER_POSTS',
  'AFFILIATE_CAMPAIGN',
  'TIKTOK_SHOP',
  'TIKTOK_SPARK_ADS',
  'META_PARTNERSHIP_ADS',
];

interface Props {
  useCase?: CampaignUseCase | null;
}

const RecommendedPrice: React.FC<Props> = (props) => {
  const { useCase } = props;
  const handleOpen = () => {
    amplitude.sendEvent<462>({
      id: '462',
      category: 'price_recommendation_modal',
      name: 'show',
      param: undefined,
    });
  };

  const handleCaseClick = (index: number) => {
    const currentCase = useCases[index];
    amplitude.sendEvent<463>({
      id: '463',
      category: 'price_recommendation_modal',
      name: 'usecase_open',
      param: { useCase: currentCase },
    });
  };

  return (
    <Drawer rootKey="recommended-price" className={styles.drawer} onOpen={handleOpen}>
      <div className={styles.drawerContainer}>
        <Text type="d2" msg="recommended_price.drawer.title" className={styles.title} />
        <Text
          msg="recommended_price.drawer.descr"
          className={styles.descr}
          formatValues={{
            price: (
              <Text
                color="green"
                msg="recommended_price.drawer.descr.price"
                className={styles.desrValues}
              />
            ),
            results: (
              <Text
                color="green"
                msg="recommended_price.drawer.descr.results"
                className={styles.desrValues}
              />
            ),
          }}
        />
        <div>
          <Text type="h1" msg="recommended_price.drawer.how.title" className={styles.itemTitle} />
          <Accordion
            items={[
              {
                arrowPosition: 'left',
                title: <Text type="md" msg="recommended_price.drawer.how.item" />,
                content: (
                  <div>
                    <Text msg="recommended_price.drawer.how.item.descr" />
                    <div className={styles.example}>
                      <Text msg="recommended_price.drawer.how.price_range" />
                      <div className={styles.examplePrices}>
                        <div className={styles.examplePrice}>
                          <Text type="md" color="grey" msg="general.min" />
                        </div>
                        <Text text="—" />
                        <div className={styles.examplePrice}>
                          <Text type="md" color="grey" msg="general.max" />
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
        <div>
          <Text
            type="h1"
            msg="recommended_price.drawer.by_campaign.title"
            className={styles.itemTitle}
          />
          <Accordion
            withoutSeperaton
            handleOpenItem={handleCaseClick}
            items={[
              {
                arrowPosition: 'left',
                title: <Text msg="recommended_price.drawer.by_campaign.ugc.title" />,
                openedByDefault: useCase === 'USER_GENERATED_CONTENT',
                content: (
                  <div>
                    <Text msg="recommended_price.drawer.by_campaign.ugc.descr" />
                  </div>
                ),
              },
              {
                arrowPosition: 'left',
                title: <Text msg="recommended_price.drawer.by_campaign.influencer.title" />,
                openedByDefault: useCase === 'INFLUENCER_POSTS',
                content: (
                  <div className={styles.content}>
                    <Text msg="recommended_price.drawer.by_campaign.subtitle" />
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={instagramColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.stories" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div>$63 - $150</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$150 - $250</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$250 - $550</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$550 - $2,500</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$2,500+</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={instagramColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.reels" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div> $125 - $300</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$300 - $500</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$500 - $1,100</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$1,100 - $5,000</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$5,000+</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={tiktokColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.followers" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div>$125 - $325</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$325 - $600</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$600 - $1,100</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$1,100 - $5,000</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$5,000+</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={tiktokColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.view" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 1,000</div>
                          <div>$125 - $325</div>
                        </div>
                        <div className={styles.tr}>
                          <div>1,000 - 5,000</div>
                          <div>$325 - $600</div>
                        </div>
                        <div className={styles.tr}>
                          <div>5,000 - 10,000</div>
                          <div>$600 - $1,100</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$1,100 - $5,000</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000+</div>
                          <div>$5,000+</div>
                        </div>
                      </div>
                    </div>
                    <Text color="grey" msg="recommended_price.drawer.by_campaign.disclaimer" />
                    <Text msg="recommended_price.drawer.by_campaign.descr" />
                  </div>
                ),
              },
              {
                arrowPosition: 'left',
                title: <Text msg="recommended_price.drawer.by_campaign.affiliate.title" />,
                openedByDefault: useCase === 'AFFILIATE_CAMPAIGN',
                content: (
                  <div>
                    <Text msg="recommended_price.drawer.by_campaign.subtitle" />
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={instagramColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.stories" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div>$30 - $75</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$75 - $125</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$125- $275</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$275 - $1,250</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$1,250+</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={instagramColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.reels" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div>$60 - $150</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$150 - $250</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$250 - $550</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$550 - $2,500</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$2,500+</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={tiktokColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.followers" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div>$60 - $160</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$160 - $300</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$300 - $550</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$550 - $2,500</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$2,500+</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={tiktokColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.view" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 1,000</div>
                          <div>$60 - $160</div>
                        </div>
                        <div className={styles.tr}>
                          <div>1,000 - 5,000</div>
                          <div>$160 - $300</div>
                        </div>
                        <div className={styles.tr}>
                          <div>5,000 - 10,000</div>
                          <div>$300 - $550</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$550 - $2,500</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000+</div>
                          <div>$2,500+</div>
                        </div>
                      </div>
                    </div>
                    <Text color="grey" msg="recommended_price.drawer.by_campaign.disclaimer" />
                    <Text msg="recommended_price.drawer.by_campaign.descr" />
                  </div>
                ),
              },
              {
                arrowPosition: 'left',
                title: <Text msg="recommended_price.drawer.by_campaign.tt_shop.title" />,
                openedByDefault: useCase === 'TIKTOK_SHOP',
                content: (
                  <div>
                    <Text msg="recommended_price.drawer.by_campaign.subtitle" />
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={tiktokColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.followers" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div>$60 - $160</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$160 - $300</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$300 - $550</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$550 - $2,500</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$2,500+</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={tiktokColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.view" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 1,000</div>
                          <div>$60 - $160</div>
                        </div>
                        <div className={styles.tr}>
                          <div>1,000 - 5,000</div>
                          <div>$160 - $300</div>
                        </div>
                        <div className={styles.tr}>
                          <div>5,000 - 10,000</div>
                          <div>$300 - $550</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$550 - $2,500</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000+</div>
                          <div>$2,500+</div>
                        </div>
                      </div>
                    </div>
                    <Text color="grey" msg="recommended_price.drawer.by_campaign.disclaimer" />
                    <Text msg="recommended_price.drawer.by_campaign.descr" />
                  </div>
                ),
              },
              {
                arrowPosition: 'left',
                title: <Text msg="recommended_price.drawer.by_campaign.spark_ads.title" />,
                openedByDefault: useCase === 'TIKTOK_SPARK_ADS',
                content: (
                  <div>
                    <Text msg="recommended_price.drawer.by_campaign.subtitle" />
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={tiktokColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.followers" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div>$225 - $425</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$425 - $700</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$700 - $1,200</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$1,200 - $5,100</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$5,100+</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={tiktokColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.view" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 1,000</div>
                          <div>$225 - $425</div>
                        </div>
                        <div className={styles.tr}>
                          <div>1,000 - 5,000</div>
                          <div>$425 - $700</div>
                        </div>
                        <div className={styles.tr}>
                          <div>5,000 - 10,000</div>
                          <div>$700 - $1,200</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$1,200 - $5,100</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000+</div>
                          <div>$5,100+</div>
                        </div>
                      </div>
                    </div>
                    <Text color="grey" msg="recommended_price.drawer.by_campaign.disclaimer" />
                    <Text msg="recommended_price.drawer.by_campaign.descr" />
                  </div>
                ),
              },
              {
                arrowPosition: 'left',
                title: <Text msg="recommended_price.drawer.by_campaign.meta_ads.title" />,
                openedByDefault: useCase === 'META_PARTNERSHIP_ADS',
                content: (
                  <div>
                    <Text msg="recommended_price.drawer.by_campaign.subtitle" />
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={instagramColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.stories" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div>$112 - $200</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$200 - $300</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$300 - $600</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$600 - $2,550</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$2,550+</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.tableTitle}>
                        <img src={instagramColoredImg} />
                        <Text msg="recommended_price.drawer.by_campaign.reels" />
                      </div>
                      <div className={styles.table}>
                        <div className={styles.tr}>
                          <div>Under 10,000</div>
                          <div>$225 - $400</div>
                        </div>
                        <div className={styles.tr}>
                          <div>10,000 - 50,000</div>
                          <div>$400 - $600</div>
                        </div>
                        <div className={styles.tr}>
                          <div>50,000 - 100,000</div>
                          <div>$600 - $1,200</div>
                        </div>
                        <div className={styles.tr}>
                          <div>100,000 - 500,000</div>
                          <div>$1,200 - $5,100</div>
                        </div>
                        <div className={styles.tr}>
                          <div>500,000+</div>
                          <div>$5,100+</div>
                        </div>
                      </div>
                    </div>
                    <Text msg="recommended_price.drawer.by_campaign.descr" />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default RecommendedPrice;
