/**
 * @generated SignedSource<<269d4590522c98b53e89ed4f0725e37d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type SubscriptionInterval = "BIANNUAL" | "CUSTOM" | "MONTHLY" | "TRIMONTHLY" | "YEARLY" | "%future added value";
export type Stripe_BuySubscriptionInput = {
  addonItems?: ReadonlyArray<AddonItemInput> | null | undefined;
  cancelUrl: string;
  clientMutationId?: string | null | undefined;
  interval: SubscriptionInterval;
  planId: PlanId;
  promocode?: string | null | undefined;
  successUrl: string;
};
export type AddonItemInput = {
  addonId: AddonId;
  quantity: number;
};
export type BuySubscriptionViaStripeMutation$variables = {
  input: Stripe_BuySubscriptionInput;
};
export type BuySubscriptionViaStripeMutation$data = {
  readonly stripe: {
    readonly buySubscription: {
      readonly checkoutSession: {
        readonly id: string;
        readonly stripeId: string;
      } | null | undefined;
      readonly clientMutationId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type BuySubscriptionViaStripeMutation = {
  response: BuySubscriptionViaStripeMutation$data;
  variables: BuySubscriptionViaStripeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Stripe_Mutation",
    "kind": "LinkedField",
    "name": "stripe",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "Stripe_BuySubscriptionPayload",
        "kind": "LinkedField",
        "name": "buySubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Stripe_CheckoutSession",
            "kind": "LinkedField",
            "name": "checkoutSession",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stripeId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BuySubscriptionViaStripeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BuySubscriptionViaStripeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9239f4c645a34be5163dd8f94713de80",
    "id": null,
    "metadata": {},
    "name": "BuySubscriptionViaStripeMutation",
    "operationKind": "mutation",
    "text": "mutation BuySubscriptionViaStripeMutation(\n  $input: Stripe_BuySubscriptionInput!\n) {\n  stripe {\n    buySubscription(input: $input) {\n      clientMutationId\n      checkoutSession {\n        id\n        stripeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "11efed7a3af2044307000d60514b956d";

export default node;
