import { graphql, useMutation, UseMutationConfig } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import { RemoveCreatorFromCustomListMutation } from 'GraphTypes/RemoveCreatorFromCustomListMutation.graphql';
import { BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation RemoveCreatorFromCustomListMutation($input: RemoveCreatorFromCustomListInput!) {
    removeCreatorFromCustomList(input: $input) {
      __typename
      ... on RemoveCreatorFromCustomListPayload {
        creator {
          id
          customLists {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const useRemoveCreatorFromCustomListMutation = (
  organizationId?: string,
  config?: UseMutationConfig<RemoveCreatorFromCustomListMutation>
): [BaseMutationExecFn<RemoveCreatorFromCustomListMutation>, boolean] => {
  const [removeCreatorFromCustomList, loading] =
    useMutation<RemoveCreatorFromCustomListMutation>(mutation);

  const removeCreatorFromCustomListCaller: BaseMutationExecFn<
    RemoveCreatorFromCustomListMutation
  > = (execConfig) => {
    const { customListId, creatorId } = execConfig.variables.input;
    removeCreatorFromCustomList({
      ...config,
      ...execConfig,
      updater: (store) => {
        const updateCustomLists = async () => {
          if (!organizationId) return;
          const customLists = store
            ?.get(organizationId)
            ?.getLinkedRecord('customLists')
            ?.getLinkedRecords('edges')
            ?.find((item) => item?.getLinkedRecord('node')?.getValue('id') === customListId);

          if (!customLists) return;

          const creators = customLists
            ?.getLinkedRecord('node')
            ?.getLinkedRecord('creators')
            ?.getLinkedRecords('edges');

          if (!creators) return;

          const edges = creators.filter((item) => {
            const id = item.getLinkedRecord('node')?.getValue('id');
            return id !== creatorId;
          });
          customLists
            .getLinkedRecord('node')
            ?.getLinkedRecord('creators')
            ?.setLinkedRecords(edges, 'edges');
        };

        const updateCustomList = async () => {
          const customList = store.get(customListId);
          if (!customList) return;
          const customListCreators = ConnectionHandler.getConnection(
            customList,
            'CustomList_creators'
          );
          const filteredCustomList = customListCreators
            ?.getLinkedRecords('edges')
            ?.filter((edge) => {
              return edge?.getLinkedRecord('node')?.getValue('id') !== creatorId;
            });
          const totalCount = filteredCustomList?.length || 0;
          customListCreators?.setLinkedRecords(filteredCustomList, 'edges');
          customListCreators?.setValue(totalCount, 'totalCount');
        };

        const updateCustomListInfo = async () => {
          const root = store.getRoot();
          const creators = root
            .getLinkedRecord('customList', { id: customListId })
            ?.getLinkedRecord('creators', { first: 0 });
          if (!creators) return;
          const totalCount = creators?.getValue('totalCount');
          const newTotalCount =
            ((totalCount && Number(totalCount) >= 0
              ? creators?.getValue('totalCount')
              : 0) as number) - 1;
          creators.setValue(newTotalCount, 'totalCount');
        };

        updateCustomLists();
        updateCustomList();
        updateCustomListInfo();
      },
    });
  };
  return [removeCreatorFromCustomListCaller, loading];
};
