import React, { useState } from 'react';
import { graphql, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';
import xor from 'lodash/xor';

import CreatorsStub from '../CreatorsStub/CreatorsStub';
import { CreatorsListQuery } from '../CreatorsList';

import styles from './CreatorsListContainer.pcss';
import CreatorCard from './CreatorCard/CreatorCard';
import CreatorsBulkActions from './CreatorsBulkActions/CreatorsBulkActions';

import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import Text from 'Components/ui/Text/Text';
import { CreatorsListContainer_campaign$key } from 'GraphTypes/CreatorsListContainer_campaign.graphql';
import {
  ProjectOrder,
  AdvertiserProjectStage,
  CreatorBadge,
  CreatorsListQuery as QueryType,
} from 'GraphTypes/CreatorsListQuery.graphql';

const COUNT = 10;
const OFFSET = 70;

interface Props {
  campaignId: string;
  organizationId?: string;
  isAdminView: boolean;
  shortlisted?: boolean;
  isBulkAction: boolean;
  invitedByOrganizationMember?: boolean;
  outreach?: boolean;
  isLongTermCampaign?: boolean;
  orderBy?: ProjectOrder;
  stages?: AdvertiserProjectStage[];
  badges?: CreatorBadge[];
  textQuery?: string;
  archived: boolean;
  canCreateMultipleDeals: boolean;
  createdByAdmin?: boolean | null;
  creatorsStages?: AdvertiserProjectStage[];
  defaultStages?: AdvertiserProjectStage[];
  archivedStages?: AdvertiserProjectStage[];
  portfolioTypeName: string;
  portfolioCategoryName: string;
  screeningQuestionIds?: string[];
  withPreviousCollaborations?: boolean;
  queryReference: PreloadedQuery<QueryType>;
}

const CreatorsListContainer: React.FC<Props> = (props) => {
  const {
    organizationId,
    campaignId,
    isAdminView,
    creatorsStages,
    defaultStages,
    archivedStages,
    portfolioTypeName,
    portfolioCategoryName,
    canCreateMultipleDeals,
    isLongTermCampaign,
    queryReference,
    isBulkAction,
  } = props;
  const [portfolioIndex, setPortfolioIndex] = useState<number>(0);
  const [idsForBulkAction, setIdsForBulkAction] = useState<string[]>([]);

  const queryData = usePreloadedQuery<QueryType>(CreatorsListQuery, queryReference);

  const fragmentData = usePaginationFragment<QueryType, CreatorsListContainer_campaign$key>(
    graphql`
      fragment CreatorsListContainer_campaign on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        stages: { type: "[AdvertiserProjectStage!]" }
        shortlisted: { type: "Boolean" }
        textQuery: { type: "String" }
        orderBy: { type: "ProjectOrder" }
        archived: { type: "Boolean", defaultValue: false }
        badges: { type: "[CreatorBadge!]" }
        createdByAdmin: { type: "Boolean" }
        invitedByOrganizationMember: { type: "Boolean" }
        outreach: { type: "Boolean" }
        organizationId: { type: "ID!" }
        campaignId: { type: "ID!" }
        screeningQuestionIds: { type: "[ID!]" }
        withPreviousCollaborations: { type: "Boolean" }
        draftDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
        publicationDeadlineIntervals: { type: "[DateInterval!]", defaultValue: [] }
      )
      @refetchable(queryName: "CreatorsListContainerPaginationList") {
        campaign(id: $campaignId) {
          activation {
            paused
          }
          projects(
            first: $count
            after: $cursor
            shortlisted: $shortlisted
            textQuery: $textQuery
            stages: $stages
            orderBy: $orderBy
            archived: $archived
            badges: $badges
            createdByAdmin: $createdByAdmin
            invitedByOrganizationMember: $invitedByOrganizationMember
            outreach: $outreach
            withPreviousCollaborations: $withPreviousCollaborations
            draftDeadlineIntervals: $draftDeadlineIntervals
            publicationDeadlineIntervals: $publicationDeadlineIntervals
            screeningQuestionIds: $screeningQuestionIds
          ) @connection(key: "Projects_projects", filters: []) {
            totalCount
            edges {
              node {
                id
                creator {
                  id
                }
                ...CreatorCard_project @arguments(organizationId: $organizationId)
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      }
    `,
    queryData
  );

  const items = Array.from(fragmentData?.data.campaign?.projects.edges || []);
  const totalCount = fragmentData?.data?.campaign?.projects.totalCount;

  if (items?.length === 0) {
    return <Text msg="new_campaign.no_creators" />;
  }

  const handleIdsClear = () => {
    setIdsForBulkAction([]);
  };

  const cards = items?.map((item, index) => {
    if (!item?.node) return null;

    const { id } = item.node;
    const onCardHover: (cardIndex: number) => void = (cardIndex) => setPortfolioIndex(cardIndex);
    const creatorId = item?.node?.creator?.id || '';

    const isSelected = idsForBulkAction.includes(id);

    const handleProjectSelect = (newId: string) => {
      const newList = xor(idsForBulkAction, [newId]);
      setIdsForBulkAction(newList);
    };

    return (
      <CreatorCard
        isBulkAction={isBulkAction}
        project={item.node}
        isAdminView={isAdminView}
        organizationId={organizationId}
        campaignId={campaignId}
        key={id}
        isSelected={isSelected}
        isPortfolioOpen={index === portfolioIndex}
        onMouseEnter={() => onCardHover(index)}
        creatorId={creatorId}
        creatorsStages={creatorsStages}
        defaultStages={defaultStages}
        archivedStages={archivedStages}
        portfolioTypeName={portfolioTypeName}
        portfolioCategoryName={portfolioCategoryName}
        canCreateMultipleDeals={canCreateMultipleDeals}
        isLongTermCampaign={isLongTermCampaign}
        onSelect={handleProjectSelect}
      />
    );
  });

  return (
    <div className={styles.creatorsContainerRoot}>
      <div className={styles.creatorsCount}>
        <Text
          type="md"
          color="grey"
          formatValues={{ count: totalCount || '-' }}
          msg="campaign.creators_count"
        />
      </div>
      <LoaderHandlerWithHooks
        relay={fragmentData}
        offset={OFFSET}
        count={COUNT}
        items={items}
        preloader={<CreatorsStub />}
      >
        <div className={styles.cards}>{cards}</div>
      </LoaderHandlerWithHooks>
      {!!idsForBulkAction.length && isBulkAction && (
        <CreatorsBulkActions idsForBulkAction={idsForBulkAction} onClear={handleIdsClear} />
      )}
    </div>
  );
};

export default CreatorsListContainer;
