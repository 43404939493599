import React, { useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import compact from 'lodash/compact';
import { graphql, useLazyLoadQuery } from 'react-relay';
import uniqBy from 'lodash/uniqBy';

import styles from './BcaPermissionModalContent.pcss';

import {
  BcaPermissionModalContentQuery as QueryType,
  BcaPermissionModalContentQuery$data,
} from 'GraphTypes/BcaPermissionModalContentQuery.graphql';
import Text from 'Components/ui/Text/Text';
import Avatar from 'Atoms/Avatar/Avatar';
import BcaConnectionStateAdsIcon from 'Components/BcaConnectionStateAdsIcon/BcaConnectionStateAdsIcon';
import PaidSocialLicenseCampaignList, {
  Campaigns,
} from 'Components/PaidSocialLicenseCampaignList/PaidSocialLicenseCampaignList';
import ActivateBca from 'Molecules/ActivateBca/ActivateBca';
import Button from 'Components/ui/Button/Button';
import { default as UsagePerformanceList } from 'Atoms/UsagePerformanceList/UsagePerformanceList';
import { createCountriesList } from 'Util/dataFormatter';
import { getCreatorData } from 'Util/creatorData';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { instagramLink } from 'Util/links';
import { BcaPermissionStateEnum } from 'Types/enums';
import Drawer from 'Components/ui/Drawer';

const BcaPermissionModalContentQuery = graphql`
  query BcaPermissionModalContentQuery {
    currentUser {
      organization {
        subscription {
          planId
          effectiveLimits {
            maxBcaConnections
          }
        }
        counters {
          bcaLicensedCreators
        }
        bcaPermissions {
          nodes {
            id
            brandInstagramUsername
            state
            creator {
              id
              type
              __typename
              username
              profile {
                countries {
                  name
                }
                profilePictureFile {
                  url
                  ... on Image {
                    transformations {
                      collageThumbnailUrl
                    }
                  }
                }
              }
              ownership {
                owner {
                  contractorProfile {
                    firstName
                    lastName
                  }
                }
              }
            }
            requests {
              edges {
                node {
                  project {
                    id
                    campaign {
                      stage
                      name
                      platform
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  className?: string;
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
  bcaPermissionId: string;
  onResponse?: (response: BcaPermissionModalContentQuery$data) => void;
  onBcaConnectionImpossible?: OnBcaConnectionImpossible;
  onLoad?: () => void;
};

const BcaPermissionModalContent: React.FC<Props> = (props) => {
  const { className, bcaPermissionId, drawerProps, onBcaConnectionImpossible, onLoad } = props;

  const response = useLazyLoadQuery<QueryType>(BcaPermissionModalContentQuery, {});

  useEffect(() => {
    if (response) onLoad?.();
  }, [response, onLoad]);

  const bcaPermission = useMemo<BcaPermission | undefined>(() => {
    return response.currentUser?.organization?.bcaPermissions?.nodes?.find(
      (item) => item?.id === bcaPermissionId
    );
  }, [response, bcaPermissionId]);

  const isMaxBcaConnectionsExceeded = useMemo<boolean>(() => {
    const maxBcaConnections =
      response.currentUser?.organization?.subscription?.effectiveLimits.maxBcaConnections || null;
    const bcaLicensedCreators =
      response.currentUser?.organization?.counters?.bcaLicensedCreators || null;

    return typeof maxBcaConnections === 'number' && typeof bcaLicensedCreators === 'number'
      ? bcaLicensedCreators >= maxBcaConnections
      : false;
  }, [response]);

  const { brandInstagramUsername, creator, requests, state } = bcaPermission || {
    brandInstagramUsername: null,
    creator: null,
    requests: null,
    state: null,
  };

  const contractorProfile = creator?.ownership?.owner.contractorProfile;

  const firstProjectId = useMemo<string>(() => {
    if (!requests?.edges) return '';
    return requests.edges[0]?.node?.project?.id || '';
  }, [requests?.edges]);

  const uniqCampaigns = useMemo(() => {
    return uniqBy(requests?.edges, 'node.project.id');
  }, [requests?.edges]);

  const countries = creator?.profile?.countries;

  const countriesList = useMemo(() => {
    return createCountriesList(countries);
  }, [countries]);

  const { avaSrc, username } = useMemo(() => {
    if (!creator) {
      return { avaSrc: null, username: null };
    }
    return getCreatorData(creator);
  }, [creator]);

  const ownerName = useMemo(() => {
    return compact([contractorProfile?.firstName, contractorProfile?.lastName]).join(' ');
  }, [contractorProfile]);

  const handleCheckingBcaConnectionPossibility = useCallback<
    NonNullable<ActivateBcaProps['onCheckingConnectionPossibility']>
  >(() => {
    if (isMaxBcaConnectionsExceeded) {
      onBcaConnectionImpossible?.('maxLicensedCreatorsExceeded');
      return false;
    }
    return true;
  }, [onBcaConnectionImpossible, isMaxBcaConnectionsExceeded]);

  const informationList: UsagePerformanceListProps['values'] = [
    {
      iconName: 'Platform-instagram',
      msgTextProps: {
        children: (
          <div className={styles.informationListContent}>
            <Text msg="bca_permission_modal.instagram_account.title" className={styles.listTitle} />
            <SmartLink path={instagramLink({ username })}>
              <Text type="md" text={`@${username}`} />
            </SmartLink>
          </div>
        ),
      },
    },
    {
      iconName: 'Platform-instagram',
      msgTextProps: {
        children: (
          <div className={styles.informationListContent}>
            <Text msg="bca_permission_modal.brand_account.title" className={styles.listTitle} />
            <SmartLink path={instagramLink({ username: brandInstagramUsername })}>
              <Text type="md" text={`@${brandInstagramUsername}`} />
            </SmartLink>
          </div>
        ),
      },
    },
  ];

  const isConnectButtonVisible = useMemo<boolean>(() => {
    if (!state) return false;
    return (
      Boolean(firstProjectId) &&
      new Set<BcaPermissionState>([
        BcaPermissionStateEnum.EXPIRED,
        BcaPermissionStateEnum.INVALID,
        BcaPermissionStateEnum.CANCELED,
        BcaPermissionStateEnum.REVOKED,
        BcaPermissionStateEnum.UNKNOWN,
      ]).has(state)
    );
  }, [state, firstProjectId]);

  const handleConnectButtonClick = useCallback(() => {
    drawerProps?.onClose?.();
  }, [drawerProps]);

  if (!bcaPermission) {
    return null;
  }

  return (
    <div className={classnames(styles.root, className)}>
      <div>
        <Text type="d2" msg="bca_permission_modal.title" className={styles.title} />
        <div className={styles.creator}>
          <div className={styles.avatar}>
            <Avatar size={64} src={avaSrc} />
          </div>
          <div>
            <Text text={ownerName} type="h1" />
            <Text text={countriesList} type="md" color="grey" />
          </div>
        </div>
        <BcaConnectionStateAdsIcon permissionState={state as BcaPermissionStateEnum} />
        <Text msg="bca_permission_modal.information.title" className={styles.contentTitle} />
        <UsagePerformanceList values={informationList} />
        {requests?.edges && requests?.edges?.length > 0 && (
          <>
            <Text msg="bca_permission_modal.campaigns.title" className={styles.contentTitle} />
            <PaidSocialLicenseCampaignList campaigns={uniqCampaigns as Campaigns} />
          </>
        )}
      </div>
      {isConnectButtonVisible && (
        <ActivateBca
          projectId={firstProjectId}
          onCheckingConnectionPossibility={handleCheckingBcaConnectionPossibility}
        >
          <Button
            color="black"
            msg="bca_permission_modal.button.connect_again"
            className={styles.btn}
            onClick={handleConnectButtonClick}
          />
        </ActivateBca>
      )}
    </div>
  );
};

export default BcaPermissionModalContent;

type DrawerProps = React.ComponentProps<typeof Drawer>;
type UsagePerformanceListProps = React.ComponentProps<typeof UsagePerformanceList>;
type BcaPermission = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<BcaPermissionModalContentQuery$data['currentUser']>['organization']
    >['bcaPermissions']
  >['nodes']
>[0];
type BcaPermissionState = NonNullable<BcaPermission>['state'];
type ActivateBcaProps = React.ComponentProps<typeof ActivateBca>;
type OnBcaConnectionImpossibleReason = 'maxLicensedCreatorsExceeded';
type OnBcaConnectionImpossible = (reason: OnBcaConnectionImpossibleReason) => void;
