import React, { useContext, useEffect, useState, ChangeEvent, Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Element } from 'react-scroll';

import { BriefContext } from '../../../Brief.Context';
import SegmentedControls from '../components/SegmentedControls/SegmentedControls';
import Section from '../components/Section/Section';
import Field from '../components/Field/Field';
import With from '../components/With/With';
import ComissionRateDetails from '../CampaignDetails/ComissionRateDetails/ComissionRateDetails';

import styles from './ProductDetails.pcss';
import ProductImage from './ProductImage/ProductImage';
import TiktokShop from './TiktokShop/TiktokShop';
import { getDeliveryTypesList, checkValidation, getViewConditions } from './util';

import { useTikTokShopAuth } from 'Hooks/useTikTokShopAuth';
import { lightWebsiteUrl } from 'Util/validate';
import TiktokShopProductsListDrawer from 'Modal/advertiser/TiktokShopProductsList/TiktokShopProductsList';
import updateBrief from 'Mutations/UpdateBrief.Mutation';
import { amplitude } from 'Helpers/amplitude';
import Input from 'Components/ui/Input/Input';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import { ProductDetails_campaign$key } from 'GraphTypes/ProductDetails_campaign.graphql';
import Button from 'Components/ui/Button/Button';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import TextButton from 'Components/ui/TextButton/TextButton';

interface Props {
  isAdmin: boolean;
  campaign: ProductDetails_campaign$key;
}

const ProductDetails: React.FC<Props> = (props) => {
  const { campaign, isAdmin } = props;

  const data = useFragment(
    graphql`
      fragment ProductDetails_campaign on Campaign {
        id
        type
        cover {
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
        }
        useCase
        brief {
          id
          submitted
          __typename
          ... on V2Brief {
            productUrl
            productDelivery
            productTitle
            productDescription
            includeReferralFee
            referralFeeRate
            productPrice
            tiktokShop_ShopId
            tiktokShop_ProductId
          }
        }
        organization {
          tiktokShopLinked
        }
        ...ComissionRateDetails_campaign
        ...TiktokShop_campaign
      }
    `,
    campaign
  );
  const [briefState, briefDispatch] = useContext(BriefContext);
  const [productTitleDefaultValue, setProductTitleDefaultValue] = useState<
    string | null | undefined
  >('');
  const { openDrawer } = useContext(DrawerContext);

  const { authorize: authorizeTiktokShop } = useTikTokShopAuth();

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const tiktokShopAvailable = hasFeatureFlag('tiktok_shop');

  const { showErrors, shownElementsWithPossibleError } = briefState;

  const { id: campaignId, cover, type, brief } = data;
  const coverUrl = cover?.transformations?.collageThumbnailUrl;
  const deliveryTypesList = getDeliveryTypesList({ campaignId, campaignType: type });
  const viewConditions = getViewConditions({ campaign: data, tiktokShopAvailable });
  const {
    id: briefId,
    productUrl,
    productTitle,
    productDelivery,
    productDescription,
    includeReferralFee,
    productPrice,
  } = brief || {};

  const handleBriefChange = (data: any) => {
    if (data.productUrl) {
      amplitude.sendEvent({
        id: '75',
        category: 'brief',
        name: 'product_url_fill',
        param: { campaignId },
      });
    }
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateBrief({ id: briefId, ...data });
  };

  const handleProductDetailsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (!briefId) return;
    updateBrief({ id: briefId, productTitle: newValue });
  };

  const handleProductPriceChanged = (event) => {
    const newValue = event?.target?.value;
    if (newValue.length === 0) {
      handleBriefChange({ productPrice: null });
    } else {
      handleBriefChange({ productPrice: Math.abs(newValue) });
    }
  };

  useEffect(() => {
    const errors = checkValidation({ campaign: data, tiktokShopAvailable });
    briefDispatch({ key: 'productInfoErrors', value: errors });
    briefDispatch({ key: 'productInfoFilled', value: errors.length === 0 });
  }, [data, tiktokShopAvailable]);

  const productDescriptionShort =
    productDescription && productDescription?.length > 50
      ? `${productDescription.slice(0, 47)}...`
      : productDescription;

  const productTitleInitialValue =
    !productTitle && productDescription ? productDescriptionShort : '';

  // setting Product title value as first 50 chars of Product description value
  // in case if title is not filled initially but description is filled (e.g. while duplicating)
  useEffect(() => {
    if (!productTitle && productDescription) {
      handleBriefChange({ productTitle: productTitleInitialValue });
    }
  }, []);

  if (!brief || !briefState.campaignInfoFilled) return null;

  const isTTShopAuthorized = data.organization?.tiktokShopLinked;

  const handleShowProductList = () => {
    if (isTTShopAuthorized) {
      openDrawer('tt-shop-product-list');
    } else {
      authorizeTiktokShop();
    }
  };

  const productUrlInvalid = productUrl && !lightWebsiteUrl(productUrl);

  let productPriceDescr = 'brief_template.field.product.price.descr';

  if (type === 'PRODUCT_SEEDING') {
    productPriceDescr = 'brief_template.field.product.price.product_seeding.descr';
  } else if (includeReferralFee) {
    productPriceDescr = 'brief_template.field.product.price.comission.descr';
  }

  const productPriceMandatory = type === 'PRODUCT_SEEDING' || includeReferralFee;

  return (
    <Section titleMsg="brief_template.section.product" className={styles.root}>
      <With condition={true} name="cover">
        <Element name="cover">
          <ProductImage campaignId={campaignId} coverUrl={coverUrl} />
        </Element>
      </With>
      <With condition={viewConditions.delivery} name="productDelivery">
        <Element name="productDelivery">
          <Field
            title="brief_template.field.product.delivery.title"
            description="brief_template.field.product.delivery.descr"
          >
            <SegmentedControls
              id="productDelivery"
              error={
                showErrors &&
                shownElementsWithPossibleError?.includes('productDelivery') &&
                !productDelivery
              }
              currentValue={productDelivery}
              items={deliveryTypesList}
              onChange={handleBriefChange}
            />
          </Field>
        </Element>
      </With>
      <TiktokShop
        ttShopVisible={!!viewConditions.ttShop}
        campaign={data}
        onChange={handleBriefChange}
      />
      <With condition={viewConditions.productName} name="productTitle">
        <Element name="productTitle">
          <Field
            element="input"
            name="productTitle"
            title="brief_template.field.product.name.title"
            description="brief_template.field.product.name.descr"
            elementData={{
              defaultValue: productTitle,
              placeholderMsg: 'brief_template.field.product.name.placeholder',
              onBlur: handleBriefChange,
              dataTest: 'input:productTitle',
              maxlength: 50,
              error:
                showErrors &&
                !productTitle &&
                shownElementsWithPossibleError?.includes('productTitle'),
            }}
          />
        </Element>
      </With>

      <With condition={viewConditions.description} name="productDescription">
        <Element name="productDescription">
          <Field
            element="input"
            name="productDescription"
            title={'brief_template.field.product.description.title'}
            description={'brief_template.field.product.description.descr'}
            elementData={{
              error:
                showErrors &&
                !productDescription &&
                shownElementsWithPossibleError?.includes('productDescription'),
              multiple: true,
              defaultValue: productDescription,
              placeholderMsg: 'brief_template.field.product.description.placeholder',
              onBlur: handleBriefChange,
              textAreaData: { minRows: 1, maxRows: 5 },
              dataTest: 'input:describeProduct',
            }}
          />
        </Element>
      </With>
      <With condition={viewConditions.productUrl} name="productUrl">
        <Element name="productUrl">
          <Field
            element="input"
            name="productUrl"
            title="brief_template.field.product.url.title"
            description="brief_template.field.product.url.descr"
            elementData={{
              defaultValue: productUrl,
              onBlur: handleBriefChange,
              placeholderMsg: 'brief_template.field.product.url.placeholder',
              dataTest: 'input:productUrl',
            }}
            error={
              productUrlInvalid ||
              (showErrors && !productUrl && shownElementsWithPossibleError?.includes('productUrl'))
            }
            errorData={{ msg: 'form.error.website' }}
          />
        </Element>
      </With>
      <With condition={viewConditions.productPrice} name="productPrice">
        <Element name="productPrice">
          <Field
            title="brief_template.field.product.price.title"
            description={productPriceDescr}
            name="referralFeeRate"
            isOptional={!productPriceMandatory}
          >
            <Input
              bordered
              value={productPrice || undefined}
              className={styles.productPriceInput}
              error={
                !!productPriceMandatory &&
                showErrors &&
                !productPrice &&
                shownElementsWithPossibleError?.includes('productPrice')
              }
              onBlur={handleProductPriceChanged}
              type="number"
              rightSymbol="$"
            />
          </Field>
        </Element>
      </With>
      {tiktokShopAvailable && data.useCase === 'TIKTOK_SHOP' && (
        <With condition={viewConditions.fee} name="fee">
          <ComissionRateDetails
            isAdmin={isAdmin}
            comissionAvailable={false}
            commissionInputVisible={true}
            campaign={data}
            onBriefChange={handleBriefChange}
          />
        </With>
      )}
      <Suspense fallback={null}>
        <TiktokShopProductsListDrawer briefId={briefId} campaignId={campaignId} />
      </Suspense>
    </Section>
  );
};

export default ProductDetails;
