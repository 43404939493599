import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { getIcon } from './data';
import styles from './CampaignData.css';

import { amplitude } from 'Helpers/amplitude';
import { createTextWithLinks, escapeText, getOuterLink } from 'Util/textFormatter';
import { createSum } from 'Util/numberFormatter';
import Text from 'Components/ui/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import NewIcon from 'Components/ui/Icon/Icon';
import { IconName } from 'Components/ui/types';
import Button from 'Atoms/Button/Button';
import TextButton from 'Atoms/TextButton/TextButton';
import Avatar from 'Atoms/Avatar/Avatar';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { barterInfoData } from 'Constants/messageModal';
import { Currency } from 'GraphTypes/ListingBriefQuery.graphql';
import { CampaignData_campaign$key } from 'GraphTypes/CampaignData_campaign.graphql';
import { modalName } from 'Types/modals';
import {
  BARTER_TYPE,
  INFLUENCER,
  BCA_INFO,
  BCA_INFO_FOR_CREATORS,
  PRODUCT_SEEDING,
  LONG_TERM,
} from 'Constants/general';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

type InfoData = {
  key?: string;
  iconName?: string;
  newIconName?: IconName;
  titleData: string;
  subtitleData: string;
  handleReadMoreClick?: () => void;
};

interface Props {
  campaign?: CampaignData_campaign$key;
  currency?: Currency | null;
  isCreator: boolean;
  mobileView?: boolean;
  compactView?: boolean;
  shortenView?: boolean;
}

const CampaignData: React.FC<Props> = (props) => {
  const {
    campaign,
    isCreator,
    mobileView,
    currency: projectCurrency,
    compactView,
    shortenView,
  } = props;

  const data = useFragment(
    graphql`
      fragment CampaignData_campaign on Campaign {
        cover {
          ... on Image {
            thumbnailUrl
          }
        }
        paymentType
        name
        type
        platform
        description
        brand {
          name
          summary
          websiteUrl
          logo {
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
        }
        brief {
          __typename
          ... on V2Brief {
            productDescription
            productDelivery
            productPrice
            productUrl
            paidSocialBca
            paidSocial
            paidSocialActivationDays
            sparkAdsActivationDays
            priceLimitMin
            priceLimitMax
            includeReferralFee
            referralFeeRate
            taskDescription
          }
        }
      }
    `,
    campaign
  );

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const tracking = useTracking();
  const intl = useIntl();
  const currency = projectCurrency || 'USD';

  const brief = data?.brief;

  if (!data || !brief || (brief && brief.__typename !== 'V2Brief')) {
    return null;
  }

  const {
    cover,
    paymentType,
    name: campaignName,
    type: campaignType,
    platform: campaignPlatform,
    brand,
    description,
  } = data;

  const brandName = brand?.name;
  const brandLogo = brand?.logo?.transformations?.collageThumbnailUrl;
  const brandSummary = brand?.summary;
  const brandWebsiteUrl = brand?.websiteUrl;

  const isBarter = paymentType === BARTER_TYPE;
  const isSeedingCampaign = campaignType === 'PRODUCT_SEEDING';

  const {
    productDescription,
    productUrl,
    productPrice,
    paidSocialActivationDays,
    paidSocial,
    paidSocialBca,
    sparkAdsActivationDays,
    productDelivery,
    priceLimitMin,
    priceLimitMax,
    includeReferralFee,
    referralFeeRate,
    taskDescription,
  } = brief;

  const platform = campaignPlatform?.toLowerCase();
  const platformText = platform
    ? intl.formatMessage({ id: `brief.campaign.platform.${platform}` })
    : '';

  const createInfoItem = (data: InfoData) => {
    const { iconName, newIconName, titleData, subtitleData, key, handleReadMoreClick } = data;

    return (
      <div key={key} className={`${styles.infoItem} ${styles.item}`}>
        {newIconName && <NewIcon name={newIconName} />}
        {iconName && <Icon name={iconName} color="black" />}
        <div className={styles.infoContent}>
          <Text type="h2" text={titleData} className={styles.infoTitle} />
          <div>
            <Text text={subtitleData} />
            {handleReadMoreClick && !mobileView && (
              <TextButton
                msg={'general.read_more'}
                onClick={handleReadMoreClick}
                display="inline"
                className={styles.readMore}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const brandAvaData = brandLogo ? { src: brandLogo } : { letters: brandName };

  const createDeliveryInfo = () => {
    if (!productDelivery) {
      return null;
    }

    let deiveryData;

    if (productDelivery === 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS') {
      deiveryData = {
        iconName: 'deliveryCar',
        titleData: intl.formatMessage({ id: 'brief.campaign.delivery.shipment.title' }),
        subtitleData: intl.formatMessage({ id: 'brief.campaign.delivery.shipment.descr' }),
      };
    } else if (productDelivery === 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT') {
      deiveryData = {
        iconName: 'shoppingBag',
        titleData: intl.formatMessage({ id: 'brief.campaign.delivery.shopping.title' }),
        subtitleData: intl.formatMessage({ id: 'brief.campaign.delivery.shopping.descr' }),
      };
    } else if (productDelivery === 'COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT') {
      deiveryData = {
        iconName: 'noDelivery',
        titleData: intl.formatMessage({ id: 'brief.campaign.delivery.no_product.title' }),
        subtitleData: intl.formatMessage({ id: 'brief.campaign.delivery.no_product.descr' }),
      };
    }

    if (!deiveryData) {
      return null;
    }

    return createInfoItem(deiveryData);
  };

  const handlePsInfoClick = () => {
    const name = isCreator ? modalName.CONNECT_PS : modalName.PS_INFO;
    const data = isCreator
      ? { onlyView: true, psDays: paidSocialActivationDays, psBca: paidSocialBca }
      : {};
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name, attach: data },
    });
  };

  const handleBcaInfoClick = () => {
    const link = isCreator ? BCA_INFO_FOR_CREATORS : BCA_INFO;
    window.open(link, '_blank');
  };

  const handleBarterInfoClick = () => {
    tracking.trackEvent({
      element: 'barter_read_more',
      event: 'clicked',
    });
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: { name: modalName.MESSAGE, attach: { ...barterInfoData } },
    });
  };

  const handleBrandUrlClick = () => {
    if (isCreator) {
      amplitude.sendEvent({ id: 91, category: 'campaign', name: 'creator_brand_website' });
    }
  };

  const handleProductUrlClick = () => {
    if (isCreator) {
      amplitude.sendEvent({ id: 90, category: 'campaign', name: 'creator_product_website' });
    }
  };

  const isLongTermCampaign = campaignType === LONG_TERM;
  const rootStyles = mobileView ? styles.mobileView : '';

  const coverUrl = isLongTermCampaign ? brandLogo : cover?.thumbnailUrl;

  const platformTitle =
    campaignType === PRODUCT_SEEDING
      ? intl.formatMessage({ id: 'brief.product_seeding.title' }, { platform: platformText })
      : campaignType === INFLUENCER
      ? intl.formatMessage({ id: `brief.campaign.platform.${platform}.campaign` })
      : intl.formatMessage({ id: 'brief.content_creation.title' }, { platform: platformText });

  const platformDescr =
    campaignType === PRODUCT_SEEDING
      ? intl.formatMessage({ id: 'brief.product_seeding.descr' })
      : campaignType === INFLUENCER
      ? intl.formatMessage(
          { id: 'brief.campaign.platform.descr' },
          {
            account: intl.formatMessage({ id: `brief.campaign.platform.${platform}` }),
          }
        )
      : intl.formatMessage({ id: 'brief.content_creation.descr' }, { platform: platformText });

  return (
    <div className={rootStyles}>
      {!shortenView && (
        <div
          style={{ backgroundImage: `url(${coverUrl})` }}
          className={cn(styles.cover, { [styles.compactCover]: compactView })}
        />
      )}

      <div className={styles.content}>
        {!shortenView && <Text type="d1" text={campaignName || ''} className={styles.title} />}
        <div className={compactView ? styles.infoContainer : ''}>
          {platform &&
            !shortenView &&
            createInfoItem({
              iconName: getIcon(platform),
              titleData: platformTitle,
              subtitleData: platformDescr,
            })}
          {!shortenView &&
            priceLimitMin !== null &&
            Number(priceLimitMin) > 0 &&
            priceLimitMax !== null &&
            Number(priceLimitMax) > 0 &&
            createInfoItem({
              iconName: 'moneyMax',
              titleData: intl.formatMessage(
                { id: 'brief.money_price_range' },
                {
                  min: createSum(priceLimitMin, currency),
                  max: createSum(priceLimitMax, currency),
                }
              ),
              subtitleData: intl.formatMessage({ id: 'brief.money_price_range.descr' }),
            })}
          {!shortenView &&
            !priceLimitMin &&
            priceLimitMax &&
            createInfoItem({
              iconName: 'moneyMax',
              titleData: intl.formatMessage(
                { id: 'brief.money_price_range.only_mav' },
                { max: createSum(priceLimitMax, currency) }
              ),
              subtitleData: intl.formatMessage({ id: 'brief.money_price_range.descr' }),
            })}
          {paidSocial &&
            !paidSocialBca &&
            paidSocialActivationDays &&
            createInfoItem({
              iconName: 'targetAd',
              titleData: intl.formatMessage(
                { id: 'brief.ps_days_descr' },
                { days: paidSocialActivationDays }
              ),
              subtitleData: intl.formatMessage(
                { id: 'brief.paid_social_descr' },
                { days: paidSocialActivationDays }
              ),
              handleReadMoreClick: handlePsInfoClick,
            })}
          {paidSocialBca &&
            createInfoItem({
              iconName: 'targetAd',
              titleData: intl.formatMessage({ id: 'brief.bca_title' }),
              subtitleData: intl.formatMessage({ id: 'brief.bca_descr' }),
              handleReadMoreClick: handleBcaInfoClick,
            })}
          {sparkAdsActivationDays &&
            createInfoItem({
              iconName: 'targetAd',
              titleData: intl.formatMessage(
                { id: 'brief.spark_ads_days.title' },
                { days: sparkAdsActivationDays }
              ),
              subtitleData: intl.formatMessage(
                { id: 'brief.spark_ads_days.descr' },
                { days: sparkAdsActivationDays }
              ),
            })}
          {createDeliveryInfo()}
          {!isBarter &&
            includeReferralFee &&
            createInfoItem({
              titleData: intl.formatMessage(
                { id: 'brief.campaign.referral.title' },
                { rate: referralFeeRate }
              ),
              subtitleData: intl.formatMessage(
                { id: 'brief.campaign.referral.description' },
                { rate: referralFeeRate }
              ),
              iconName: 'salesCommissionPercent',
            })}
          {productPrice &&
            createInfoItem({
              newIconName: 'Money',
              titleData: intl.formatMessage(
                { id: 'brief.campaign.product_price.title' },
                { price: createSum(productPrice) }
              ),
              subtitleData: intl.formatMessage({ id: 'brief.campaign.product_price.descr' }),
            })}
        </div>
        {productDescription && (
          <div className={styles.section}>
            <Text>
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: createTextWithLinks(escapeText(productDescription)),
                }}
              />
            </Text>

            {productUrl && !mobileView && (
              <SimpleLink
                href={getOuterLink(productUrl)}
                target="_blank"
                display="block"
                className={styles.websiteBtn}
                onClick={handleProductUrlClick}
              >
                <Button
                  width="full"
                  size="lg"
                  color="secondary"
                  contentAlign="center"
                  msg="brief.open_product_website"
                  rightIcon={<Icon name="openLink" />}
                />
              </SimpleLink>
            )}
          </div>
        )}
        {description && (
          <div className={styles.section}>
            <Text text={description} />
          </div>
        )}
        {isSeedingCampaign && taskDescription && (
          <div className={styles.section}>
            <Text type="h1" weight="700" msg="brief.deliverables" />
            <Text text={taskDescription || ''} />
          </div>
        )}
        <div className={styles.section}>
          <div className={styles.brandHeader}>
            <div>
              <Text type="h1" text={brandName || ''} />
              <Text msg="brief.about_brand" />
            </div>
            <Avatar size="52" {...brandAvaData} />
          </div>
          {brandSummary && <Text text={brandSummary} />}
          {brandWebsiteUrl && !mobileView && (
            <SimpleLink
              href={getOuterLink(brandWebsiteUrl)}
              target="_blank"
              display="block"
              className={styles.websiteBtn}
              onClick={handleBrandUrlClick}
            >
              <Button
                width="full"
                size="lg"
                color="secondary"
                contentAlign="center"
                msg="brief.open_brand_website"
                rightIcon={<Icon name="openLink" />}
              />
            </SimpleLink>
          )}
        </div>
      </div>
    </div>
  );
};

const CampaignDataWrap = track({ subsection: 'campaign_data' })(CampaignData);

export default CampaignDataWrap;
