import React from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchQuery } from 'relay-runtime';
import { graphql, useFragment } from 'react-relay';

import styles from './CampaignPreviewCounters.pcss';

import { getProjectsLink } from 'Util/links';
import environment from 'Api/Environment';
import { CampaignStage } from 'GraphTypes/Campaign_campaign.graphql';
import { CampaignPreviewCounters_campaign$key } from 'GraphTypes/CampaignPreviewCounters_campaign.graphql';
import {
  CampaignPreviewCountersQuery as QueryType,
  AdvertiserProjectStage,
} from 'GraphTypes/CampaignPreviewCountersQuery.graphql';
import { amplitude } from 'Helpers/amplitude';
import TextButton from 'Components/ui/TextButton/TextButton';

const CampaignPreviewCountersQuery = graphql`
  query CampaignPreviewCountersQuery(
    $campaignId: ID!
    $withNewApplicants: Boolean
    $withUnreadEventsExcludingOfferAcceptance: Boolean
    $stages: [AdvertiserProjectStage!]
  ) {
    campaign(id: $campaignId) {
      requestedProjects: projects(
        first: 1
        withNewApplicants: $withNewApplicants
        withUnreadEventsExcludingOfferAcceptance: $withUnreadEventsExcludingOfferAcceptance
        orderBy: ACTIVITY
        stages: $stages
      ) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

type FetchData = {
  withNewApplicants?: boolean;
  withUnreadEventsExcludingOfferAcceptance?: boolean;
  stages?: AdvertiserProjectStage[];
  unread?: boolean;
};

type LinkData = {
  campaignId?: string;
  stages?: string;
  withUnreadEvents?: boolean;
};

interface Props {
  isAdmin?: boolean;
  organizationId?: string;
  campaignStage?: CampaignStage | null;
  campaign: CampaignPreviewCounters_campaign$key;
  withNewApplicants?: boolean;
  withUnreadEventsExcludingOfferAcceptance?: boolean;
  unread?: boolean;
}

const CampaignPreviewCounters: React.FC<Props> = (props) => {
  const {
    campaign,
    organizationId,
    isAdmin,
    withNewApplicants = true,
    withUnreadEventsExcludingOfferAcceptance = true,
    unread = true,
    campaignStage,
  } = props;
  const data = useFragment(
    graphql`
      fragment CampaignPreviewCounters_campaign on Campaign {
        id
        completion {
          id
        }
        counters {
          launchedProjects
          completedProjects
          newApplicants
          projectsWithUnreadEventsExcludingOfferAcceptances
          unreadProjectEventsExcludingOfferAcceptance
          projectsWithSubmittedContent
        }
      }
    `,
    campaign
  );

  const counters = data.counters;
  const campaignId = data.id;
  const campaignCompleted = !!data.completion?.id;

  const isEasyMessagingEnabled = !isAdmin;

  const navigate = useNavigate();

  if (!counters) return null;

  const {
    newApplicants,
    projectsWithUnreadEventsExcludingOfferAcceptances,
    projectsWithSubmittedContent,
  } = counters;

  const handleCounterClick = (data: FetchData, dataLink: LinkData) => {
    fetchQuery<QueryType>(environment, CampaignPreviewCountersQuery, {
      campaignId,
      ...data,
    }).subscribe({
      next: (result) => {
        const edges = result.campaign?.requestedProjects.edges;
        const projectId = edges ? edges[0]?.node?.id : undefined;
        navigate(getProjectsLink({ projectId, organizationId, ...dataLink }));
      },
    });
  };

  const handleNewAppClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!newApplicants) return;
    if (campaignCompleted || !isEasyMessagingEnabled) {
      handleCounterClick(
        { withNewApplicants: true, stages: ['ACCEPTED'] },
        { campaignId, stages: 'ACCEPTED' }
      );
    } else {
      const link = getProjectsLink({ campaignId, organizationId, stages: 'ACCEPTED' });
      navigate(link);
    }
    amplitude.sendEvent({
      id: 117,
      name: 'new_creators_in_campaign_click',
      category: 'dashboard',
      param: { campaign_id: campaignId },
    });
  };

  const handleNewMsgClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!projectsWithUnreadEventsExcludingOfferAcceptances) return;
    if (campaignCompleted || !isEasyMessagingEnabled) {
      handleCounterClick(
        {
          unread: true,
          stages: [
            'ACCEPTED',
            'COMPLETED',
            'CONTENT_SUBMITTED',
            'LAUNCHED',
            'PRODUCT_SHIPMENT_COMPLETED',
            'PRODUCT_SHIPMENT_STARTED',
            'PUBLISHING_STARTED',
          ],
        },
        {
          withUnreadEvents: true,
          campaignId,
        }
      );
    } else {
      const link = getProjectsLink({ withUnreadEvents: true, campaignId, organizationId });
      navigate(link);
    }
    amplitude.sendEvent<118>({
      id: '118',
      name: 'new_messages_in_campaign_click',
      category: 'dashboard',
      param: { campaign_id: campaignId, status: campaignStage },
    });
  };

  const handleContentOnReviewClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!projectsWithSubmittedContent) return;
    if (campaignCompleted || !isEasyMessagingEnabled) {
      handleCounterClick(
        { stages: ['CONTENT_SUBMITTED'] },
        {
          stages: 'CONTENT_SUBMITTED',
          campaignId,
        }
      );
    } else {
      const link = getProjectsLink({ stages: 'CONTENT_SUBMITTED', campaignId, organizationId });
      navigate(link);
    }
    amplitude.sendEvent({
      id: 119,
      name: 'new_content_in_campaign_click',
      category: 'dashboard',
      param: { campaign_id: campaignId },
    });
  };

  return (
    <div className={styles.root}>
      {withNewApplicants && (
        <div className={styles.applicants}>
          <TextButton
            color={newApplicants > 0 ? 'pink' : 'grey'}
            text={newApplicants.toString()}
            className={styles.button}
            onClick={handleNewAppClick}
          />
        </div>
      )}
      {withUnreadEventsExcludingOfferAcceptance && (
        <div className={styles.messages}>
          <TextButton
            color={projectsWithUnreadEventsExcludingOfferAcceptances > 0 ? 'pink' : 'grey'}
            text={projectsWithUnreadEventsExcludingOfferAcceptances.toString()}
            className={styles.button}
            onClick={handleNewMsgClick}
          />
        </div>
      )}
      {unread && (
        <div className={styles.review}>
          <TextButton
            color={projectsWithSubmittedContent > 0 ? 'pink' : 'grey'}
            text={projectsWithSubmittedContent.toString()}
            onClick={handleContentOnReviewClick}
            className={styles.button}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignPreviewCounters;
