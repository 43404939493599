/**
 * @generated SignedSource<<8326b44869ccbf8b349764e7113aa587>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Locale = "EN" | "RU" | "%future added value";
export type UpdateOutreachTemplateInput = {
  clientMutationId?: string | null | undefined;
  htmlBody?: string | null | undefined;
  id: string;
  locale?: Locale | null | undefined;
  subject?: string | null | undefined;
};
export type UpdateOutreachTemplateMutation$variables = {
  input: UpdateOutreachTemplateInput;
};
export type UpdateOutreachTemplateMutation$data = {
  readonly updateOutreachTemplate: {
    readonly __typename: "UpdateOutreachTemplatePayload";
    readonly outreachTemplate: {
      readonly htmlBody: string;
      readonly id: string;
      readonly subject: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type UpdateOutreachTemplateMutation = {
  response: UpdateOutreachTemplateMutation$data;
  variables: UpdateOutreachTemplateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateOutreachTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachTemplate",
            "kind": "LinkedField",
            "name": "outreachTemplate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "htmlBody",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subject",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "UpdateOutreachTemplatePayload",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOutreachTemplateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateOutreachTemplateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7cbb27726086901a7822b1222138671f",
    "id": null,
    "metadata": {},
    "name": "UpdateOutreachTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOutreachTemplateMutation(\n  $input: UpdateOutreachTemplateInput!\n) {\n  updateOutreachTemplate(input: $input) {\n    __typename\n    ... on UpdateOutreachTemplatePayload {\n      outreachTemplate {\n        id\n        htmlBody\n        subject\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "25369091b2a31e21c8ed4478f052cd33";

export default node;
