import { ParseResult } from 'papaparse';
import { createContext } from 'react';

import { CampaignPlatform } from 'GraphTypes/CampaignInfo_campaign.graphql';
import { OutreachTransport } from 'GraphTypes/CreateOutreachBatchMutation.graphql';
import { OutreachStepBlueprint_ProjectEventType } from 'GraphTypes/CreateOutreachSequenceBlueprintMutation.graphql';
import { Locale } from 'GraphTypes/CreateOutreachTemplateMutation.graphql';
import { OutreachStepBlueprintInput } from 'GraphTypes/UpdateOutreachSequenceBlueprintMutation.graphql';

type InviteOutreachCreatorsContextType = {
  source: InviteOutreachCreatorsSource | null;
  fileName: string;
  fileSize: number;
  fileError: string;
  locale: string;
  selectedLists: InviteOutreachCreatorsSelectedLists;
  rows: InviteOutreachImportCsvDataRow[];
  templateData: InviteOutreachTemplateData;
  transport: OutreachTransport;
  senderName: string;
  senderEmail: string;
  batchId: string;
  error: InviteOutreachCreatorsError | null;
  templatePreview: InviteOutreachTemplateItemData | null;
  isTemplateChanged: boolean;
  platform: CampaignPlatform;
  handleTemplateClose: () => void;
  handleFileReset: () => void;
  handleListChange: (files: FileList | null) => void;
  handleParseComplete: (result: ParseResult<string>) => void;
  handleImportOutreachBatch: (campaignId: string, sequenceId?: string) => void;
  isFormValid: boolean;
  createOutreachBatchLoading: boolean;
  processing: boolean;
  isTemplateCreating: boolean;
  rowMetrics?: InviteOutreachImportCsvDataRowsMetrics;
  rowErrors?: InviteOutreachImportCsvDataErrorRow[];
  setTemplateData: (data: InviteOutreachTemplateData) => void;
  setTemplatePreview: (data: InviteOutreachTemplateItemData | null) => void;
  setTransportProvider: (provider: OutreachTransport) => void;
  setSenderName: (name: string) => void;
  setSenderActiveAuth: (authId: string) => void;
  setSenderEmail: (email: string) => void;
  setError: (error: InviteOutreachCreatorsError | null) => void;
  setCreatingTemplateStatus: (status: boolean) => void;
  setTemplateChangedStatus: (status: boolean) => void;
  setSource: (source: InviteOutreachCreatorsSource) => void;
  setPlatform: (platform: CampaignPlatform) => void;
  setSelectedLists: (lists: InviteOutreachCreatorsSelectedLists) => void;
  sequenceId: string;
  setSequenceId: (id: string) => void;
  sequenceList: SequenceStep[];
  setSequenceList: (list: SequenceStep[]) => void;
  activeStep: number;
  setActiveStep: (index: number) => void;
  stepsWithError: number[];
  setStepsWithErrorList: (list: number[]) => void;
  timezone: number;
  setTimezone: (offset: number) => void;
  saveEmailTemplates: (
    sequenceSteps: SequenceStep[],
    callback: (steps: OutreachStepBlueprintInput[]) => void
  ) => void;
};

export type InviteOutreachTemplateData = {
  id: string;
  name: string;
} & Partial<InviteOutreachTemplateItemData>;

export type InviteOutreachTemplateItemData = {
  subject: string;
  body: string;
  name: string;
};

export type InviteOutreachImportCsvDataRow = {
  username: string;
  email: string;
};

export type InviteOutreachImportCsvDataErrorRow = {
  index: number;
  username?: string;
  email?: string;
};

export type InviteOutreachImportCsvDataRowsMetrics = {
  totalCount: number;
  parsedCount: number;
};

export type InviteOutreachCreatorsTemplate =
  | {
      htmlBody: string;
      id: string;
      locale: Locale;
      subject: string;
      htmlText?: string;
    }
  | undefined
  | null;

export enum InviteOutreachCreatorsError {
  NONE,
  GENERAL_ERROR,
  INVALID_GMAIL_SCOPE,
  FAILED_TO_CREATE_LIST,
}

export enum InviteOutreachCreatorsSource {
  DISCOVERY = 'Discovery',
  LISTS = 'Lists',
  CSV_FILE = 'CSV',
  MANUALLY = 'Manually',
}

export type InviteOutreachCreatorsSelectedLists = string[];

const InviteOutreachCreatorsContext = createContext<InviteOutreachCreatorsContextType>(
  {} as InviteOutreachCreatorsContextType
);

export interface SequenceStep {
  id?: string;
  delayInDays?: number | null;
  projectEventType: OutreachStepBlueprint_ProjectEventType;
  startsAtHour: Date;
  validTemplate: boolean;
  template: InviteOutreachCreatorsTemplate;
  templateId?: string;
}

export { InviteOutreachCreatorsContext };
export type { InviteOutreachCreatorsContextType };
