import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import { BriefContext } from '../../../Brief.Context';
import Field from '../components/Field/Field';
import Section from '../components/Section/Section';
import With from '../components/With/With';

import styles from './Deliverables.pcss';

import Text from 'Components/ui/Text/Text';
import { Deliverables_campaign$key } from 'GraphTypes/Deliverables_campaign.graphql';
import updateBrief from 'Api/mutations/UpdateBrief.Mutation';

interface Props {
  campaign: Deliverables_campaign$key;
}

const Deliverables: React.FC<Props> = (props) => {
  const { campaign } = props;
  const intl = useIntl();
  const descriptionPreset = intl.formatMessage({
    id: 'brief_template.field.deliverables.description.descr.preset',
  });

  const data = useFragment(
    graphql`
      fragment Deliverables_campaign on Campaign {
        brief {
          id
          ... on V2Brief {
            taskDescription
          }
        }
      }
    `,
    campaign
  );

  const taskDescription = data.brief?.taskDescription || '';
  const briefId = data.brief?.id || '';

  const [briefState, briefDispatch] = useContext(BriefContext);

  useEffect(() => {
    if (taskDescription?.length > 0 && taskDescription !== descriptionPreset) {
      briefDispatch({
        key: 'creativesInfoFilled',
        value: true,
      });
    }
  }, []);

  const handleBriefChange = (data: { taskDescription: string }) => {
    const { taskDescription } = data;
    if (taskDescription?.length > 0 && taskDescription !== descriptionPreset) {
      briefDispatch({ key: 'briefIsSaving', value: true });
      briefDispatch({
        key: 'creativesInfoFilled',
        value: true,
      });
      updateBrief({ id: briefId, ...data });
    } else if (!taskDescription?.length) {
      briefDispatch({
        key: 'creativesInfoFilled',
        value: false,
      });
    }
  };

  if (!briefId || !briefState.creatorsInfoFilled || !briefState.productInfoFilled) return null;

  return (
    <Section
      titleMsg="brief_template.section.deliverables"
      className={styles.section}
      titleClassName={styles.title}
    >
      <With condition={true} name="creativesInfo">
        <div>
          <div className={styles.root}>
            <Text
              type="md"
              msg="brief_template.section.creative_assets.deliverables.title"
              className={styles.title}
            />
            <Text
              type="label"
              msg="brief_template.section.creative_assets.deliverables.descr"
              className={styles.descr}
            />
            <Field
              element="input"
              name="taskDescription"
              elementData={{
                multiple: true,
                maxlength: 500,
                defaultValue: taskDescription || descriptionPreset,
                onChange: handleBriefChange,
                textAreaData: { minRows: 1, maxRows: 5 },
                dataTest: 'input:deliverablesInstruction',
              }}
            />
          </div>
        </div>
      </With>
    </Section>
  );
};

export default Deliverables;
