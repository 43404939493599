import React, { useEffect } from 'react';
import { fetchQuery, graphql } from 'react-relay';

import environment from 'Api/Environment';
import { ExportFileStatusQuery } from 'GraphTypes/ExportFileStatusQuery.graphql';

const INTERVAL = 5000;

const query = graphql`
  query ExportFileStatusQuery($id: ID!) {
    contentExport(id: $id) {
      completed
      failed
      type
    }
  }
`;

interface Props {
  id: string;
  onGetResult: (value: boolean) => void;
}

const ExportFileStatus: React.FC<Props> = (props) => {
  const { id, onGetResult } = props;
  let interval: NodeJS.Timeout;

  useEffect(() => {
    interval = setInterval(() => handleFetchStatus(), INTERVAL);

    handleFetchStatus();

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleFetchStatus = () => {
    fetchQuery<ExportFileStatusQuery>(environment, query, { id }).subscribe({
      next: (result) => {
        const completed = !!result?.contentExport?.completed;
        const failed = !!result?.contentExport?.failed;

        if (completed) {
          onGetResult(true);
          clearInterval(interval);
        } else if (failed) {
          onGetResult(false);
          clearInterval(interval);
        }
      },
    });
  };

  return null;
};

export default ExportFileStatus;
