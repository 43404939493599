export type Timezone = {
  offset: string;
  numericOffset: number;
  name: string;
  city: string;
};

export type Timezones = {
  [continent: string]: Timezone[];
};

export const timezoneList = {
  continents: {
    NorthAmerica: 'North America',
    SouthAmerica: 'South America',
    Europe: 'Europe',
    Africa: 'Africa',
    Asia: 'Asia',
    Oceania: 'Oceania',
    Antarctica: 'Antarctica',
  },
  zones: {
    NorthAmerica: [
      { offset: 'UTC-08:00', numericOffset: -8, name: 'Pacific Time', city: 'Los Angeles' },
      { offset: 'UTC-07:00', numericOffset: -7, name: 'Mountain Time', city: 'Denver' },
      { offset: 'UTC-06:00', numericOffset: -6, name: 'Central Time', city: 'Chicago' },
      { offset: 'UTC-05:00', numericOffset: -5, name: 'Eastern Time', city: 'New York' },
      { offset: 'UTC-09:00', numericOffset: -9, name: 'Alaska Time', city: 'Anchorage' },
      { offset: 'UTC-10:00', numericOffset: -10, name: 'Hawaii-Aleutian Time', city: 'Honolulu' },
      { offset: 'UTC-11:00', numericOffset: -11, name: 'Samoa Time', city: 'Pago Pago' },
      { offset: 'UTC-12:00', numericOffset: -12, name: 'Baker Island Time', city: 'Baker Island' },
    ],
    SouthAmerica: [
      { offset: 'UTC-04:00', numericOffset: -4, name: 'Atlantic Time', city: 'Caracas' },
      { offset: 'UTC-03:00', numericOffset: -3, name: 'Brasilia Time', city: 'São Paulo' },
      {
        offset: 'UTC-02:00',
        numericOffset: -2,
        name: 'South Georgia Time',
        city: 'King Edward Point',
      },
      { offset: 'UTC-01:00', numericOffset: -1, name: 'Azores Time', city: 'Ponta Delgada' },
    ],
    Europe: [
      { offset: 'UTC+00:00', numericOffset: 0, name: 'Greenwich Mean Time', city: 'London' },
      { offset: 'UTC+01:00', numericOffset: 1, name: 'Central European Time', city: 'Berlin' },
      { offset: 'UTC+02:00', numericOffset: 2, name: 'Eastern European Time', city: 'Athens' },
      { offset: 'UTC+03:00', numericOffset: 3, name: 'Moscow Time', city: 'Moscow' },
    ],
    Africa: [
      { offset: 'UTC+00:00', numericOffset: 0, name: 'West Africa Time', city: 'Casablanca' },
      { offset: 'UTC+02:00', numericOffset: 2, name: 'Central Africa Time', city: 'Johannesburg' },
      { offset: 'UTC+03:00', numericOffset: 3, name: 'East Africa Time', city: 'Nairobi' },
    ],
    Asia: [
      { offset: 'UTC+04:00', numericOffset: 4, name: 'Gulf Standard Time', city: 'Dubai' },
      { offset: 'UTC+05:00', numericOffset: 5, name: 'Pakistan Standard Time', city: 'Karachi' },
      { offset: 'UTC+05:30', numericOffset: 5.5, name: 'Indian Standard Time', city: 'New Delhi' },
      { offset: 'UTC+06:00', numericOffset: 6, name: 'Bangladesh Time', city: 'Dhaka' },
      { offset: 'UTC+07:00', numericOffset: 7, name: 'Indochina Time', city: 'Bangkok' },
      { offset: 'UTC+08:00', numericOffset: 8, name: 'China Standard Time', city: 'Beijing' },
      { offset: 'UTC+09:00', numericOffset: 9, name: 'Japan Standard Time', city: 'Tokyo' },
    ],
    Oceania: [
      { offset: 'UTC+10:00', numericOffset: 10, name: 'Australian Eastern Time', city: 'Sydney' },
      { offset: 'UTC+11:00', numericOffset: 11, name: 'Solomon Islands Time', city: 'Honiara' },
      {
        offset: 'UTC+12:00',
        numericOffset: 12,
        name: 'New Zealand Standard Time',
        city: 'Auckland',
      },
      { offset: 'UTC+13:00', numericOffset: 13, name: 'Tonga Time', city: "Nuku'alofa" },
      { offset: 'UTC+14:00', numericOffset: 14, name: 'Line Islands Time', city: 'Kiritimati' },
    ],
    Antarctica: [
      { offset: 'UTC+12:00', numericOffset: 12, name: 'Antarctic Time', city: 'McMurdo Station' },
    ],
  },
};

export const findTimezoneByOffset = (offset: number): Timezone | null => {
  const continents = Object.keys(timezoneList.continents);
  let result = null;
  continents.forEach((continent: keyof typeof timezoneList.zones) => {
    const matchedTimezone = timezoneList.zones[continent].find(
      (timezone) => timezone.numericOffset === offset
    );
    if (matchedTimezone) {
      result = matchedTimezone;
    }
  });
  return result;
};
