/**
 * @generated SignedSource<<a7e62d973a9d76049db034502f1bc046>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type SubscriptionInterval = "BIANNUAL" | "CUSTOM" | "MONTHLY" | "TRIMONTHLY" | "YEARLY" | "%future added value";
export type BrandsLimitQuery$variables = Record<PropertyKey, never>;
export type BrandsLimitQuery$data = {
  readonly brandCategories: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null | undefined;
  readonly brandCount: {
    readonly totalCount: number;
  } | null | undefined;
  readonly currentUser: {
    readonly admin: boolean;
    readonly organization: {
      readonly counters: {
        readonly activeBrands: number;
      } | null | undefined;
      readonly subscription: {
        readonly addonItems: ReadonlyArray<{
          readonly addon: {
            readonly id: AddonId;
            readonly resourcesPerUnit: number;
          };
          readonly quantity: number;
        }>;
        readonly availableAddonIds: ReadonlyArray<AddonId>;
        readonly baseLimits: {
          readonly maxActiveBrands: number | null | undefined;
        };
        readonly effectiveLimits: {
          readonly maxActiveBrands: number | null | undefined;
        };
        readonly interval: SubscriptionInterval | null | undefined;
        readonly paused: boolean;
        readonly planId: PlanId;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type BrandsLimitQuery = {
  response: BrandsLimitQuery$data;
  variables: BrandsLimitQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "brandCount",
  "args": null,
  "concreteType": "BrandConnection",
  "kind": "LinkedField",
  "name": "brands",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationCounters",
  "kind": "LinkedField",
  "name": "counters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeBrands",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availableAddonIds",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AddonItem",
  "kind": "LinkedField",
  "name": "addonItems",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Addon",
      "kind": "LinkedField",
      "name": "addon",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "resourcesPerUnit",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paused",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxActiveBrands",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionLimits",
  "kind": "LinkedField",
  "name": "effectiveLimits",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionLimits",
  "kind": "LinkedField",
  "name": "baseLimits",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandCategory",
  "kind": "LinkedField",
  "name": "brandCategories",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrandsLimitQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v12/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BrandsLimitQuery",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v12/*: any*/)
    ]
  },
  "params": {
    "cacheID": "61164b09fdd847f092ecea83fc45ca52",
    "id": null,
    "metadata": {},
    "name": "BrandsLimitQuery",
    "operationKind": "query",
    "text": "query BrandsLimitQuery {\n  brandCount: brands {\n    totalCount\n  }\n  currentUser {\n    admin\n    organization {\n      counters {\n        activeBrands\n      }\n      subscription {\n        planId\n        availableAddonIds\n        addonItems {\n          addon {\n            id\n            resourcesPerUnit\n          }\n          quantity\n        }\n        paused\n        interval\n        effectiveLimits {\n          maxActiveBrands\n        }\n        baseLimits {\n          maxActiveBrands\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n  brandCategories {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "88a04d54bdc25e6cefe23485e639da0f";

export default node;
