/**
 * @generated SignedSource<<c6624cd18671ff5a3f6f82c1bbf6cfb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchResultControls_result$data = {
  readonly creator: {
    readonly customLists: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly username: string;
  } | null | undefined;
  readonly favorite: boolean;
  readonly project: {
    readonly archivation: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly invitedByOrganizationMember: boolean;
    readonly launch: {
      readonly id: string;
    } | null | undefined;
    readonly offer: {
      readonly acceptance: {
        readonly id: string;
      } | null | undefined;
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly viewed: boolean;
  readonly " $fragmentType": "SearchResultControls_result";
};
export type SearchResultControls_result$key = {
  readonly " $data"?: SearchResultControls_result$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchResultControls_result">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchResultControls_result",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "side",
              "value": "ADVERTISER"
            }
          ],
          "concreteType": "ProjectArchivation",
          "kind": "LinkedField",
          "name": "archivation",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "archivation(side:\"ADVERTISER\")"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Offer",
          "kind": "LinkedField",
          "name": "offer",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "OfferAcceptance",
              "kind": "LinkedField",
              "name": "acceptance",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectLaunch",
          "kind": "LinkedField",
          "name": "launch",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitedByOrganizationMember",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomListConnection",
          "kind": "LinkedField",
          "name": "customLists",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomListEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CustomList",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CreatorSearchResult",
  "abstractKey": null
};
})();

(node as any).hash = "6ad358d06f735c0a764cbfffb2011135";

export default node;
