import React, { FC, useMemo, useState, ChangeEvent } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import styles from './RadioDropdown.pcss';

import useOutsideClick from 'Hooks/useClickOutside';
import Input from 'Components/ui/Input/Input';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { IconName } from 'Components/ui/types';

export type RadioDropdownItemType<T extends string = string> = {
  value?: string;
  valueMsg?: string;
  isChecked: boolean;
  id: T;
  imageSrc?: string;
  icon?: IconName;
};

type RadioDropdownItemProps = {
  item: RadioDropdownItemType;
  onClick: (item: RadioDropdownItemType) => void;
  checkedIcon?: IconName;
};

const RadioDropdownItem: FC<RadioDropdownItemProps> = ({ item, onClick, checkedIcon }) => {
  const { value, valueMsg, icon, imageSrc, isChecked } = item;

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (onClick) {
      onClick(item);
    }
  };
  return (
    <div
      onClick={handleOnClick}
      className={classnames(styles.roundItem, { [styles.checked]: isChecked })}
    >
      <div className={styles.itemContent}>
        {icon && <Icon name={icon} />}
        {imageSrc && <img src={imageSrc} className={styles.img} />}
        <Text text={value} msg={valueMsg} type="md" />
      </div>
      {!checkedIcon && (
        <div className={classnames(styles.round, { [styles.checked]: isChecked })}>
          {isChecked && <div className={styles.radioCheckedItem} />}
        </div>
      )}
      {checkedIcon && isChecked && <Icon name={checkedIcon} />}
    </div>
  );
};

export interface IRadioDropdownProps {
  items: RadioDropdownItemType[];
  placeholderMsg?: string;
  placeholder?: string;
  handleClick: (variable: string) => void;
  handleDirty?: (newValue: boolean) => void;
  handleClear?: () => void;
  isDirty?: boolean;
  withSearch?: boolean;
  className?: string;
  accent?: boolean;
  bordered?: boolean;
  disabled?: boolean;
  checkedIcon?: IconName;
  withArrow?: boolean;
  variantTitleMsg?: string;
  ignoreValue?: string;
  place?: 'left' | 'right';
}

export const RadioDropdown: FC<IRadioDropdownProps> = ({
  items,
  placeholderMsg,
  placeholder,
  handleClick,
  isDirty,
  handleDirty,
  className,
  accent,
  bordered = true,
  ignoreValue = '',
  withSearch = false,
  checkedIcon,
  handleClear,
  withArrow,
  variantTitleMsg,
  disabled,
  place = 'left',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleContainerClick = () => setIsOpen((prevState) => !prevState);

  const [currentSearch, setCurrentSearch] = useState<string>('');
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentSearch(event?.target?.value);
  };

  const dropdownRef = useOutsideClick<HTMLDivElement>(() => {
    setIsOpen(false);
  });

  const intl = useIntl();

  const isTrullyDirty = useMemo(() => {
    const haveSelected = items?.filter((item) => item.isChecked).length > 0;
    if (isDirty === undefined) {
      return haveSelected;
    }
    return isDirty;
  }, [items, isDirty]);

  const currentPlaceholder = useMemo(() => {
    const checkedItem = items?.filter((item) => item.isChecked)[0];
    const valueMsg = checkedItem?.valueMsg
      ? intl.formatMessage({ id: checkedItem.valueMsg })
      : checkedItem?.value;
    const placeholderFromValue = checkedItem?.id !== ignoreValue ? valueMsg : undefined;
    if (placeholderFromValue) {
      return placeholderFromValue;
    }
    return placeholderMsg ? intl.formatMessage({ id: placeholderMsg }) : placeholder;
  }, [items]);

  const handleSelectionChange = (newItem: RadioDropdownItemType) => {
    handleClick(newItem.id);
    if (!isDirty && !!handleDirty) {
      handleDirty(true);
    }
  };

  const handleResetSearch = () => setCurrentSearch('');

  const filteredBySearchItems = useMemo(
    () =>
      currentSearch
        ? items.filter((item) => item?.value?.toUpperCase().includes(currentSearch.toUpperCase()))
        : items,
    [items, currentSearch]
  );

  return (
    <div
      onClick={disabled ? undefined : handleContainerClick}
      ref={dropdownRef}
      className={classnames(styles.root, className, {
        [styles.open]: isOpen,
        [styles.dirty]: isTrullyDirty,
        [styles.accentSelect]: !isDirty && accent,
        [styles.bordered]: bordered,
        [styles.disabled]: disabled,
      })}
    >
      <Text type="md" text={currentPlaceholder} className={styles.title} />
      {withArrow && !disabled && <Icon name={isOpen ? 'Arrow-small-up' : 'Arrow-small-down'} />}

      {isOpen && (
        <div
          className={classnames(styles.container, styles[place || ''])}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {withSearch && (
            <Input
              leftIcon="Search-loop"
              placeholderMsg="search"
              onChange={handleSearchChange}
              value={currentSearch}
              className={styles.searchInput}
              borderless
              onResetValue={handleResetSearch}
            />
          )}
          {variantTitleMsg && (
            <Text
              color={'grey'}
              type={'label'}
              msg={variantTitleMsg}
              className={styles.varitantTitle}
            />
          )}
          <div className={styles.content}>
            {filteredBySearchItems?.map((item) => (
              <RadioDropdownItem
                item={item}
                onClick={handleSelectionChange}
                key={item.id}
                checkedIcon={checkedIcon}
              />
            ))}
          </div>
          {isDirty && handleClear && (
            <AlterButton
              msg={'projects.filter.clear'}
              className={styles.clearBtn}
              handleClick={handleClear}
            />
          )}
          {filteredBySearchItems.length === 0 && (
            <Text type="label" color="grey" msg="searchNotFound" className={styles.emptyLabel} />
          )}
        </div>
      )}
    </div>
  );
};
