import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';

import styles from './CreatorStatsTabs.css';

import Tabs from 'Components/Tabs/Tabs';
import { CreatorStatsTabs_creator$key } from 'GraphTypes/CreatorStatsTabs_creator.graphql';

const CREATOR = 'creator';
const AUDIENCE = 'audience';
const POSTS = 'posts';
const STORIES = 'stories';
const REVIEWS = 'reviews';
const SETTINGS = 'settings';

interface Props {
  admin?: boolean;
  currentTab?: string;
  creator: CreatorStatsTabs_creator$key;
}

const CreatorStatsTabs: React.FC<Props> = (props) => {
  const { admin, creator } = props;

  const data = useFragment(
    graphql`
      fragment CreatorStatsTabs_creator on Creator {
        id
        ... on InstagramCreator {
          userInsightsData {
            media
            stories
          }
        }
        ratingCount: ratingVotes(side: AGENCY) {
          totalCount
        }
      }
    `,
    creator
  );

  const reviewsCount = data.ratingCount.totalCount;
  const posts = data.userInsightsData?.media;
  const stories = data.userInsightsData?.stories;

  const [section, setSection] = useState(CREATOR);

  const tabs = compact([
    {
      id: CREATOR,
      msg: `creator_statistic.tab_${CREATOR}`,
      linkTo: CREATOR,
      linkContainerId: 'statistic',
    },
    {
      id: AUDIENCE,
      msg: `creator_statistic.tab_${AUDIENCE}`,
      linkTo: AUDIENCE,
      linkContainerId: 'statistic',
    },
    posts
      ? {
          id: POSTS,
          msg: `creator_statistic.tab_${POSTS}`,
          linkTo: POSTS,
          linkContainerId: 'statistic',
        }
      : null,
    stories
      ? {
          id: STORIES,
          msg: `creator_statistic.tab_${STORIES}`,
          linkTo: STORIES,
          linkContainerId: 'statistic',
        }
      : null,
    reviewsCount
      ? {
          id: REVIEWS,
          msg: `creator_statistic.tab_${REVIEWS}`,
          linkTo: REVIEWS,
          linkContainerId: 'statistic',
        }
      : null,
    admin
      ? {
          id: SETTINGS,
          msg: `creator_statistic.tab_${SETTINGS}`,
          linkTo: SETTINGS,
          linkContainerId: 'statistic',
        }
      : null,
  ]);

  const handleTabSelected = (currentTab: string) => {
    setSection(currentTab);
  };

  return (
    <div className={styles.root}>
      <Tabs items={tabs} currentId={section} onSelected={handleTabSelected} />
    </div>
  );
};

export default CreatorStatsTabs;
