import React, { PropsWithChildren, useState, useContext } from 'react';
import track, { useTracking } from 'react-tracking';

import { encodeQueryData } from 'Util/encodeQueryData';
import ExportFileStatus from 'Organisms/ExportFileStatus/ExportFileStatus';
import { TIKTOK_OAUTH, TIKTOK_OAUTH_API } from 'Constants/general';
import { modalName } from 'Types/modals';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

interface Props {
  className?: string;
  type: string;
  disabled: boolean;
  mediaUrls: string[];
  callLoading?: () => void;
  callSuccess?: () => void;
  callFail?: () => void;
  independent?: boolean;
}

const Tiktok: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    className,
    children,
    mediaUrls,
    type,
    disabled,
    callFail,
    callLoading,
    callSuccess,
    independent,
  } = props;

  const [exportId, setExportId] = useState('');

  const tracking = useTracking();
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const loginTiktok = () => {
    tracking.trackEvent({ event: 'click' });

    const path = location.origin;
    const params = {
      redirect_uri: `${path}${TIKTOK_OAUTH}`,
      app_id: process.env.TIKTOK_APP_ID,
    };
    const url = `${TIKTOK_OAUTH_API}${encodeQueryData(params)}`;
    (window as any).callSuccess = () => {
      modalDispatch({
        type: ModalTypes.SET_MODAL,
        payload: {
          name: modalName.TIKTOK_ADVERTISERS,
          attach: {
            mediaUrls,
            type,
            independent,
            callback: handleExportStarted,
          },
        },
      });
    };
    (window as any).callFail = () => {
      if (callFail) {
        callFail();
      }
    };
    window.open(url, 'auth', 'width=1024,height=900');
  };

  const handleExportStarted = (id: string) => {
    if (callLoading) {
      callLoading();
    }
    setExportId(id);
  };

  const handleExportResult = (value: boolean) => {
    setExportId('');
    if (value) {
      if (callSuccess) {
        callSuccess();
      }
    } else {
      if (callFail) {
        callFail();
      }
    }
  };

  return (
    <div className={className} onClick={disabled ? undefined : loginTiktok}>
      {children}
      {exportId && <ExportFileStatus id={exportId} onGetResult={handleExportResult} />}
    </div>
  );
};

export default track({
  element: 'add_to_tiktok',
})(Tiktok);
