/**
 * @generated SignedSource<<680f323395d00b2638ae7ad549c6b42a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type CreatorBadge = "HIGHLY_RESPONSIVE" | "HIGH_POTENTIAL" | "TOP_RATED" | "UGC_EXPERT" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR" | "%future added value";
export type ProjectCreatorDetailsQuery$variables = {
  id: string;
};
export type ProjectCreatorDetailsQuery$data = {
  readonly project: {
    readonly advertiserStage: AdvertiserProjectStage | null | undefined;
    readonly archivation: {
      readonly id: string;
    } | null | undefined;
    readonly archivationRequest: {
      readonly acceptance: {
        readonly id: string;
      } | null | undefined;
      readonly rejection: {
        readonly id: string;
      } | null | undefined;
      readonly side: ProjectArchivationSide;
    } | null | undefined;
    readonly campaign: {
      readonly id: string;
      readonly type: CampaignType | null | undefined;
    };
    readonly creator: {
      readonly __typename: string;
      readonly channel?: {
        readonly id: string | null | undefined;
        readonly name: string | null | undefined;
      } | null | undefined;
      readonly id: string;
      readonly profile: {
        readonly badge: CreatorBadge | null | undefined;
        readonly profilePictureFile: {
          readonly transformations?: {
            readonly collageThumbnailUrl: string;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly type: CreatorType;
      readonly username: string;
    } | null | undefined;
    readonly launch: {
      readonly id: string;
    } | null | undefined;
    readonly shortlisted: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectDraftDate_project" | "ProjectPrice_project" | "ShipmentInformation_project">;
  } | null | undefined;
};
export type ProjectCreatorDetailsQuery = {
  response: ProjectCreatorDetailsQuery$data;
  variables: ProjectCreatorDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortlisted",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivation",
  "kind": "LinkedField",
  "name": "archivation",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivationRequestAcceptance",
  "kind": "LinkedField",
  "name": "acceptance",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectArchivationRequestRejection",
  "kind": "LinkedField",
  "name": "rejection",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "side",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ProjectLaunch",
  "kind": "LinkedField",
  "name": "launch",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "advertiserStage",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "badge",
  "storageKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "collageThumbnailUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "YoutubeChannel",
      "kind": "LinkedField",
      "name": "channel",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v16/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "YoutubeCreator",
  "abstractKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectCreatorDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectArchivationRequest",
            "kind": "LinkedField",
            "name": "archivationRequest",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectDraftDate_project"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectPrice_project"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShipmentInformation_project"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v12/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CreatorProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "profilePictureFile",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectCreatorDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectArchivationRequest",
            "kind": "LinkedField",
            "name": "archivationRequest",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showShipmentTab",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopifyAuthorization",
                "kind": "LinkedField",
                "name": "shopifyAuthorization",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          (v3/*: any*/),
          {
            "alias": "archivedByAdvertiser",
            "args": [
              {
                "kind": "Literal",
                "name": "side",
                "value": "ADVERTISER"
              }
            ],
            "concreteType": "ProjectArchivation",
            "kind": "LinkedField",
            "name": "archivation",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "archivation(side:\"ADVERTISER\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Deadline",
            "kind": "LinkedField",
            "name": "deadline",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "draftDate",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectCompletion",
            "kind": "LinkedField",
            "name": "completion",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PriceChangeRequest",
            "kind": "LinkedField",
            "name": "priceChangeRequest",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShippingAddress",
            "kind": "LinkedField",
            "name": "shippingAddress",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iso2Code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Province",
                    "kind": "LinkedField",
                    "name": "provinces",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "provinceCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zip",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Offer",
            "kind": "LinkedField",
            "name": "offer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferAcceptance",
                "kind": "LinkedField",
                "name": "acceptance",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shippingInformation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shippingRecipientFirstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shippingRecipientLastName",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v12/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CreatorProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "profilePictureFile",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v14/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e5c201a3080b60c30989b0f40a4d66c",
    "id": null,
    "metadata": {},
    "name": "ProjectCreatorDetailsQuery",
    "operationKind": "query",
    "text": "query ProjectCreatorDetailsQuery(\n  $id: ID!\n) {\n  project(id: $id) {\n    shortlisted\n    archivation {\n      id\n    }\n    archivationRequest {\n      acceptance {\n        id\n      }\n      rejection {\n        id\n      }\n      side\n      id\n    }\n    campaign {\n      id\n      type\n    }\n    launch {\n      id\n    }\n    advertiserStage\n    ...ProjectDraftDate_project\n    ...ProjectPrice_project\n    ...ShipmentInformation_project\n    creator {\n      id\n      username\n      type\n      profile {\n        badge\n        profilePictureFile {\n          __typename\n          ... on Image {\n            transformations {\n              collageThumbnailUrl\n            }\n          }\n          id\n        }\n        id\n      }\n      __typename\n      ... on YoutubeCreator {\n        channel {\n          id\n          name\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ProjectDraftDate_project on Project {\n  id\n  launch {\n    id\n  }\n  archivedByAdvertiser: archivation(side: ADVERTISER) {\n    id\n  }\n  deadline {\n    draftDate\n    id\n  }\n  completion {\n    id\n  }\n  campaign {\n    type\n    id\n  }\n}\n\nfragment ProjectPrice_project on Project {\n  id\n  price\n  currency\n  priceChangeRequest {\n    id\n    price\n  }\n  launch {\n    id\n  }\n  completion {\n    id\n  }\n  campaign {\n    paymentType\n    id\n  }\n}\n\nfragment ShipmentInformation_project on Project {\n  shippingAddress {\n    id\n    address1\n    address2\n    city\n    country {\n      name\n      iso2Code\n      provinces {\n        code\n        name\n      }\n      id\n    }\n    firstName\n    lastName\n    phone\n    provinceCode\n    zip\n  }\n  launch {\n    id\n  }\n  offer {\n    acceptance {\n      shippingInformation\n      shippingRecipientFirstName\n      shippingRecipientLastName\n      id\n    }\n    id\n  }\n  campaign {\n    id\n    showShipmentTab\n    shopifyAuthorization {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4fc01fc03bc5184ccda63eb9b21fcb39";

export default node;
