/**
 * @generated SignedSource<<6555138c4e3334a30c4a860c68b0f9f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TiktokShopProductsListQuery$variables = {
  campaignId: string;
};
export type TiktokShopProductsListQuery$data = {
  readonly campaign: {
    readonly organization: {
      readonly tiktokShopAuthorizations: ReadonlyArray<{
        readonly shops: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly products: ReadonlyArray<{
            readonly description: string | null | undefined;
            readonly id: string;
            readonly isNotForSale: boolean;
            readonly mainImages: ReadonlyArray<{
              readonly urls: ReadonlyArray<string>;
            }> | null | undefined;
            readonly packageDimensions: {
              readonly height: string;
              readonly width: string;
            } | null | undefined;
            readonly shopId: string;
            readonly status: string;
            readonly title: string;
          }> | null | undefined;
        }> | null | undefined;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type TiktokShopProductsListQuery = {
  response: TiktokShopProductsListQuery$data;
  variables: TiktokShopProductsListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "TiktokShop_Shop",
  "kind": "LinkedField",
  "name": "shops",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TiktokShop_Product",
      "kind": "LinkedField",
      "name": "products",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shopId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isNotForSale",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TiktokShop_Product_PackageDimensions",
          "kind": "LinkedField",
          "name": "packageDimensions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "height",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TiktokShop_Product_MainImage",
          "kind": "LinkedField",
          "name": "mainImages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urls",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TiktokShopProductsListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SellerAuthorization",
                "kind": "LinkedField",
                "name": "tiktokShopAuthorizations",
                "plural": true,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TiktokShopProductsListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SellerAuthorization",
                "kind": "LinkedField",
                "name": "tiktokShopAuthorizations",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "935640a8ade222431098a4ce66daf6c0",
    "id": null,
    "metadata": {},
    "name": "TiktokShopProductsListQuery",
    "operationKind": "query",
    "text": "query TiktokShopProductsListQuery(\n  $campaignId: ID!\n) {\n  campaign(id: $campaignId) {\n    organization {\n      tiktokShopAuthorizations {\n        shops {\n          id\n          name\n          products {\n            id\n            shopId\n            description\n            isNotForSale\n            status\n            title\n            packageDimensions {\n              height\n              width\n            }\n            mainImages {\n              urls\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "347a98909f6fffa4e0314647df94cabb";

export default node;
