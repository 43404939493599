import React, { useContext, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Element } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { BriefContext } from '../../../../Brief.Context';
import With from '../../components/With/With';
import Field from '../../components/Field/Field';
import SegmentedControls from '../../components/SegmentedControls/SegmentedControls';

import styles from './UseCase.pcss';
import { getTypesList, getGoalList, getUseCaseList } from './util';

import { amplitude } from 'Helpers/amplitude';
import deleteBriefCreative from 'Mutations/DeleteBriefCreative.Mutation';
import updateBriefCreative from 'Mutations/UpdateBriefCreative.Mutation';
import Text from 'Components/ui/Text/Text';
import {
  CONTENT_CREATION,
  CREATOR_ADS,
  INFLUENCER,
  MONEY_TYPE,
  ORGANIC_POSTING,
  PRODUCT_SEEDING,
  TIKTOK,
} from 'Constants/general';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import {
  UseCase_campaign$key,
  CampaignUseCase,
  CampaignPostingType,
} from 'GraphTypes/UseCase_campaign.graphql';
import type { UpdateCampaignInput } from 'GraphTypes/UpdateCampaignMutation.graphql';
import type { UpdateBriefInput } from 'GraphTypes/UpdateBriefMutation.graphql';

interface Props {
  campaign: UseCase_campaign$key;
  objectiveVisible?: boolean;
  newCasesVisible?: boolean;
  onCampaignUpdate: (data: Partial<UpdateCampaignInput>) => void;
  onBriefUpdate: (data: Partial<UpdateBriefInput>) => void;
}

const UseCase: React.FC<Props> = (props) => {
  const { campaign, objectiveVisible, newCasesVisible, onCampaignUpdate, onBriefUpdate } = props;

  const [briefState, dispatchBriefValue] = useContext(BriefContext);

  const availableCampaignTypes = briefState.availableCampaignTypes;
  const availableCampaignUseCases = briefState.availableCampaignUseCases;

  const { showErrors, shownElementsWithPossibleError } = briefState;

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const data = useFragment(
    graphql`
      fragment UseCase_campaign on Campaign {
        id
        type
        postingType
        useCase
        useCasesUnlocked
        objective
        organization {
          tiktokShopLinked
        }
        brief {
          id
          ... on V2Brief {
            creatives {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    `,
    campaign
  );

  const campaignId = data.id;
  const briefId = data.brief?.id;
  const type = data.type;
  const postingType = data.postingType;
  const objective = data.objective;
  const useCase = data.useCase;
  const organization = data.organization;
  const creatives = data.brief?.creatives?.edges;

  const hasNewCases = hasFeatureFlag('use_cases_5389') && data?.useCasesUnlocked;

  const typeValue = useMemo(() => {
    if (type === CONTENT_CREATION || type === PRODUCT_SEEDING) return type;
    if (type === INFLUENCER && postingType) return postingType;
    return undefined;
  }, [type, postingType]);

  const typesList = useMemo(() => {
    return getTypesList({ campaignId, availableCampaignTypes });
  }, [campaignId, availableCampaignTypes]);

  const goalList = getGoalList();

  const handleContentCreationSelect = (campaignData?: Partial<UpdateCampaignInput>) => {
    dispatchBriefValue({ key: 'campaignType', value: CONTENT_CREATION });
    onCampaignUpdate({
      type: CONTENT_CREATION,
      objective: hasNewCases ? 'JUST_CONTENT' : null,
      postingType: null,
      ...campaignData,
    });
    creatives?.forEach((creative) => {
      if (!creative?.node?.id) return;
      updateBriefCreative({ id: creative.node.id, publishingRequired: false });
    });
  };

  const handleProductSeedingSelect = (
    campaignData?: Partial<UpdateCampaignInput>,
    briefData?: Partial<UpdateBriefInput>
  ) => {
    const newUseCase = campaignData?.useCase;
    dispatchBriefValue({
      key: 'campaignType',
      value: PRODUCT_SEEDING,
    });
    onBriefUpdate({
      paidSocial: false,
      paidSocialBca: false,
      priceLimitMin: null,
      priceLimitMax: null,
      paidSocialActivationDays: null,
      sparkAds: false,
      sparkAdsActivationDays: null,
      ...(!hasNewCases && newUseCase !== 'AFFILIATE_CAMPAIGN' && newUseCase !== 'TIKTOK_SHOP'
        ? {
            referralFeeRate: null,
            includeReferralFee: false,
          }
        : {}),
      ...briefData,
    });
    onCampaignUpdate({
      type: PRODUCT_SEEDING,
      paymentType: MONEY_TYPE,
      postingType: null,
      ...campaignData,
    });
    creatives?.forEach((creative) => {
      if (creative?.node?.id) {
        deleteBriefCreative({ id: creative.node.id, briefId });
      }
    });
  };

  const handleInfluencerSelect = (campaignData?: Partial<UpdateCampaignInput>) => {
    dispatchBriefValue({ key: 'campaignType', value: INFLUENCER });
    onCampaignUpdate({ type: INFLUENCER, postingType: campaignData?.postingType, ...campaignData });
  };

  const handleOldTypesChange = (data: { type: string }) => {
    switch (data.type) {
      case CONTENT_CREATION:
        handleContentCreationSelect();
        break;
      case PRODUCT_SEEDING:
        handleProductSeedingSelect();
        break;

      default:
        handleInfluencerSelect({ postingType: data.type as CampaignPostingType });
        break;
    }
  };

  const handleGoalChange = (data: any) => {
    onCampaignUpdate({ ...data, useCase: null });
  };

  const handleUseCaseSampleCheck = (useCase: CampaignUseCase) => {
    amplitude.sendEvent<395>({
      id: '395',
      category: 'brief',
      name: 'sample_ugc_click',
      param: {
        strategy_type: useCase,
        campaignId,
      },
    });
  };

  const getTooltipContent: React.FC = () => {
    return (
      <div className={styles.upgradeTooltip}>
        <Text msg="campaign.stategy.tooltip" />
        <NavLink to="/billing/plans">
          <Text msg="campaign.stategy.link" color="pink" />
        </NavLink>
      </div>
    );
  };

  const useCaseList = getUseCaseList(
    objective,
    availableCampaignUseCases,
    handleUseCaseSampleCheck,
    getTooltipContent,
    organization
  );

  const handleUseCaseChanged = (data: { useCase: CampaignUseCase }) => {
    if (data?.useCase) {
      amplitude.sendEvent<394>({
        id: '394',
        category: 'brief',
        name: 'strategy_ugc_click',
        param: {
          strategy_type: data.useCase,
          campaignId,
        },
      });
      dispatchBriefValue({ key: 'useCase', value: data.useCase });
      switch (data?.useCase) {
        case 'USER_GENERATED_CONTENT': {
          handleContentCreationSelect({ useCase: data.useCase });
          onBriefUpdate({ includeReferralFee: false, referralFeeRate: null });
          break;
        }
        case 'INFLUENCER_POSTS': {
          handleInfluencerSelect({
            useCase: data.useCase,
            postingType: ORGANIC_POSTING,
            platform: 'INSTAGRAM',
          });
          onBriefUpdate({
            includeReferralFee: false,
            referralFeeRate: null,
            sparkAds: false,
            sparkAdsActivationDays: null,
          });
          break;
        }
        case 'PRODUCT_SEEDING': {
          handleProductSeedingSelect(
            {
              useCase: data.useCase,
              platform: 'INSTAGRAM',
            },
            {
              includeReferralFee: false,
              referralFeeRate: null,
              sparkAds: false,
              sparkAdsActivationDays: null,
            }
          );
          break;
        }
        case 'TIKTOK_SPARK_ADS': {
          handleInfluencerSelect({
            useCase: data.useCase,
            postingType: CREATOR_ADS,
            platform: 'TIKTOK',
          });
          onBriefUpdate({
            includeReferralFee: false,
            referralFeeRate: null,
            paidSocial: false,
            paidSocialBca: false,
            paidSocialActivationDays: null,
          });
          break;
        }
        case 'META_PARTNERSHIP_ADS': {
          handleInfluencerSelect({
            useCase: data.useCase,
            postingType: CREATOR_ADS,
            platform: 'INSTAGRAM',
          });
          onBriefUpdate({
            includeReferralFee: false,
            referralFeeRate: null,
            sparkAds: false,
            sparkAdsActivationDays: null,
          });
          break;
        }
        case 'AFFILIATE_CAMPAIGN': {
          onBriefUpdate({
            includeReferralFee: true,
            referralFeeRate: 10,
          });
          handleInfluencerSelect({
            useCase: data.useCase,
            postingType: ORGANIC_POSTING,
          });
          break;
        }
        case 'TIKTOK_SHOP': {
          handleInfluencerSelect({
            useCase: data.useCase,
            postingType: ORGANIC_POSTING,
            platform: 'TIKTOK',
          });
          onBriefUpdate({
            includeReferralFee: true,
            referralFeeRate: 15,
            paidSocial: false,
            paidSocialBca: false,
            paidSocialActivationDays: null,
          });
        }
      }
    }
  };

  if (!hasNewCases) {
    return (
      <With condition={true} name="type">
        <Field
          title="brief_template.field.campaign_type.title"
          description="brief_template.field.campaign_type.descr"
        >
          <Element name="type">
            <SegmentedControls
              id="type"
              error={shownElementsWithPossibleError?.includes('type') && showErrors && !typeValue}
              currentValue={typeValue}
              items={typesList}
              onChange={handleOldTypesChange}
              className={styles.types}
              itemClassName={styles.typeItem}
            />
          </Element>
        </Field>
      </With>
    );
  }

  return (
    <>
      <With condition={!!(hasNewCases && objectiveVisible)} name="objective">
        <Field title="campaign.goal.title">
          <SegmentedControls
            id="objective"
            items={goalList}
            error={
              shownElementsWithPossibleError?.includes('objective') && showErrors && !objective
            }
            currentValue={objective}
            onChange={handleGoalChange}
            itemClassName={styles.goalItem}
          />
        </Field>
      </With>
      <With condition={!!newCasesVisible} name="useCases">
        <Field title="campaign.strategy.title">
          <Element name="useCases">
            <SegmentedControls
              id="useCase"
              items={useCaseList}
              error={shownElementsWithPossibleError?.includes('useCases') && showErrors && !useCase}
              onChange={handleUseCaseChanged}
              currentValue={useCase}
              itemClassName={classnames(styles.strategyControl, {
                [styles.small]: useCaseList.length === 1,
              })}
              outerLinkPosition={'bottom'}
            />
          </Element>
        </Field>
      </With>
    </>
  );
};

export default UseCase;
