import React from 'react';
import { graphql, useFragment } from 'react-relay';
import compact from 'lodash/compact';

import SubSection from '../components/SubSection/SubSection';
import BarChart from '../components/BarChart/BarChart';

import { generateCreatorData, generateBarCharts } from './data';
import styles from './CreatorInfo.pcss';

import { CreatorInfoStats_creator$key } from 'GraphTypes/CreatorInfoStats_creator.graphql';
import { Currency } from 'GraphTypes/MediaplanItemInfoQuery.graphql';

interface Props {
  creator: CreatorInfoStats_creator$key;
  price?: number | null;
  currency?: Currency | null;
  admin?: boolean;
}

const CreatorInfo: React.FC<Props> = (props) => {
  const { price, currency, creator, admin } = props;

  const data = useFragment(
    graphql`
      fragment CreatorInfoStats_creator on Creator {
        type
        id
        username
        profile {
          countries {
            name
          }
          cities {
            name
          }
          estimatedPrice
          hashtags {
            count
            name
            percentage
          }
          portfolioLink
          postBrands {
            count
            name
            percentage
          }
          mentions {
            count
            name
            percentage
          }
        }
        ... on InstagramCreator {
          user {
            followedByCount
            engagementRate
            averageLikes
            averageComments
            averageViews
          }
          userInsightsData {
            followers
            followersDelta
            reach
            impressions
            websiteClicks
            mediaEngagementRate
          }
        }

        ... on YoutubeCreator {
          channel {
            subscribers
          }
        }
        ... on TiktokCreator {
          tiktokUser: user {
            name
            averageLikes
            averageComments
            averageViews
            followedByCount
            engagementRate
            medianViews
          }
        }
      }
    `,
    creator
  );

  if (!data) return null;

  const creatorDataList = generateCreatorData({ creator: data, price, currency, admin });
  const barCharts = generateBarCharts({ creator: data });

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.section}>
          {creatorDataList.map((item) => {
            const { title } = item;

            return (
              <div key={title} className={styles.item}>
                <SubSection {...item} />
              </div>
            );
          })}
        </div>
        {barCharts && barCharts.length > 0 && (
          <div className={styles.section}>
            {compact(
              barCharts.map((item) => {
                const { title, tooltipMsg, items } = item;
                if (!items.length) return null;

                return (
                  <div key={title} className={styles.bar}>
                    <SubSection title={title} tooltipMsg={tooltipMsg}>
                      <BarChart items={items} />
                    </SubSection>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreatorInfo;
