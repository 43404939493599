import { commitMutation, graphql } from 'react-relay';
import includes from 'lodash/includes';

import environment from 'Api/Environment';
import {
  UpdateBriefCreativeMutation,
  UpdateBriefCreativeMutation$data,
  UpdateBriefCreativeInput,
} from 'GraphTypes/UpdateBriefCreativeMutation.graphql';

const mutation = graphql`
  mutation UpdateBriefCreativeMutation($input: UpdateBriefCreativeInput!) {
    updateBriefCreative(input: $input) {
      brief {
        id
        ... on V2Brief {
          creatives {
            edges {
              node {
                mediaType
                draftDate
                publicationDate
                contentAmount
                contentPlacement
                v2ContentPlacement
                v2ContentType
                postCaption
                dos
                donts
                technicalRequirements
                publishingRequired
                contentFormat
                videoDurationMin
                videoDurationMax
                contentPreparedness
                contentMusic
                contentMusicDescription
                contentType
                references(first: 2147483647) @connection(key: "Creative_references") {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UpdateBriefCreativeInput,
  resolve?: (response: UpdateBriefCreativeMutation$data) => void,
  reject?: fnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<UpdateBriefCreativeMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const briefCreative = store.get(data.id);
      if (!briefCreative) return;

      const keys = Object.keys(data);

      if (includes(keys, 'mediaType')) {
        briefCreative.setValue(data.mediaType, 'mediaType');
      }

      if (includes(keys, 'dos')) {
        briefCreative.setValue(data.dos, 'dos');
      }

      if (includes(keys, 'donts')) {
        briefCreative.setValue(data.donts, 'donts');
      }

      if (includes(keys, 'technicalRequirements')) {
        briefCreative.setValue(data.technicalRequirements, 'technicalRequirements');
      }

      if (includes(keys, 'postCaption')) {
        briefCreative.setValue(data.postCaption, 'postCaption');
      }

      if (includes(keys, 'contentType')) {
        briefCreative.setValue(data.contentType, 'contentType');
      }

      if (includes(keys, 'contentFormat')) {
        briefCreative.setValue(data.contentFormat, 'contentFormat');
      }

      if (includes(keys, 'v2ContentType')) {
        briefCreative.setValue(data.v2ContentType, 'v2ContentType');
      }

      if (includes(keys, 'contentPreparedness')) {
        briefCreative.setValue(data.contentPreparedness, 'contentPreparedness');
      }

      if (includes(keys, 'contentMusic')) {
        briefCreative.setValue(data.contentMusic, 'contentMusic');
      }

      if (includes(keys, 'contentMusicDescription')) {
        briefCreative.setValue(data.contentMusicDescription, 'contentMusicDescription');
      }

      if (includes(keys, 'contentPlacement')) {
        briefCreative.setValue(data.contentPlacement, 'v2ContentPlacement');
      }

      if (includes(keys, 'publishingRequired')) {
        briefCreative.setValue(data.publishingRequired, 'publishingRequired');
      }

      if (includes(keys, 'videoDurationMin')) {
        briefCreative.setValue(data.videoDurationMin, 'videoDurationMin');
      }

      if (includes(keys, 'videoDurationMax')) {
        briefCreative.setValue(data.videoDurationMax, 'videoDurationMax');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};
