/**
 * @generated SignedSource<<71dacd2e40f0c2c8e69000d63fe44379>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
export type CampaignsPaginationQuery$variables = {
  count: number;
  cursor?: string | null | undefined;
  organizationId: string;
  stages?: ReadonlyArray<CampaignStage> | null | undefined;
  textQuery?: string | null | undefined;
};
export type CampaignsPaginationQuery$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"Campaigns_organization">;
  } | null | undefined;
};
export type CampaignsPaginationQuery = {
  response: CampaignsPaginationQuery$data;
  variables: CampaignsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stages"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "textQuery"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v6 = {
  "kind": "Variable",
  "name": "stages",
  "variableName": "stages"
},
v7 = {
  "kind": "Variable",
  "name": "textQuery",
  "variableName": "textQuery"
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paused",
  "storageKey": null
},
v13 = [
  (v11/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "Campaigns_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CampaignsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "CampaignConnection",
            "kind": "LinkedField",
            "name": "campaigns",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CampaignEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "platform",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postingType",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canDuplicate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useCase",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Brand",
                        "kind": "LinkedField",
                        "name": "brand",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "logo",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ImageTransformation",
                                    "kind": "LinkedField",
                                    "name": "transformations",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "brandLogoUrl",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "Image",
                                "abstractKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BriefSubmission",
                        "kind": "LinkedField",
                        "name": "briefSubmission",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BriefSubmissionAcceptance",
                            "kind": "LinkedField",
                            "name": "acceptance",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignCounters",
                        "kind": "LinkedField",
                        "name": "counters",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "applicants",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "launchedProjects",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "completedProjects",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalSpend",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "newApplicants",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectsWithUnreadEventsExcludingOfferAcceptances",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unreadProjectEventsExcludingOfferAcceptance",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectsWithSubmittedContent",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignActivation",
                        "kind": "LinkedField",
                        "name": "activation",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canBeCompleted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignCompletion",
                        "kind": "LinkedField",
                        "name": "completion",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CampaignCompletionRequest",
                        "kind": "LinkedField",
                        "name": "completionRequest",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdvertiserSubscription",
                            "kind": "LinkedField",
                            "name": "subscription",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "planId",
                                "storageKey": null
                              },
                              (v12/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "userSubscribed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "brief",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "productDelivery",
                                "storageKey": null
                              }
                            ],
                            "type": "V2Brief",
                            "abstractKey": null
                          },
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": [
              "stages",
              "textQuery"
            ],
            "handle": "connection",
            "key": "Campaigns_campaigns",
            "kind": "LinkedHandle",
            "name": "campaigns"
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e2a3fdda6ab2ea11e76fe46bb14c590",
    "id": null,
    "metadata": {},
    "name": "CampaignsPaginationQuery",
    "operationKind": "query",
    "text": "query CampaignsPaginationQuery(\n  $organizationId: ID!\n  $count: Int!\n  $cursor: String\n  $textQuery: String\n  $stages: [CampaignStage!]\n) {\n  organization(id: $organizationId) {\n    ...Campaigns_organization_2HNZ0B\n    id\n  }\n}\n\nfragment CampaignPreviewCounters_campaign on Campaign {\n  id\n  completion {\n    id\n  }\n  counters {\n    launchedProjects\n    completedProjects\n    newApplicants\n    projectsWithUnreadEventsExcludingOfferAcceptances\n    unreadProjectEventsExcludingOfferAcceptance\n    projectsWithSubmittedContent\n  }\n}\n\nfragment Campaign_campaign on Campaign {\n  platform\n  type\n  postingType\n  id\n  name\n  stage\n  createdAt\n  canDuplicate\n  useCase\n  brand {\n    name\n    logo {\n      __typename\n      ... on Image {\n        transformations {\n          brandLogoUrl\n        }\n      }\n      id\n    }\n    id\n  }\n  briefSubmission {\n    acceptance {\n      id\n    }\n    id\n  }\n  counters {\n    applicants\n    launchedProjects\n    completedProjects\n    totalSpend\n  }\n  activation {\n    id\n    paused\n  }\n  deletable\n  canBeCompleted\n  completion {\n    __typename\n    id\n  }\n  completionRequest {\n    __typename\n    id\n  }\n  organization {\n    id\n    subscription {\n      planId\n      paused\n      id\n    }\n  }\n  userSubscribed\n  brief {\n    __typename\n    ... on V2Brief {\n      productDelivery\n    }\n    id\n  }\n  ...CampaignPreviewCounters_campaign\n}\n\nfragment Campaigns_organization_2HNZ0B on Organization {\n  campaigns(first: $count, after: $cursor, stages: $stages, textQuery: $textQuery) {\n    edges {\n      node {\n        id\n        ...Campaign_campaign\n        brief {\n          __typename\n          ... on V2Brief {\n            productDelivery\n          }\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb64e2d44bedfcf32bea5911bd56496d";

export default node;
