import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchQuery } from 'react-relay';

import { Auth0Context, authSessionState } from '..';
import { isAlreadyRegisteredUser } from '../utils';
import { Auth0PostMessageType } from '../types';

import type { CreateContractorProfileInput } from 'GraphTypes/CreateContractorProfileMutation.graphql';
import { BASIC_ROUTE, SIGNIN_ADVERTISER_ROUTE } from 'Constants/general';
import type { CurrentUserQuery } from 'GraphTypes/CurrentUserQuery.graphql';
import environment from 'Api/Environment';
import currentUserQuery from 'Containers/Auth0/Auth0Context/CurrentUser.Query';
import type { CurrentUserQuery$data } from 'GraphTypes/CurrentUserQuery.graphql';
import type { CreateContractorProfileMutation$data } from 'GraphTypes/CreateContractorProfileMutation.graphql';
import createContractorProfile from 'Mutations/CreateContractorProfile.Mutation';

type UseSignUpCreatorFlow = () => HookResult;
const useCreatorSignUpFlow: UseSignUpCreatorFlow = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticatedUser, setIsRegisteredUserForce } = useContext(Auth0Context);
  const navigate = useNavigate();

  const runSignUpCreatorFlow: HookResult['runSignUpCreatorFlow'] = async (
    createContractorProfileInput
  ) => {
    setIsLoading(true);

    const currentUserQuery$data = await new Promise<CurrentUserQuery$data>((resolve, reject) => {
      fetchQuery<CurrentUserQuery>(environment, currentUserQuery, {}).subscribe({
        next: (result) => {
          resolve(result);
        },
        error: () => reject(),
      });
    });

    const currentUser = currentUserQuery$data?.currentUser;

    if (!currentUser) {
      navigate(SIGNIN_ADVERTISER_ROUTE);
      return;
    }

    if (isAlreadyRegisteredUser(currentUser)) {
      setIsRegisteredUserForce(true);
      return;
    }

    const createContractorProfileMutation$data =
      await new Promise<CreateContractorProfileMutation$data>((resolve, reject) => {
        createContractorProfile(
          {
            ...createContractorProfileInput,
            userId: currentUser.id,
          },
          resolve,
          reject
        );
      }).finally(() => {
        setIsLoading(false);
      });

    if (createContractorProfileMutation$data?.createContractorProfile?.profile?.id) {
      setIsRegisteredUserForce(true);
    }

    return {
      createContractorProfileMutation$data,
      currentUserQuery$data,
    };
  };

  const onSignUpCreatorComplete = () => {
    const state = authSessionState.get();

    if (state?.popup) {
      if (window.opener) {
        window.opener.postMessage(
          {
            type: Auth0PostMessageType.SignUpComplete,
          },
          window.location.origin
        );
      }
      setTimeout(() => {
        window.close();
      }, 500);

      return;
    }
    if (state?.redirectUriAfterRegistration) {
      window.location.href = state?.redirectUriAfterRegistration;
      return;
    }

    const outreachBriefLink = sessionStorage.getItem('outreachBriefLink');

    if (outreachBriefLink) {
      navigate(outreachBriefLink);
    }

    navigate(BASIC_ROUTE);
  };

  return {
    runSignUpCreatorFlow,
    onSignUpCreatorComplete,
    isCreatorSignUpFlowLoading: isLoading,
  };
};

export { useCreatorSignUpFlow };

type RunSignUpCreatorFlowResult = {
  currentUserQuery$data: CurrentUserQuery$data;
  createContractorProfileMutation$data: CreateContractorProfileMutation$data;
};

type HookResult = {
  isCreatorSignUpFlowLoading: boolean;
  runSignUpCreatorFlow: (
    createContractorProfileInput: Omit<CreateContractorProfileInput, 'userId'>
  ) => Promise<RunSignUpCreatorFlowResult | void>;
  onSignUpCreatorComplete: () => void;
};
