import React, { useContext, useCallback, ChangeEventHandler } from 'react';
import { Element } from 'react-scroll';
import { graphql, useFragment } from 'react-relay';
import debounce from 'lodash/debounce';

import { BriefContext } from '../../../../Brief.Context';
import With from '../../components/With/With';
import Field from '../../components/Field/Field';
import SegmentedControls from '../../components/SegmentedControls/SegmentedControls';

import { getCurrentCreatorsAmountValue, getCreatorsAmountList } from './util';
import styles from './HiringGoal.pcss';

import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import type { UpdateBriefInput } from 'GraphTypes/UpdateBriefMutation.graphql';
import { HiringGoal_brief$key } from 'GraphTypes/HiringGoal_brief.graphql';

const AMOUNT_FOR_EXACT_COUNT = 21;

interface Props {
  creatorsAmountVisible: boolean;
  exactCreatorAmountVisible: boolean;
  brief: HiringGoal_brief$key;
  onBriefUpdate: (data: Partial<UpdateBriefInput>) => void;
}

const HiringGoal: React.FC<Props> = (props) => {
  const [briefState] = useContext(BriefContext);

  const { showErrors, shownElementsWithPossibleError } = briefState;

  const { brief, onBriefUpdate, creatorsAmountVisible, exactCreatorAmountVisible } = props;

  const data = useFragment(
    graphql`
      fragment HiringGoal_brief on Brief {
        ... on V2Brief {
          id
          creatorsCountFrom
          creatorsCountTo
          hiringGoal
        }
      }
    `,
    brief
  );

  const creatorsCountFrom = data.creatorsCountFrom;
  const creatorsCountTo = data.creatorsCountTo;
  const hiringGoal = data.hiringGoal;

  const creatorsAmountList = getCreatorsAmountList();

  const creatorsAmountValue = getCurrentCreatorsAmountValue(creatorsCountFrom, creatorsCountTo);

  const handleCreatorsAmountChange = (data: { creatorsAmount: string }) => {
    const creatorsAmount = data.creatorsAmount;
    const amountArr = creatorsAmount.split('-');
    const from = Number(amountArr[0]) || undefined;
    const to = Number(amountArr[1]) || null;
    onBriefUpdate(
      Number(from) < AMOUNT_FOR_EXACT_COUNT
        ? {
            creatorsCountFrom: from,
            creatorsCountTo: to,
          }
        : { creatorsCountFrom: from, creatorsCountTo: null, hiringGoal: null }
    );
  };

  const handleExactCreatorAmountChange: ChangeEventHandler<HTMLInputElement> = debounce((event) => {
    onBriefUpdate({ hiringGoal: Number(event?.target?.value) });
  }, 500);

  const handleCreatorAmountReset = useCallback(() => {
    onBriefUpdate({ hiringGoal: 0 });
  }, []);

  return (
    <>
      <With condition={creatorsAmountVisible} name="creatorsCount">
        <Field
          title="brief_template.field.campaign_creators_amount.title"
          description="brief_template.field.campaign_creators_amount.descr"
          className={styles.creatorsAmountContainer}
        >
          <Element name="creatorsCount">
            <SegmentedControls
              error={
                shownElementsWithPossibleError?.includes('creatorsCount') &&
                showErrors &&
                !creatorsAmountValue
              }
              id="creatorsAmount"
              currentValue={creatorsAmountValue}
              items={creatorsAmountList}
              onChange={handleCreatorsAmountChange}
            />
          </Element>
        </Field>
      </With>
      <With condition={exactCreatorAmountVisible} name={'hiringGoal'} forceRerender>
        <ProgressiveInput
          type="input"
          className={styles.exactAmount}
          inputProps={{
            placeholderMsg: 'brief_template.field.campaign_creators_exact_amount.placeholder',
            onChange: handleExactCreatorAmountChange,
            type: 'number',
            value: hiringGoal || undefined,
            onResetValue: handleCreatorAmountReset,
            error:
              (hiringGoal !== null && Number(hiringGoal) < AMOUNT_FOR_EXACT_COUNT) ||
              (shownElementsWithPossibleError?.includes('hiringGoal') && showErrors && !hiringGoal),
          }}
          errorMsg={
            (hiringGoal !== null && Number(hiringGoal) < AMOUNT_FOR_EXACT_COUNT) ||
            (shownElementsWithPossibleError?.includes('hiringGoal') && showErrors)
              ? 'brief_template.field.campaign_creators_exact_amount.error'
              : ''
          }
        />
      </With>
    </>
  );
};

export default HiringGoal;
