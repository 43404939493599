import React from 'react';
import styles from './OutreachSettings.pcss';
import GmailSettings from '../GmailSettings/GmailSettings';
import { GmailSettingsForOutreach_campaign$key } from 'GraphTypes/GmailSettingsForOutreach_campaign.graphql';
import CreatorsListSelector from '../CreatorsListSelector/CreatorsListSelector';
import SequenceSelector from '../SequenceSelector/SequenceSelector';
import { SequenceSelectorForOutreach_list$key } from 'GraphTypes/SequenceSelectorForOutreach_list.graphql';
import TimezoneSelector from '../TimezoneSelector/TimezoneSelector';
import { OutreachSettingsTabs } from '../InviteOutreachCreators';

interface Props {
  query: GmailSettingsForOutreach_campaign$key | SequenceSelectorForOutreach_list$key;
  setActiveTab: (tab: OutreachSettingsTabs) => void;
}

const OutreachSettings: React.FC<Props> = (props) => {
  const { query, setActiveTab } = props;
  return (
    <div className={styles.root}>
      <GmailSettings query={query as GmailSettingsForOutreach_campaign$key} />
      <CreatorsListSelector />
      <SequenceSelector
        setActiveTab={setActiveTab}
        query={query as SequenceSelectorForOutreach_list$key}
      />
      <TimezoneSelector />
    </div>
  );
};

export default OutreachSettings;
