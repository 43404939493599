import {
  BriefPriceRange_campaign$data,
  V2ContentPlacement,
  BriefCreativeMediaType,
  CampaignUseCase,
} from 'GraphTypes/BriefPriceRange_campaign.graphql';
import { getAges } from 'Util/dateCreator';
import { createSum } from 'Util/numberFormatter';
import compact from 'lodash/compact';
import find from 'lodash/find';

import {
  INFLUENCER_IG_POST_FOLLOWERS,
  INFLUENCER_IG_STORIES_FOLLOWERS,
  INFLUENCER_TIKTOK_POST_FOLLOWERS,
  INFLUENCER_TIKTOK_POST_VIEWS,
  AFFILIATE_IG_POST_FOLLOWERS,
  AFFILIATE_IG_STORIES_FOLLOWERS,
  AFFILIATE_TIKTOK_POST_FOLLOWERS,
  AFFILIATE_TIKTOK_POST_VIEWS,
  TIKTOK_SHOP_POST_FOLLOWERS,
  TIKTOK_SHOP_POST_VIEWS,
  TIKTOK_SPARK_ADS_FOLLOWERS,
  TIKTOK_SPARK_ADS_VIEWS,
  META_ADS_STORIES_FOLLOWERS,
  META_ADS_POST_FOLLOWERS,
  SPECIAL_CATEGORIES,
  FIRST_VIDEO,
  NEXT_VIDEOS,
  FIRST_PHOTO,
  NEXT_PHOTOS,
  PriceDataType,
} from './constants';

type NameData = {
  v2ContentPlacement?: V2ContentPlacement | null;
  mediaType?: BriefCreativeMediaType | null;
  publishingRequired: Boolean;
};
const getPlacementName = (data: NameData) => {
  const { publishingRequired, v2ContentPlacement, mediaType } = data;
  const postfix = publishingRequired ? '' : ' no posting';
  switch (v2ContentPlacement) {
    case 'REEL':
      return 'Reels';
    case 'STORY':
      return 'Strories';
    case 'FEED':
      return 'Post';

    default:
      return mediaType === 'VIDEO' ? `Video${postfix}` : `Photo${postfix}`;
  }
};

const getIgPriceData = (useCase: CampaignUseCase) => {
  switch (useCase) {
    case 'AFFILIATE_CAMPAIGN':
      return {
        priceForStoriesData: AFFILIATE_IG_STORIES_FOLLOWERS,
        priceForPostData: AFFILIATE_IG_POST_FOLLOWERS,
      };
    case 'INFLUENCER_POSTS':
      return {
        priceForStoriesData: INFLUENCER_IG_STORIES_FOLLOWERS,
        priceForPostData: INFLUENCER_IG_POST_FOLLOWERS,
      };
    case 'META_PARTNERSHIP_ADS':
      return {
        priceForStoriesData: META_ADS_STORIES_FOLLOWERS,
        priceForPostData: META_ADS_POST_FOLLOWERS,
      };

    default:
      return {
        priceForStoriesData: INFLUENCER_IG_STORIES_FOLLOWERS,
        priceForPostData: INFLUENCER_IG_POST_FOLLOWERS,
      };
  }
};

const getTiktokPriceData = (useCase: CampaignUseCase) => {
  switch (useCase) {
    case 'AFFILIATE_CAMPAIGN':
      return {
        priceByFollowersData: AFFILIATE_TIKTOK_POST_FOLLOWERS,
        priceByViewsData: AFFILIATE_TIKTOK_POST_VIEWS,
      };
    case 'INFLUENCER_POSTS':
      return {
        priceByFollowersData: INFLUENCER_TIKTOK_POST_FOLLOWERS,
        priceByViewsData: INFLUENCER_TIKTOK_POST_VIEWS,
      };
    case 'TIKTOK_SPARK_ADS':
      return {
        priceByFollowersData: TIKTOK_SPARK_ADS_FOLLOWERS,
        priceByViewsData: TIKTOK_SPARK_ADS_VIEWS,
      };
    case 'TIKTOK_SHOP':
      return {
        priceByFollowersData: TIKTOK_SHOP_POST_FOLLOWERS,
        priceByViewsData: TIKTOK_SHOP_POST_VIEWS,
      };

    default:
      return {
        priceByFollowersData: INFLUENCER_TIKTOK_POST_FOLLOWERS,
        priceByViewsData: INFLUENCER_TIKTOK_POST_VIEWS,
      };
  }
};

type CalculationParamsType = {
  campaign?: BriefPriceRange_campaign$data | null;
};

type Row = {
  label: string;
  subLabel?: string;
  price: string | number;
};

type RecommndationPriceDataType = {
  rows: Row[];
  finalPrice?: number;
};

export const calculateRecommendedPrice = (
  params: CalculationParamsType
): RecommndationPriceDataType => {
  const { campaign } = params;

  const adjusmentInfo: string[] = [];
  const adjusmentText: string[] = [];

  const useCase = campaign?.useCase;
  const platform = campaign?.platform;
  const categories = campaign?.searchQuery?.categories;
  const creatorBirthdateTo = campaign?.searchQuery?.creatorBirthdateTo;

  categories?.forEach((category) => {
    if (SPECIAL_CATEGORIES.includes(category.name)) {
      adjusmentInfo.push(category.name);
    }
  });

  if (adjusmentInfo.length > 0) {
    adjusmentText.push('Category');
  }

  if ((getAges(creatorBirthdateTo) || 0) >= 40) {
    adjusmentText.push('Age');
    adjusmentInfo.push('+40');
  }

  const getAdjustmentRow = (priceValue: number) => {
    return adjusmentInfo.length > 0
      ? {
          price: priceValue * 0.25,
          label: adjusmentText.join(' / ') + ' +25%',
          subLabel: adjusmentInfo.join(', '),
        }
      : undefined;
  };

  if (useCase === 'USER_GENERATED_CONTENT') {
    const data = calculateRecommendationPriceForUgc({ ...params });
    const finalPrice = adjusmentInfo.length > 0 ? Math.ceil(data.price * 1.25) : data.price;
    const adjusmentRow = getAdjustmentRow(data.price);
    return { ...data, finalPrice, rows: compact([...data.rows, adjusmentRow]) };
  }

  if (
    platform &&
    ['FACEBOOK', 'INSTAGRAM'].includes(platform) &&
    useCase &&
    ['META_PARTNERSHIP_ADS', 'INFLUENCER_POSTS', 'AFFILIATE_CAMPAIGN'].includes(useCase)
  ) {
    const { priceForStoriesData, priceForPostData } = getIgPriceData(useCase);
    const data = calculateRecommendationPriceForIg(
      { ...params },
      priceForStoriesData,
      priceForPostData
    );
    const finalPrice = adjusmentInfo.length > 0 ? Math.ceil(data.price * 1.25) : data.price;
    const adjusmentRow = getAdjustmentRow(data.price);
    return { ...data, finalPrice, rows: compact([...data.rows, adjusmentRow]) };
  }

  if (
    platform &&
    ['TIKTOK'].includes(platform) &&
    useCase &&
    [
      'TIKTOK_SHOP',
      'TIKTOK_SPARK_ADS',
      'AFFILIATE_CAMPAIGN',
      'INFLUENCER_POSTS',
      'TIKTOK_SPARK_ADS',
    ].includes(useCase)
  ) {
    const { priceByFollowersData, priceByViewsData } = getTiktokPriceData(useCase);
    const data = calculateRecommendationPriceForTiktok(
      { ...params },
      priceByFollowersData,
      priceByViewsData
    );
    const finalPrice = adjusmentInfo.length > 0 ? Math.ceil(data.price * 1.25) : data.price;
    const adjusmentRow = getAdjustmentRow(data.price);
    return { ...data, finalPrice, rows: compact([...data.rows, adjusmentRow]) };
  }

  return { finalPrice: undefined, rows: [] };
};

const calculateRecommendationPriceForIg = (
  params: CalculationParamsType,
  dataForStories: PriceDataType,
  dataForPost: PriceDataType
) => {
  const { campaign } = params;

  const followersFrom = campaign?.searchQuery?.followersFrom;
  const followersTo = campaign?.searchQuery?.followersTo;

  const labelPrefix =
    !followersFrom && followersTo
      ? `Followers < ${followersTo}`
      : `Followers from ${followersFrom || 0}`;

  const creatives = (
    campaign?.brief?.briefCreatives?.edges ? [...campaign?.brief?.briefCreatives?.edges] : []
  ).sort((a, b) => {
    return b?.node?.publishingRequired &&
      ['REEL', 'FEED'].includes(b.node?.v2ContentPlacement || 'FEED')
      ? 1
      : -1;
  });

  let tempSum = 0;
  let priceStoriesData = dataForStories[0];
  let pricePostData = dataForPost[0];

  const followersCount = followersFrom || followersTo;

  if (followersCount) {
    dataForStories.forEach((item) => {
      if (followersCount >= item.minValue) {
        priceStoriesData = item;
      }
    });
    dataForPost.forEach((item) => {
      if (followersCount >= item.minValue) {
        pricePostData = item;
      }
    });
  }

  const priceForStoriesPosting = followersCount
    ? priceStoriesData.maxValue
      ? priceStoriesData.min +
        ((priceStoriesData.max - priceStoriesData.min) /
          (priceStoriesData.maxValue - priceStoriesData.minValue)) *
          (followersCount - priceStoriesData.minValue)
      : priceStoriesData.min +
        (priceStoriesData.min / priceStoriesData.minValue) *
          (followersCount - priceStoriesData.minValue)
    : priceStoriesData.min;

  const priceForFeedPosting = followersCount
    ? pricePostData.maxValue
      ? pricePostData.min +
        ((pricePostData.max - pricePostData.min) /
          (pricePostData.maxValue - pricePostData.minValue)) *
          (followersCount - pricePostData.minValue)
      : pricePostData.min +
        (pricePostData.min / pricePostData.minValue) * (followersCount - pricePostData.minValue)
    : pricePostData.min;

  let hasAnyWithPosting = !!find(creatives, (item) => !!item?.node?.publishingRequired);

  let hasFirstWithPosting = false;
  let reelsCount = 0;
  let reelsSum = 0;
  let storiesCount = 0;
  let storiesSum = 0;
  let postCount = 0;
  let postSum = 0;
  let videosCount = 0;
  let videosWithPostingCount = 0;
  let videosWithPostingSum = 0;
  let photosWithPostingCount = 0;
  let photosWithPostingSum = 0;
  let photosCount = 0;
  let videosSum = 0;
  let photosSum = 0;

  const rows: Row[] = [];

  creatives.forEach((item) => {
    if (!item?.node) return;
    const publishingRequired = item?.node?.publishingRequired;
    const contentPlacement = item?.node?.v2ContentPlacement;
    const mediaType = item.node.mediaType;
    if (!mediaType) return;
    const contentAmount = item.node.contentAmount || 1;
    if (publishingRequired || (!hasAnyWithPosting && !hasFirstWithPosting)) {
      const priceForPosting =
        contentPlacement === 'STORY' ? priceForStoriesPosting : priceForFeedPosting;
      const label = getPlacementName({
        v2ContentPlacement: contentPlacement,
        mediaType,
        publishingRequired: !!publishingRequired,
      });
      const price = hasFirstWithPosting ? Math.ceil(priceForPosting / 2) : priceForPosting;

      if (!hasFirstWithPosting) {
        tempSum += price;
        rows.push({
          price,
          label,
          subLabel: hasFirstWithPosting ? undefined : labelPrefix,
        });
      }
      if (hasFirstWithPosting) {
        if (contentPlacement === 'REEL') {
          reelsCount++;
          reelsSum += price;
        } else if (contentPlacement === 'FEED') {
          postCount++;
          postSum += price;
        } else if (contentPlacement === 'STORY') {
          storiesCount++;
          storiesSum += price;
        } else {
          if (mediaType === 'VIDEO') {
            videosWithPostingCount++;
            videosWithPostingSum += price;
          } else {
            photosWithPostingCount++;
            photosWithPostingSum += price;
          }
        }
      }
      if (contentAmount > 1) {
        if (publishingRequired) {
          photosWithPostingCount += contentAmount - 1;
          photosWithPostingSum += NEXT_PHOTOS * (contentAmount - 1);
        } else {
          photosCount += contentAmount - 1;
          photosSum += NEXT_PHOTOS * (contentAmount - 1);
        }
      }
      hasFirstWithPosting = true;
    } else {
      if (mediaType === 'VIDEO') {
        videosCount++;
        videosSum += NEXT_VIDEOS;
      } else {
        photosCount += contentAmount;
        photosSum += NEXT_PHOTOS * contentAmount;
      }
    }
  });

  tempSum +=
    videosSum +
    photosSum +
    photosWithPostingSum +
    videosWithPostingSum +
    reelsSum +
    postSum +
    storiesSum;

  if (reelsCount > 0) {
    rows.push({ price: reelsSum, label: `${reelsCount} additional x Reels` });
  }
  if (postCount > 0) {
    rows.push({ price: postSum, label: `${postCount} additional x Post` });
  }
  if (storiesCount > 0) {
    rows.push({ price: storiesSum, label: `${storiesCount} additional x Stories` });
  }
  if (videosWithPostingCount > 0) {
    rows.push({ price: videosWithPostingSum, label: `${videosWithPostingCount} x Video` });
  }
  if (photosWithPostingCount > 0) {
    rows.push({ price: photosWithPostingSum, label: `${photosWithPostingCount} x Photo` });
  }
  if (videosCount > 0) {
    rows.push({ price: videosSum, label: `${videosCount} x Video no posting` });
  }
  if (photosCount > 0) {
    rows.push({ price: photosSum, label: `${photosCount} x Photo no posting` });
  }

  return { price: tempSum, rows };
};

const calculateRecommendationPriceForUgc = (params: CalculationParamsType) => {
  const { campaign } = params;
  let videosCount = 0;
  let photosCount = 0;
  let tempSum = 0;

  campaign?.brief?.briefCreatives?.edges?.forEach((item) => {
    if (item?.node?.mediaType === 'VIDEO') {
      videosCount++;
    } else if (item?.node?.mediaType === 'PHOTO') {
      photosCount = photosCount + (item?.node?.contentAmount || 1);
    }
  });

  let videoSum = 0;
  let photoSum = 0;

  const rows = [];

  if (videosCount) {
    videoSum = videosCount > 1 ? FIRST_VIDEO + NEXT_VIDEOS * (videosCount - 1) : FIRST_VIDEO;
    rows.push({ price: createSum(videoSum), label: `${videosCount} x Video` });
  }

  if (photosCount) {
    photoSum = photosCount > 1 ? FIRST_PHOTO + NEXT_PHOTOS * (photosCount - 1) : FIRST_PHOTO;
    rows.push({ price: createSum(photoSum), label: `${photosCount} x Photo` });
  }

  tempSum = videoSum + photoSum;

  return { price: tempSum, rows };
};

const calculateRecommendationPriceForTiktok = (
  params: CalculationParamsType,
  dataByFollowers: PriceDataType,
  dataByViews: PriceDataType
) => {
  const { campaign } = params;

  const followersFrom = campaign?.searchQuery?.followersFrom;
  const followersTo = campaign?.searchQuery?.followersTo;
  const viewsMedianFrom = campaign?.searchQuery?.viewsMedianFrom;
  const viewsMedianTo = campaign?.searchQuery?.viewsMedianTo;

  const followersCount = followersFrom || followersTo;
  const viewsCount = viewsMedianFrom || viewsMedianTo;

  let tempPriceByFollowersData = dataByFollowers[0];
  let tempPriceByViewData = dataByViews[0];

  if (followersCount) {
    dataByFollowers.forEach((item) => {
      if (followersCount >= item.minValue) {
        tempPriceByFollowersData = item;
      }
    });
  }

  if (viewsCount) {
    dataByViews.forEach((item) => {
      if (viewsCount >= item.minValue) {
        tempPriceByViewData = item;
      }
    });
  }

  const priceForFollowersPosting = followersCount
    ? tempPriceByFollowersData.maxValue
      ? tempPriceByFollowersData.min +
        ((tempPriceByFollowersData.max - tempPriceByFollowersData.min) /
          (tempPriceByFollowersData.maxValue - tempPriceByFollowersData.minValue)) *
          (followersCount - tempPriceByFollowersData.minValue)
      : tempPriceByFollowersData.min +
        (tempPriceByFollowersData.min / tempPriceByFollowersData.minValue) *
          (followersCount - tempPriceByFollowersData.minValue)
    : tempPriceByFollowersData.min;

  const priceForViewsPosting = viewsCount
    ? tempPriceByViewData.maxValue
      ? tempPriceByViewData.min +
        ((tempPriceByViewData.max - tempPriceByViewData.min) /
          (tempPriceByViewData.maxValue - tempPriceByViewData.minValue)) *
          (viewsCount - tempPriceByViewData.minValue)
      : tempPriceByViewData.min +
        (tempPriceByViewData.min / tempPriceByViewData.minValue) *
          (viewsCount - tempPriceByViewData.minValue)
    : tempPriceByViewData.min;

  const finalPrice = Math.max(priceForFollowersPosting, priceForViewsPosting);

  const creatives = campaign?.brief?.briefCreatives?.edges || [];

  let tempSum = 0;
  let hasAnyWithPosting = !!find(creatives, (item) => !!item?.node?.publishingRequired);

  let hasFirstWithPosting = false;
  let videosWithPostingCount = 0;
  let videosWithPostingSum = 0;
  let photosWithPostingCount = 0;
  let photosWithPostingSum = 0;
  let photosCount = 0;
  let videosCount = 0;
  let videosSum = 0;
  let photosSum = 0;

  const rows: Row[] = [];

  const labelFollowersPrefix =
    !followersFrom && followersTo
      ? `Followers < ${followersTo}`
      : `Followers from ${followersFrom || 0}`;

  const labelViewsPrefix =
    !viewsMedianFrom && viewsMedianTo
      ? `Views < ${viewsMedianTo}`
      : `Views from ${viewsMedianFrom || 0}`;

  let labelPrefix = 'Followers from 0';

  if (followersCount && priceForFollowersPosting >= priceForViewsPosting) {
    labelPrefix = labelFollowersPrefix;
  } else if (viewsCount && priceForViewsPosting >= priceForFollowersPosting) {
    labelPrefix = labelViewsPrefix;
  }

  creatives.forEach((item) => {
    if (!item?.node) return;
    const publishingRequired = item?.node?.publishingRequired;
    const mediaType = item.node.mediaType;
    if (!mediaType) return;
    const contentAmount = item.node.contentAmount || 1;
    if (publishingRequired || (!hasAnyWithPosting && !hasFirstWithPosting)) {
      const labelText = mediaType === 'VIDEO' ? 'Video' : 'Photo';
      const labelPostfix = publishingRequired ? '' : ' no posting';
      const label = `${labelText}${labelPostfix}`;
      const price = hasFirstWithPosting ? Math.ceil(finalPrice / 2) : finalPrice;

      if (!hasFirstWithPosting) {
        tempSum += price;
        rows.push({
          price,
          label,
          subLabel: hasFirstWithPosting ? undefined : labelPrefix,
        });
      }
      if (hasFirstWithPosting) {
        if (mediaType === 'VIDEO') {
          videosWithPostingCount++;
          videosWithPostingSum += price;
        } else {
          photosWithPostingCount++;
          photosWithPostingSum += price;
        }
      }
      if (contentAmount > 1) {
        if (publishingRequired) {
          photosWithPostingCount += contentAmount - 1;
          photosWithPostingSum += NEXT_PHOTOS * (contentAmount - 1);
        } else {
          photosCount += contentAmount - 1;
          photosSum += NEXT_PHOTOS * (contentAmount - 1);
        }
      }
      hasFirstWithPosting = true;
    } else {
      if (mediaType === 'VIDEO') {
        videosCount++;
        videosSum += NEXT_VIDEOS;
      } else {
        photosCount += contentAmount;
        photosSum += NEXT_PHOTOS * contentAmount;
      }
    }
  });

  tempSum += videosSum + photosSum + photosWithPostingSum + videosWithPostingSum;

  if (videosWithPostingCount > 0) {
    rows.push({ price: videosWithPostingSum, label: `${videosWithPostingCount} x Video` });
  }
  if (photosWithPostingCount > 0) {
    rows.push({ price: photosWithPostingSum, label: `${photosWithPostingCount} x Photo` });
  }
  if (videosCount > 0) {
    rows.push({ price: videosSum, label: `${videosCount} x Video no posting` });
  }
  if (photosCount > 0) {
    rows.push({ price: photosSum, label: `${photosCount} x Photo no posting` });
  }

  return { price: tempSum, rows };
};
