import React, { Component } from 'react';
import track from 'react-tracking';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Content from '../Content/Content';

import styles from './Dropdown.pcss';

import modsClasses from 'Util/modsClasses.js';
import Button from 'Atoms/Button/Button';
import { CUSTOM } from 'Constants/general';

const MODS = ['position'];

class Dropdown extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    const root = document.getElementById('root');
    root.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { delay } = this.props;
    if (this.contentElement && !this.contentElement.contains(event.target)) {
      if (delay) {
        setTimeout(() => {
          this.setState({ open: false });
        }, delay);
      } else {
        this.setState({ open: false });
      }
    }
  }

  @track((props, state) => ({
    event: 'opened',
    payload: {
      open: !state.open,
    },
  }))
  toggleDropdown() {
    const open = !this.state.open;
    this.setState({ open });
    const root = document.getElementById('root');
    if (open) {
      root.addEventListener('click', this.handleClickOutside);
    } else {
      root.removeEventListener('click', this.handleClickOutside);
    }
  }

  checkDirtyState() {
    const { contents, searchParams } = this.props;
    let hasValue = false;
    for (let index = 0; index < contents.length; index++) {
      const { view, type } = contents[index];
      const customType = type === CUSTOM;

      if (view === 'toggle') {
        const { items } = contents[index];
        for (let y = 0; y < items.length; y++) {
          const element = items[y];
          const { name } = element;
          const value = searchParams[name];
          if (!isEmpty(value) || value > 0) {
            hasValue = true;
            break;
          }
        }
      } else {
        const { name } = contents[index];
        const value = searchParams[name];

        if (!customType && (!isEmpty(value) || value > 0)) {
          hasValue = true;
        }
      }
    }

    return hasValue;
  }

  checkGroup() {
    const { contents } = this.props;
    for (let i = 0; i < contents.length; i++) {
      if (contents[i].group) {
        return true;
      }
    }

    return false;
  }

  render() {
    const {
      dropDownlabel,
      dropDownStyle,
      disabled,
      searchParams,
      onChange,
      contents,
      position,
      newDesign,
      buttonPropsFn,
      accent,
    } = this.props;

    const { open } = this.state;
    const labelText = dropDownlabel ? { msg: `search_section.form.${dropDownlabel}` } : {};

    const dirty = this.checkDirtyState();

    const wrapContent = !!(contents.length > 1) || this.checkGroup();

    const classes = modsClasses(MODS, this.props, styles);

    const specialStyle = dropDownStyle ? dropDownStyle : '';

    const classesList = classnames(styles.root, classes, styles[position], {
      [styles.open]: open,
      [styles.dirty]: dirty,
      [styles.newDesign]: newDesign,
    });
    const contentClasses = classnames(styles.content, specialStyle, {
      [styles.wrapContent]: wrapContent,
    });

    const disabledData = disabled ? { disabled: true, color: 'secondary' } : {};
    const buttonProps = typeof buttonPropsFn === 'function' ? buttonPropsFn({ open, dirty }) : {};
    return (
      <div
        className={classesList}
        ref={(e) => {
          this.contentElement = e;
        }}
      >
        {accent && !dirty && <div className={styles.accentDot} />}
        <Button
          color={dirty && !newDesign ? undefined : 'normal'}
          className={styles.button}
          onClick={this.toggleDropdown}
          {...labelText}
          {...disabledData}
          {...buttonProps}
        />
        <div className={contentClasses}>
          <Content
            isVisible={open}
            onChange={onChange}
            searchParams={searchParams}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

export default track((props) => ({
  element: `${props.dropDownlabel}_dropdown`,
}))(Dropdown);
