import React from 'react';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';

import PieChart from '../components/PieChart/PieChart';
import TinyBarChart from '../components/TinyBarChart/TinyBarChart';

import styles from './AudienceInfo.pcss';

import { formatValue } from 'Util/dataFormatter';
import { createPercent } from 'Util/numberFormatter';
import colors from 'Styles/vars/colors.css';
import Text from 'Atoms/Text/Text';
import { AudienceInfo_creator$data } from 'GraphTypes/AudienceInfo_creator.graphql';

const createGenderList = (
  genders: NonNullable<AudienceInfo_creator$data['profile']>['audienceGenders']
) => {
  const list =
    genders.map((item) => {
      const genderName = item.gender?.name;
      const percentage = item.percentage;
      let color = colors.colorBase;

      if (genderName === 'Female' || genderName === 'Женский') {
        color = colors.colorPink100;
      } else if (genderName === 'Other') {
        color = colors.colorSun100;
      }

      return {
        title: genderName,
        value: Number(percentage) * 100,
        valueText: createPercent(percentage),
        color,
      };
    }) || [];

  return sortBy(list, ['value']).reverse();
};

const createAudienceReachability = (
  audienceReachability: NonNullable<AudienceInfo_creator$data['profile']>['audienceReachability']
) => {
  if (!audienceReachability) return null;

  return [
    { name: 'Easy', bars: [{ value: audienceReachability.easy }] },
    { name: 'Medium', bars: [{ value: audienceReachability.medium }] },
    { name: 'Hard', bars: [{ value: audienceReachability.hard }] },
  ];
};

type DataType = {
  creator: AudienceInfo_creator$data;
};

export const generateAudienceData = (data: DataType) => {
  const genders = data.creator.profile?.audienceGenders;
  const gendersList = genders ? createGenderList(genders) : null;

  const audienceReachability = data.creator.profile?.audienceReachability;
  const audienceReachabilityList = audienceReachability
    ? createAudienceReachability(audienceReachability)
    : null;

  const ageRanges = data.creator.profile?.audienceAgeRanges || [];
  const formatttedAgeRanges = [...ageRanges].sort((a, b) => {
    const aValue = Number(a.ageRange?.name.split('-')[0]);
    const bValue = Number(b.ageRange?.name.split('-')[0]);
    if (aValue < bValue) {
      return -1;
    } else if (aValue > bValue) {
      return 1;
    }

    return 0;
  });

  const ageRangesList = formatttedAgeRanges.map((item) => {
    return {
      name: item.ageRange?.name,
      bars: [{ value: item.percentage }],
    };
  });

  return compact([
    // realFollowers
    //   ? {
    //       title: 'creator_statistic.section_real_followers',
    //       tooltipMsg: 'creator_statistic.section_real_followers.tooltip',
    //       children: (
    //         <div className={styles.realFollowers}>
    //           <Text weight="700" text={formatValue({ format: 'percent', value: realFollowers })} />
    //         </div>
    //       ),
    //     }
    //   : null,
    audienceReachabilityList && audienceReachabilityList.length !== 0
      ? {
          title: 'creator_statistic.section_reachability',
          tooltipMsg: 'creator_statistic.section_reachability_tooltip',
          children: <TinyBarChart items={audienceReachabilityList} />,
        }
      : null,
    gendersList && gendersList.length !== 0
      ? {
          title: 'creator_statistic.section_gender_split',
          tooltipMsg: 'creator_statistic.section_gender_split_tooltip',
          children: <PieChart dataKey="gender" items={gendersList} />,
        }
      : null,
    ageRangesList && ageRangesList.length !== 0
      ? {
          title: 'creator_statistic.section_age_split',
          tooltipMsg: 'creator_statistic.section_age_split_tooltip',
          children: <TinyBarChart items={ageRangesList} />,
        }
      : null,
  ]);
};

const createList = (list: any, key: string) => {
  return list.map((item: any) => {
    return { percentage: item.percentage, name: item[key].name };
  });
};

export const generateAudienceBarChartsData = (data: DataType) => {
  const creator = data.creator;

  const audienceCities = data.creator.profile?.audienceCities;
  const audienceCountries = data.creator?.profile?.audienceCountries;

  const countries = creator.profile?.audienceCountries;
  const countriesList = createList(countries, 'country');

  const ethnicities = creator.profile?.audienceEthnicities;
  const ethnicitiesList = createList(ethnicities, 'ethnicity');

  const interests = creator.profile?.audienceInterests;
  const interestsList = createList(interests, 'interest');

  const userCities = sortBy(audienceCities, ['count']);
  const userCitiesList = userCities.map((item) => {
    return {
      name: item.city?.name,
      percentage: item.percentage,
    };
  });

  const userCountries = sortBy(audienceCountries, ['count']);
  const userCountriesList = userCountries.map((item) => {
    return { name: item.country?.name, percentage: item.percentage };
  });

  return compact([
    userCountriesList && userCountriesList.length > 0
      ? {
          title: 'creator_statistic.subtitles.au.countries',
          tooltipMsg: 'creator_statistic.subtitles.au.countries.tooltip',
          items: userCountriesList,
        }
      : null,
    userCitiesList && userCitiesList.length > 0
      ? {
          title: 'creator_statistic.subtitles.au.cities',
          tooltipMsg: 'creator_statistic.subtitles.au.cities.tooltip',
          items: userCitiesList,
        }
      : null,
    !audienceCountries && countriesList && countriesList.length > 0
      ? {
          title: 'creator_statistic.subtitles.audience.countries',
          tooltipMsg: 'creator_statistic.subtitles.audience.countries.tooltip',
          items: countriesList,
        }
      : null,
    ethnicitiesList && ethnicitiesList.length > 0
      ? {
          title: 'creator_statistic.subtitles.audience.ethnicities',
          tooltipMsg: 'creator_statistic.subtitles.audience.ethnicities.tooltip',
          items: ethnicitiesList,
        }
      : null,
    interestsList && interestsList.length > 0
      ? {
          title: 'creator_statistic.subtitles.audience.interests',
          tooltipMsg: 'creator_statistic.subtitles.audience.interests.tooltip',
          items: interestsList,
        }
      : null,
  ]);
};
