import React, { useState, useEffect, Suspense } from 'react';
import track, { useTracking } from 'react-tracking';

import { getStagesList } from '../utils';

import styles from './Creators.pcss';
import CreatorsList from './CreatorsList/CreatorsList';
import Search from './Search/Search';
import OrderFilter from './OrderFilter/OrderFilter';
import ProjectsFilters from './ProjectsFilters/ProjectsFilters';

import { amplitude } from 'Helpers/amplitude';
import {
  CREATORS,
  AWAITING_LIST,
  ARCHIVED,
  LONG_TERM,
  APPLICANTS,
  COMPLETED,
} from 'Constants/general';
import Spinner from 'Atoms/Spinner/Spinner';

const Creators = (props) => {
  const {
    isAdminView,
    section,
    currentUser,
    campaignPlatform,
    campaignId,
    campaignType,
    publishingRequired,
    campaign,
    email,
    organizationId,
  } = props;

  const canBrowseCreators = !!currentUser?.organization?.subscription?.canBrowseCreators;
  const canFilterCreatorsByBadge =
    !!currentUser?.organization?.subscription?.canFilterCreatorsByBadge;

  const currency = currentUser?.organization?.paymentAccount?.currency;

  const { preferredContentType, preferredCreatorCategory } = campaign;

  const isLongTermCampaign = campaignType === LONG_TERM;

  const defaultStages = getStagesList(section, isAdminView, canBrowseCreators, publishingRequired);
  const creatorsStages = getStagesList(
    CREATORS,
    isAdminView,
    canBrowseCreators,
    publishingRequired
  );

  let defaultOrder = section === CREATORS ? 'ACTIVITY' : 'RATING';

  const [stages, setStages] = useState(defaultStages);
  const [textQuery, setTextQuery] = useState('');
  const [shortlisted, setShortlisted] = useState(null);
  const [withPreviousCollaborations, setWithPreviousCollaborations] = useState(null);
  const [orderBy, setOrderBy] = useState(defaultOrder);
  const [badges, setBadges] = useState([]);
  const [screeningQuestionIds, setScreeningQuestionIds] = useState(undefined);
  const [invitedByOrganizationMember, setInvitedByOrganizationMember] = useState(null);
  const [outreach, setOutreach] = useState(null);
  const [draftDueIntervals, setDraftDueIntervals] = useState([]);
  const [publicationDueIntervals, setPublicationDueIntervals] = useState([]);

  const tracking = useTracking();

  useEffect(() => {
    const analyticData = {
      category: 'pageview',
    };
    if (section === CREATORS) {
      analyticData.id = '55';
      analyticData.name = 'campaign_hired_list';
    } else if (section === AWAITING_LIST) {
      analyticData.id = '107';
      analyticData.name = 'campaign_waiting_list';
    } else if (section === APPLICANTS) {
      analyticData.id = '446';
      analyticData.name = 'applicants_list';
    } else if (section === COMPLETED) {
      analyticData.id = '461';
      analyticData.name = 'campaign_completed_projects';
    }
    if (analyticData.id) {
      amplitude.sendEvent({ ...analyticData, param: { campaign_id: campaignId } });
    }
  }, []);

  useEffect(() => {
    setDefaultStages();
  }, [isAdminView]);

  const setDefaultStages = () => {
    const defaultStages = getStagesList(
      section,
      isAdminView,
      canBrowseCreators,
      publishingRequired
    );
    setStages(defaultStages);
  };

  const handleQueryChange = (textQuery) => {
    setTextQuery(textQuery);
  };

  const handleFilterChange = (data) => {
    const keys = Object.keys(data);
    if (keys.includes('shortlisted')) {
      setShortlisted(data.shortlisted);
    }
    if (keys.includes('outreach')) {
      setOutreach(data.outreach);
    }
    if (keys.includes('invitedByOrganizationMember')) {
      setInvitedByOrganizationMember(data.invitedByOrganizationMember);
    }
    if (keys.includes('screeningQuestionIds')) {
      setScreeningQuestionIds(data.screeningQuestionIds);
    }
    if (keys.includes('withPreviousCollaborations')) {
      setWithPreviousCollaborations(data.withPreviousCollaborations);
    } else if (keys.includes('stages')) {
      if (data.stages.length === 0) {
        setDefaultStages();
      } else {
        setStages(data.stages);
      }
    }
    if (keys.includes('badges')) {
      setBadges(data.badges);
    }
    if (keys.includes('draftDueIntervals')) {
      setDraftDueIntervals(data.draftDueIntervals);
      setPublicationDueIntervals(data.publicationDueIntervals);
    }
  };

  const handleSortTypeChange = (sortType) => {
    tracking.trackEvent({
      element: 'sort',
      event: 'selected',
      payload: {
        value: sortType,
      },
    });
    setOrderBy(sortType);
  };

  const projectStage = stages.length > 2 ? null : stages[0];

  const archived = section === ARCHIVED ? true : false;

  return (
    <Suspense fallback={<Spinner className={styles.preloader} />}>
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.filtersContainer}>
            <Suspense fallback={null}>
              <ProjectsFilters
                shortlisted={shortlisted}
                badges={badges}
                invitedByOrganizationMember={invitedByOrganizationMember}
                outreach={outreach}
                currency={currency}
                campaignId={campaignId}
                archived={archived}
                withPreviousCollaborations={withPreviousCollaborations}
                defaultStages={defaultStages}
                projectStage={projectStage}
                screeningQuestionIds={screeningQuestionIds}
                isLongTermCampaign={isLongTermCampaign}
                publishingRequired={publishingRequired}
                canFilterCreatorsByBadge={canFilterCreatorsByBadge}
                onFilterChange={handleFilterChange}
                section={section}
                isAdminView={isAdminView}
              />
            </Suspense>
            <div className={styles.filters}>
              <Search onChange={handleQueryChange} />
              <OrderFilter
                defaultOrder={defaultOrder}
                isAdminView={isAdminView}
                campaignId={campaignId}
                platform={campaignPlatform}
                currentOrder={orderBy}
                onOrderChange={handleSortTypeChange}
              />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <CreatorsList
              isBulkAction={section === 'applicants'}
              campaignId={campaignId}
              isAdminView={isAdminView}
              creatorsStages={creatorsStages}
              defaultStages={defaultStages}
              shortlisted={shortlisted}
              withPreviousCollaborations={withPreviousCollaborations}
              orderBy={orderBy}
              stages={stages}
              badges={badges}
              textQuery={textQuery}
              archived={archived}
              section={section}
              isLongTermCampaign={isLongTermCampaign}
              portfolioTypeName={preferredContentType}
              portfolioCategoryName={preferredCreatorCategory}
              email={email}
              invitedByOrganizationMember={invitedByOrganizationMember}
              outreach={outreach}
              organizationId={organizationId}
              publicationDeadlineIntervals={publicationDueIntervals}
              draftDeadlineIntervals={draftDueIntervals}
              screeningQuestionIds={screeningQuestionIds}
            />
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default track(
  {
    page: 'creators',
  },
  { dispatchOnMount: true }
)(Creators);
