/**
 * @generated SignedSource<<5edde966b0d04d85c17897232beeafe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Permission = "ADMIN__MANAGE_ALL_CAMPAIGNS" | "ADMIN__MANAGE_ALL_ORGANIZATIONS" | "ADMIN__MANAGE_ALL_PROJECTS" | "APPROVAL_TOOL__VIEW_APPROVAL_TOOL_URL" | "CREATOR_PROFILES__EDIT" | "CREATOR_PROFILES__MODERATE" | "CREATOR_PROFILES__VIEW_CAMPAIGNS_HISTORY" | "LONGLISTS__COMMENTS_CREATED_HIDDEN_BY_DEFAULT" | "LONGLISTS__CREATE_CAMPAIGNS" | "LONGLISTS__EXPORT_LONGLISTS" | "LONGLISTS__LIST_CAMPAIGNS" | "LONGLISTS__RATE_CREATORS" | "LONGLISTS__UPDATE_LONGLISTS" | "LONGLISTS__VIEW_ACTIVE_AUDIENCE" | "LONGLISTS__VIEW_HIDDEN_COMMENTS" | "LONGLISTS__VIEW_LONGLISTS" | "LONGLISTS__VIEW_PRICES" | "PAYMENT__VIEW_TRANSACTIONS" | "REVENUE_TRANSACTIONS__MANAGE" | "%future added value";
export type CustomListQuery$variables = {
  id: string;
  textQuery: string;
};
export type CustomListQuery$data = {
  readonly currentUser: {
    readonly admin: boolean;
    readonly organization: {
      readonly churned: boolean;
      readonly id: string;
      readonly newClient: boolean;
      readonly subscription: {
        readonly paused: boolean;
      } | null | undefined;
    } | null | undefined;
    readonly permissions: ReadonlyArray<Permission>;
  } | null | undefined;
  readonly customList: {
    readonly name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CustomListCreators_customList">;
  } | null | undefined;
};
export type CustomListQuery = {
  response: CustomListQuery$data;
  variables: CustomListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "textQuery"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newClient",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "churned",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paused",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "kind": "Variable",
  "name": "textQuery",
  "variableName": "textQuery"
},
v10 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 18
  },
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v13 = [
  (v8/*: any*/),
  (v3/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "followedByCount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engagementRate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "averageViews",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "CustomList",
        "kind": "LinkedField",
        "name": "customList",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "args": [
              (v9/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "CustomListCreators_customList"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertiserSubscription",
                "kind": "LinkedField",
                "name": "subscription",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "CustomList",
        "kind": "LinkedField",
        "name": "customList",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "CreatorConnection",
            "kind": "LinkedField",
            "name": "creators",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreatorEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isCreator"
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RatingVoteConnection",
                        "kind": "LinkedField",
                        "name": "ratingVotes",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreatorOwnership",
                        "kind": "LinkedField",
                        "name": "ownership",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "owner",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CreatorRating",
                                "kind": "LinkedField",
                                "name": "rating",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "averageScore",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "voteCount",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreatorProfile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Badge",
                            "kind": "LinkedField",
                            "name": "badges",
                            "plural": true,
                            "selections": (v13/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Country",
                            "kind": "LinkedField",
                            "name": "countries",
                            "plural": true,
                            "selections": (v13/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Category",
                            "kind": "LinkedField",
                            "name": "categories",
                            "plural": true,
                            "selections": (v13/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "profilePictureFile",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ImageTransformation",
                                    "kind": "LinkedField",
                                    "name": "transformations",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "collageThumbnailUrl",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "Image",
                                "abstractKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shippingRecipientLastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shippingRecipientFirstName",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InstagramUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "estimatedLikes",
                                "storageKey": null
                              },
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "InstagramCreator",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "YoutubeChannel",
                            "kind": "LinkedField",
                            "name": "channel",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "YoutubeCreator",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TiktokUser",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v15/*: any*/),
                              (v14/*: any*/),
                              (v16/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TiktokUserInsightsData",
                            "kind": "LinkedField",
                            "name": "userInsightsData",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "followersCount",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "TiktokCreator",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "CustomList_creators",
            "kind": "LinkedHandle",
            "name": "creators"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "54e780f850a1a61a5e386934d6becde5",
    "id": null,
    "metadata": {},
    "name": "CustomListQuery",
    "operationKind": "query",
    "text": "query CustomListQuery(\n  $id: ID!\n  $textQuery: String!\n) {\n  currentUser {\n    admin\n    permissions\n    organization {\n      id\n      newClient\n      churned\n      subscription {\n        paused\n        id\n      }\n    }\n    id\n  }\n  customList(id: $id) {\n    name\n    ...CustomListCreators_customList_4o953A\n    id\n  }\n}\n\nfragment CreatorDetails_creator on Creator {\n  __isCreator: __typename\n  id\n  ratingVotes {\n    totalCount\n  }\n  ownership {\n    owner {\n      id\n      rating {\n        averageScore\n        voteCount\n        id\n      }\n    }\n    id\n  }\n  profile {\n    badges {\n      name\n      id\n    }\n    countries {\n      name\n      id\n    }\n    categories {\n      name\n      id\n    }\n    profilePictureFile {\n      __typename\n      url\n      ... on Image {\n        transformations {\n          collageThumbnailUrl\n        }\n      }\n      id\n    }\n    shippingRecipientLastName\n    shippingRecipientFirstName\n    id\n  }\n  type\n  username\n  ... on InstagramCreator {\n    user {\n      estimatedLikes\n      followedByCount\n      engagementRate\n      averageViews\n      id\n    }\n  }\n  ... on YoutubeCreator {\n    channel {\n      id\n      name\n    }\n  }\n  ... on TiktokCreator {\n    user {\n      name\n      engagementRate\n      followedByCount\n      averageViews\n      id\n    }\n    userInsightsData {\n      engagementRate\n      followersCount\n      id\n    }\n  }\n}\n\nfragment CustomListCreatorItem_creator on Creator {\n  __isCreator: __typename\n  id\n  ...CreatorDetails_creator\n}\n\nfragment CustomListCreators_customList_4o953A on CustomList {\n  id\n  name\n  creators(first: 18, textQuery: $textQuery) {\n    edges {\n      node {\n        __typename\n        ...CustomListCreatorItem_creator\n        id\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "059031b677267e16b4304eea9b24923e";

export default node;
