/**
 * @generated SignedSource<<e155f7c1d3b59603ddda84a2e96f5894>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type FileType = "IMAGE" | "OTHER" | "VIDEO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProjectItem_project$data = {
  readonly campaign: {
    readonly id: string;
    readonly name: string | null | undefined;
    readonly organization: {
      readonly id: string;
    } | null | undefined;
  };
  readonly creator: {
    readonly __typename: string;
    readonly id: string;
    readonly profile: {
      readonly profilePictureFile: {
        readonly transformations?: {
          readonly collageThumbnailUrl: string;
        } | null | undefined;
        readonly url: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly type: CreatorType;
    readonly username: string;
  } | null | undefined;
  readonly id: string;
  readonly lastEvent: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly actor: {
          readonly __typename: string;
          readonly id: string;
        } | null | undefined;
        readonly attachments?: {
          readonly edges: ReadonlyArray<{
            readonly cursor: string;
            readonly node: {
              readonly __typename: "MessageAttachment";
              readonly file: {
                readonly __typename: string;
                readonly id: string;
                readonly type: FileType;
              };
              readonly id: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
          readonly pageInfo: {
            readonly endCursor: string | null | undefined;
            readonly hasNextPage: boolean;
          };
        } | null | undefined;
        readonly comment?: string | null | undefined;
        readonly createdAt: any;
        readonly id: string;
        readonly readByContractor: boolean;
        readonly text?: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly unreadCount: number;
  } | null | undefined;
  readonly shortlisted: boolean;
  readonly " $fragmentType": "ProjectItem_project";
};
export type ProjectItem_project$key = {
  readonly " $data"?: ProjectItem_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectItem_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectItem_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "lastEvent",
      "args": [
        {
          "kind": "Literal",
          "name": "last",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "types",
          "value": [
            "MESSAGE",
            "OFFER_ACCEPTANCE",
            "PRICE_CHANGE",
            "PRICE_CHANGE_REQUEST",
            "PRICE_CHANGE_REQUEST_REJECTION",
            "PRICE_CHANGE_REQUEST_ACCEPTANCE",
            "PRODUCT_SHIPMENT_COMPLETION",
            "CONTENT_REVIEW_FAIL",
            "CONTENT_REVIEW_PASS",
            "PUBLICATION_EVENT",
            "PUBLICATION_REQUEST",
            "DEADLINE_CHANGE_REQUEST",
            "EXTRA_PAYOUT",
            "DEADLINE",
            "PUBLICATION_DEADLINE_CHANGE",
            "LAUNCH",
            "ARCHIVATION",
            "DEADLINE_CHANGE_REQUEST_ACCEPTANCE",
            "CONTENT_SUBMISSION",
            "CONTENT_REVIEW_FAIL"
          ]
        }
      ],
      "concreteType": "ProjectEventConnection",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectEventEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "readByContractor",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "text",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MessageAttachmentConnection",
                      "kind": "LinkedField",
                      "name": "attachments",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MessageAttachmentEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "MessageAttachment",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": null,
                                  "kind": "LinkedField",
                                  "name": "file",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v2/*: any*/),
                                    (v0/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v0/*: any*/),
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "cursor",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PageInfo",
                          "kind": "LinkedField",
                          "name": "pageInfo",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "endCursor",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "hasNextPage",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Message",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "comment",
                      "storageKey": null
                    }
                  ],
                  "type": "OfferAcceptance",
                  "abstractKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "actor",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "events(last:1,types:[\"MESSAGE\",\"OFFER_ACCEPTANCE\",\"PRICE_CHANGE\",\"PRICE_CHANGE_REQUEST\",\"PRICE_CHANGE_REQUEST_REJECTION\",\"PRICE_CHANGE_REQUEST_ACCEPTANCE\",\"PRODUCT_SHIPMENT_COMPLETION\",\"CONTENT_REVIEW_FAIL\",\"CONTENT_REVIEW_PASS\",\"PUBLICATION_EVENT\",\"PUBLICATION_REQUEST\",\"DEADLINE_CHANGE_REQUEST\",\"EXTRA_PAYOUT\",\"DEADLINE\",\"PUBLICATION_DEADLINE_CHANGE\",\"LAUNCH\",\"ARCHIVATION\",\"DEADLINE_CHANGE_REQUEST_ACCEPTANCE\",\"CONTENT_SUBMISSION\",\"CONTENT_REVIEW_FAIL\"])"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortlisted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "profilePictureFile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ImageTransformation",
                      "kind": "LinkedField",
                      "name": "transformations",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "collageThumbnailUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Image",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "faa6964b8247a1d0f75bdfe47e746f3e";

export default node;
