import React, { useContext, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { SequenceSelectorForOutreach_list$key } from 'GraphTypes/SequenceSelectorForOutreach_list.graphql';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import classNames from 'classnames';
import { OutreachSettingsTabs } from '../InviteOutreachCreators';

interface Props {
  query: SequenceSelectorForOutreach_list$key;
  setActiveTab: (tab: OutreachSettingsTabs) => void;
}

const SequenceSelector: React.FC<Props> = (props) => {
  const { query, setActiveTab } = props;
  const { sequenceId, setSequenceList, setTemplateChangedStatus, setSequenceId } = useContext(
    InviteOutreachCreatorsContext
  );
  const data = useFragment(
    graphql`
      fragment SequenceSelectorForOutreach_list on Campaign {
        organization {
          outreachSequenceBlueprints {
            edges {
              node {
                id
                name
                steps {
                  edges {
                    node {
                      id
                      startsAtHour
                      delayInDays
                      projectEventType
                      template {
                        id
                        subject
                        htmlBody
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    query
  );

  const templates = data.organization?.outreachSequenceBlueprints?.edges;
  const sequenceName = useMemo(() => {
    return data.organization?.outreachSequenceBlueprints?.edges?.find(
      (sequence) => sequence?.node?.id === sequenceId
    )?.node?.name;
  }, [sequenceId]);

  const handleSequenceClick = () => {
    setActiveTab(OutreachSettingsTabs.SEQUENCE);
  };

  return (
    <>
      <div className={styles.subtitle}>
        <Text type="md" msg="invite_outreach_creators_modal.step_2" />
      </div>
      <Dropdown
        value={
          <Text
            type="md"
            className={classNames(styles.dropdownValue, {
              [styles.placeholder]: !sequenceId,
            })}
            text={sequenceName}
            textPriority
            msg={
              templates?.length === 0
                ? 'invite_outreach_creators_modal.empty_template_lists'
                : 'invite_outreach_creators_modal.template.placeholder'
            }
          />
        }
        className={styles.dropdownSettings}
      >
        <DropdownGroup className={styles.dropdownMenu}>
          {templates?.map((item) => {
            if (!item?.node) return null;

            const { id, name } = item.node;

            const isActive = sequenceId === id;

            const handleTemplateSelect = () => {
              amplitude.sendEvent<411>({
                id: '411',
                category: 'campaign',
                name: 'outreach_sequence_click',
                param: { sequence_name: name },
              });
              const steps = item.node?.steps?.edges?.map((step) => {
                return {
                  ...step?.node,
                  validTemplate: true,
                };
              });
              setSequenceId(id);
              if (steps) {
                setSequenceList(steps);
              }
              setTemplateChangedStatus(false);
            };

            return (
              <DropdownGroupItem key={item.node.id}>
                <AlterButton
                  text={item.node.name}
                  onClick={handleTemplateSelect}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownGroupItem>
            );
          })}
          <AlterButton
            type="white"
            icon="Add-plus"
            msg="invite_outreach_creators_modal.add_sequence"
            classes={{
              buttonText: styles.addListButton,
            }}
            onClick={handleSequenceClick}
          />
        </DropdownGroup>
      </Dropdown>
    </>
  );
};

export default SequenceSelector;
