/**
 * @generated SignedSource<<2ee148de7534cdedaafa89c7671e2452>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type SubscriptionInterval = "BIANNUAL" | "CUSTOM" | "MONTHLY" | "TRIMONTHLY" | "YEARLY" | "%future added value";
export type Stripe_PreviewProrationInput = {
  addonItems?: ReadonlyArray<AddonItemInput> | null | undefined;
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  interval: SubscriptionInterval;
  planId: PlanId;
  promocode?: string | null | undefined;
};
export type AddonItemInput = {
  addonId: AddonId;
  quantity: number;
};
export type PreviewProrationMutation$variables = {
  input: Stripe_PreviewProrationInput;
};
export type PreviewProrationMutation$data = {
  readonly stripe: {
    readonly previewProration: {
      readonly prorationPreview: {
        readonly amountDue: number;
        readonly appliedBalanceCredit: number;
        readonly createdAt: any;
        readonly currency: Currency;
        readonly currentPlanId: PlanId;
        readonly discount: {
          readonly amountOffInCents: number | null | undefined;
          readonly durationInMonths: number | null | undefined;
          readonly percentOff: number | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly interval: SubscriptionInterval;
        readonly lines: ReadonlyArray<{
          readonly amountExcludingTax: number | null | undefined;
          readonly description: string | null | undefined;
          readonly taxAmount: number | null | undefined;
          readonly taxPercentage: number | null | undefined;
          readonly taxState: string | null | undefined;
          readonly taxType: string | null | undefined;
        }>;
        readonly nextInvoiceAmount: number;
        readonly nextInvoiceAt: any;
        readonly planId: PlanId;
        readonly subtotal: number;
        readonly total: number;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type PreviewProrationMutation = {
  response: PreviewProrationMutation$data;
  variables: PreviewProrationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountDue",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextInvoiceAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextInvoiceAmount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Stripe_ProrationPreviewLine",
  "kind": "LinkedField",
  "name": "lines",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountExcludingTax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxType",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentPlanId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appliedBalanceCredit",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtotal",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentOff",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountOffInCents",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationInMonths",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewProrationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Stripe_Mutation",
        "kind": "LinkedField",
        "name": "stripe",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Stripe_PreviewProrationPayload",
            "kind": "LinkedField",
            "name": "previewProration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Stripe_ProrationPreview",
                "kind": "LinkedField",
                "name": "prorationPreview",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Discount",
                    "kind": "LinkedField",
                    "name": "discount",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreviewProrationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Stripe_Mutation",
        "kind": "LinkedField",
        "name": "stripe",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Stripe_PreviewProrationPayload",
            "kind": "LinkedField",
            "name": "previewProration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Stripe_ProrationPreview",
                "kind": "LinkedField",
                "name": "prorationPreview",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Discount",
                    "kind": "LinkedField",
                    "name": "discount",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd90ffa6454f177ac61972f4f86ca2a7",
    "id": null,
    "metadata": {},
    "name": "PreviewProrationMutation",
    "operationKind": "mutation",
    "text": "mutation PreviewProrationMutation(\n  $input: Stripe_PreviewProrationInput!\n) {\n  stripe {\n    previewProration(input: $input) {\n      prorationPreview {\n        id\n        createdAt\n        currency\n        amountDue\n        nextInvoiceAt\n        nextInvoiceAmount\n        lines {\n          amountExcludingTax\n          description\n          taxAmount\n          taxPercentage\n          taxState\n          taxType\n        }\n        currentPlanId\n        planId\n        interval\n        appliedBalanceCredit\n        subtotal\n        total\n        discount {\n          percentOff\n          amountOffInCents\n          durationInMonths\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "be8ecbb0390ecab3e0ad37f49a628e97";

export default node;
