import { graphql, useMutation } from 'react-relay';

import { BulkCreateMessagesMutation } from 'GraphTypes/BulkCreateMessagesMutation.graphql';
import { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const bulkCreateMessagesMutation = graphql`
  mutation BulkCreateMessagesMutation($input: BulkCreateMessagesInput!) {
    bulkCreateMessages(input: $input) {
      ... on EmptyPayload {
        clientMutationId
        __typename
      }
      ... on NotFoundError {
        __typename
      }
      ... on ValidationError {
        __typename
      }
    }
  }
`;

export const useBulkCreateMessagesMutation: BaseMutation<BulkCreateMessagesMutation> = (config) => {
  const [bulkCreateMessages, loading] = useMutation<BulkCreateMessagesMutation>(
    bulkCreateMessagesMutation
  );
  const updateBulkCreateMessages: BaseMutationExecFn<BulkCreateMessagesMutation> = (execConfig) => {
    bulkCreateMessages({ ...config, ...execConfig });
  };
  return [updateBulkCreateMessages, loading];
};
