import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './DuplicateCampaign.pcss';

import cloneCampaign from 'Mutations/CloneCampaign.Mutation';
import { getCampaignBriefLink } from 'Util/links';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { CloneCampaignMutation$data } from 'GraphTypes/CloneCampaignMutation.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import copyImg from 'Images/modals/copy.png';
import copy2xImg from 'Images/modals/copy_2x.png';

interface Props {
  campaignId?: string;
}

const DuplicateCampaign: React.FC<Props> = (props) => {
  const { campaignId } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCloneDone = (response: CloneCampaignMutation$data) => {
    const newCampaignId = response.cloneCampaign?.campaign.id || '';
    const newPath = getCampaignBriefLink({ campaignId: newCampaignId });
    navigate(`${newPath}?cloned`);
  };

  const handleBtnClick = () => {
    setLoading(true);
    if (campaignId) {
      cloneCampaign({ campaignId }, handleCloneDone);
    }
  };

  const handleClose = () => {
    setLoading(false);
    closeDrawer('duplicate-campaign');
  };

  return (
    <Drawer
      fullView
      rootKey="duplicate-campaign"
      position="center"
      className={styles.drawer}
      needCloseButton={false}
    >
      <div className={styles.content}>
        <div className={styles.closeWrap}>
          <TextButton icon="Close-small" onClick={handleClose} />
        </div>
        <img srcSet={`${copyImg} 1x, ${copy2xImg} 2x`} className={styles.image} />
        <Text
          weight="bold"
          type="d2"
          msg="duplicate_campaign_modal.title"
          className={styles.title}
        />
        <Text color="grey" msg="duplicate_campaign_modal.descr" />
        <div className={styles.buttons}>
          <AlterButton
            type="white"
            bordered
            msg="general.discard"
            loading={loading}
            onClick={handleClose}
          />
          <AlterButton
            type="pink"
            msg="duplicate_campaign_modal.duplicate"
            loading={loading}
            onClick={handleBtnClick}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default DuplicateCampaign;
