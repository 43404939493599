import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Link } from 'react-router-dom';

import styles from './MobileBloggerInfo.pcss';

import { getCreatorData } from 'Util/creatorData';
import { getCampaignCreatorsLink } from 'Util/links';
import { createSum } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import Avatar from 'Atoms/Avatar/Avatar';
import Icon from 'Components/ui/Icon/Icon';
import { BARTER_TYPE, DASHBOARD_ROUTE } from 'Constants/general';
import NewPrice from 'Modal/contractor/NewPrice/NewPrice';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { MobileBloggerInfo_project$key } from 'GraphTypes/MobileBloggerInfo_project.graphql';

interface Props {
  className?: string;
  project: MobileBloggerInfo_project$key;
  isAdvertiser?: boolean;
}

const MobileBloggerInfo = (props: Props) => {
  const { className, project, isAdvertiser } = props;

  const data = useFragment(
    graphql`
      fragment MobileBloggerInfo_project on Project {
        id
        currency
        price
        launch {
          id
        }
        creator {
          id
          username
          profile {
            profilePictureFile {
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
          }
          ... on YoutubeCreator {
            channel {
              name
            }
          }
          type
        }
        campaign {
          id
          paymentType
          brief {
            ... on V2Brief {
              priceCap
            }
          }
        }
      }
    `,
    project
  );

  const { id: projectId, price, currency, campaign, creator, launch } = data;

  const { id, paymentType } = campaign;
  const { username, fullName, avaSrc } = getCreatorData(creator);

  const { openDrawer } = useContext(DrawerContext);

  const handlePriceChange = () => {
    openDrawer(`new-project-price-${projectId}`);
  };

  const isBarter = paymentType === BARTER_TYPE;
  const priceText = isBarter
    ? { msg: 'chat_creator.barter_payment' }
    : { text: createSum(price, currency) };

  const link = isAdvertiser ? getCampaignCreatorsLink({ campaignId: id }) : DASHBOARD_ROUTE;

  return (
    <div className={`${className} ${styles.root}`}>
      <Link to={link} className={styles.back}>
        <Icon name="Arrow-big-left" />
      </Link>
      <Avatar size="40" src={avaSrc} />
      <div className={styles.names}>
        <Text tag="h3" weight="700" text={username} className={styles.username} />
        <Text size="sm" text={fullName} className={styles.fullName} />
      </div>
      <div onClick={launch ? undefined : handlePriceChange}>
        <Text tag="h3" {...priceText} className={styles.price} />
      </div>
      <NewPrice projectId={projectId} currency={currency} priceCap={campaign.brief?.priceCap} />
    </div>
  );
};

export default MobileBloggerInfo;
