import { commitMutation, graphql } from 'react-relay';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  CloneBriefCreativeMutation,
  CloneBriefCreativeMutation$data,
  CloneBriefCreativeInput,
} from 'GraphTypes/CloneBriefCreativeMutation.graphql';

const mutation = graphql`
  mutation CloneBriefCreativeMutation($input: CloneBriefCreativeInput!) {
    cloneBriefCreative(input: $input) {
      brief {
        ... on V2Brief {
          creatives {
            edges {
              node {
                id
                ...CreativeAsset_briefCreative
              }
            }
            totalCount
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

interface DataInput {
  briefId: string;
}

export default (
  data: CloneBriefCreativeInput & DataInput,
  resolve?: (response: CloneBriefCreativeMutation$data) => void,
  reject?: fnType
) => {
  const id = uuid();

  const { briefCreativeId } = data;

  const variables = {
    input: {
      id,
      briefCreativeId,
    },
  };

  commitMutation<CloneBriefCreativeMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};
