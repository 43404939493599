import React, { useMemo, PropsWithChildren, MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames';
import { useIntl, IntlFormatters } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';

import { Color, TextType } from '../types';

import styles from './Text.pcss';

type TextInline = boolean;
type TextSize = number;
type TextValue = string | number | JSX.Element;
type TextClickHandler = MouseEventHandler<HTMLElement>;
type TextLocaleMessage = string;

export type Props = PropsWithChildren<{
  inline?: TextInline;
  type?: TextType;
  size?: TextSize;
  text?: TextValue;
  handleClick?: TextClickHandler;
  msg?: TextLocaleMessage;
  color?: Color;
  weight?: string;
  formatValues?: {
    [key: string]: string | number | JSX.Element | FormatMessage;
  };
  className?: string;
  dataTest?: string;
  textPriority?: boolean;
}>;

const Text: React.FC<Props> = (props) => {
  const {
    type = 'md',
    children,
    handleClick,
    inline = false,
    size,
    msg,
    text,
    color,
    formatValues,
    className,
    dataTest,
    textPriority,
  } = props;

  const intl = useIntl();

  const classNameList = classnames(
    styles.text,
    type ? styles[type] : null,
    color ? styles[color] : null,
    handleClick ? styles.pointer : null,
    className
  );

  const childData = useMemo(() => {
    let result = null;
    if (msg) {
      const localeText = intl.formatMessage(
        { id: msg },
        {
          ...formatValues,
          b: (chunk: ReactNode) => <b>{chunk}</b>,
          s: (chunk: ReactNode) => <s>{chunk}</s>,
        }
      );
      if (localeText) {
        result = localeText;
      }
    } else if (text) {
      result = text;
    } else {
      result = children;
    }

    if (textPriority && text) {
      result = text;
    }

    return result;
  }, [msg, children, text, formatValues]);

  return React.createElement(
    inline ? 'span' : 'div',
    {
      'data-test': dataTest,
      className: classNameList,
      style: { ...(size ? { fontSize: size } : {}) },
      ...(handleClick ? { onClick: handleClick } : {}),
    },
    childData
  );
};

export default Text;

// types

type FormatMessage = Parameters<IntlFormatters['formatMessage']>[1];
