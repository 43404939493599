import React, { useRef, useState, useContext } from 'react';

import styles from './SearchContent.pcss';
import { createParamsData } from './utils';
import SearchForm from './SearchForm/SearchForm';
import SearchResultsContainer from './SearchResults/SearchResultsContainer';

import {
  Currency,
  CampaignPlatform,
  CampaignType,
  MediaplanQuery$data,
} from 'GraphTypes/MediaplanQuery.graphql';
import { INSTAGRAM, TIKTOK, CONTENT } from 'Constants/general';
import { SearchQueryInput } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { CreatorsSearchContext } from 'AdvertiserPage/Mediaplan/SearchSection/CreatorsSearch.Context';

interface Props {
  admin: boolean;
  campaignId: string;
  currency: Currency;
  hasContentCreation: boolean;
  isYoutube: boolean;
  organizationId: string;
  campaignPlatform?: CampaignPlatform;
  campaignType?: CampaignType;
  showUnlockCreatorBanner: () => void;
  searchQuery: NonNullable<MediaplanQuery$data['campaign']>['searchQuery'];
}

const getDefaultParams = (currency: Currency) => {
  return {
    ageRanges: [],
    audienceGenderId: null,
    audienceGenderPercentage: null,
    audienceAgeRangePercentage: null,
    audienceCountryPercentage: null,
    audienceCityPercentage: null,
    audienceInterestPercentage: null,
    textQuery: null,
    cities: [],
    audienceCities: [],
    creatorGenderId: null,
    countries: [],
    creatorCountries: [],
    categoryIds: [],
    creatorLanguages: [],
    creatorTypes: [INSTAGRAM, TIKTOK, CONTENT],
    audienceEthnicity: [],
    creatorEthnicity: [],
    interests: [],
    creatorBirthdateFrom: null,
    creatorBirthdateTo: null,
    followersFrom: null,
    followersTo: null,
    realFollowersFrom: null,
    realFollowersTo: null,
    postPriceFrom: null,
    postPriceTo: null,
    engagementRateFrom: null,
    engagementRateTo: null,
    viewsPerPostTo: null,
    viewsPerPostFrom: null,
    viewsMedianFrom: null,
    viewsMedianTo: null,
    storiesReachFrom: null,
    storiesReachTo: null,
    postsReachFrom: null,
    postsReachTo: null,
    reelsPlaysMedianFrom: null,
    reelsPlaysMedianTo: null,
    reviewedAtFrom: null,
    reviewedAtTo: null,
    campaignsCompletionsCountFrom: null,
    campaignsCompletionsCountTo: null,
    favorite: null,
    contentCreator: null,
    owned: null,
    onlyCreatorsWorkedWith: null,
    active: null,
    paidSocialEnabled: null,
    includeHidden: null,
    acceptsBarter: null,
    excludeBadges: null,
    experienced: null,
    highAverageRating: null,
    reliable: null,
    strictlyByPrimaryCategory: null,
    includeAmazonStorefrontLink: null,
    includeAmazonStorefrontValue: [],
    withTiktokShop: null,
    withTiktokShopValue: [],
    employed: null,
    badges: [],
    creatorBadgeIds: [],
    exclusions: [],
    creatorIdsToExclude: [],
    creatorAnimals: [],
    creatorHairTypes: [],
    creatorBodyTypes: [],
    creatorTagIds: [],
    creatorLanguageIds: [],
    creatorAnimalIds: [],
    creatorHairTypeIds: [],
    currency: 'USD',
  };
};

const parseSearchQuery = (
  query: NonNullable<MediaplanQuery$data['campaign']>['searchQuery']
): SearchQueryInput => {
  const result = {
    ...query,
    creatorAnimalIds: query?.creatorAnimals.map((item) => item.id),
    ageRangeIds: query?.ageRanges.map((item) => item?.id),
    audienceCitiesIds: query?.audienceCities.map((item) => item?.id),
    creatorCountryIds: query?.creatorCountries.map((item) => item?.id),
    audienceEthnicityIds: query?.audienceEthnicities.map((item) => item?.id),
    creatorLanguageIds: query?.creatorLanguages.map((item) => item?.id),
    categoryIds: query?.categories.map((item) => item?.id),
    creatorEthnicityIds: query?.creatorEthnicities.map((item) => item?.id),
    interestIds: query?.interests.map((item) => item?.id),
    creatorBodyTypeIds: query?.creatorBodyTypes.map((item) => item?.id),
    creatorHairTypeIds: query?.creatorHairTypes.map((item) => item?.id),
    creatorTagIds: query?.tags.map((item) => item?.id),
    creatorGenderId: query?.creatorGender?.id,
    audienceGenderId: query?.audienceGender?.id,
    customListIds: query?.customLists?.map((item) => item?.id),
    cityIds: query?.cities?.map((item) => item?.id),
    countryIds: query?.countries?.map((item) => item?.id),
  };
  delete result.creatorAnimals;
  delete result.ageRanges;
  delete result.audienceCities;
  delete result.creatorCountries;
  delete result.creatorGender;
  delete result.audienceEthnicities;
  delete result.creatorLanguages;
  delete result.categories;
  delete result.creatorEthnicities;
  delete result.interests;
  delete result.creatorBodyTypes;
  delete result.creatorHairTypes;
  delete result.audienceGender;
  delete result.tags;
  delete result.cities;
  delete result.customLists;
  delete result.countries;
  return result;
};

const SearchContent: React.FC<Props> = (props) => {
  const {
    admin,
    campaignId,
    currency = 'USD',
    hasContentCreation,
    isYoutube,
    organizationId,
    campaignPlatform,
    campaignType,
    searchQuery,
    showUnlockCreatorBanner,
  } = props;
  const controller = useRef<AbortController>();
  const { state: creatorsSearchState } = useContext(CreatorsSearchContext);

  const creatorIdsForExclusion = creatorsSearchState.creatorIdsForExclusion;

  const defaultParams = {
    creatorTypes: [INSTAGRAM, TIKTOK, CONTENT],
    onlyCreatorsWorkedWith: [],
  };

  const defualtComputedParams = {
    searchQueryInput: {
      ...defaultParams,
      ...parseSearchQuery(searchQuery),
    },
  };

  const [params, updateParams] = useState(defualtComputedParams);

  const handleParamsChange = (params: any, optimize = true) => {
    if (optimize) {
      if (controller.current) {
        controller.current.abort();
      }
      controller.current = new AbortController();
    }
    const data = createParamsData(params, creatorIdsForExclusion);
    updateParams(data);
  };

  return (
    <div>
      <SearchForm
        admin={admin}
        currency={currency}
        isYoutube={isYoutube}
        hasContentCreation={hasContentCreation}
        onParamsChange={handleParamsChange}
        className={styles.searchForm}
        organizationId={organizationId}
        campaignId={campaignId}
        campaignPlatform={campaignPlatform}
        campaignType={campaignType}
        queryParams={searchQuery}
      />
      <SearchResultsContainer
        isAdmin={admin}
        currency={currency}
        organizationId={organizationId}
        campaignId={campaignId}
        searchParams={params}
        hasContentCreation={hasContentCreation}
        controller={controller}
        showUnlockCreatorBanner={showUnlockCreatorBanner}
      />
    </div>
  );
};

export default SearchContent;
