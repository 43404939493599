/**
 * @generated SignedSource<<1eda6d7755f36c197f6a2adf58287ebf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutreachBatchState = "ACTIVE" | "INITIAL" | "%future added value";
export type OutreachDashboardBatchesQuery$variables = {
  campaignId: string;
};
export type OutreachDashboardBatchesQuery$data = {
  readonly campaign: {
    readonly outreachBatches: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly sequenceBlueprint: {
            readonly name: string;
          } | null | undefined;
          readonly state: OutreachBatchState;
          readonly " $fragmentSpreads": FragmentRefs<"OutreachBatch_outreachBatch">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    };
  } | null | undefined;
};
export type OutreachDashboardBatchesQuery = {
  response: OutreachDashboardBatchesQuery$data;
  variables: OutreachDashboardBatchesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "campaignId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "campaignId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutreachDashboardBatchesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachBatchConnection",
            "kind": "LinkedField",
            "name": "outreachBatches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachBatchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OutreachBatch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OutreachSequenceBlueprint",
                        "kind": "LinkedField",
                        "name": "sequenceBlueprint",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OutreachBatch_outreachBatch"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutreachDashboardBatchesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OutreachBatchConnection",
            "kind": "LinkedField",
            "name": "outreachBatches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OutreachBatchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OutreachBatch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OutreachSequenceBlueprint",
                        "kind": "LinkedField",
                        "name": "sequenceBlueprint",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OutreachStepBlueprintConnection",
                            "kind": "LinkedField",
                            "name": "steps",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "transport",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GmailAuthorization",
                        "kind": "LinkedField",
                        "name": "gmailAuthorization",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reauthenticationRequired",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OutreachSequenceConnection",
                        "kind": "LinkedField",
                        "name": "sequences",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "375c9f8b46d58b3302dc7d7c9178038a",
    "id": null,
    "metadata": {},
    "name": "OutreachDashboardBatchesQuery",
    "operationKind": "query",
    "text": "query OutreachDashboardBatchesQuery(\n  $campaignId: ID!\n) {\n  campaign(id: $campaignId) {\n    outreachBatches {\n      edges {\n        node {\n          id\n          state\n          sequenceBlueprint {\n            name\n            id\n          }\n          ...OutreachBatch_outreachBatch\n        }\n      }\n      totalCount\n    }\n    id\n  }\n}\n\nfragment OutreachBatch_outreachBatch on OutreachBatch {\n  id\n  state\n  transport\n  createdAt\n  gmailAuthorization {\n    id\n    reauthenticationRequired\n  }\n  sequences {\n    totalCount\n  }\n  sequenceBlueprint {\n    name\n    steps {\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "25d33e8142616311f2f8e02fe804ed2c";

export default node;
