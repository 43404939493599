import { commitMutation, graphql } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { v4 as uuid } from 'uuid';

import environment from 'Api/Environment';
import {
  UnarchiveProjectInput,
  UnarchiveProjectMutation,
  UnarchiveProjectMutation$data,
  UnarchiveProjectMutation$variables,
} from 'GraphTypes/UnarchiveProjectMutation.graphql';
import { ADVERTISER } from 'Constants/general';

const mutation = graphql`
  mutation UnarchiveProjectMutation(
    $input: UnarchiveProjectInput!
    $stages: [AdvertiserProjectStage!]
    $archivedStages: [AdvertiserProjectStage!]
    $createdByAdmin: Boolean
  ) {
    unarchiveProject(input: $input) {
      project {
        archivation(side: ADVERTISER) {
          id
        }
        archivationRequest {
          id
          acceptance {
            id
          }
          rejection {
            id
          }
        }
        campaign {
          commonEvents: projectEvents(archived: false) {
            unreadCount
          }
          archiveEvents: projectEvents(archived: true) {
            unreadCount
          }
          archived: projects(stages: $archivedStages, archived: true, orderBy: LAST_ADDED) {
            totalCount
          }
          unsentProjects: projects(
            stages: [UNSENT, UNREAD, READ]
            archived: false
            orderBy: LAST_ADDED
            createdByAdmin: $createdByAdmin
          ) {
            summary {
              totalCount
            }
          }
          projects(stages: $stages, archived: false, orderBy: ACTIVITY) {
            totalCount
            edges {
              node {
                price
                currency
                longlistCreator {
                  interactedWith
                }
                creator {
                  username
                  type
                  ... on InstagramCreator {
                    user {
                      fullName
                      estimatedLikes
                      followedByCount
                      engagementRate
                    }
                    businessAccountEnabled
                    paidSocialEnabled
                  }
                  ... on YoutubeCreator {
                    channel {
                      name
                      thumbnailUrl
                      subscribers
                    }
                  }
                  ... on TiktokCreator {
                    tiktokUser: user {
                      name
                      followedByCount
                      engagementRate
                    }
                  }
                  ... on ContentCreator {
                    name
                    id
                  }
                  profile {
                    estimatedPrice
                    currency
                    profilePictureFile {
                      url
                      ... on Image {
                        transformations {
                          collageThumbnailUrl
                        }
                      }
                    }
                  }
                }
              }
            }
            summary {
              totalPrice
            }
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

interface Data {
  campaignId?: string;
  creatorsStages?: UnarchiveProjectMutation$variables['stages'];
  archivedStages?: UnarchiveProjectMutation$variables['archivedStages'];
  isAdmin?: boolean;
  preventUpdate?: boolean;
}

export default (
  data: UnarchiveProjectInput & Data,
  resolve?: (response: UnarchiveProjectMutation$data) => void,
  reject?: fnType
) => {
  const { projectId, campaignId, creatorsStages, archivedStages, isAdmin, preventUpdate } = data;
  const additionalData = isAdmin ? { createdByAdmin: null } : { createdByAdmin: false };

  const id = uuid();
  const variables: UnarchiveProjectMutation$variables = {
    input: {
      id,
      projectId,
      side: ADVERTISER,
    },
    stages: creatorsStages,
    archivedStages,
    ...additionalData,
  };
  commitMutation<UnarchiveProjectMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      if (!campaignId) return;

      const campaign = store.get(campaignId);

      if (!campaign) return;

      const mediaplan = ConnectionHandler.getConnection(campaign, 'Mediaplan_projects');

      if (mediaplan) {
        const edges = campaign
          .getLinkedRecord('projects', { archived: false, orderBy: 'LAST_ADDED' })
          ?.getLinkedRecords('edges');

        if (edges) mediaplan.setLinkedRecords(edges, 'edges');
      }

      const projects = ConnectionHandler.getConnection(campaign, 'Projects_projects');

      if (projects) {
        const unarchivedProject = store.get(projectId);

        if (unarchivedProject && !preventUpdate) {
          const edge = ConnectionHandler.createEdge(
            store,
            projects,
            unarchivedProject,
            'UnarchivedProjectEdge'
          );

          ConnectionHandler.insertEdgeBefore(projects, edge);
        }
      }

      const projectsInChat = ConnectionHandler.getConnection(
        campaign,
        'CampaignProjectsList_projects'
      );

      if (projectsInChat) {
        ConnectionHandler.deleteNode(projectsInChat, projectId);
      }
    },
    updater: (store) => {
      if (!campaignId) return;

      const campaign = store.get(campaignId);

      if (!campaign) return;

      const mediaplan = ConnectionHandler.getConnection(campaign, 'Mediaplan_projects');

      if (mediaplan) {
        const edges = campaign
          .getLinkedRecord('projects', { archived: false, orderBy: 'LAST_ADDED' })
          ?.getLinkedRecords('edges');

        if (edges) mediaplan.setLinkedRecords(edges, 'edges');
      }

      const projects = ConnectionHandler.getConnection(campaign, 'Projects_projects');

      if (projects) {
        ConnectionHandler.deleteNode(projects, projectId);

        const unarchivedProject = store.get(projectId);

        if (unarchivedProject && !preventUpdate) {
          const edge = ConnectionHandler.createEdge(
            store,
            projects,
            unarchivedProject,
            'UnarchivedProjectEdge'
          );

          ConnectionHandler.insertEdgeBefore(projects, edge);
        }
      }

      const projectsInChat = ConnectionHandler.getConnection(
        campaign,
        'CampaignProjectsList_projects'
      );

      if (projectsInChat) {
        ConnectionHandler.deleteNode(projectsInChat, projectId);
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};
