import React from 'react';
import styles from './EditorEmptyPlaceholder.pcss';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';

const EditorEmptyPlaceholder: React.FC = () => {
  return (
    <div className={styles.root}>
      <Icon name="Mail" />
      <Text type="md" msg="invite_outreach_creators_modal.empty_editor" />
    </div>
  );
};

export default EditorEmptyPlaceholder;
