/**
 * @generated SignedSource<<7259fa5682628b960fdccb12b27e81a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BulkCreateMessagesInput = {
  clientMutationId?: string | null | undefined;
  id?: string | null | undefined;
  projectIds: ReadonlyArray<string>;
  text: string;
};
export type BulkCreateMessagesMutation$variables = {
  input: BulkCreateMessagesInput;
};
export type BulkCreateMessagesMutation$data = {
  readonly bulkCreateMessages: {
    readonly __typename: "EmptyPayload";
    readonly clientMutationId: string | null | undefined;
  } | {
    readonly __typename: "NotFoundError";
  } | {
    readonly __typename: "ValidationError";
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type BulkCreateMessagesMutation = {
  response: BulkCreateMessagesMutation$data;
  variables: BulkCreateMessagesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkCreateMessagesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "bulkCreateMessages",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "EmptyPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v4/*: any*/),
            "type": "NotFoundError",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v4/*: any*/),
            "type": "ValidationError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkCreateMessagesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "bulkCreateMessages",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "EmptyPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f03ecb91e7a85c2fbbbcc982bdee6cf",
    "id": null,
    "metadata": {},
    "name": "BulkCreateMessagesMutation",
    "operationKind": "mutation",
    "text": "mutation BulkCreateMessagesMutation(\n  $input: BulkCreateMessagesInput!\n) {\n  bulkCreateMessages(input: $input) {\n    __typename\n    ... on EmptyPayload {\n      clientMutationId\n      __typename\n    }\n    ... on NotFoundError {\n      __typename\n    }\n    ... on ValidationError {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad887e32e3963ce4ef0c85ad9289d7e1";

export default node;
