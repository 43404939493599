import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import type { FiltersData, FilterValuesState } from '../types';
import { FilterCampaignType, FilterPlatformType } from '../types';

import type { PerformanceFiltersQueryType } from './PerformanceFilters.Query';

import type { RadioDropdownItemType } from 'Components/ui/RadioDropdown/RadioDropdown';
import { Performance_Granularity as PerformanceGranularityEnum } from 'Types/enums';
import { CampaignFilterQuery$data } from 'GraphTypes/CampaignFilterQuery.graphql';
import { CheckboxItemType } from 'Components/ui/CheckboxSelect/CheckboxSelect';

type UseFiltersData = (
  response: PerformanceFiltersQueryType['response'],
  organization: CampaignFilterQuery$data['organization'],
  filterValues: FilterValuesState
) => FiltersData;
const useFiltersData: UseFiltersData = (response, organization, filterValues) => {
  const intl = useIntl();

  const brandsEdges = response.organization?.brands?.edges || [];
  const campaignsEdges = organization?.campaigns?.edges || [];

  const result = useMemo<FiltersData>(() => {
    return {
      brand: brandsEdges
        .map<RadioDropdownItemType>((item, index) => ({
          id: `${item?.node?.id}`,
          isChecked: filterValues.brand === item?.node?.id || (!filterValues.brand && index === 0),
          // isChecked: filterValues.brand === item?.node?.id,
          value: `${item?.node?.name}`,
        }))
        .sort((a, b) => (a.value || '').localeCompare(b.value || '')),
      campaigns: campaignsEdges.map<CheckboxItemType>((item) => ({
        id: item?.node?.id || '',
        isChecked: filterValues.campaigns?.includes(item?.node?.id || '') || false,
        label: item?.node?.name || '',
      })),
      platform: [
        {
          id: FilterPlatformType.ALL_PLATFORMS,
          isChecked: filterValues.platform === FilterPlatformType.ALL_PLATFORMS,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.platform.all_platforms',
          }),
        },
        {
          id: FilterPlatformType.INSTAGRAM,
          isChecked: filterValues.platform === FilterPlatformType.INSTAGRAM,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.platform.instagram',
          }),
        },
        {
          id: FilterPlatformType.TIK_TOK,
          isChecked: filterValues.platform === FilterPlatformType.TIK_TOK,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.platform.tiktok',
          }),
        },
        {
          id: FilterPlatformType.FACEBOOK,
          isChecked: filterValues.platform === FilterPlatformType.FACEBOOK,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.platform.facebook',
          }),
        },
        {
          id: FilterPlatformType.OTHER,
          isChecked: filterValues.platform === FilterPlatformType.OTHER,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.platform.other',
          }),
        },
      ],
      granularity: [
        {
          id: PerformanceGranularityEnum.DAILY,
          isChecked: filterValues.granularity === PerformanceGranularityEnum.DAILY,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.granularity.daily',
          }),
        },
        {
          id: PerformanceGranularityEnum.WEEKLY,
          isChecked: filterValues.granularity === PerformanceGranularityEnum.WEEKLY,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.granularity.weekly',
          }),
        },
        {
          id: PerformanceGranularityEnum.MONTHLY,
          isChecked: filterValues.granularity === PerformanceGranularityEnum.MONTHLY,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.granularity.monthly',
          }),
        },
        {
          id: PerformanceGranularityEnum.QUARTERLY,
          isChecked: filterValues.granularity === PerformanceGranularityEnum.QUARTERLY,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.granularity.quarterly',
          }),
        },
      ],
      campaignType: [
        {
          id: FilterCampaignType.ALL_TYPES,
          isChecked: filterValues.campaignType === FilterCampaignType.ALL_TYPES,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.campaign_type.all_types',
          }),
        },
        {
          id: FilterCampaignType.JUST_CONTENT,
          isChecked: filterValues.campaignType === FilterCampaignType.JUST_CONTENT,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.campaign_type.just_content',
          }),
        },
        {
          id: FilterCampaignType.ORGANIC_POSTING,
          isChecked: filterValues.campaignType === FilterCampaignType.ORGANIC_POSTING,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.campaign_type.organic_posting',
          }),
        },
        {
          id: FilterCampaignType.CREATOR_ADS,
          isChecked: filterValues.campaignType === FilterCampaignType.CREATOR_ADS,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.campaign_type.creator_ads',
          }),
        },
        {
          id: FilterCampaignType.PRODUCT_SEEDING,
          isChecked: filterValues.campaignType === FilterCampaignType.PRODUCT_SEEDING,
          value: intl.formatMessage({
            id: 'performance.dashboard.filters.campaign_type.product_seeding',
          }),
        },
      ],
      useCase: [
        {
          id: 'ALL_TYPES',
          isChecked: filterValues.useCase === 'ALL_TYPES',
          value: intl.formatMessage({
            id: 'performance.dashboard.updated_campaign_type.all_types',
          }),
        },
        {
          id: 'USER_GENERATED_CONTENT',
          isChecked: filterValues.useCase === 'USER_GENERATED_CONTENT',
          value: intl.formatMessage({
            id: 'performance.dashboard.updated_campaign_type.ugc',
          }),
        },
        {
          id: 'INFLUENCER_POSTS',
          isChecked: filterValues.useCase === 'INFLUENCER_POSTS',
          value: intl.formatMessage({
            id: 'performance.dashboard.updated_campaign_type.influencer_posts',
          }),
        },
        {
          id: 'AFFILIATE_CAMPAIGN',
          isChecked: filterValues.useCase === 'AFFILIATE_CAMPAIGN',
          value: intl.formatMessage({
            id: 'performance.dashboard.updated_campaign_type.affiliate',
          }),
        },
        {
          id: 'PRODUCT_SEEDING',
          isChecked: filterValues.useCase === 'PRODUCT_SEEDING',
          value: intl.formatMessage({
            id: 'performance.dashboard.updated_campaign_type.product_seeding',
          }),
        },
        {
          id: 'TIKTOK_SHOP',
          isChecked: filterValues.useCase === 'TIKTOK_SHOP',
          value: intl.formatMessage({
            id: 'performance.dashboard.updated_campaign_type.tiktok_shop',
          }),
        },
        {
          id: 'TIKTOK_SPARK_ADS',
          isChecked: filterValues.useCase === 'TIKTOK_SPARK_ADS',
          value: intl.formatMessage({
            id: 'performance.dashboard.updated_campaign_type.tiktok_sparkads',
          }),
        },
        {
          id: 'META_PARTNERSHIP_ADS',
          isChecked: filterValues.useCase === 'META_PARTNERSHIP_ADS',
          value: intl.formatMessage({
            id: 'performance.dashboard.updated_campaign_type.meta_ads',
          }),
        },
      ],
    };
  }, [response, organization, filterValues]);

  return result;
};

export { useFiltersData };
