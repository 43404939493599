import React from 'react';
import classnames from 'classnames';

import styles from './Bar.pcss';

import Text from 'Components/ui/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';

const Bar = (props) => {
  const { className, items } = props;

  const list = items.map((item) => {
    const { id, title, width, style, titleTooltip, disabled } = item;

    if (!title) {
      const itemStyle = width ? { width } : { flexGrow: 1 };

      return <div key={id} style={itemStyle} className={styles.item} />;
    }

    return (
      <div key={id} style={{ ...style, width }} className={styles.item}>
        <Tooltip id={`bar-${id}`} tooltipMsg={titleTooltip} place="bottom" className={styles.title}>
          <Text
            msg={`bar.${title}`}
            className={styles.title}
            color={disabled ? 'grey' : undefined}
          />
        </Tooltip>
      </div>
    );
  });

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.list}>{list}</div>
    </div>
  );
};

export default Bar;
