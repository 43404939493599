import React, { useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import classnames from 'classnames';

import styles from './SearchResult.pcss';
import { createMarks } from './data';
import SearchResultContent from './SearchResultContent/SearchResultContent';

import markCreatorSearchResultAsViewed from 'Mutations/MarkCreatorSearchResultAsViewed.Mutation';
import { getCreatorData } from 'Util/creatorData';
import { formatValue } from 'Util/numberFormatter';
import { createCountriesList } from 'Util/dataFormatter';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Avatar from 'Atoms/Avatar/Avatar';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import RegistrationMark from 'Molecules/RegistrationMark/RegistrationMark';
import BlockedUsername from 'Molecules/BlockedUsername/BlockedUsername';
import CreatorBadge from 'Molecules/CreatorBadge/CreatorBadge';
import { CreatorType } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { SearchResult_result$key } from 'GraphTypes/SearchResult_result.graphql';
import { CreatorsSearchContext } from 'AdvertiserPage/Mediaplan/SearchSection/CreatorsSearch.Context';

interface Props {
  className?: string;
  isAdmin: boolean;
  isBlockedView?: boolean;
  canUsePaidSocial?: boolean | null;
  hasContentCreation: boolean;
  creatorType?: CreatorType | null;
  campaignId: string;
  result: SearchResult_result$key;
  organizationId?: string;
  showUnlockCreatorBanner: () => void;
}

const SearchResult: React.FC<Props> = (props) => {
  const {
    isAdmin,
    className,
    campaignId,
    creatorType,
    result,
    hasContentCreation,
    canUsePaidSocial,
    organizationId,
    isBlockedView,
    showUnlockCreatorBanner,
  } = props;

  const data = useFragment(
    graphql`
      fragment SearchResult_result on CreatorSearchResult {
        currency
        estimatedPrice
        project {
          id
          archivation(side: ADVERTISER) {
            id
          }
          invitedByOrganizationMember
        }
        viewed
        creator {
          id
          type
          ownership {
            owner {
              id
            }
          }
          username
          ... on InstagramCreator {
            user {
              estimatedLikes
              followedByCount
              engagementRate
            }
          }
          ... on YoutubeCreator {
            channel {
              id
              name
              subscribers
              thumbnailUrl
            }
          }
          ... on TiktokCreator {
            tiktokUser: user {
              name
              followedByCount
              engagementRate
              averageLikes
            }
          }
          type
          profile {
            badges {
              id
              name
            }
            profilePictureFile {
              url
              ... on Image {
                transformations {
                  collageThumbnailUrl
                }
              }
            }
            searchable
            countries {
              name
            }
          }
        }
        ...SearchResultContent_result
      }
    `,
    result
  );

  const creator = data?.creator;
  const viewed = data?.viewed;
  const currency = data?.currency;
  const estimatedPrice = data?.estimatedPrice;
  const searchable = data?.creator?.profile?.searchable;
  const countries = data?.creator?.profile?.countries;
  const creatorId = creator?.id;
  const badges = creator?.profile?.badges;
  const project = data?.project;
  const archivation = project?.archivation;
  const invitedByOrganizationMember = project?.invitedByOrganizationMember;

  const tracking = useTracking();

  const { state: creatorsSearchState } = useContext(CreatorsSearchContext);

  const creatorIdsForExclusion = creatorsSearchState.creatorIdsForExclusion;

  const creatorIdsForExclusionList = creatorIdsForExclusion.map((item) => item.creatorId);

  if (!creator || (creatorId && creatorIdsForExclusionList.includes(creatorId))) return null;

  const creatorData = getCreatorData(creator);
  const { username, avatarUrl, link, type: typeValue } = creatorData;

  const statList = createMarks(creator, estimatedPrice, currency);
  const countriesList = createCountriesList(countries);

  const isHidden = !searchable && isAdmin;
  const isRegistered = !!creator.ownership?.owner.id;
  const isAddedToMediaplan = !!(
    project &&
    !archivation &&
    (invitedByOrganizationMember || (!invitedByOrganizationMember && isAdmin))
  );

  const handleGoToLink = () => {
    tracking.trackEvent({
      element: `${typeValue}_link`,
      event: 'clicked',
    });

    if (!viewed && creatorId) {
      markCreatorSearchResultAsViewed({ campaignId, creatorId, organizationId });
    }
  };

  const createPrice = () => {
    const msgAdditional = hasContentCreation ? '_content_creation' : '';
    const price = formatValue({ value: estimatedPrice, currency, format: 'currency' });
    if (estimatedPrice === 0) return null;

    return (
      <div className={styles.price}>
        <Tooltip
          id={`peice_${creatorId}`}
          tooltipMsg={`tooltip.per_post${msgAdditional}`}
          className={styles.priceTooltip}
        >
          <div>
            <Text weight="500" text={`~${price} `} />
            <Text size="sm" color="grayDog" msg="creator.per_creative" />
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <div
      className={classnames(className, styles.root, {
        [styles.addedToMediaplan]: isAddedToMediaplan,
        [styles.viewed]: viewed,
      })}
    >
      <div className={styles.content}>
        <div className={styles.container}>
          <SearchResultContent
            result={data}
            isAdmin={isAdmin}
            creatorType={creatorType}
            campaignId={campaignId}
            isHidden={isHidden}
            canUsePaidSocial={canUsePaidSocial}
            isAddedToMediaplan={isAddedToMediaplan}
            archivation={!!archivation}
            organizationId={organizationId}
          />
        </div>
        {isHidden && <Text msg="general.hidden" className={styles.hiddenState} />}
        <div className={styles.details}>
          {
            <div className={styles.info}>
              <div className={styles.user}>
                <Avatar size={40} src={avatarUrl} className={styles.ava} />
                <div className={styles.names}>
                  <div className={styles.userName}>
                    <div onClick={handleGoToLink} className={styles.userLinkWrap}>
                      {isBlockedView ? (
                        <BlockedUsername
                          creatorId={creatorId}
                          onLockClick={showUnlockCreatorBanner}
                        />
                      ) : (
                        <SmartLink path={link} className={styles.userLink}>
                          <Text
                            weight="500"
                            color="creamCan"
                            className={`${styles.name} ${styles.link}`}
                            text={username}
                          />
                        </SmartLink>
                      )}
                    </div>
                    <div className={styles.badges}>
                      {badges?.map((item) => {
                        if (!item || !item.name) return null;

                        return (
                          <Tooltip
                            id={`badge_${item.name}_${creatorId}`}
                            tooltipMsg={`creator.badge.${item.name.toLowerCase()}.tooltip`}
                            place="top"
                            type="light"
                            tooltipClassName={styles.tooltip}
                          >
                            <CreatorBadge
                              key={item.name}
                              badge={item.name}
                              withText={false}
                              className={styles.badge}
                              iconClassName={styles.badgeIcon}
                            />
                          </Tooltip>
                        );
                      })}
                    </div>
                    {isRegistered && isAdmin && (
                      <div className={styles.registartionMark}>
                        <RegistrationMark size="sm" />
                      </div>
                    )}
                  </div>
                  {countriesList && (
                    <Text size="sm" color="grayDog" text={countriesList} overflow />
                  )}
                </div>
              </div>
              {createPrice()}
            </div>
          }
          {creatorType && (
            <div className={styles.marks}>
              {statList.map((item) => {
                const { title, value, tooltipMsg } = item;

                return (
                  <div key={title} className={styles.mark}>
                    <Tooltip id={title} tooltipMsg={tooltipMsg}>
                      <Text size="sm" color="grayDog" msg={title} />
                      <Text text={value} />
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SearchResultWrap = track({
  subsection: 'search_result',
})(SearchResult);

export default SearchResultWrap;
