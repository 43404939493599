import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';

import styles from './SequenceItem.pcss';

import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { SequenceStep } from '../../InviteOutreachCreatorsContext';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import DelaySelector from './DelaySelector/DelaySelector';
import TimeOptionSelector from './TimeOptionSelector/TimeOptionSelector';

interface Props {
  data: SequenceStep;
  onSelect: (index: number) => void;
  onRemove: (index: number) => void;
  onChangeData: (data: Partial<SequenceStep>) => void;
  isActive: boolean;
  index: number;
  isLastInList: boolean;
  error: boolean;
}

const SequenceItem: React.FC<Props> = (props) => {
  const { data, index, isActive, onSelect, onRemove, isLastInList, error, onChangeData } = props;

  const { openDrawer } = useContext(DrawerContext);

  const handleSelectClick = () => {
    onSelect(index);
  };

  const handleRemoveClick = () => {
    onSelect(index);
    openDrawer('sequence-delete-item');
  };

  const handleDelayValue = (value: number) => {
    onChangeData({ delayInDays: value });
  };

  const handleTimeValue = (value: number) => {
    const newDate = new Date(data.startsAtHour);
    newDate.setHours(value);
    onChangeData({ startsAtHour: newDate });
  };

  const startDate = useMemo(() => {
    if (typeof data.startsAtHour === 'string') {
      return new Date(data.startsAtHour);
    }
    return data.startsAtHour;
  }, [data.startsAtHour]);

  return (
    <>
      <div
        className={classNames(styles.root, { [styles.active]: isActive, [styles.error]: error })}
        onClick={handleSelectClick}
      >
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div className={styles.label}>
              <Icon name="Mail" />
            </div>
            <Text
              type="md"
              className={styles.titleText}
              text={data.template?.subject || 'Subject'}
            />
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.mailInfo}>
            <DelaySelector
              data={data}
              handleDelayValue={handleDelayValue}
              disableEmptyValue={index > 0}
            />
            <TimeOptionSelector data={data} handleTimeValue={handleTimeValue} />
            {!isLastInList ? (
              <AlterButton
                icon="Trash-delele"
                className={styles.removeButton}
                onClick={handleRemoveClick}
                hover={false}
              />
            ) : (
              <div className={styles.deletePlaceholder} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SequenceItem;
