import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './CreatorBirthdate.pcss';

import Text from 'Components/ui/Text/Text';
import { CreatorBirthdate_birthdate$key } from 'GraphTypes/CreatorBirthdate_birthdate.graphql';
import DatePicker from 'Components/ui/DatePicker/DatePicker';

interface Props {
  profile: CreatorBirthdate_birthdate$key;
  onChange: (data: { birthdate: Date | null }) => void;
}

const CreatorBirthdate: React.FC<Props> = (props) => {
  const { profile, onChange } = props;

  const data = useFragment(
    graphql`
      fragment CreatorBirthdate_birthdate on CreatorProfile {
        currency
        birthdate
      }
    `,
    profile
  );
  const birthdate = data?.birthdate;

  const handleBirthDateChange = (date: Date | null) => {
    onChange({ birthdate: date });
  };

  const handleBirthDateReset = () => {
    onChange({ birthdate: null });
  };

  return (
    <div className={styles.root}>
      <Text type="md" msg="creator_profile.birthdate" className={styles.title} />
      <DatePicker
        onResetValue={handleBirthDateReset}
        customInputProps={{
          className: styles.inputWrap,
          inputClassName: styles.input,
        }}
        reactDatePickerProps={{
          selected: birthdate ? new Date(birthdate) : undefined,
          onChange: handleBirthDateChange,
        }}
      />
    </div>
  );
};

export default CreatorBirthdate;
