import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import compact from 'lodash/compact';

import styles from './PaidSocialLicenseModal.pcss';

import environment from 'Api/Environment';
import { getCreatorData } from 'Util/creatorData';
import { createCountriesList } from 'Util/dataFormatter';
import { createDate } from 'Util/dateCreator';
import { instagramLink, creatorFbPageLink } from 'Util/links';
import Text from 'Components/ui/Text/Text';
import Avatar from 'Atoms/Avatar/Avatar';
import Icon from 'Components/ui/Icon/Icon';
import PaidSocialLicenseCampaignList from 'Components/PaidSocialLicenseCampaignList/PaidSocialLicenseCampaignList';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Spinner from 'Atoms/Spinner/Spinner';
import { Color } from 'Components/ui/types';
import { PaidSocialLicenseState } from 'Types/enums';
import { PaidSocialLicenseModalQuery as QueryType } from 'GraphTypes/PaidSocialLicenseModalQuery.graphql';

const PaidSocialLicenseModalQuery = graphql`
  query PaidSocialLicenseModalQuery($id: ID!) {
    paidSocialLicense(id: $id) {
      id
      facebookPageId
      facebookPageName
      createdAt
      expiresAt
      daysLeft
      state
      activeCampaigns: activations(active: true) {
        edges {
          node {
            project {
              id
              campaign {
                id
                brand {
                  logo {
                    ... on Image {
                      transformations {
                        brandLogoUrl
                      }
                    }
                  }
                }
                name
                platform
                stage
              }
            }
          }
        }
        totalCount
      }
      closedCampaigns: activations(active: false) {
        edges {
          node {
            project {
              id
              campaign {
                id
                brand {
                  logo {
                    ... on Image {
                      transformations {
                        brandLogoUrl
                      }
                    }
                  }
                }
                name
                platform
                stage
              }
            }
          }
        }
        totalCount
      }
      creator {
        type
        id
        profile {
          countries {
            name
          }
          profilePictureFile {
            url
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
        }
        username
        ownership {
          owner {
            contractorProfile {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

interface Props {
  id?: string | null;
}

const PaidSocialLicenseModal: React.FC<Props> = (props) => {
  const { id } = props;

  if (!id) return null;

  const createSectionTitle = (msg: string) => {
    return <Text msg={msg} color="grey" type="s" className={styles.sectionTitle} />;
  };

  return (
    <QueryRenderer<QueryType>
      environment={environment}
      query={PaidSocialLicenseModalQuery}
      variables={{ id }}
      render={({ props: queryProps }) => {
        if (!queryProps) return <Spinner className={styles.preloader} />;

        const license = queryProps.paidSocialLicense;

        if (!license) return null;

        const creator = license.creator;
        const { avaSrc, username } = getCreatorData(creator);

        const contractor = creator?.ownership?.owner.contractorProfile;
        const ownerName = compact([contractor?.firstName, contractor?.lastName]).join(' ');

        const countries = creator?.profile?.countries;
        const countriesList = createCountriesList(countries);

        const { facebookPageName, facebookPageId, createdAt, expiresAt, daysLeft, state } = license;

        const startDate = createDate(createdAt);
        const endDate = createDate(expiresAt);
        const datesInfo = startDate && endDate ? compact([startDate, endDate]).join(' - ') : null;

        let stateInfo;

        if (
          state === PaidSocialLicenseState.EXPIRED ||
          state === PaidSocialLicenseState.FAILED_TO_EXPIRE
        ) {
          stateInfo = {
            msg: 'dashboard.advertiser.licenses_section.license_item.state.disconnected',
            color: 'sun' as Color,
          };
        } else if (daysLeft) {
          stateInfo = {
            msg: 'dashboard.advertiser.licenses_section.license_item.days_left',
            formatValues: daysLeft ? { daysLeft } : undefined,
          };
        }

        const { activeCampaigns, closedCampaigns } = license;

        return (
          <div className={styles.root}>
            <Text type="d2" msg="ps_license_modal.title" className={styles.title} />
            <div className={styles.creator}>
              <div className={styles.ava}>
                <Avatar size={64} src={avaSrc} />
              </div>
              <div>
                <Text text={ownerName} type="h1" />
                <Text text={countriesList} type="md" color="grey" />
              </div>
            </div>
            <div>
              {createSectionTitle('ps_license_modal.info')}
              <div className={styles.infoBlock}>
                <div className={styles.iconWrap}>
                  <Icon name="Time-clock" />
                </div>
                {datesInfo && <Text type="md" text={datesInfo} className={styles.dates} />}
                {stateInfo && <Text type="md" {...stateInfo} />}
              </div>
              <div className={styles.infoBlock}>
                <div className={styles.iconWrap}>
                  <Icon name="Platform-instagram" />
                </div>
                <div>
                  <Text type="s" color="grey" msg="ps_license_modal.ig_account" />
                  <SmartLink path={instagramLink({ username })}>
                    <Text type="md" text={`@${username}`} />
                  </SmartLink>
                </div>
              </div>
              <div className={styles.infoBlock}>
                <div className={styles.iconWrap}>
                  <Icon name="Platform-facebook" />
                </div>
                <div>
                  <Text type="s" color="grey" msg="ps_license_modal.fb_page" />
                  <SmartLink path={creatorFbPageLink({ facebookPageId })}>
                    <Text type="md" text={facebookPageName} />
                  </SmartLink>
                </div>
              </div>
            </div>
            {activeCampaigns?.totalCount !== 0 && (
              <div>
                {createSectionTitle('ps_license_modal.active_campaigns')}
                <PaidSocialLicenseCampaignList campaigns={activeCampaigns?.edges} />
              </div>
            )}
            {closedCampaigns?.totalCount !== 0 && (
              <div>
                {createSectionTitle('ps_license_modal.closed_campaigns')}
                <PaidSocialLicenseCampaignList campaigns={closedCampaigns?.edges} />
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default PaidSocialLicenseModal;
