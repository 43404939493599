/**
 * @generated SignedSource<<5f0505431012de1d01e49f0346e2d1e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
export type NewCampaignsPaginationList$variables = {
  brandNames?: ReadonlyArray<string> | null | undefined;
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  onlySubscribed?: boolean | null | undefined;
  stages?: ReadonlyArray<CampaignStage> | null | undefined;
  textQuery?: string | null | undefined;
};
export type NewCampaignsPaginationList$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CampaignsList_campaigns">;
};
export type NewCampaignsPaginationList = {
  response: NewCampaignsPaginationList$data;
  variables: NewCampaignsPaginationList$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandNames"
  },
  {
    "defaultValue": 18,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "onlySubscribed"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stages"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "textQuery"
  }
],
v1 = {
  "kind": "Variable",
  "name": "brandNames",
  "variableName": "brandNames"
},
v2 = {
  "kind": "Variable",
  "name": "onlySubscribed",
  "variableName": "onlySubscribed"
},
v3 = {
  "kind": "Variable",
  "name": "stages",
  "variableName": "stages"
},
v4 = {
  "kind": "Variable",
  "name": "textQuery",
  "variableName": "textQuery"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paused",
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewCampaignsPaginationList",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CampaignsList_campaigns"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewCampaignsPaginationList",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CampaignConnection",
        "kind": "LinkedField",
        "name": "campaigns",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CampaignEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Campaign",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "platform",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postingType",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canDuplicate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "useCase",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "brand",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "logo",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ImageTransformation",
                                "kind": "LinkedField",
                                "name": "transformations",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "brandLogoUrl",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "Image",
                            "abstractKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BriefSubmission",
                    "kind": "LinkedField",
                    "name": "briefSubmission",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BriefSubmissionAcceptance",
                        "kind": "LinkedField",
                        "name": "acceptance",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CampaignCounters",
                    "kind": "LinkedField",
                    "name": "counters",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "applicants",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "launchedProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completedProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalSpend",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "newApplicants",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectsWithUnreadEventsExcludingOfferAcceptances",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unreadProjectEventsExcludingOfferAcceptance",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectsWithSubmittedContent",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CampaignActivation",
                    "kind": "LinkedField",
                    "name": "activation",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deletable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canBeCompleted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CampaignCompletion",
                    "kind": "LinkedField",
                    "name": "completion",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CampaignCompletionRequest",
                    "kind": "LinkedField",
                    "name": "completionRequest",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdvertiserSubscription",
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "planId",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userSubscribed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "brief",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "productDelivery",
                            "storageKey": null
                          }
                        ],
                        "type": "V2Brief",
                        "abstractKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "stages",
          "brandNames",
          "textQuery",
          "onlySubscribed"
        ],
        "handle": "connection",
        "key": "CampaignList_campaigns",
        "kind": "LinkedHandle",
        "name": "campaigns"
      }
    ]
  },
  "params": {
    "cacheID": "80052010f3ca63772243865da37d214c",
    "id": null,
    "metadata": {},
    "name": "NewCampaignsPaginationList",
    "operationKind": "query",
    "text": "query NewCampaignsPaginationList(\n  $brandNames: [String!]\n  $count: Int = 18\n  $cursor: String\n  $onlySubscribed: Boolean\n  $stages: [CampaignStage!]\n  $textQuery: String\n) {\n  ...CampaignsList_campaigns_1D9LDo\n}\n\nfragment CampaignPreviewCounters_campaign on Campaign {\n  id\n  completion {\n    id\n  }\n  counters {\n    launchedProjects\n    completedProjects\n    newApplicants\n    projectsWithUnreadEventsExcludingOfferAcceptances\n    unreadProjectEventsExcludingOfferAcceptance\n    projectsWithSubmittedContent\n  }\n}\n\nfragment Campaign_campaign on Campaign {\n  platform\n  type\n  postingType\n  id\n  name\n  stage\n  createdAt\n  canDuplicate\n  useCase\n  brand {\n    name\n    logo {\n      __typename\n      ... on Image {\n        transformations {\n          brandLogoUrl\n        }\n      }\n      id\n    }\n    id\n  }\n  briefSubmission {\n    acceptance {\n      id\n    }\n    id\n  }\n  counters {\n    applicants\n    launchedProjects\n    completedProjects\n    totalSpend\n  }\n  activation {\n    id\n    paused\n  }\n  deletable\n  canBeCompleted\n  completion {\n    __typename\n    id\n  }\n  completionRequest {\n    __typename\n    id\n  }\n  organization {\n    id\n    subscription {\n      planId\n      paused\n      id\n    }\n  }\n  userSubscribed\n  brief {\n    __typename\n    ... on V2Brief {\n      productDelivery\n    }\n    id\n  }\n  ...CampaignPreviewCounters_campaign\n}\n\nfragment CampaignsList_campaigns_1D9LDo on Query {\n  campaigns(first: $count, after: $cursor, brandNames: $brandNames, stages: $stages, textQuery: $textQuery, onlySubscribed: $onlySubscribed) {\n    edges {\n      node {\n        id\n        ...Campaign_campaign\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7f734a9d7f31e037e64abc95605f258d";

export default node;
