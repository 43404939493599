/**
 * @generated SignedSource<<6c0f4ec2e21d8147f880122fd9e79e40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MediaQuery$variables = {
  url: string;
};
export type MediaQuery$data = {
  readonly instagramOembed: {
    readonly thumbnailUrl: string | null | undefined;
  };
};
export type MediaQuery = {
  response: MediaQuery$data;
  variables: MediaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "url"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "instagramPostUrl",
        "variableName": "url"
      }
    ],
    "concreteType": "InstagramOembed",
    "kind": "LinkedField",
    "name": "instagramOembed",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnailUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MediaQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MediaQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "796c7ffeebdd99ba19d4d3630e450ca3",
    "id": null,
    "metadata": {},
    "name": "MediaQuery",
    "operationKind": "query",
    "text": "query MediaQuery(\n  $url: String!\n) {\n  instagramOembed(instagramPostUrl: $url) {\n    thumbnailUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "390bfcf9070f296481e0943c94d6b0b3";

export default node;
