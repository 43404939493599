import { getCreatorData } from 'Util/creatorData';
import { formatValue } from 'Util/numberFormatter';
import { SearchResult_result, Currency } from 'GraphTypes/SearchResult_result.graphql';
import { YOUTUBE, TIKTOK, INSTAGRAM } from 'Constants/general';

type MarkType = {
  title: string;
  id: 'estimatedLikes' | 'followedByCount' | 'engagementRate' | 'subscribers' | 'cpe';
  format?: string;
  tooltipMsg?: string;
};

export const igMarks: MarkType[] = [
  {
    title: 'filter_mark.followers',
    id: 'followedByCount',
    format: 'shortNumber',
  },
  {
    title: 'filter_mark.estimated_likes',
    id: 'estimatedLikes',
    format: 'shortNumber',
  },
  {
    title: 'filter_mark.er',
    id: 'engagementRate',
    format: 'percent',
    tooltipMsg: 'tooltip.mark_er',
  },
  {
    title: 'filter_mark.cpe',
    id: 'cpe',
    format: 'currency',
    tooltipMsg: 'tooltip.mark_cpe',
  },
];

export const tiktokMarks: MarkType[] = [
  {
    title: 'filter_mark.followers',
    id: 'followedByCount',
    format: 'shortNumber',
  },
  {
    title: 'filter_mark.estimated_likes',
    id: 'estimatedLikes',
    format: 'shortNumber',
  },
  {
    title: 'filter_mark.er',
    id: 'engagementRate',
    format: 'percent',
    tooltipMsg: 'tooltip.mark_er',
  },
];

export const youtubeMarks: MarkType[] = [
  {
    title: 'filter_mark.subscribers',
    id: 'subscribers',
    format: 'shortNumber',
  },
];

export const tooltips = {
  fbAvailable: {
    descr: 'tooltip.fb_ads_available',
    target: 'tooltip.fb',
  },
  baAvailable: {
    descr: 'tooltip.instagram_available',
    target: 'tooltip.instagram',
  },
};

type FinalDataType = {
  estimatedLikes?: number | null;
  followedByCount?: number | null;
  engagementRate?: number | null;
  subscribers?: number | null;
  cpe?: number;
};

export const createMarks = (
  creator: SearchResult_result['creator'] | null,
  estimatedPrice?: number | null,
  currency?: Currency | null
) => {
  const type = creator?.type;

  const creatorData = getCreatorData(creator);

  let finalData: FinalDataType;
  let marks: MarkType[] = [];

  if (type === INSTAGRAM) {
    const { estimatedLikes, followedByCount, engagementRate } = creatorData;

    const cpe = Number(estimatedLikes) && estimatedPrice ? estimatedPrice / estimatedLikes : 0;

    finalData = {
      estimatedLikes,
      followedByCount,
      engagementRate,
      cpe,
    };
    marks = igMarks;
  } else if (type === YOUTUBE) {
    const { subscribers } = creatorData;
    finalData = { subscribers };
    marks = youtubeMarks;
  } else if (type === TIKTOK) {
    const { followedByCount, engagementRate, averageLikes } = creatorData;
    const estimatedLikes = averageLikes;

    finalData = { followedByCount, engagementRate, estimatedLikes };
    marks = tiktokMarks;
  }

  return marks.map((mark) => {
    const { title, id, format, tooltipMsg } = mark;
    const value = finalData[id];
    const formattedValue = Number(value) ? formatValue({ value, format, currency }) : '-';

    return { title, value: formattedValue, tooltipMsg };
  });
};
