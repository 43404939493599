/**
 * @generated SignedSource<<3ea652f132e7c7ca351d335352d2c0b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type CreatorImportProgressRefetchQuery$variables = {
  createdByAdmin?: boolean | null | undefined;
  id: string;
  stages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
};
export type CreatorImportProgressRefetchQuery$data = {
  readonly campaign: {
    readonly creatorImport: {
      readonly " $fragmentSpreads": FragmentRefs<"CreatorImportProgress_creatorImport">;
    } | null | undefined;
    readonly newCount: {
      readonly totalCount: number;
    };
    readonly projects: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly creator: {
            readonly channel?: {
              readonly id: string | null | undefined;
              readonly name: string | null | undefined;
            } | null | undefined;
            readonly id: string;
            readonly profile: {
              readonly currency: Currency | null | undefined;
              readonly estimatedPrice: number | null | undefined;
              readonly profilePictureFile: {
                readonly transformations?: {
                  readonly collageThumbnailUrl: string;
                } | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly type: CreatorType;
            readonly username: string;
          } | null | undefined;
          readonly currency: Currency | null | undefined;
          readonly id: string;
          readonly longlistCreator: {
            readonly interactedWith: boolean;
          } | null | undefined;
          readonly price: number | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    };
    readonly unsentBriefs: {
      readonly totalCount: number;
    };
  } | null | undefined;
};
export type CreatorImportProgressRefetchQuery = {
  response: CreatorImportProgressRefetchQuery$data;
  variables: CreatorImportProgressRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdByAdmin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stages"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interactedWith",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "collageThumbnailUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedPrice",
  "storageKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "YoutubeChannel",
      "kind": "LinkedField",
      "name": "channel",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "YoutubeCreator",
  "abstractKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "kind": "Literal",
  "name": "archived",
  "value": false
},
v18 = {
  "kind": "Variable",
  "name": "createdByAdmin",
  "variableName": "createdByAdmin"
},
v19 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "LAST_ADDED"
},
v20 = [
  (v15/*: any*/)
],
v21 = {
  "alias": "newCount",
  "args": [
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v20/*: any*/),
  "storageKey": null
},
v22 = {
  "alias": "unsentBriefs",
  "args": [
    (v17/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "UNSENT"
      ]
    }
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v20/*: any*/),
  "storageKey": null
},
v23 = [
  (v18/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Variable",
    "name": "stages",
    "variableName": "stages"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorImportProgressRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorImport",
            "kind": "LinkedField",
            "name": "creatorImport",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreatorImportProgress_creatorImport"
              }
            ],
            "storageKey": null
          },
          {
            "alias": "projects",
            "args": null,
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "__Mediaplan_projects_connection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Longlists_Creator",
                        "kind": "LinkedField",
                        "name": "longlistCreator",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "creator",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreatorProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "profilePictureFile",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v11/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v21/*: any*/),
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreatorImportProgressRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorImport",
            "kind": "LinkedField",
            "name": "creatorImport",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completed",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "importedCreators",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCreators",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v23/*: any*/),
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Longlists_Creator",
                        "kind": "LinkedField",
                        "name": "longlistCreator",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "creator",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v4/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreatorProfile",
                            "kind": "LinkedField",
                            "name": "profile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "profilePictureFile",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v10/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              (v11/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v23/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Mediaplan_projects",
            "kind": "LinkedHandle",
            "name": "projects"
          },
          (v21/*: any*/),
          (v22/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0cc7c45c733857f26fae31b353ea3b97",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "campaign",
            "projects"
          ]
        }
      ]
    },
    "name": "CreatorImportProgressRefetchQuery",
    "operationKind": "query",
    "text": "query CreatorImportProgressRefetchQuery(\n  $id: ID!\n  $stages: [AdvertiserProjectStage!]\n  $createdByAdmin: Boolean\n) {\n  campaign(id: $id) {\n    creatorImport {\n      ...CreatorImportProgress_creatorImport\n      id\n    }\n    projects(first: 20, stages: $stages, createdByAdmin: $createdByAdmin) {\n      edges {\n        node {\n          id\n          price\n          currency\n          longlistCreator {\n            interactedWith\n            id\n          }\n          creator {\n            __typename\n            id\n            type\n            username\n            profile {\n              profilePictureFile {\n                __typename\n                ... on Image {\n                  transformations {\n                    collageThumbnailUrl\n                  }\n                }\n                id\n              }\n              id\n              estimatedPrice\n              currency\n            }\n            ... on YoutubeCreator {\n              channel {\n                id\n                name\n              }\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    newCount: projects(archived: false, orderBy: LAST_ADDED, createdByAdmin: $createdByAdmin) {\n      totalCount\n    }\n    unsentBriefs: projects(archived: false, stages: [UNSENT], orderBy: LAST_ADDED, createdByAdmin: $createdByAdmin) {\n      totalCount\n    }\n    id\n  }\n}\n\nfragment CreatorImportProgress_creatorImport on CreatorImport {\n  completed\n  id\n  importedCreators\n  totalCreators\n}\n"
  }
};
})();

(node as any).hash = "4d12f46f767e5e4f93a32b76766de8b0";

export default node;
