/**
 * @generated SignedSource<<cf4154ad2288737f904330e6c9985f8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BriefCreativeContentFormat = "HORIZONTAL_16_9" | "SQUARE" | "STATIC" | "VERTICAL_4_5" | "VERTICAL_9_16" | "%future added value";
export type BriefCreativeContentPreparedness = "FINAL" | "RAW" | "RAW_AND_FINAL" | "%future added value";
export type BriefCreativeContentType = "CUSTOM" | "HOWTO" | "PRODUCT_DEMO" | "PRODUCT_REVIEW" | "TESTIMONIAL" | "UNBOXING" | "%future added value";
export type BriefCreativeMediaType = "PHOTO" | "VIDEO" | "%future added value";
export type ContentPlacement = "BOOSTED_FEED" | "BOOSTED_STORY" | "NONE" | "ORGANIC_FEED" | "ORGANIC_STORY" | "%future added value";
export type ContentType = "BOOMERANG" | "HORIZONTAL_VIDEO" | "IMAGE" | "SQUARE_VIDEO" | "VERTICAL_VIDEO" | "%future added value";
export type V2ContentPlacement = "FEED" | "MIDDLEROLL" | "NONE" | "POSTROLL" | "PREROLL" | "REEL" | "STORY" | "%future added value";
export type UpdateBriefCreativeInput = {
  boostedContent?: boolean | null | undefined;
  clientMutationId?: string | null | undefined;
  contentAmount?: number | null | undefined;
  contentFormat?: BriefCreativeContentFormat | null | undefined;
  contentMusic?: boolean | null | undefined;
  contentMusicDescription?: string | null | undefined;
  contentPlacement?: V2ContentPlacement | null | undefined;
  contentPreparedness?: BriefCreativeContentPreparedness | null | undefined;
  contentType?: ContentType | null | undefined;
  donts?: string | null | undefined;
  dos?: string | null | undefined;
  id: string;
  mediaType?: BriefCreativeMediaType | null | undefined;
  postCaption?: string | null | undefined;
  promotedUntil?: any | null | undefined;
  publishingRequired?: boolean | null | undefined;
  technicalRequirements?: string | null | undefined;
  v2ContentType?: BriefCreativeContentType | null | undefined;
  videoDurationMax?: number | null | undefined;
  videoDurationMin?: number | null | undefined;
};
export type UpdateBriefCreativeMutation$variables = {
  input: UpdateBriefCreativeInput;
};
export type UpdateBriefCreativeMutation$data = {
  readonly updateBriefCreative: {
    readonly brief: {
      readonly creatives?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly contentAmount: number | null | undefined;
            readonly contentFormat: BriefCreativeContentFormat | null | undefined;
            readonly contentMusic: boolean;
            readonly contentMusicDescription: string | null | undefined;
            readonly contentPlacement: ContentPlacement | null | undefined;
            readonly contentPreparedness: BriefCreativeContentPreparedness | null | undefined;
            readonly contentType: ContentType | null | undefined;
            readonly donts: string | null | undefined;
            readonly dos: string | null | undefined;
            readonly draftDate: any | null | undefined;
            readonly mediaType: BriefCreativeMediaType | null | undefined;
            readonly postCaption: string | null | undefined;
            readonly publicationDate: any | null | undefined;
            readonly publishingRequired: boolean | null | undefined;
            readonly references: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            };
            readonly technicalRequirements: string | null | undefined;
            readonly v2ContentPlacement: V2ContentPlacement | null | undefined;
            readonly v2ContentType: BriefCreativeContentType | null | undefined;
            readonly videoDurationMax: number | null | undefined;
            readonly videoDurationMin: number | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      };
      readonly id: string;
    };
  } | null | undefined;
};
export type UpdateBriefCreativeMutation = {
  response: UpdateBriefCreativeMutation$data;
  variables: UpdateBriefCreativeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "draftDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicationDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentAmount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentPlacement",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "v2ContentPlacement",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "v2ContentType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postCaption",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dos",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "donts",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "technicalRequirements",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishingRequired",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentFormat",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoDurationMin",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoDurationMax",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentPreparedness",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentMusic",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentMusicDescription",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentType",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v23 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ReferenceEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Reference",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v22/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v24 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBriefCreativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBriefCreativePayload",
        "kind": "LinkedField",
        "name": "updateBriefCreative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BriefCreativeConnection",
                    "kind": "LinkedField",
                    "name": "creatives",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BriefCreativeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BriefCreative",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              {
                                "alias": "references",
                                "args": null,
                                "concreteType": "ReferenceConnection",
                                "kind": "LinkedField",
                                "name": "__Creative_references_connection",
                                "plural": false,
                                "selections": (v23/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "V2Brief",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBriefCreativeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBriefCreativePayload",
        "kind": "LinkedField",
        "name": "updateBriefCreative",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BriefCreativeConnection",
                    "kind": "LinkedField",
                    "name": "creatives",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BriefCreativeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BriefCreative",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              {
                                "alias": null,
                                "args": (v24/*: any*/),
                                "concreteType": "ReferenceConnection",
                                "kind": "LinkedField",
                                "name": "references",
                                "plural": false,
                                "selections": (v23/*: any*/),
                                "storageKey": "references(first:2147483647)"
                              },
                              {
                                "alias": null,
                                "args": (v24/*: any*/),
                                "filters": null,
                                "handle": "connection",
                                "key": "Creative_references",
                                "kind": "LinkedHandle",
                                "name": "references"
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "V2Brief",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9fb4d629d966b7f5f7558036957f8d12",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": null
        }
      ]
    },
    "name": "UpdateBriefCreativeMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBriefCreativeMutation(\n  $input: UpdateBriefCreativeInput!\n) {\n  updateBriefCreative(input: $input) {\n    brief {\n      __typename\n      id\n      ... on V2Brief {\n        creatives {\n          edges {\n            node {\n              mediaType\n              draftDate\n              publicationDate\n              contentAmount\n              contentPlacement\n              v2ContentPlacement\n              v2ContentType\n              postCaption\n              dos\n              donts\n              technicalRequirements\n              publishingRequired\n              contentFormat\n              videoDurationMin\n              videoDurationMax\n              contentPreparedness\n              contentMusic\n              contentMusicDescription\n              contentType\n              references(first: 2147483647) {\n                edges {\n                  node {\n                    id\n                    __typename\n                  }\n                  cursor\n                }\n                pageInfo {\n                  endCursor\n                  hasNextPage\n                }\n              }\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b2cb47f5519d64f61c6020572739858d";

export default node;
