import React from 'react';

import styles from './MediaCount.css';

import { createShortNumber } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import Icon from 'Components/ui/Icon/Icon';

const MediaCount = (props) => {
  const { className, viewCount, likeCount, commentCount, engagement, reach, impressions } = props;

  const createInfoItem = (value, iconName) => {
    if (!value) return null;

    return (
      <div className={styles.detail}>
        <Icon name={iconName} className={styles.icon} />
        <Text weight="500" text={createShortNumber(value)} />
      </div>
    );
  };

  const createStatItem = (count, msg) => {
    return (
      <div className={styles.statItem}>
        <Text size="sm" color="grayDog" msg={msg} />
        <Text weight="500" text={count} />
      </div>
    );
  };

  return (
    <div className={`${className} ${styles.root}`}>
      <div className={styles.details}>
        {createInfoItem(likeCount, 'Heart')}
        {createInfoItem(commentCount, 'Chat')}
        {viewCount && createInfoItem(viewCount, 'Eye')}
        {reach && reach !== -1 && createStatItem(createShortNumber(reach), 'summary.reach')}
        {impressions &&
          impressions !== -1 &&
          createStatItem(createShortNumber(impressions), 'summary.impressions_short')}
        {engagement && createStatItem(engagement, 'summary.er_short')}
      </div>
    </div>
  );
};

export default MediaCount;
