/**
 * @generated SignedSource<<495d18ba0d3dcb516a2aee1ce1c448fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HiringGoal_brief$data = {
  readonly creatorsCountFrom?: number | null | undefined;
  readonly creatorsCountTo?: number | null | undefined;
  readonly hiringGoal?: number | null | undefined;
  readonly id?: string;
  readonly " $fragmentType": "HiringGoal_brief";
};
export type HiringGoal_brief$key = {
  readonly " $data"?: HiringGoal_brief$data;
  readonly " $fragmentSpreads": FragmentRefs<"HiringGoal_brief">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HiringGoal_brief",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorsCountFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creatorsCountTo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hiringGoal",
          "storageKey": null
        }
      ],
      "type": "V2Brief",
      "abstractKey": null
    }
  ],
  "type": "Brief",
  "abstractKey": "__isBrief"
};

(node as any).hash = "eec8168ca908785ced28a5929872ac18";

export default node;
