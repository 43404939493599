import React, { useContext, useEffect, useMemo } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyLoadQuery, graphql } from 'react-relay';
import classNames from 'classnames';

import styles from './InviteOutreachCreators.pcss';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachCreatorsError,
  InviteOutreachCreatorsSource,
  InviteOutreachTemplateItemData,
  SequenceStep,
} from './InviteOutreachCreatorsContext';
import TemplatePreview from './TemplatePreview/TemplatePreview';
import OutreachSettings from './OutreachSettings/OutreachSettings';
import EditorEmptyPlaceholder from './EditorEmptyPlaceholder/EditorEmptyPlaceholder';
import SequenceSettings from './SequenceSettings/SequenceSettings';
import TemplateEditor from './TemplateEditor/TemplateEditor';
import CreateSequenceDrawer from './CreateSequenceDrawer/CreateSequenceDrawer';

import { OUTREACH_LIST_ROUTE } from 'Constants/general';
import Page from 'Templates/Page/Page';
import { InviteOutreachCreatorsQuery as QueryType } from 'GraphTypes/InviteOutreachCreatorsQuery.graphql';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Text from 'Components/ui/Text/Text';
import { amplitude } from 'Helpers/amplitude';
import { CreateOutreachSequenceBlueprintMutation$data } from 'GraphTypes/CreateOutreachSequenceBlueprintMutation.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { useUpdateOutreachSequenceBlueprintMutation } from 'Api/mutations/UpdateOutreachSequenceBlueprint.Mutation';
import { OutreachStepBlueprintInput } from 'GraphTypes/UpdateOutreachSequenceBlueprintMutation.graphql';

const InviteOutreachCreatorsQuery = graphql`
  query InviteOutreachCreatorsQuery($campaignId: ID!) {
    currentUser {
      organization {
        id
      }
    }
    campaign(id: $campaignId) {
      ...GmailSettingsForOutreach_campaign
      ...SequenceSelectorForOutreach_list
      platform
      sampleOutreachTemplateVariables {
        name
        value
      }
    }
  }
`;

export enum OutreachSettingsTabs {
  EMAIL_SETUP = 'EMAIL_SETUP',
  SEQUENCE = 'SEQUENCE',
}

const InviteOutreachCreators: React.FC = () => {
  const { id: campaignId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { openDrawer } = useContext(DrawerContext);
  const [activeTab, setActiveTab] = React.useState(OutreachSettingsTabs.EMAIL_SETUP);

  const fromDiscovery = location?.state?.fromDiscovery;
  const {
    isFormValid,
    isTemplateChanged,
    processing,
    templateData,
    templatePreview,
    setTemplatePreview,
    setSource,
    setError,
    setPlatform,
    handleImportOutreachBatch,
    sequenceId,
    sequenceList,
    activeStep,
    setSequenceList,
    saveEmailTemplates,
    setSequenceId,
    createOutreachBatchLoading,
    setTemplateChangedStatus,
    isTemplateCreating,
    setCreatingTemplateStatus,
    stepsWithError,
  } = useContext(InviteOutreachCreatorsContext);

  const queryData = useLazyLoadQuery<QueryType>(
    InviteOutreachCreatorsQuery,
    { campaignId },
    {
      fetchPolicy: 'network-only',
    }
  );
  const [updateOutreachSequenceBlueprintMutation] = useUpdateOutreachSequenceBlueprintMutation();
  const platform = queryData.campaign?.platform;
  const organizationId = queryData.currentUser?.organization?.id;
  const sampleOutreachTemplateVariables = queryData.campaign?.sampleOutreachTemplateVariables;

  const templateVars = useMemo(() => {
    return sampleOutreachTemplateVariables?.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as TemplateSpecification;
  }, [sampleOutreachTemplateVariables]);

  useEffect(() => {
    if (platform) {
      setPlatform(platform);
    }
  }, [platform]);

  useEffect(() => {
    if (fromDiscovery) {
      setSource(InviteOutreachCreatorsSource.LISTS);
    }
  }, [fromDiscovery]);

  const handleSendClick = () => {
    amplitude.sendEvent<412>({
      id: '412',
      category: 'campaign',
      name: 'outreach_send_click',
      param: { sequence_id: sequenceId },
    });
    if (sequenceId) {
      if (isTemplateChanged) {
        saveEmailTemplates(sequenceList, (newSteps) => {
          updateSequence(sequenceId, newSteps);
        });
      } else {
        handleImportOutreachBatch(campaignId);
      }
    } else {
      openDrawer('sequence-create');
    }
  };

  const updateSequence = (id: string, steps: OutreachStepBlueprintInput[]) => {
    updateOutreachSequenceBlueprintMutation({
      variables: {
        input: {
          id,
          steps,
        },
      },
      onCompleted: (response) => {
        if (
          response.updateOutreachSequenceBlueprint?.__typename ===
          'UpdateOutreachSequenceBlueprintPayload'
        ) {
          handleImportOutreachBatch(
            campaignId,
            response.updateOutreachSequenceBlueprint.outreachSequenceBlueprint.id
          );
        } else {
          setError(InviteOutreachCreatorsError.GENERAL_ERROR);
        }
      },
      onError: () => {
        setError(InviteOutreachCreatorsError.GENERAL_ERROR);
      },
    });
  };

  const handlePreviewClick = () => {
    setTemplatePreview(templateData as InviteOutreachTemplateItemData);
  };

  const handleBackToBatches = () => {
    const outreachUrl = generatePath(OUTREACH_LIST_ROUTE, { id: campaignId });
    navigate(outreachUrl);
  };

  const handleSelectSequenceTab = () => {
    setActiveTab(OutreachSettingsTabs.SEQUENCE);
  };

  const handleSelectSettingsTab = () => {
    setActiveTab(OutreachSettingsTabs.EMAIL_SETUP);
  };

  const handleCreateSequenceComplete = (response: CreateOutreachSequenceBlueprintMutation$data) => {
    if (
      response.createOutreachSequenceBlueprint?.__typename ===
      'CreateOutreachSequenceBlueprintPayload'
    ) {
      const sequenceData = response.createOutreachSequenceBlueprint.outreachSequenceBlueprint;
      setSequenceId(sequenceData.id);
      if (!isTemplateCreating) {
        handleImportOutreachBatch(campaignId, sequenceData.id);
      }
      setCreatingTemplateStatus(false);
    }
  };

  const handleActiveElementChange = (data: Partial<SequenceStep>, index?: number) => {
    const usedIndex = typeof index === 'number' ? index : activeStep;
    setSequenceList((list) => {
      return list.map((item, idx) => {
        if (idx === usedIndex) {
          return { ...item, ...data };
        }
        return item;
      });
    });
    if (typeof data.validTemplate !== 'boolean') {
      if (!isTemplateChanged) {
        setTemplateChangedStatus(true);
      }
    }
  };

  if (!queryData.campaign) return null;

  return (
    <Page className={styles.root}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.backContainer}>
            <AlterButton type="grey" icon="Arrow-big-left" onClick={handleBackToBatches} />
            <Text type="d2" msg="invite_outreach_creators_modal.title" className={styles.title} />
          </div>
          <div className={styles.backContainer}>
            <AlterButton
              type="grey"
              icon={'Eye'}
              disabled={stepsWithError.length > 0}
              msg="invite_outreach_creators_modal.preview"
              onClick={handlePreviewClick}
            />
            <AlterButton
              type="black"
              icon={'Rocket'}
              loading={createOutreachBatchLoading || processing}
              disabled={!isFormValid}
              msg={
                sequenceId && !isTemplateChanged
                  ? 'invite_outreach_creators_modal.send'
                  : 'invite_outreach_creators_modal.send_save'
              }
              onClick={handleSendClick}
            />
          </div>
        </div>
        <div className={styles.settingsContainer}>
          <div className={styles.settings}>
            <div className={styles.tabContainer}>
              <div
                className={classNames(styles.tab, {
                  [styles.active]: activeTab === OutreachSettingsTabs.EMAIL_SETUP,
                })}
                onClick={handleSelectSettingsTab}
              >
                <Text type="md" msg="invite_outreach_creators_modal.tab.setup" />
              </div>
              <div
                className={classNames(styles.tab, {
                  [styles.active]: activeTab === OutreachSettingsTabs.SEQUENCE,
                })}
                onClick={handleSelectSequenceTab}
              >
                <Text type="md" msg="invite_outreach_creators_modal.tab.sequence" />
              </div>
            </div>
            {activeTab === OutreachSettingsTabs.EMAIL_SETUP && (
              <OutreachSettings setActiveTab={setActiveTab} query={queryData.campaign} />
            )}
            {activeTab === OutreachSettingsTabs.SEQUENCE && (
              <SequenceSettings onChangeData={handleActiveElementChange} />
            )}
          </div>
          <div className={styles.templateEditor}>
            {activeTab === OutreachSettingsTabs.EMAIL_SETUP && <EditorEmptyPlaceholder />}
            {activeTab === OutreachSettingsTabs.SEQUENCE && (
              <div className={styles.template}>
                {sequenceList.map((item, idx) => (
                  <TemplateEditor
                    index={idx}
                    variables={templateVars}
                    isActive={idx === activeStep}
                    key={item.id || `${item.template?.id}_${idx}`}
                    template={sequenceList[idx].template}
                    onChangeData={handleActiveElementChange}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {templatePreview && (
        <TemplatePreview onChangeData={handleActiveElementChange} variables={templateVars} />
      )}
      <CreateSequenceDrawer
        organizationId={organizationId}
        saveEmailTemplates={saveEmailTemplates}
        onCreateCompleted={handleCreateSequenceComplete}
        sequenceSteps={sequenceList}
      />
    </Page>
  );
};

export default InviteOutreachCreators;
