/**
 * @generated SignedSource<<541edb00cd8119fc86afc5f7aeb597ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type SubscriptionInterval = "BIANNUAL" | "CUSTOM" | "MONTHLY" | "TRIMONTHLY" | "YEARLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CurrentSubscriptionInfo_organization$data = {
  readonly id: string;
  readonly name: string;
  readonly paymentAccount: {
    readonly currency: Currency;
  } | null | undefined;
  readonly subscription: {
    readonly canApplyDiscount: boolean;
    readonly cancelAt: any | null | undefined;
    readonly discountOfferAcceptedAt: any | null | undefined;
    readonly id: string;
    readonly interval: SubscriptionInterval | null | undefined;
    readonly lastSchedule: {
      readonly discount: {
        readonly percentOff: number | null | undefined;
      } | null | undefined;
      readonly startDate: any;
      readonly trialEnd: any | null | undefined;
    } | null | undefined;
    readonly pauseEvent: {
      readonly pausedAt: any;
      readonly resumesAt: any;
    } | null | undefined;
    readonly paused: boolean;
    readonly planId: PlanId;
    readonly priceAmount: number | null | undefined;
    readonly renewsAt: any | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CurrentSubscriptionInfo_organization";
};
export type CurrentSubscriptionInfo_organization$key = {
  readonly " $data"?: CurrentSubscriptionInfo_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentSubscriptionInfo_organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentSubscriptionInfo_organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdvertiserSubscription",
      "kind": "LinkedField",
      "name": "subscription",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "planId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "renewsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cancelAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priceAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paused",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canApplyDiscount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discountOfferAcceptedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SubscriptionPauseEvent",
          "kind": "LinkedField",
          "name": "pauseEvent",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pausedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "resumesAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SubscriptionSchedule",
          "kind": "LinkedField",
          "name": "lastSchedule",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Discount",
              "kind": "LinkedField",
              "name": "discount",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percentOff",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trialEnd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Payment_Account",
      "kind": "LinkedField",
      "name": "paymentAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "348987aa65d71396cdd8ee0138cb9519";

export default node;
