import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import flattenDeep from 'lodash/flattenDeep';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import { CollaborationInformation, TiktokShopProductsListQuery } from '../TiktokShopProductsList';

import styles from './ProductList.pcss';

import Text from 'Components/ui/Text/Text';
import {
  TiktokShopProductsListQuery as QueryType,
  TiktokShopProductsListQuery$data,
} from 'GraphTypes/TiktokShopProductsListQuery.graphql';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Input from 'Components/ui/Input/Input';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Image from 'Components/ui/Image/Image';
import { Checkbox } from 'Components/ui/Checkbox';

interface Props {
  campaignId?: string;
  selectedProductId?: string;
  selectedShopId?: string;
  queryReference: PreloadedQuery<QueryType, Record<string, unknown>>;
  handleSelectProduct: (id: string) => void;
  additionalProductInfo: CollaborationInformation;
  setProductInfo: (params: Partial<CollaborationInformation>) => void;
  onSelectedShopIdChange: (id: string) => void;
  createShopAction: () => void;
}

type Shop = NonNullable<
  NonNullable<
    NonNullable<TiktokShopProductsListQuery$data['campaign']>['organization']
  >['tiktokShopAuthorizations']
>[number];

export type TikTokShopItem = NonNullable<Shop['shops']>[number];

type SelecteShopType = {
  id: string;
  name: string;
};

const TiktokShopProductsList: React.FC<Props> = (props) => {
  const {
    queryReference,
    handleSelectProduct,
    additionalProductInfo,
    setProductInfo,
    campaignId,
    selectedShopId,
    selectedProductId,
    onSelectedShopIdChange,
    createShopAction,
  } = props;
  const [selectedShop, setSelectedShop] = useState<SelecteShopType | null>(null);

  const [comissionRate, setComissionRate] = useState(additionalProductInfo.comissionRate);
  const [freeSample, setFreeSampleFlag] = useState(additionalProductInfo.hasFreeSample);
  const [sampleWithoutApprove, setSampleWithoutApproveFlag] = useState(
    additionalProductInfo.isSampleApprovalExempt
  );
  const queryData = usePreloadedQuery<QueryType>(TiktokShopProductsListQuery, queryReference);
  const tiktokShopAuthorization = queryData.campaign?.organization?.tiktokShopAuthorizations;

  const shopList = useMemo(() => {
    const tempList: TikTokShopItem[] = [];
    tiktokShopAuthorization?.forEach((auth) => {
      if (auth.shops) {
        tempList.push(auth.shops as unknown as TikTokShopItem);
      }
    });
    return flattenDeep(tempList);
  }, [tiktokShopAuthorization]);

  const products = useMemo(() => {
    if (selectedShop) {
      const shopData = shopList.find(
        (item: typeof shopList[number]) => item.id === selectedShop.id
      );
      return shopData?.products;
    }
    return [];
  }, [selectedShop]);

  const handleChangeComissionText = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2) {
      setComissionRate((rate) => rate);
      return;
    }
    setComissionRate(Number(e.target.value));
  };

  useEffect(() => {
    setProductInfo({
      comissionRate,
    });
  }, [comissionRate]);

  useEffect(() => {
    setProductInfo({
      hasFreeSample: freeSample,
    });
  }, [freeSample]);

  useEffect(() => {
    setProductInfo({
      isSampleApprovalExempt: sampleWithoutApprove,
    });
  }, [sampleWithoutApprove]);

  const handleChangeFreeSampleFlag = (e: ChangeEvent<HTMLInputElement>) => {
    const isSampleEnabled = Boolean(e.target.value);
    setFreeSampleFlag(isSampleEnabled);
    if (!isSampleEnabled) {
      setSampleWithoutApproveFlag(false);
    }
  };

  const handleChangeSampleWithoutApproveFlag = (e: ChangeEvent<HTMLInputElement>) => {
    setSampleWithoutApproveFlag(Boolean(e.target.value));
  };

  return (
    <div className={styles.root}>
      <Dropdown
        closeBySelect
        bordered
        showArrow={false}
        className={styles.dropdownContainer}
        value={
          <Text type="md" text={selectedShop?.name || 'Select shop'} className={styles.label} />
        }
      >
        <DropdownGroup className={styles.menuGroup}>
          <div className={styles.listContainer}>
            {shopList.length === 0 ? (
              <DropdownGroupItem>
                <AlterButton
                  icon={'Add-plus'}
                  msg="tiktok_shop_products_modal.product_list.add_button"
                  onClick={createShopAction}
                />
              </DropdownGroupItem>
            ) : (
              shopList.map((shop) => {
                return (
                  <DropdownGroupItem
                    key={shop.id}
                    handleClick={() => {
                      if (!shop) return;
                      setSelectedShop({ id: shop.id, name: shop.name });
                      if (shop.id) {
                        onSelectedShopIdChange(shop.id);
                      }
                    }}
                  >
                    <AlterButton
                      text={shop.name || '-'}
                      fluid
                      iconPosition={'right'}
                      icon={shop.id === selectedShopId ? 'Check' : null}
                    />
                  </DropdownGroupItem>
                );
              })
            )}
          </div>
        </DropdownGroup>
      </Dropdown>
      {selectedShop && (
        <>
          <Text
            type="md"
            className={styles.productListTitle}
            msg="tiktok_shop_products_modal.product_list.title"
          />
          <div className={styles.productsContainer}>
            {products?.map((product) => {
              return (
                <div
                  key={product.id}
                  onClick={() => {
                    handleSelectProduct(product.id);
                  }}
                  className={styles.productItem}
                >
                  <div className={styles.productTitle}>
                    <div className={styles.productLogo}>
                      <Image src={product.mainImages?.[0].urls[0]} size={48} />
                    </div>
                    <div className={styles.productTitle}>
                      <Text type="md" text={product.title} />
                    </div>
                  </div>
                  {selectedProductId === product.id && (
                    <>
                      <Input
                        pattern="\d{1,2}"
                        labelMsg="tiktok_shop_products_modal.product_list.comission.label"
                        placeholderMsg="tiktok_shop_products_modal.product_list.comission.placeholder"
                        value={comissionRate || ''}
                        onChange={handleChangeComissionText}
                        rightSymbol={'%'}
                        maxLength={2}
                        hideCloseIcon
                        borderless
                        className={styles.comissionInput}
                      />
                      {!campaignId && (
                        <>
                          <div className={styles.productTitle}>
                            <Checkbox
                              disableRootPadding
                              checked={freeSample}
                              onChange={handleChangeFreeSampleFlag}
                            />
                            <Text
                              type="md"
                              msg="tiktok_shop_products_modal.product_list.free_example"
                            />
                          </div>
                          {freeSample && (
                            <div className={styles.productTitle}>
                              <Checkbox
                                disableRootPadding
                                checked={sampleWithoutApprove}
                                onChange={handleChangeSampleWithoutApproveFlag}
                              />
                              <Text
                                type="md"
                                msg="tiktok_shop_products_modal.product_list.sample_exempt"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default TiktokShopProductsList;
