import React from 'react';
import compact from 'lodash/compact';
import remove from 'lodash/remove';
import classnames from 'classnames';

import styles from './CreatorCities.css';

import SelectedOption from 'Molecules/SelectedOption/SelectedOption';
import CitiesFilter from 'Organisms/CitiesFilter/CitiesFilter';
import { ProfileSettings_creator$data } from 'GraphTypes/ProfileSettings_creator.graphql';

interface Props {
  className?: string;
  cities?: NonNullable<ProfileSettings_creator$data['profile']>['cities'] | null;
  onCitiesChange: (cityIds: string[]) => void;
  isSingleSelect?: boolean;
  error?: boolean;
}

const CreatorCities: React.FC<Props> = (props) => {
  const { className, cities = [], onCitiesChange, isSingleSelect, error } = props;

  const selectedCities = compact(cities?.map((item) => item.id));

  const handleCityDelete = (id: string) => {
    const tempList = [...selectedCities];
    remove(tempList, (item) => item === id);
    onCitiesChange(tempList);
  };

  return (
    <div className={classnames(styles.root, className)}>
      <CitiesFilter
        selectedCities={selectedCities}
        onCitiesChange={onCitiesChange}
        isSingleSelect={isSingleSelect}
        error={error}
      />
      {!isSingleSelect && (
        <div className={styles.cities}>
          {cities?.map((item) => {
            return (
              <SelectedOption
                key={item.id}
                id={item.id}
                title={item.name}
                onDelete={handleCityDelete}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CreatorCities;
