import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from 'react-intl';

import styles from './CreatorDetails.pcss';

import { getCreatorData } from 'Util/creatorData';
import { createPercent, createShortNumber } from 'Util/numberFormatter';
import { getBadgeData } from 'Molecules/CreatorBadge/util';
import Tag from 'Components/ui/Tag/Tag';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { CreatorBadgeEnum } from 'Types/enums';
import { CreatorDetails_project$key } from 'GraphTypes/CreatorDetails_project.graphql';
import timerImg from 'Images/icons/timer-return.svg';

interface Props {
  project: CreatorDetails_project$key;
}

const CreatorDetails: React.FC<Props> = (props) => {
  const intl = useIntl();

  const { project } = props;

  const data = useFragment(
    graphql`
      fragment CreatorDetails_project on Project
      @argumentDefinitions(organizationId: { type: "ID!" }) {
        id
        advertiserStage
        previousCollaborator(organizationId: $organizationId)
        creator {
          id
          username
          profile {
            badges {
              name
            }
          }
          ratingVotes(side: AGENCY) {
            totalCount
          }
          ownership {
            owner {
              rating {
                averageScore
              }
            }
          }
          type
          ... on InstagramCreator {
            user {
              followedByCount
              engagementRate
            }
          }
          ... on YoutubeCreator {
            channel {
              subscribers
            }
          }
          ... on TiktokCreator {
            tiktokUser: user {
              followedByCount
              engagementRate
            }
          }
        }
      }
    `,
    project
  );

  const creator = data.creator;
  const previousCollaborator = data.previousCollaborator;
  const advertiserStage = data.advertiserStage;
  const rating = creator?.ownership?.owner.rating;

  const voteCount = creator?.ratingVotes.totalCount || '—';
  const averageScore = rating?.averageScore ? rating.averageScore.toFixed(2) : '—';

  const { followedByCount, engagementRate } = getCreatorData(creator);

  const followers = intl.formatMessage(
    { id: 'creator_card.followers' },
    { count: Number(followedByCount) ? createShortNumber(followedByCount) : '—' }
  );

  const er = intl.formatMessage(
    { id: 'creator_card.er' },
    { count: Number(engagementRate) ? createPercent(engagementRate) : '—' }
  );

  const badges = creator?.profile?.badges;
  const badgesList = badges?.map((item) => {
    const badgeName = item.name;
    const { icon, label, tooltip } = getBadgeData(badgeName as CreatorBadgeEnum);
    return (
      <Tooltip
        place="top"
        id={`badge_${creator?.id}_${badgeName}`}
        tooltipMsg={tooltip}
        key={badgeName}
      >
        <Tag msg={label} image={icon} color="grey" needMargin={false} />
      </Tooltip>
    );
  });

  return (
    <div className={styles.root}>
      <Tooltip place="top" id={`rating_${creator?.id}`} tooltipMsg="creator_card.rating.tooltip">
        <Tag text={`${averageScore} (${voteCount})`} color="grey" icon="Star" needMargin={false} />
      </Tooltip>
      <Tooltip
        place="top"
        id={`followers_${creator?.id}`}
        tooltipMsg="creator_card.followers.tooltip"
      >
        <Tag text={followers} color="grey" needMargin={false} />
      </Tooltip>
      <Tooltip place="top" id={`er_${creator?.id}`} tooltipMsg="creator_card.er.tooltip">
        <Tag text={er} color="grey" needMargin={false} />
      </Tooltip>
      {badgesList}
      {advertiserStage === 'ACCEPTED' && previousCollaborator && (
        <Tag color="grey" needMargin={false} image={timerImg} msg="creator_card.previous_collabs" />
      )}
    </div>
  );
};

export default CreatorDetails;
