/**
 * @generated SignedSource<<e04ee613c467a31cf3953431ef06fa57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignObjective = "APP_INSTALLS" | "BRAND_AWARENESS" | "CATALOG_SALES" | "CONVERSIONS" | "ENGAGEMENT" | "JUST_CONTENT" | "LEAD_GENERATION" | "MESSAGES" | "REACH" | "STORE_TRAFFIC" | "TRAFFIC" | "VIDEO_VIEWS" | "%future added value";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type PaymentType = "BARTER" | "MONEY" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CampaignDetails_campaign$data = {
  readonly brand: {
    readonly id: string;
  } | null | undefined;
  readonly brief: {
    readonly creatives?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
    readonly creatorsCountFrom?: number | null | undefined;
    readonly creatorsCountTo?: number | null | undefined;
    readonly hiringGoal?: number | null | undefined;
    readonly id: string;
    readonly includeReferralFee?: boolean | null | undefined;
    readonly paidSocial?: boolean;
    readonly paidSocialActivationDays?: number | null | undefined;
    readonly paidSocialBca?: boolean;
    readonly priceLimitMax?: number | null | undefined;
    readonly priceLimitMin?: number | null | undefined;
    readonly referralFeeRate?: number | null | undefined;
    readonly sparkAdsActivationDays?: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"HiringGoal_brief" | "PartnershipAdsDetails_brief">;
  } | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly objective: CampaignObjective | null | undefined;
  readonly organization: {
    readonly counters: {
      readonly bcaLicensedCreators: number;
      readonly psLicensedCreators: number;
    } | null | undefined;
    readonly currency: Currency;
    readonly id: string;
    readonly paying: boolean;
    readonly subscription: {
      readonly effectiveLimits: {
        readonly maxLicensedCreators: number | null | undefined;
        readonly maxPaidSocialActivationDays: number | null | undefined;
      };
      readonly planId: PlanId;
    } | null | undefined;
    readonly test: boolean;
    readonly trialMonthPlanAvailable: boolean;
  } | null | undefined;
  readonly paymentType: PaymentType | null | undefined;
  readonly platform: CampaignPlatform | null | undefined;
  readonly postingType: CampaignPostingType | null | undefined;
  readonly type: CampaignType | null | undefined;
  readonly useCase: CampaignUseCase | null | undefined;
  readonly useCasesUnlocked: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"BrandDetails_campaign" | "ComissionRateDetails_campaign" | "UseCase_campaign" | "WlSlotsBanner_campaign">;
  readonly " $fragmentType": "CampaignDetails_campaign";
};
export type CampaignDetails_campaign$key = {
  readonly " $data"?: CampaignDetails_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignDetails_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignDetails_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCasesUnlocked",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objective",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useCase",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Brand",
      "kind": "LinkedField",
      "name": "brand",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "test",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paying",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trialMonthPlanAvailable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationCounters",
          "kind": "LinkedField",
          "name": "counters",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bcaLicensedCreators",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "psLicensedCreators",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdvertiserSubscription",
          "kind": "LinkedField",
          "name": "subscription",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SubscriptionLimits",
              "kind": "LinkedField",
              "name": "effectiveLimits",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxLicensedCreators",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxPaidSocialActivationDays",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WlSlotsBanner_campaign"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PartnershipAdsDetails_brief"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HiringGoal_brief"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BriefCreativeConnection",
              "kind": "LinkedField",
              "name": "creatives",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BriefCreativeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BriefCreative",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "includeReferralFee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "referralFeeRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocial",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialActivationDays",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paidSocialBca",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creatorsCountFrom",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creatorsCountTo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sparkAdsActivationDays",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceLimitMin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceLimitMax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hiringGoal",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BrandDetails_campaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ComissionRateDetails_campaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UseCase_campaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "5059759e620b6d36fa66173982d7747a";

export default node;
